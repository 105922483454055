import { Heading, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { AdminRegistrationsList } from './AdminRegistrationsList';

// import { AdminRegistrationsList } from './AdminRegistrationsList';

type Props = unknown;

/**
 * AdminRegistrations.
 * Dashboard of the admin user.
 *
 * @returns {JSX.Element}
 */
export function AdminRegistrations(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack spacing="6">
      <Heading>{t('Registration', { nb: 2 })}</Heading>
      <AdminRegistrationsList />
    </Stack>
  );
}

export type AdminRegistrationsProps = Props;
