import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  theme,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { IntlSpaceTypes } from '@blockpulse3/data/locales/types';
import { noop } from '@blockpulse3/data/shared';
import { useCreateSpaceMutation } from '@blockpulse3/graphql/hooks';
import {
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';

import { getBrandOptions, getSpaceTypeOptions } from '../utils';
import { schema, spaceFormDefaultValues } from './schema';
import { SpaceForm } from './types';

type Props = Omit<ResponsiveModalProps, 'onClose' | 'children'> & {
  /* ** Space default form values ** */
  defaultValues?: SpaceForm;
  /* ** Callback on form submit success ** */
  onSubmitSuccess?: () => void;
  /* ** Callback on form submit error ** */
  onSubmitError?: () => void;
  /* ** ResponsiveModal `onClose` callback ** */
  onClose: () => void;
};

/**
 * NewSpaceFormModal.
 * Modal form to create a new space.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function NewSpaceFormModal({
  defaultValues = spaceFormDefaultValues,
  onClose,
  onSubmitSuccess = noop,
  onSubmitError = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nSpaceTypes = useTranslations('SpaceTypes');

  const brandOptions = getBrandOptions();
  const spaceTypeOptions = getSpaceTypeOptions();

  const [createSpace] = useCreateSpaceMutation();

  const { register, control, formState, handleSubmit, reset } = useForm<SpaceForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleFormSubmit: SubmitHandler<SpaceForm> = (data) => {
    const { name, type, brand, owner } = data;

    createSpace({
      variables: {
        createSpaceInput: {
          name,
          type: type.value,
          brand: brand.value,
          owner,
        },
      },
      onCompleted: () => {
        onSubmitSuccess();
        handleSpaceFormModalClose();
      },
      onError: () => {
        onSubmitError();
        handleSpaceFormModalClose();
      },
    });
  };

  /* ** Close and reset modal form, avoid unnecessary useEffect ** */
  const handleSpaceFormModalClose = (): void => {
    reset({ ...defaultValues });
    onClose();
  };

  return (
    <ResponsiveModal onClose={handleSpaceFormModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('NewSpace')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody id="prout">
          <form id="add-space" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <FormControl isInvalid={!!formState.errors.name}>
                <FormLabel>{t('Name')}</FormLabel>
                <Input type="text" {...register('name')} />
                <ErrorMessage error={formState.errors.name} />
              </FormControl>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <Controller
                  control={control}
                  name="type"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors.type}>
                      <FormLabel htmlFor="assetType">{t('Type')}</FormLabel>
                      <Select
                        id="type"
                        menuPlacement="bottom"
                        menuPortalTarget={document.body}
                        options={spaceTypeOptions}
                        getOptionLabel={(option): string =>
                          i18nSpaceTypes(option.value as IntlSpaceTypes)
                        }
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: theme.zIndices.modal + 1,
                          }),
                        }}
                        {...field}
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="brand"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors.type}>
                      <FormLabel htmlFor="brand">{t('Brand')}</FormLabel>
                      <Select
                        id="brand"
                        menuPlacement="bottom"
                        menuPortalTarget={document.body}
                        options={brandOptions}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: theme.zIndices.modal + 1,
                          }),
                        }}
                        {...field}
                      />
                    </FormControl>
                  )}
                />
              </Stack>
              <Divider />
              <Stack bg="gray.50" borderRadius="md" p="3" spacing="2">
                <Text fontSize="lg" fontWeight="bold">
                  {t('Owner', { nb: 1 })}
                </Text>
                <Stack spacing="4">
                  <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                    <FormControl isInvalid={!!formState.errors.owner?.firstName}>
                      <FormLabel>{t('FirstName')}</FormLabel>
                      <Input type="text" {...register('owner.firstName')} />
                      <ErrorMessage error={formState.errors.owner?.firstName} />
                    </FormControl>
                    <FormControl isInvalid={!!formState.errors.owner?.lastName}>
                      <FormLabel>{t('LastName')}</FormLabel>
                      <Input type="text" {...register('owner.lastName')} />
                      <ErrorMessage error={formState.errors.owner?.lastName} />
                    </FormControl>
                  </Stack>
                  <FormControl isInvalid={!!formState.errors.owner?.email}>
                    <FormLabel>{t('Email', { nb: 1 })}</FormLabel>
                    <Input type="email" {...register('owner.email')} />
                    <ErrorMessage error={formState.errors.owner?.email} />
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button type="button" variant="secondary" onClick={handleSpaceFormModalClose}>
            {t('Cancel')}
          </Button>
          <Button form="add-space" type="submit">
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type NewSpaceFormModalProps = Props;
