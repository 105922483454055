import {
  Badge,
  Button,
  Card,
  HStack,
  Icon,
  ModalHeader,
  Skeleton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  PencilIcon,
  TemplateIcon,
  UserGroupIcon,
  XCircleIcon,
} from '@heroicons/react/outline';
import {
  SortingState,
  Updater,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, resolvePath, useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IdentityVerificationStatus, routes } from '@blockpulse3/data/shared';
import { Identity, IndividualIdentityListInfosFragment } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import {
  IconButtonWithTooltip,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  IdentityValidationModal,
  PaginationButtons,
  TableContainer,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { PaginationHandlers } from '@blockpulse3/ui/ui-hooks';

import { AdminSpaceManagerModal } from '../AdminSpaces';
import { AdminIndividualsResetKycModal } from './AdminIndividualsResetKycModal';

type Props = PaginationHandlers<IndividualIdentityListInfosFragment> & {
  onRowClick?: (id: IndividualIdentityListInfosFragment['id']) => void;
  sorting: SortingState;
  onSortingChange: (sortFn: Updater<SortingState>) => void;
};

/**
 * AdminIndividualsTable.
 * Display user in a Table
 *
 * @returns {JSX.Element}
 */
export function AdminIndividualsTable({
  results,
  sorting,
  pageInfo,
  pageCount,
  currentPage,
  loading,
  isLoadingMore,
  handleNextPage,
  handlePreviousPage,
  onSortingChange,
  refetch,
}: Props): JSX.Element {
  const t = useTranslations();

  const [identityId, setIdentityId] = useState<string>();
  const [individualIdentityId, setIndividualIdentityId] = useState<string>();
  const resetKycConfirmRef = useRef(null);

  const navigate = useNavigate();

  const identityModal = useDisclosure();
  const kycResetModal = useDisclosure();
  const spaceManagerModal = useDisclosure();

  const { onCopy, value, setValue, hasCopied } = useClipboard('');
  const successToast = useSuccessToast();

  useEffect(() => {
    if (!value) return;

    onCopy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  useEffect(() => {
    if (!hasCopied || !value) return;

    successToast({ title: `Id: ${value} copié` });
    setValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCopied, value]);

  const getColorBadge = useMemo(
    () =>
      (status: IdentityVerificationStatus): { color: string; label: string } => {
        switch (status) {
          case 'APPROVED':
            return { color: 'green', label: t('Approved') };
          case 'PENDING':
          case 'PROCESSING':
            return { color: 'yellow', label: t('InProgress') };
          case 'REFUSED':
            return { color: 'red', label: t('Rejected') };
          default:
            return { color: 'gray', label: t('None') };
        }
      },
    [t],
  );

  const handleCopy = useCallback(
    (id: string): void => {
      setValue(id);
    },
    [setValue],
  );

  const handleRowDocumentsClick = useCallback(
    (individualIdentityId: string): void => {
      navigate(
        resolvePath(
          routes.me.documents.href,
          generatePath(routes.space.individual.full, { individualIdentityId }),
        ).pathname,
      );
    },
    [navigate],
  );

  const handleRowEditClick = useCallback(
    (individualIdentityId: string): void => {
      navigate(
        resolvePath(
          routes.me.settings.href,
          generatePath(routes.space.individual.full, { individualIdentityId }),
        ).pathname,
      );
    },
    [navigate],
  );

  const handleRowManagerClick = useCallback(
    (identityId?: Identity['id']): void => {
      if (!identityId) return;
      setIdentityId(identityId);
      spaceManagerModal.onOpen();
    },
    [setIdentityId, spaceManagerModal],
  );

  const handleRowBadgeClick = useCallback(
    (identityId: string): void => {
      if (!identityId) return;
      setIdentityId(identityId);
      identityModal.onOpen();
    },
    [setIdentityId, identityModal],
  );

  const handleRowKYCResetClick = useCallback(
    (individualIdentityId: string): void => {
      if (!individualIdentityId) return;

      setIndividualIdentityId(individualIdentityId);
      kycResetModal.onOpen();
    },
    [setIndividualIdentityId, kycResetModal],
  );

  const columnHelper = useMemo(() => createColumnHelper<IndividualIdentityListInfosFragment>(), []);
  const columns = useMemo(
    () => [
      columnHelper.accessor('lastName', {
        header: t('Identity', { nb: 1 }),
        enableSorting: true,
        cell: (info) => {
          const identity = info.row.original.identity;
          return (
            <Td>
              <IdentityCard>
                <IdentityAvatar src={identity?.profilePicture} type={identity?.type} />
                <Stack alignItems="flex-start" spacing="1">
                  <IdentityCardTitle
                    fontSize="md"
                    fontWeight="500"
                    isChecked={identity?.isVerified}
                  >
                    <Text fontWeight="600">{identity?.name}</Text>
                  </IdentityCardTitle>
                  <IdentityCardDescription fontSize="md" fontWeight="400">
                    {identity?.email}
                  </IdentityCardDescription>
                </Stack>
              </IdentityCard>
            </Td>
          );
        },
      }),
      columnHelper.accessor('id', {
        header: 'id',
        enableSorting: false,
        cell: (row) => (
          <Td>
            <HStack maxWidth="250px" spacing="1">
              <Text isTruncated color="gray.500" maxW="100px">
                {row.getValue()}
              </Text>
              <Icon
                as={DocumentDuplicateIcon}
                boxSize="20px"
                cursor="pointer"
                onClick={(): void => handleCopy(row.getValue())}
              />
            </HStack>
          </Td>
        ),
      }),
      columnHelper.accessor(() => 'actions', {
        header: 'Actions',
        enableSorting: false,
        cell: (info) => {
          const data = info.row.original;
          return (
            <Td>
              <HStack spacing="1">
                <IconButtonWithTooltip
                  aria-label="edit"
                  icon={<Icon as={PencilIcon} boxSize="18px" color="gray.700" />}
                  isDisabled={!data.user}
                  label={t('EditInformation')}
                  variant="secondary"
                  onClick={(): void => handleRowEditClick(data.id)}
                />
                <IconButtonWithTooltip
                  aria-label="AddDocument"
                  icon={<Icon as={FolderIcon} boxSize="18px" color="gray.700" />}
                  isDisabled={!data.id}
                  label={t('AddDocument')}
                  variant="secondary"
                  onClick={(): void => handleRowDocumentsClick(data.id)}
                />
                <IconButtonWithTooltip
                  aria-label="manager"
                  icon={<Icon as={UserGroupIcon} boxSize="18px" color="gray.700" />}
                  isDisabled={!data.id}
                  label={t('ManageIdentityBySpace')}
                  variant="secondary"
                  onClick={(): void => handleRowManagerClick(data?.identity?.id)}
                />
              </HStack>
            </Td>
          );
        },
      }),
      columnHelper.accessor('kycVerificationStatus', {
        header: t('KYC'),
        enableSorting: true,
        cell: (info) => {
          const individualIdentity = info.row.original;
          const { identity } = individualIdentity;

          const badge = getColorBadge(info.getValue() as IdentityVerificationStatus);
          const cantResetKyc =
            identity?.verificationStatus &&
            [IdentityVerificationStatus.APPROVED, IdentityVerificationStatus.NONE].includes(
              identity.verificationStatus,
            );

          return (
            <Td>
              <HStack>
                <Button
                  variant="unstyled"
                  onClick={(): void => handleRowBadgeClick(identity?.id || '')}
                >
                  <Badge colorScheme={badge.color}>{badge.label}</Badge>
                </Button>
                <IconButtonWithTooltip
                  aria-label="edit"
                  icon={<Icon as={XCircleIcon} boxSize="18px" color="gray.700" />}
                  isDisabled={cantResetKyc}
                  label={t('ResetKYC')}
                  ml="auto"
                  variant="secondary"
                  onClick={(): void => handleRowKYCResetClick(individualIdentity?.id || '')}
                />
              </HStack>
            </Td>
          );
        },
      }),
      columnHelper.accessor('kycOrigin', {
        header: t('KYCOrigin'),
        enableSorting: true,
        cell: (row) => <Td>{row.getValue() || '-'}</Td>,
      }),
      columnHelper.accessor((row) => row.user?.currentBrand || '-', {
        id: 'user.currentBrand',
        header: t('Environment'),
        enableSorting: true,
        cell: (row) => <Td>{row.getValue() || '-'}</Td>,
      }),
      columnHelper.accessor((row) => row.user?.timezone || '-', {
        id: 'user.timezone',
        header: 'Timezone',
        enableSorting: true,
        cell: (row) => <Td>{row.getValue()}</Td>,
      }),
      columnHelper.accessor('createdAt', {
        header: t('CreationDate'),
        enableSorting: true,
        cell: (row) => (
          <Td align="right">{row.getValue() ? formatDate(row.getValue(), 'DD/MM/YYYY') : '-'}</Td>
        ),
      }),
      columnHelper.accessor('updatedAt', {
        header: t('LastModification'),
        enableSorting: true,
        cell: (row) => (
          <Td align="right">{row.getValue() ? formatDate(row.getValue(), 'DD/MM/YYYY') : '-'}</Td>
        ),
      }),
    ],
    [
      t,
      handleCopy,
      columnHelper,
      getColorBadge,
      handleRowEditClick,
      handleRowBadgeClick,
      handleRowManagerClick,
      handleRowDocumentsClick,
      handleRowKYCResetClick,
    ],
  );

  const table = useReactTable({
    data: (results.length ? results : []) as IndividualIdentityListInfosFragment[],
    columns,
    state: { sorting },
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="400px" />
      </Skeleton>
    );
  }

  if (!isLoadingMore && !results.length) {
    return (
      <Stack layerStyle="emptyState">
        <Icon as={TemplateIcon} boxSize="40px" color="gray.500" />
        <Text>{t('NoFilterResult')}</Text>
      </Stack>
    );
  }

  return (
    <Stack spacing="4">
      <TableContainer maxH="none">
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    <HStack justifyContent="space-between" spacing="2">
                      <HStack
                        cursor={header.column.getCanSort() ? 'pointer' : 'initial'}
                        spacing="0"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Text>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </Text>
                        {header.column.getIsSorted() && (
                          <Icon
                            boxSize="16px"
                            as={
                              header.column.getIsSorted() === 'asc'
                                ? ChevronUpIcon
                                : ChevronDownIcon
                            }
                          />
                        )}
                      </HStack>
                    </HStack>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isLoadingMore ? (
              <Tr key="0">
                <Td colSpan={5}>
                  <Stack alignItems="center" h="737px" justifyContent="center">
                    <Spinner />
                  </Stack>
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.original.id}>
                  {row.getVisibleCells().map((cell) =>
                    flexRender(cell.column.columnDef.cell, {
                      ...cell.getContext(),
                      key: cell.id,
                    }),
                  )}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationButtons
        currentPage={currentPage}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        loading={isLoadingMore}
        pageCount={pageCount}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
      />
      {identityId && identityModal.isOpen && (
        <IdentityValidationModal
          identityId={identityId}
          isOpen={identityModal.isOpen}
          isUserAuthorized={true}
          onClose={identityModal.onClose}
        />
      )}
      {identityId && spaceManagerModal.isOpen && (
        <AdminSpaceManagerModal
          identityId={identityId}
          isOpen={spaceManagerModal.isOpen}
          onClose={(): void => spaceManagerModal.onClose()}
        >
          <ModalHeader>{t('ManageCompanyBySpace')}</ModalHeader>
        </AdminSpaceManagerModal>
      )}
      {individualIdentityId && kycResetModal.isOpen && (
        <AdminIndividualsResetKycModal
          individualIdentityId={individualIdentityId}
          isOpen={kycResetModal.isOpen}
          leastDestructiveRef={resetKycConfirmRef}
          refetch={refetch}
          onClose={kycResetModal.onClose}
        />
      )}
    </Stack>
  );
}

export type AdminIndividualsTableProps = Props;
