import { Heading, Stack, useDisclosure } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { LinkButton } from '@blockpulse3/ui/commons';
import {
  CompanyAccess,
  CompanyRepresentatives,
  CompanyUbos,
  ExistingCompanyParameters,
  StepFormContextProvider,
} from '@blockpulse3/web-client/onboardings';

import { SettingsCompanyAccess } from './SettingsCompanyAccess';
import {
  SettingsCompanyAssetPrice,
  SettingsCompanyAssetPriceForm,
} from './SettingsCompanyAssetPrice';
import { SettingsCompanyEditModal } from './SettingsCompanyEditModal';
import { SettingsCompanyInformations } from './SettingsCompanyInformations';
import { SettingsCompanyRepresentatives } from './SettingsCompanyRepresentatives';
import { SettingsCompanyUbos } from './SettingsCompanyUbos';

type Props = {
  backHref?: string;
};

export function WorkspaceSettingsView({ backHref }: Props): JSX.Element {
  const t = useTranslations();

  const { isOpen: isOpenInfos, onOpen: onOpenInfos, onClose: onCloseInfos } = useDisclosure();
  const { isOpen: isOpenReps, onOpen: onOpenReps, onClose: onCloseReps } = useDisclosure();
  const { isOpen: isOpenUbos, onOpen: onOpenUbos, onClose: onCloseUbos } = useDisclosure();
  const { isOpen: isOpenAccess, onOpen: onOpenAccess, onClose: onCloseAccess } = useDisclosure();
  const {
    isOpen: isOpenAssetPrice,
    onOpen: onOpenAssetPrice,
    onClose: onCloseAssetPrice,
  } = useDisclosure();

  return (
    <>
      <Stack spacing="6">
        {backHref && <LinkButton label={t('Back')} route={backHref} />}
        <Heading>{t('CompanySettings')}</Heading>
        <Stack spacing="6">
          <SettingsCompanyInformations onOpen={onOpenInfos} />
          <SettingsCompanyAssetPrice onOpen={onOpenAssetPrice} />
          <SettingsCompanyRepresentatives onOpen={onOpenReps} />
          <SettingsCompanyUbos onOpen={onOpenUbos} />
          <SettingsCompanyAccess onOpen={onOpenAccess} />
        </Stack>
      </Stack>
      {isOpenAssetPrice && (
        <StepFormContextProvider>
          <SettingsCompanyEditModal
            isOpen={isOpenAssetPrice}
            title={t('PricePerShare')}
            onClose={onCloseAssetPrice}
          >
            <SettingsCompanyAssetPriceForm
              onCancel={onCloseAssetPrice}
              onSubmit={onCloseAssetPrice}
            />
          </SettingsCompanyEditModal>
        </StepFormContextProvider>
      )}
      {isOpenInfos && (
        <StepFormContextProvider>
          <SettingsCompanyEditModal
            isOpen={isOpenInfos}
            title={t('MyCompany')}
            onClose={onCloseInfos}
          >
            <ExistingCompanyParameters
              isEditing={true}
              onCancel={onCloseInfos}
              onSubmit={onCloseInfos}
            />
          </SettingsCompanyEditModal>
        </StepFormContextProvider>
      )}
      {isOpenReps && (
        <StepFormContextProvider>
          <SettingsCompanyEditModal
            isOpen={isOpenReps}
            title={t('Representative', { nb: 1 })}
            onClose={onCloseReps}
          >
            <CompanyRepresentatives
              isEditing={true}
              onCancel={onCloseReps}
              onSubmit={onCloseReps}
            />
          </SettingsCompanyEditModal>
        </StepFormContextProvider>
      )}
      {isOpenUbos && (
        <StepFormContextProvider>
          <SettingsCompanyEditModal
            isOpen={isOpenUbos}
            title={t('Owner', { nb: 1 })}
            onClose={onCloseUbos}
          >
            <CompanyUbos isEditing={true} onCancel={onCloseUbos} onSubmit={onCloseUbos} />
          </SettingsCompanyEditModal>
        </StepFormContextProvider>
      )}
      {isOpenAccess && (
        <StepFormContextProvider>
          <SettingsCompanyEditModal
            isOpen={isOpenAccess}
            title={t('AccessSharing')}
            onClose={onCloseAccess}
          >
            <CompanyAccess isEditing={true} onCancel={onCloseAccess} onSubmit={onCloseAccess} />
          </SettingsCompanyEditModal>
        </StepFormContextProvider>
      )}
    </>
  );
}

export type WorkspaceSettingsViewProps = Props;
