import { InfoIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import {
  AssetType,
  CreateOrUpdateAssetDraftInput,
  FundraisingWorkflowType,
  useGetAssetDraftsByOperationQuery,
  useGetAssetLazyQuery,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorMessage } from '@blockpulse3/ui/commons';
import {
  AllowedFiscalAdvantageSelect,
  CreateBondAssetDraftModal,
  IBondAssetDraft,
  OperationAssetOption,
  OperationAssetSelect,
  bondAssetDraftDefaultValues,
  defaultFiscalAdvantageOptions,
  getBondAssetDraftInfos,
  getBondAssetDraftsOptions,
} from '@blockpulse3/web-client/operation/commons';

import { getPricePerShare, getValuation } from '../../utils';
import { crowdfundingSubscriptionPeriodOptions, schemaFundraisingCrowdfunding } from '../schema';
import { ICrowdfundingFundraisingParametersForm } from '../types';

type Props = {
  /* ** On submit loading ** */
  isLoading?: boolean;
  /* ** Optional loading text to render on the submit button ** */
  loadingText?: string;
  /* ** Default values of the form depending on the page (create & parameters) ** */
  defaultValues?: ICrowdfundingFundraisingParametersForm;
  /* ** Callback on submit form ** */
  onSubmit: (data: ICrowdfundingFundraisingParametersForm) => void;
};

/**
 * NewCrowdfundingFundraisingInformations.
 * Crowdfunding fundraising parameters form used in both Create and Update pages.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function NewCrowdfundingFundraisingInformations({
  isLoading = false,
  loadingText = '',
  defaultValues,
  onSubmit = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const bondAssetModal = useDisclosure();

  const [assetDraftInput, setTmpAssetDraftInput] = useState<CreateOrUpdateAssetDraftInput | null>(
    null,
  );
  const [tmpDraftOptions, setTmpDraftOptions] = useState<OperationAssetOption[]>([]);

  const navigate = useNavigate();
  const { companyId = '', operationId = '' } = useParams();

  const companyReq = useGetCompanyQuery({ variables: { companyId }, skip: !companyId });
  const assetDraftsReq = useGetAssetDraftsByOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });
  /* ** Fetch asset on modify click, avoid truth state gap between events ** */
  const [getAsset] = useGetAssetLazyQuery();

  const company = companyReq?.data?.company;
  const draftsBonds = assetDraftsReq.data?.getAssetDraftsByOperation;
  /* ** Previously created bond asset draft ** */
  const draftOptions = getBondAssetDraftsOptions(draftsBonds);

  /* ** Fetched default values ** */
  const [bondAssetDraftDefaults, setBondAssetDraftDefaults] = useState<IBondAssetDraft>(
    bondAssetDraftDefaultValues,
  );

  const { register, control, formState, handleSubmit, reset, setValue, watch } =
    useForm<ICrowdfundingFundraisingParametersForm>({
      defaultValues,
      resolver: yupResolver(schemaFundraisingCrowdfunding),
    });
  const asset = watch('asset');

  const { onChange: onValuationChange, ...registerValuation } = register('valuation', {
    valueAsNumber: true,
  });
  const { onChange: onPricePerShareChange, ...registerPricePerShare } = register('pricePerShare', {
    valueAsNumber: true,
  });

  const [workflowType, setWorkflowType] = useState<FundraisingWorkflowType>(
    FundraisingWorkflowType.FIRST_IN,
  );

  const workflowTypeValue = watch('workflowType');
  useEffect(() => {
    if (workflowTypeValue !== workflowType) {
      setWorkflowType(workflowTypeValue);
    }
  }, [workflowTypeValue]);

  const handleValuationChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (company?.actionsOwnedByCompany) {
      const valuation = new BigNumber(e.target.value);
      const pricePerShare = getPricePerShare(valuation, company.actionsOwnedByCompany);
      setValue('pricePerShare', pricePerShare);
    }
    onValuationChange(e);
  };

  const handlePricePerShareChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (company?.actionsOwnedByCompany) {
      const sharePrice = new BigNumber(e.target.value);
      const valuation = getValuation(sharePrice, company.actionsOwnedByCompany);
      setValue('valuation', valuation);
    }
    onPricePerShareChange(e);
  };

  const isAllInWorkflowType = workflowType === FundraisingWorkflowType.ALL_IN;

  const handleBondAssetDraftOpen = (): void => {
    bondAssetModal.onOpen();
  };

  const handleBondAssetDraftModify = async (option: OperationAssetOption): Promise<void> => {
    if (option.value !== option.type) {
      const assetReq = await getAsset({
        variables: {
          getAssetInput: {
            companyId,
            assetId: option.value,
          },
        },
      });
      setBondAssetDraftDefaults(getBondAssetDraftInfos(assetReq.data?.asset));
    }
    bondAssetModal.onOpen();
  };

  const handleBondAssetDraftSubmit = (assetDraftInput: CreateOrUpdateAssetDraftInput): void => {
    setTmpAssetDraftInput(assetDraftInput);
    setTmpDraftOptions([
      {
        label: assetDraftInput.name,
        value: assetDraftInput.type,
        type: assetDraftInput.type,
      },
    ]);
    setValue('asset', {
      label: assetDraftInput.name,
      value: assetDraftInput.type,
      type: assetDraftInput.type,
    });
    bondAssetModal.onClose();
  };

  const handleBondAssetDraftSubmitSuccess = (bondAssetDraft: IBondAssetDraft): void => {
    if (bondAssetDraft.assetId) {
      setValue('asset', {
        label: bondAssetDraft.name,
        value: bondAssetDraft.assetId,
        type: bondAssetDraft.type.value,
      });
    }
    bondAssetModal.onClose();
    assetDraftsReq.refetch();
  };

  const handleFormCancel = (): void => {
    if (company) {
      navigate(generatePath(routes.company.href, { companyId }));
    }
  };

  const handleFormSubmit: SubmitHandler<ICrowdfundingFundraisingParametersForm> = (data) => {
    if (company) {
      onSubmit({ ...data, createAsset: assetDraftInput });
    }
  };

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  return (
    <Card variant="divider-top">
      <CardHeader>
        <Heading size="lg">{t('FundraisingSettings')}</Heading>
      </CardHeader>
      <Divider />
      <CardBody>
        <form id="crowdfunding-fundraising-create" onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack spacing="4">
            <FormControl isInvalid={!!formState.errors?.name}>
              <FormLabel htmlFor="line">{t('CapitalRaiseName')}</FormLabel>
              <Input id="line" type="string" {...register('name')} />
              <ErrorMessage error={formState.errors.name} />
            </FormControl>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <Controller
                control={control}
                name="subscriptionPeriod"
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.subscriptionPeriod}>
                    <HStack alignItems="flex-start" spacing="0">
                      <FormLabel htmlFor="city">
                        {t('SubscriptionPeriod')}
                        <Tooltip hasArrow label={t('SubscriptionPeriodInfo')} placement="top">
                          <Icon as={InfoIcon} color="gray.500" ml="2" />
                        </Tooltip>
                      </FormLabel>
                    </HStack>
                    <Select
                      isSearchable={false}
                      menuPlacement="auto"
                      options={crowdfundingSubscriptionPeriodOptions}
                      {...field}
                    />
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="asset"
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.asset}>
                    <FormLabel htmlFor="asset">{t('AssetType')}</FormLabel>
                    <OperationAssetSelect
                      id="asset"
                      isAddButtonDisabled={draftOptions.length > 0 || tmpDraftOptions.length > 0}
                      menuPlacement="auto"
                      value={field.value}
                      options={[
                        {
                          label: t('OrdinaryShare', { nb: 2 }),
                          type: AssetType.ORDINARY_SHARE,
                          value: AssetType.ORDINARY_SHARE,
                        },
                        ...draftOptions,
                        ...tmpDraftOptions,
                      ]}
                      onBondAssetDraftAdd={handleBondAssetDraftOpen}
                      onBondAssetDraftModify={handleBondAssetDraftModify}
                      onChange={field.onChange}
                    />
                    <ErrorMessage error={formState.errors?.asset?.value} />
                  </FormControl>
                )}
              />
            </Stack>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.softCap}>
                <FormLabel htmlFor="softCap">{t('MinimumFundraisingAmount')}</FormLabel>
                <InputGroup>
                  <Input
                    id="softCap"
                    step="0.01"
                    type="number"
                    {...register('softCap', { valueAsNumber: true })}
                  />
                  <InputRightElement color="gray.500">€</InputRightElement>
                </InputGroup>
                <ErrorMessage error={formState.errors.softCap} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.hardCap}>
                <FormLabel htmlFor="hardCap">{t('MaximumFundraisingAmount')}</FormLabel>
                <InputGroup>
                  <Input
                    id="hardCap"
                    step="0.01"
                    type="number"
                    {...register('hardCap', { valueAsNumber: true })}
                  />
                  <InputRightElement color="gray.500">€</InputRightElement>
                </InputGroup>
                <ErrorMessage error={formState.errors.hardCap} />
              </FormControl>
            </Stack>
            {asset &&
              [AssetType.ORDINARY_SHARE, AssetType.PREFERRED_SHARE].includes(asset.type) && (
                <>
                  <Stack
                    alignItems="flex-end"
                    direction={{ base: 'column', md: 'row' }}
                    spacing="4"
                  >
                    <FormControl isInvalid={!!formState.errors?.valuation}>
                      <FormLabel htmlFor="line">
                        {t('CompanyValuationBeforeCapitalIncrease')}
                      </FormLabel>
                      <InputGroup>
                        <Input
                          id="valuation"
                          step="0.01"
                          type="number"
                          onChange={handleValuationChange}
                          {...registerValuation}
                        />
                        <InputRightElement color="gray.500">€</InputRightElement>
                      </InputGroup>
                      <ErrorMessage error={formState.errors.valuation} />
                    </FormControl>
                    <FormControl isInvalid={!!formState.errors?.pricePerShare}>
                      <FormLabel htmlFor="pricePerShare">{t('PricePerShare')}</FormLabel>
                      <InputGroup>
                        <Input
                          id="pricePerShare"
                          step="0.01"
                          type="number"
                          onChange={handlePricePerShareChange}
                          {...registerPricePerShare}
                        />
                        <InputRightElement color="gray.500">€</InputRightElement>
                      </InputGroup>
                      <ErrorMessage error={formState.errors.pricePerShare} />
                    </FormControl>
                  </Stack>
                  <Stack
                    alignItems="flex-end"
                    direction={{ base: 'column', md: 'row' }}
                    spacing="4"
                  >
                    <Controller
                      control={control}
                      name="allowedFiscalAdvantages"
                      render={({ field }): JSX.Element => (
                        <FormControl isInvalid={!!formState.errors?.allowedFiscalAdvantages}>
                          <HStack alignItems="flex-start" spacing="0">
                            <FormLabel htmlFor="allowedFiscalAdvantages">
                              {t('EligibleTaxDevicesForOperation')}
                            </FormLabel>
                          </HStack>
                          <AllowedFiscalAdvantageSelect
                            options={defaultFiscalAdvantageOptions}
                            value={field.value}
                            onChange={field.onChange}
                          />
                        </FormControl>
                      )}
                    />
                  </Stack>
                </>
              )}
            <Controller
              control={control}
              name="workflowType"
              render={({ field }): JSX.Element => (
                <FormControl isInvalid={!!formState.errors.workflowType} w="full">
                  <FormLabel>{t('SubscriptionValidationMode')}</FormLabel>
                  <RadioGroup
                    as={Stack}
                    direction={{ base: 'column', md: 'row' }}
                    spacing="4"
                    {...field}
                  >
                    <Radio
                      as={Stack}
                      flex="1"
                      value={FundraisingWorkflowType.FIRST_IN}
                      variant="solid"
                    >
                      <Text mb="2">{t('FundraisingWorkflowFIRST_IN')}</Text>
                      <Text color="gray.500" fontSize="sm" lineHeight="normal">
                        {t('FundraisingWorkflowFIRST_INDescription')}
                      </Text>
                    </Radio>
                    <Radio
                      as={Stack}
                      flex="1"
                      value={FundraisingWorkflowType.ALL_IN}
                      variant="solid"
                    >
                      <Text mb="2">{t('FundraisingWorkflowALL_IN')}</Text>
                      <Text color="gray.500" fontSize="sm" lineHeight="normal">
                        {t('FundraisingWorkflowALL_INDescription')}
                      </Text>
                    </Radio>
                  </RadioGroup>
                </FormControl>
              )}
            />
            {isAllInWorkflowType && (
              <FormControl isInvalid={!!formState.errors.closingDate}>
                <FormLabel htmlFor="closingDate">
                  {t('FundraisingClosedDate')}
                  <Tooltip hasArrow label={t('FundraisingWorkflowALL_INInfo')} placement="top">
                    <Icon as={InfoIcon} color="gray.500" ml="2" />
                  </Tooltip>
                </FormLabel>
                <Input id="closingDate" type="date" {...register('closingDate')} />
                <ErrorMessage error={formState.errors?.closingDate} />
              </FormControl>
            )}
            <Accordion
              allowToggle
              defaultIndex={defaultValues?.minimalAmount || defaultValues?.maximalAmount ? [0] : []}
              variant="unstyled"
            >
              <AccordionItem>
                <AccordionButton data-cy="expand-parameters">
                  <Text fontWeight="semibold">{t('AdvancedSettings')}</Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel mt="24px" p="0">
                  <Stack spacing="4">
                    <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                      <FormControl isInvalid={!!formState.errors?.minimalAmount}>
                        <FormLabel htmlFor="minimalAmount">
                          {t('SubscriptionAmountMinimum')}
                        </FormLabel>
                        <InputGroup>
                          <Input
                            id="minimalAmount"
                            step="0.01"
                            type="number"
                            {...register('minimalAmount', { valueAsNumber: true })}
                          />
                          <InputRightElement color="gray.500">€</InputRightElement>
                        </InputGroup>
                        <ErrorMessage error={formState.errors.minimalAmount} />
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="maximalAmount">
                          {t('SubscriptionAmountMaximum')}
                        </FormLabel>
                        <InputGroup>
                          <Input
                            id="maximalAmount"
                            step="0.01"
                            type="number"
                            {...register('maximalAmount', { valueAsNumber: true })}
                          />
                          <InputRightElement color="gray.500">€</InputRightElement>
                        </InputGroup>
                        <ErrorMessage error={formState.errors.maximalAmount} />
                      </FormControl>
                    </Stack>
                    <FormControl data-cy="has-mifid" isInvalid={!!formState.errors.hasMifid}>
                      <Checkbox
                        alignItems="baseline"
                        {...register('hasMifid')}
                        color="gray.700"
                        fontWeight="500"
                      >
                        {t('PublicOfferWithMifidQuestionnaire')}
                      </Checkbox>
                    </FormControl>
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Stack>
        </form>
        <CreateBondAssetDraftModal
          defaultValues={bondAssetDraftDefaults}
          isOpen={bondAssetModal.isOpen}
          onClose={bondAssetModal.onClose}
          onSubmit={handleBondAssetDraftSubmit}
          onSubmitSuccess={handleBondAssetDraftSubmitSuccess}
        />
      </CardBody>
      <CardFooter as={HStack} spacing="4">
        <Button type="button" variant="secondary" w="full" onClick={handleFormCancel}>
          {t('BackToDashboard')}
        </Button>
        <Button
          data-cy="next"
          form="crowdfunding-fundraising-create"
          isLoading={isLoading}
          loadingText={loadingText}
          type="submit"
          w="full"
        >
          {t('Next')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export type NewCrowdfundingFundraisingInformationsProps = Props;
