import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { generatePath, resolvePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  SubscriptionStepStatus,
  SubscriptionStepType,
  noop,
  routes,
} from '@blockpulse3/data/shared';
import {
  useGenerateSwanAuthUrlLazyQuery,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import { useIdentity } from '@blockpulse3/web-client/auth';

type Props = {
  onClose: () => void;
  renderAlone?: boolean;
};

export function ScheduleCreditTransferMethod({ onClose, renderAlone }: Props): JSX.Element {
  const t = useTranslations();

  const popupWindowRef = useRef<Window | null>(null);

  const { subscriptionId = '', companyId = '' } = useParams();
  const { identityId } = useIdentity();

  const [generateSwanAuthUrl] = useGenerateSwanAuthUrlLazyQuery();
  const [isTransferInProgress, setIsTransferInProgress] = useState(false);
  const [isPollingStarted, setIsPollingStarted] = useState(false);

  const { data, startPolling, stopPolling, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const subscription = data?.subscription;

  const isPaymentStepPending = subscription?.data.mergedSteps.some(
    (el) =>
      el.type === SubscriptionStepType.PAYMENT && el.status === SubscriptionStepStatus.IN_PROGRESS,
  );

  useEffect(() => {
    if (isPollingStarted) {
      if (!isTransferInProgress) {
        setIsTransferInProgress(true);
      }

      const timeout = setTimeout(() => {
        stopPolling();
        setIsPollingStarted(false);
        setIsTransferInProgress(false);
      }, 120_000);

      if (isPaymentStepPending) {
        setIsTransferInProgress(false);
        stopPolling();
        setIsPollingStarted(false);
        refetch();
        onClose();
      }

      return (): void => clearTimeout(timeout);
    }
    return noop;
  }, [
    isPollingStarted,
    isTransferInProgress,
    isPaymentStepPending,
    subscription,
    stopPolling,
    refetch,
    onClose,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Reset loader when popup window is closed
      if (popupWindowRef?.current?.closed && !isPollingStarted) {
        setIsTransferInProgress(false);
      }
    }, 500);

    return (): void => clearInterval(interval);
  }, []);

  // Function triggered by child popup window on close
  window.closePopup = async function (): Promise<void> {
    if (!popupWindowRef.current) return;
    setIsPollingStarted(true);
    startPolling(1000);
    popupWindowRef.current.close();
  };

  const handleInitiateScheduledPayment = (): void => {
    if (popupWindowRef?.current && !popupWindowRef?.current?.closed) return;

    const redirectUrlState = resolvePath(
      generatePath(routes.payment.initiatePayments.subscription.href, { subscriptionId }),
      companyId ? generatePath(routes.company.href, { companyId }) : routes.me.href,
    ).pathname;
    generateSwanAuthUrl({
      variables: {
        identityId,
        redirectUrlState,
      },
      onCompleted: (data) => {
        setIsTransferInProgress(true);
        const swanAuthorizationUrl = data.generateSwanAuthUrl;

        const popupWidth = 500;
        const popupHeight = 700;

        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width =
          window.innerWidth || document.documentElement.clientWidth || window.screen.width;
        const height =
          window.innerHeight || document.documentElement.clientHeight || window.screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft;
        const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop;

        popupWindowRef.current = window.open(
          swanAuthorizationUrl,
          'SwanOAuthPopup',
          `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`,
        );
      },
    });
  };

  const transactionDebitDate = subscription?.operation?.transactionDebitDate;

  const Component = (): JSX.Element => {
    return (
      <>
        <Alert status="info">
          <AlertIcon />
          <Stack spacing="1">
            <AlertTitle>{t('SchedulePaymentAlertTitle')}</AlertTitle>
            <AlertDescription>
              {t('SchedulePaymentAlertDescription', {
                date: formatDate(transactionDebitDate),
              })}
            </AlertDescription>
          </Stack>
        </Alert>
        <Button
          isDisabled={isPaymentStepPending}
          isLoading={isTransferInProgress}
          onClick={handleInitiateScheduledPayment}
        >
          {t(isPaymentStepPending ? 'ScheduledPayment' : 'SchedulePayment')}
        </Button>
      </>
    );
  };

  if (renderAlone) {
    return <Component />;
  }

  return (
    <AccordionItem>
      <AccordionButton justifyContent="space-between">
        <Text fontWeight="500">{t('SchedulePayment')}</Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <Component />
      </AccordionPanel>
    </AccordionItem>
  );
}
