import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { CubeTransparentIcon, PlusIcon } from '@heroicons/react/outline';
import { generatePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import {
  CompanyStatus,
  CompanyType,
  useGetAllOperationsCountQuery,
  useGetCompanyQuery,
  useGetOperationsCountQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';
import { OperationTypeModal } from '@blockpulse3/web-client/operation/commons';

import { OperationHeadlessCompanyModal } from './OperationCompanyHeadlessModal';
import {
  OperationTableCurrent,
  OperationTableDrafts,
  OperationTablePassed,
} from './OperationTable';

/**
 * OperationList.
 * Dashboard section of operation tables (DRAFT, PASSED, STARTED).
 *
 * @returns {JSX.Element}
 */
export function OperationList(): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const navigate = useNavigate();

  const isSpace = useMatch(routes.space.href + '/*');

  const operationTypeModal = useDisclosure();
  const operationTypeHeadlessModal = useDisclosure();

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const isFeatureOperationHeadless = isFeatureEnabled('fundraisingHeadless');

  const allOperationsCount = useGetAllOperationsCountQuery({
    fetchPolicy: 'cache-and-network',
    skip: !isSpace,
  });

  const operationsCount = useGetOperationsCountQuery({
    variables: { companyId: companyId || '' },
    fetchPolicy: 'cache-and-network',
    skip: !companyId,
  });

  if (operationsCount.loading || allOperationsCount.loading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (operationsCount.error || allOperationsCount.error) {
    return <ErrorQueryCard h="200px" />;
  }

  const isRestrictedToOpportunities =
    !company?.type ||
    !(
      (!isFeatureOperationHeadless &&
        [CompanyType.SPV, CompanyType.TOKENIZED].includes(company?.type)) ||
      (isFeatureOperationHeadless &&
        [CompanyType.SPV, CompanyType.PARTIAL, CompanyType.TOKENIZED].includes(company?.type))
    ) ||
    (company?.type === CompanyType.SPV && company?.status !== CompanyStatus.RUNNING);

  const {
    started = 0,
    passed = 0,
    drafts = 0,
  } = {
    ...(operationsCount.data?.getOperationsCount || {}),
    ...(allOperationsCount.data?.getAllOperationsCount || {}),
  };

  const computedOperationsCount = started + passed + drafts;

  const handleNewOperationClick = (): void => {
    if (!companyId) return;

    if (company?.type === CompanyType.PARTIAL) {
      if (isFeatureOperationHeadless) {
        return operationTypeHeadlessModal.onOpen();
      }
      return;
    }

    return openOperationTypeModal();
  };

  const handleNewOpportunity = (): void => {
    if (!companyId) return;

    navigate(generatePath(routes.company.newOpportunity.full, { companyId }));
  };

  const openOperationTypeModal = (): void => {
    if (operationTypeHeadlessModal.isOpen) {
      operationTypeHeadlessModal.onClose();
    }

    return operationTypeModal.onOpen();
  };

  const hasOperations = started + passed + drafts > 0;

  return (
    <Card>
      <OperationTypeModal isOpen={operationTypeModal.isOpen} onClose={operationTypeModal.onClose} />
      <OperationHeadlessCompanyModal
        isOpen={operationTypeHeadlessModal.isOpen}
        onClose={operationTypeHeadlessModal.onClose}
        onSubmit={openOperationTypeModal}
      />
      {!isSpace && (
        <CardHeader>
          <Stack
            alignItems={{ base: 'flex-start', md: 'center' }}
            direction={{ base: 'column', md: 'row' }}
            justifyContent="space-between"
          >
            <Heading size="md">{t('MyOperations')}</Heading>
            {hasOperations &&
              (isRestrictedToOpportunities ? (
                <Tooltip
                  isDisabled={computedOperationsCount === 0}
                  label={
                    company?.type === CompanyType.SPV
                      ? t('AddUniqueOpportunitySPVTooltip')
                      : t('AddUniqueOpportunityCompanyTooltip')
                  }
                >
                  <Button
                    data-cy="new-opportunity"
                    isDisabled={!company || computedOperationsCount > 0}
                    leftIcon={<Icon as={PlusIcon} boxSize="16px" />}
                    size="sm"
                    onClick={handleNewOpportunity}
                  >
                    {t('CreateOpportunity')}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  data-cy="new-fundraising"
                  isDisabled={!company || isRestrictedToOpportunities}
                  leftIcon={<Icon as={PlusIcon} boxSize="16px" />}
                  size="sm"
                  onClick={handleNewOperationClick}
                >
                  {t('CreateNewOperation')}
                </Button>
              ))}
          </Stack>
        </CardHeader>
      )}
      <CardBody>
        {hasOperations ? (
          <Tabs isLazy defaultIndex={0}>
            <TabList>
              <Tab>
                <Text>{t('InProgress')}</Text>
                <Badge ml="2">{started}</Badge>
              </Tab>
              <Tab>
                <Text>{t('Past')}</Text>
                <Badge ml="2">{passed}</Badge>
              </Tab>
              <Tab>
                <Text>{t('Draft', { nb: 1 })}</Text>
                <Badge ml="2">{drafts}</Badge>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <OperationTableCurrent />
              </TabPanel>
              <TabPanel>
                <OperationTablePassed />
              </TabPanel>
              <TabPanel>
                <OperationTableDrafts />
              </TabPanel>
            </TabPanels>
          </Tabs>
        ) : (
          <Stack h="150px" layerStyle="emptyState">
            <Icon as={CubeTransparentIcon} boxSize="40px" color="gray.500" />
            <Text>
              {isRestrictedToOpportunities ? t('StartGatheringIntentsNow') : t('NoOperationsYet')}
            </Text>
            {!isSpace && (
              <Stack>
                {isRestrictedToOpportunities ? (
                  <Button
                    data-cy="new-opportunity"
                    isDisabled={!company || computedOperationsCount > 0}
                    leftIcon={<Icon as={PlusIcon} boxSize="16px" />}
                    size="sm"
                    onClick={handleNewOpportunity}
                  >
                    {t('CreateOpportunity')}
                  </Button>
                ) : (
                  <Button
                    data-cy="new-fundraising"
                    isDisabled={!company || isRestrictedToOpportunities}
                    leftIcon={<Icon as={PlusIcon} boxSize="16px" />}
                    size="sm"
                    onClick={handleNewOperationClick}
                  >
                    {t('CreateNewOperation')}
                  </Button>
                )}
              </Stack>
            )}
          </Stack>
        )}
      </CardBody>
    </Card>
  );
}
