import {
  Accordion,
  Button,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { getBrand, getBrandConfig, getHostName } from '@blockpulse3/data/shared';
import {
  HoldingMethod,
  OperationDocumentType,
  OperationType,
  useGetSubscriptionFundraisingDocumentsQuery,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorQueryModal,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  SkeletonModal,
  WarningCard,
} from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import * as PaymentMethods from './PaymentMethods';

type PaymentMethodType = keyof typeof PaymentMethods;

type Props = {
  onClose: () => void;
} & Omit<ResponsiveModalProps, 'children'>;

/**
 * SubscriptionBankDetailsModal.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SubscriptionBankDetailsModal({ onClose, ...props }: Props): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const { data, loading, error } = useGetSubscriptionFundraisingDocumentsQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const bankDetailsDocument = data?.subscription?.operation?.documents?.find(
    (document) => document.type === OperationDocumentType.IBAN,
  );

  const { fundTransferAlert } = getBrandConfig(getBrand(getHostName()));

  if (loading) {
    return (
      <ResponsiveModal onClose={onClose} {...props}>
        <ModalOverlay />
        <SkeletonModal />
      </ResponsiveModal>
    );
  }

  if (error || !data) {
    return (
      <ResponsiveModal onClose={onClose} {...props}>
        <ModalOverlay />
        <ErrorQueryModal />
      </ResponsiveModal>
    );
  }

  const { subscription } = data;
  const { operation, buyerIdentity } = subscription;
  const buyerHasBankAccount = !!buyerIdentity?.bankAccount;

  // Determine which payment methods to display
  const displayedPaymentMethods: PaymentMethodType[] = [];
  if (
    operation.type === OperationType.FUNDRAISING &&
    operation.transactionDebitDate &&
    buyerHasBankAccount
  ) {
    displayedPaymentMethods.push('ScheduleCreditTransferMethod');
  } else if (operation.type === OperationType.SECONDARY && buyerHasBankAccount) {
    displayedPaymentMethods.push('QueueCreditTransferMethod', 'SepaMethod');
  } else {
    displayedPaymentMethods.push('SepaMethod');
  }

  return (
    <ResponsiveModal isCentered onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('FinalizeInvestmentAction')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack} spacing="4">
          <Text>
            {bankDetailsDocument
              ? t('FinalizeInvestmentIBANAttention')
              : t.rich('FinalizeInvestmentIBAN', {
                  important: (chunk) => <b>{chunk}</b>,
                })}
          </Text>
          <Stack spacing="4">
            {[HoldingMethod.PEA, HoldingMethod.PEA_PME].includes(subscription.holdingMethod) && (
              <WarningCard title={fundTransferAlert || t('PEAInvestmentInstructions')} />
            )}
            {displayedPaymentMethods.length === 1 ? (
              PaymentMethods[displayedPaymentMethods[0]]({ onClose, renderAlone: true })
            ) : (
              <Accordion allowToggle defaultIndex={[0]}>
                {displayedPaymentMethods.map((paymentMethod) => {
                  const Component = PaymentMethods[paymentMethod];
                  return <Component key={paymentMethod} onClose={onClose} />;
                })}
              </Accordion>
            )}
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            {t('Close')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type SubscriptionBankDetailsModalProps = Props;
