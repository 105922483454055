import {
  HStack,
  Icon,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { PencilAltIcon, TrashIcon } from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { useRef, useState } from 'react';
import { useTranslations } from 'use-intl';

import { IdentityInfosFragment } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberPercentage } from '@blockpulse3/helpers';
import {
  DeleteConfirmModal,
  IdentityAvatar,
  TableContainer,
  WarningCard,
} from '@blockpulse3/ui/commons';
import { IOperationSubscriber } from '@blockpulse3/web-client/operation/commons';

import { IFundraisingSubscribersTable } from '../../types';
import { toTableEntry } from './utils';

type Props = {
  rows: IFundraisingSubscribersTable;
  invalidLegalSubscriber: IdentityInfosFragment['companyIdentity'] | undefined;
  onSubscriberEdit: (key: string, data: IOperationSubscriber) => void;
  onSubscriberDelete: (key: string) => void;
};

/**
 * SubscribersTable.
 * Subscribers table with edit and delete row buttons.
 *
 * @returns {JSX.Element}
 */
export function SubscribersTable({
  rows,
  invalidLegalSubscriber = undefined,
  onSubscriberEdit,
  onSubscriberDelete,
}: Props): JSX.Element {
  const t = useTranslations();

  const confirmRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [deleteId, setDeleteId] = useState<string | null>(null);

  const lengthRows = Object.entries(rows).length;
  const formatedRows = Object.entries(rows).map(([id, row]) => toTableEntry(id, row));
  const sumAmount = Object.values(rows).reduce(
    (acc, cur) => acc + Number(cur.subscriber.amount),
    0,
  );

  const handleSubscriberEdit = (key: string, data: IOperationSubscriber): void => {
    onSubscriberEdit(key, data);
  };

  const handleSubscriberDelete = (key: string): void => {
    setDeleteId(key);
    onOpen();
  };

  const handleSubscriberDeleteConfirm = (): void => {
    if (deleteId) {
      onSubscriberDelete(deleteId);
    }
    onClose();
  };

  return (
    <Stack spacing="4">
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>{t('Subscriber', { nb: 1 })}</Th>
              <Th isNumeric>{t('Amount', { nb: 1 })}</Th>
              <Th isNumeric>{t('EntryFees')}</Th>
              <Th />
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {formatedRows.map((row) => {
              return (
                <Tr key={row.id}>
                  <Td>
                    <HStack spacing="3">
                      <IdentityAvatar identity={row} />
                      <Stack spacing="0.5">
                        <HStack spacing="1">
                          <Text fontWeight="600" maxW="200px" title={row.name} variant="ellipsis">
                            {row.name}
                          </Text>
                          {row?.identifier && <Text fontWeight="500">({row.identifier})</Text>}
                          {row.verified && (
                            <Icon as={CheckCircleIcon} boxSize="18px" color="green.400" />
                          )}
                        </HStack>
                        <Text color="gray.500" fontSize="sm">
                          {row.email}
                        </Text>
                      </Stack>
                    </HStack>
                  </Td>
                  <Td isNumeric fontWeight="600">
                    {formatNumberCurrency(row.investAmount)}
                  </Td>
                  <Td isNumeric fontWeight="600">
                    {row.admissionFees ? formatNumberPercentage(row.admissionFees) : '-'}
                  </Td>
                  <Td p={4}>
                    <Icon
                      _hover={{ color: 'gray.600' }}
                      as={PencilAltIcon}
                      boxSize="18px"
                      color="gray.400"
                      cursor="pointer"
                      onClick={(): void => {
                        handleSubscriberEdit(row.id, rows[row.id]);
                      }}
                    />
                  </Td>
                  <Td p={4}>
                    <Icon
                      _hover={{ color: 'gray.600' }}
                      as={TrashIcon}
                      boxSize="18px"
                      color="gray.400"
                      cursor="pointer"
                      onClick={(): void => {
                        handleSubscriberDelete(row.id);
                      }}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th fontWeight="bold">
                {lengthRows} {t('Subscriber', { nb: lengthRows })}
              </Th>
              <Th isNumeric>{formatNumberCurrency(sumAmount)}</Th>
              <Th />
              <Th />
              <Th />
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <DeleteConfirmModal
        isOpen={isOpen}
        leastDestructiveRef={confirmRef}
        subtitle={t('DefinitiveAction')}
        title={t('DeleteSubscriberQuestion', { nb: 1 })}
        onClose={onClose}
        onDelete={handleSubscriberDeleteConfirm}
      />
      {invalidLegalSubscriber && (
        <WarningCard
          subtitle={t('PossibleErrorContactUs')}
          title={t('CompanyAlreadyRegisteredHint', {
            companyName: invalidLegalSubscriber?.name,
            registrationNumber: invalidLegalSubscriber?.registrationNumber,
          })}
        />
      )}
    </Stack>
  );
}

export type SubscribersTableProps = Props;
