import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';
import { useTranslations } from 'use-intl';

import { FormErrors, nationalityOptions } from '@blockpulse3/data/shared';
import { useIsPhoneAvailableLazyQuery } from '@blockpulse3/graphql/hooks';
import { ErrorMessage } from '@blockpulse3/ui/commons';

import { personFormDefaultValues, schema } from './schema';
import { IPersonForm } from './types';

type Props = {
  onSubmit: (data: IPersonForm) => void;
  defaultValues?: IPersonForm;
};

export function PersonForm({
  defaultValues = personFormDefaultValues,
  onSubmit,
}: Props): JSX.Element {
  const t = useTranslations();

  /* ** Check if number is available ** */
  const [isPhoneAvailable] = useIsPhoneAvailableLazyQuery();

  const { register, control, formState, handleSubmit, reset, setError, clearErrors } =
    useForm<IPersonForm>({
      defaultValues,
      resolver: yupResolver(schema),
    });

  const handleFormSubmit: SubmitHandler<IPersonForm> = (data: IPersonForm) => {
    const { phone } = data;
    clearErrors();

    if (data.phone) {
      isPhoneAvailable({
        variables: { phone },
        onCompleted: ({ isPhoneAvailable }) => {
          if (!isPhoneAvailable) {
            setError('phone', { type: 'custom', message: FormErrors.PhoneAlreadyInUse });
          } else {
            onSubmit(data);
          }
        },
      });
    } else {
      onSubmit(data);
    }
  };

  useEffect(() => {
    reset({ ...defaultValues });
  }, [reset, defaultValues]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing="6">
        <Stack spacing="4">
          <Controller
            control={control}
            name="gender"
            render={({ field }): JSX.Element => (
              <FormControl isInvalid={!!formState.errors?.gender}>
                <FormLabel htmlFor="gender">{t('CivilStatus')}</FormLabel>
                <RadioGroup id="gender" {...field}>
                  <HStack spacing={{ base: 2, md: 4 }}>
                    <Radio value="M">{t('Mr')}</Radio>
                    <Radio value="F">{t('Mrs')}</Radio>
                  </HStack>
                </RadioGroup>
                <ErrorMessage error={formState.errors?.gender} />
              </FormControl>
            )}
          />
          <FormControl isInvalid={!!formState.errors?.firstName}>
            <FormLabel color="gray.900" htmlFor="firstName">
              {t('FirstName')}
            </FormLabel>
            <Input id="firstName" type="text" {...register('firstName')} />
            <ErrorMessage error={formState.errors?.firstName} />
          </FormControl>
          <FormControl isInvalid={!!formState.errors?.lastName}>
            <FormLabel htmlFor="lastName">{t('LastName')}</FormLabel>
            <Input id="lastName" type="text" {...register('lastName')} />
            <ErrorMessage error={formState.errors?.lastName} />
          </FormControl>
          <FormControl isInvalid={!!formState.errors?.phone}>
            <FormLabel htmlFor="phone">
              {t('PhoneNumberOptional')}
              <Tooltip hasArrow label={t('SecurityCodeForAccountSupport')} placement="top">
                <Icon as={InformationCircleIcon} color="gray.500" />
              </Tooltip>
            </FormLabel>
            <PhoneInputWithCountry
              control={control}
              defaultCountry="FR"
              inputComponent={Input}
              name="phone"
            />
            <ErrorMessage error={formState.errors?.phone} />
          </FormControl>
          <FormControl isInvalid={!!formState.errors?.birthdate}>
            <FormLabel htmlFor="birthdate">{t('BirthDate')}</FormLabel>
            <Input id="birthdate" type="date" {...register('birthdate')} />
            <ErrorMessage error={formState.errors?.birthdate} />
          </FormControl>
          <FormControl isInvalid={!!formState.errors?.birthCity}>
            <FormLabel htmlFor="birthCity">{t('BirthCity')}</FormLabel>
            <Input id="birthCity" type="text" {...register('birthCity')} />
            <ErrorMessage error={formState.errors?.birthCity} />
          </FormControl>
          <Controller
            control={control}
            name="nationality"
            render={({ field }): JSX.Element => (
              <FormControl isInvalid={!!formState.errors?.nationality}>
                <FormLabel htmlFor="nationality">{t('Nationality')}</FormLabel>
                <Select
                  id="nationality"
                  menuPlacement="auto"
                  options={nationalityOptions}
                  placeholder={t('ExampleNationality')}
                  {...field}
                />
                <ErrorMessage error={formState.errors?.nationality?.value} />
              </FormControl>
            )}
          />
        </Stack>
        <Button type="submit">{t('Next')}</Button>
      </Stack>
    </form>
  );
}

export type PersonFormProps = Props;
