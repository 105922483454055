import { InfoIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { AssetType, noop, routes } from '@blockpulse3/data/shared';
import { useGetAssetsByCompanyQuery } from '@blockpulse3/graphql/hooks';
import { ErrorMessage } from '@blockpulse3/ui/commons';

import {
  secondaryOperationInformationsDefaults,
  secondaryOperationInformationsSchema,
} from './schema';
import { ISecondaryOperationInformationsForm } from './types';

type Props = {
  defaultValues?: ISecondaryOperationInformationsForm;
  isLoading?: boolean;
  onSubmit?: (data: ISecondaryOperationInformationsForm) => void;
  isRelatedToOpportunity?: boolean;
};

/**
 * NewSecondaryOperationInformations.
 *
 * @returns {JSX.Element}
 */
export function NewSecondaryOperationInformations({
  defaultValues = secondaryOperationInformationsDefaults,
  isLoading = false,
  onSubmit = noop,
  isRelatedToOpportunity = false,
}: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const { data } = useGetAssetsByCompanyQuery({ variables: { companyId } });
  const assets = data?.getAssetsByCompany || [];

  const options = assets
    .filter((asset) => [AssetType.ORDINARY_SHARE, AssetType.PREFERRED_SHARE].includes(asset.type))
    .map(({ name, id }) => ({
      label: name,
      value: id,
    }));

  const navigate = useNavigate();

  const { register, control, formState, handleSubmit, reset } =
    useForm<ISecondaryOperationInformationsForm>({
      defaultValues,
      resolver: yupResolver(secondaryOperationInformationsSchema),
    });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  const handleFormSubmit: SubmitHandler<ISecondaryOperationInformationsForm> = (data): void => {
    onSubmit(data);
  };

  const handleFormCancel = (): void => {
    navigate(generatePath(routes.company.href, { companyId }));
  };

  return (
    <Card variant="divider-top" width="full">
      <CardHeader>
        <Heading size="lg">{t('Settings')}</Heading>
      </CardHeader>
      <Divider />
      <CardBody>
        <form id="new-secondary-operation" onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack spacing="4">
            <FormControl isInvalid={!!formState.errors.name}>
              <FormLabel htmlFor="name">{t('OperationName')}</FormLabel>
              <Input id="name" type="string" {...register('name')} />
              <ErrorMessage error={formState.errors.name} />
            </FormControl>
            <Stack alignItems="flex-start" direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors.startSubscriptionDate}>
                <FormLabel htmlFor="startSubscriptionDate">{t('OperationStartDate')}</FormLabel>
                <Input
                  id="startSubscriptionDate"
                  type="date"
                  {...register('startSubscriptionDate')}
                />
                <ErrorMessage error={formState.errors.startSubscriptionDate} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors.endSubscriptionDate}>
                <FormLabel htmlFor="endSubscriptionDate">{t('OperationEndDate')}</FormLabel>
                <Input id="endSubscriptionDate" type="date" {...register('endSubscriptionDate')} />
                <ErrorMessage error={formState.errors.endSubscriptionDate} />
              </FormControl>
            </Stack>
            <Stack alignItems="flex-start" direction={{ base: 'column', md: 'row' }} spacing="4">
              <Controller
                control={control}
                name="asset"
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.asset}>
                    <FormLabel htmlFor="asset">{t('ShareType')}</FormLabel>
                    <Select id="asset" menuPlacement="auto" options={options} {...field} />
                    <ErrorMessage error={formState.errors?.asset?.value} />
                  </FormControl>
                )}
              />
              <FormControl isInvalid={!!formState.errors.pricePerShare}>
                <FormLabel htmlFor="price-per-share">{t('PricePerShare')}</FormLabel>
                <InputGroup>
                  <Input
                    id="price-per-share"
                    step="0.01"
                    type="number"
                    {...register('pricePerShare')}
                  />
                  <InputRightElement color="gray.500">€</InputRightElement>
                </InputGroup>
                <ErrorMessage error={formState.errors.pricePerShare} />
              </FormControl>
            </Stack>
            <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors.usePivotFiscalAccount}>
                <Checkbox {...register('usePivotFiscalAccount')}>
                  {t('SetupPivotAccount')}
                  <Tooltip hasArrow label={t('PivotAccountSetupCheckboxPrompt')} placement="top">
                    <Icon as={InfoIcon} color="gray.500" ml="2" />
                  </Tooltip>
                </Checkbox>
                <ErrorMessage error={formState.errors.usePivotFiscalAccount} />
              </FormControl>
            </Stack>
            {!isRelatedToOpportunity && (
              <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors.isPromiseToSell}>
                  <Checkbox {...register('isPromiseToSell')}>
                    {t('MarketAssociatedToAPromiseToSell')}
                    <Tooltip
                      hasArrow
                      label={t('CheckboxPromptForDesignatedSellers')}
                      placement="top"
                    >
                      <Icon as={InfoIcon} color="gray.500" ml="2" />
                    </Tooltip>
                  </Checkbox>
                  <ErrorMessage error={formState.errors.isPromiseToSell} />
                </FormControl>
              </Stack>
            )}
          </Stack>
        </form>
      </CardBody>
      <CardFooter as={HStack} spacing="4">
        <Button
          isDisabled={isLoading}
          type="button"
          variant="secondary"
          w="full"
          onClick={handleFormCancel}
        >
          {t('Back')}
        </Button>
        <Button form="new-secondary-operation" isLoading={isLoading} type="submit" w="full">
          {t('Next')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export type NewSecondaryOperationInformationsProps = Props;
