import { Stack, useRadioGroup } from '@chakra-ui/react';
import { useEffect } from 'react';

import { FiscalAdvantage, HoldingMethod, noop } from '@blockpulse3/data/shared';
import { useGetFiscalAccountsQuery } from '@blockpulse3/graphql/hooks';

import { HoldingMethodEntry } from './HoldingMethodEntry';
import { IFiscalAccountForm } from './types';
import { getFiscalAccountValues, mapFiscalAccountsByHoldingMethod } from './utils';

type Props = {
  individualIdentityId: string;
  defaultValue?: HoldingMethod;
  allowedFiscalAdvantages?: FiscalAdvantage[];
  isDisabled?: boolean;
  isReading?: boolean;
  hasDefaultHoldingMethod?: boolean;
  onHoldingMethodChange?: (value: HoldingMethod) => void;
  onFiscalAccountCreate?: (holdingMethod: HoldingMethod) => void;
  onFiscalAccountUpdate?: (fiscalAccount: IFiscalAccountForm) => void;
};

export function HoldingMethodSelect({
  individualIdentityId,
  defaultValue,
  allowedFiscalAdvantages = [],
  isDisabled = false,
  isReading = false,
  hasDefaultHoldingMethod = true,
  onHoldingMethodChange = noop,
  onFiscalAccountCreate = noop,
  onFiscalAccountUpdate = noop,
}: Props): JSX.Element {
  const { data } = useGetFiscalAccountsQuery({
    variables: { individualIdentityId },
    skip: !individualIdentityId,
  });
  const { fiscalAccounts = [] } = data || {};
  const formattedFiscalAccounts = mapFiscalAccountsByHoldingMethod(fiscalAccounts);

  const { getRootProps, getRadioProps, setValue, value } = useRadioGroup({
    defaultValue,
    onChange: onHoldingMethodChange,
  });

  useEffect(() => {
    if (defaultValue && defaultValue !== value) {
      setValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const hasIrPme = allowedFiscalAdvantages.includes(FiscalAdvantage.IR_PME);

  const handleFiscalAccountAdd = (holdingMethod: HoldingMethod): void => {
    onFiscalAccountCreate(holdingMethod);
  };

  const handleFiscalAccountUpdate = (holdingMethod: HoldingMethod): void => {
    const fiscalAccountValues = getFiscalAccountValues(formattedFiscalAccounts[holdingMethod]);
    onFiscalAccountUpdate(fiscalAccountValues);
  };

  return (
    <Stack {...getRootProps()}>
      {(isReading || hasDefaultHoldingMethod) && (
        <HoldingMethodEntry
          hasIrPme={hasIrPme}
          isDisabled={isDisabled}
          isReading={isReading}
          value={HoldingMethod.DIRECT}
          {...getRadioProps({ value: HoldingMethod.DIRECT })}
        />
      )}
      {(isReading || allowedFiscalAdvantages.includes(FiscalAdvantage.PEA)) && (
        <HoldingMethodEntry
          fiscalAccount={formattedFiscalAccounts[HoldingMethod.PEA]}
          isDisabled={isDisabled}
          isReading={isReading}
          value={HoldingMethod.PEA}
          onAddFiscalAccount={(): void => handleFiscalAccountAdd(HoldingMethod.PEA)}
          onUpdateFiscalAccount={(): void => handleFiscalAccountUpdate(HoldingMethod.PEA)}
          {...getRadioProps({ value: HoldingMethod.PEA })}
        />
      )}
      {(isReading || allowedFiscalAdvantages.includes(FiscalAdvantage.PEA_PME)) && (
        <HoldingMethodEntry
          fiscalAccount={formattedFiscalAccounts[HoldingMethod.PEA_PME]}
          isDisabled={isDisabled}
          isReading={isReading}
          value={HoldingMethod.PEA_PME}
          onAddFiscalAccount={(): void => handleFiscalAccountAdd(HoldingMethod.PEA_PME)}
          onUpdateFiscalAccount={(): void => handleFiscalAccountUpdate(HoldingMethod.PEA_PME)}
          {...getRadioProps({ value: HoldingMethod.PEA_PME })}
        />
      )}
    </Stack>
  );
}
