import { PathMatch, useMatch } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';

type UseSecondaryOperationSteps = {
  progress: number;
  activeSteps: { label: string; value: PathMatch<string> | null }[];
};

/**
 * useSecondaryOperationSteps.
 *
 * @returns {UseSecondaryOperationSteps}
 */
export function useSecondaryOperationSteps(): UseSecondaryOperationSteps {
  const t = useTranslations();

  const secondaryRoutes = routes.company.newSecondary;

  const isCreateActive = useMatch(secondaryRoutes.href);
  const isCreateFromActive = useMatch(secondaryRoutes.fromOpportunity.href);
  const isParametersActive = useMatch(secondaryRoutes.edit.full);
  const isSellersActive = useMatch(
    secondaryRoutes.edit.full + '/' + secondaryRoutes.edit.sellers.href,
  );
  const isBuyersActive = useMatch(
    secondaryRoutes.edit.full + '/' + secondaryRoutes.edit.buyers.href,
  );
  const isSummaryActive = useMatch(
    secondaryRoutes.edit.full + '/' + secondaryRoutes.edit.summary.href,
  );

  const activeSteps = [
    {
      label: t('Settings'),
      value: isParametersActive || isCreateActive || isCreateFromActive,
    },
    { label: t('SellersConfiguration'), value: isSellersActive },
    { label: t('BuyersConfiguration'), value: isBuyersActive },
    { label: t('Summary'), value: isSummaryActive },
  ];

  const progress = activeSteps.findIndex((step) => step.value);

  return { progress, activeSteps };
}
