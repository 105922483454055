import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  CardBody,
  CardHeader,
  Divider,
  HStack,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { OperationDocumentType } from '@blockpulse3/data/shared';
import {
  AssetType,
  useGetDocumentPdfUrlQuery,
  useGetSubscriptionFundraisingDocumentsQuery,
} from '@blockpulse3/graphql/hooks';
import {
  formatNumberCurrency,
  formatNumberPercentage,
  formatReferenceLabel,
} from '@blockpulse3/helpers';
import { BankDetailsCard, BankDetailsCardDocument, ErrorQueryCard } from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

type Props = {
  onClose: () => void;
  renderAlone?: boolean;
};

export function SepaMethod({ renderAlone }: Props): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const { data, loading, error } = useGetSubscriptionFundraisingDocumentsQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const bankDetailsDocument = data?.subscription?.operation?.documents?.find(
    (document) => document.type === OperationDocumentType.IBAN,
  );

  const { data: bankDetailsDocumentData } = useGetDocumentPdfUrlQuery({
    variables: {
      documentId: bankDetailsDocument?.document.id || '',
    },
    skip: !bankDetailsDocument?.document?.id,
  });

  if (loading) {
    return <Skeleton height="350px" width="full" />;
  }

  if (error || !data) {
    return <ErrorQueryCard />;
  }

  const { subscription } = data;
  const {
    admissionFees = 0,
    feesAmount = 0,
    amountDue = 0,
    investAmount = 0,
    operation,
    buyerIdentity,
  } = subscription;
  const virtualIBAN = subscription?.bankVirtualIban;
  const bankDetailsTitle = `${t('BankDetails')} ${operation.company.name}`;
  const bankDetailsDocumentLink = bankDetailsDocumentData?.getDocumentPdfUrl;

  const referenceLabel =
    subscription.reference && buyerIdentity
      ? formatReferenceLabel(buyerIdentity.name, subscription.reference.label)
      : '';

  const amountSharesPurchased = operation?.pricePerShare
    ? Math.round(investAmount / operation.pricePerShare)
    : 0;

  const Component = (): JSX.Element => {
    return (
      <>
        {bankDetailsDocumentData ? (
          <BankDetailsCardDocument
            referenceLabel={referenceLabel}
            src={bankDetailsDocumentLink}
            title={bankDetailsTitle}
          />
        ) : (
          <BankDetailsCard
            iban={virtualIBAN?.iban}
            recipientName={virtualIBAN?.bankAccount.holderName}
            referenceLabel={referenceLabel}
            swift={virtualIBAN?.swift}
            title={bankDetailsTitle}
          />
        )}
        <Card bg="gray.50" boxShadow="none">
          <CardHeader as={Stack}>
            <Text fontSize="md" fontWeight="600">
              {t('Payment')}
            </Text>
            <HStack justify="space-between">
              <Text color="gray.500" fontSize="sm" fontWeight="600">
                {operation.assetType === AssetType.BOND
                  ? t('SubscriptionOfBonds')
                  : t('SubscriptionOfAmountSharesPurchased', {
                      nb: amountSharesPurchased,
                    })}
              </Text>
              <Text fontSize="sm" fontWeight="600">
                {formatNumberCurrency(investAmount)}
              </Text>
            </HStack>
            {admissionFees && (
              <HStack justify="space-between">
                <Text color="gray.500" fontSize="sm" fontWeight="600">
                  {t('FeesAmount', {
                    feePercentage: formatNumberPercentage(admissionFees),
                  })}
                </Text>
                <Text fontSize="sm" fontWeight="600">
                  {formatNumberCurrency(feesAmount)}
                </Text>
              </HStack>
            )}
          </CardHeader>
          <CardBody as={Stack} spacing="2">
            <Divider />
            <HStack justifyContent="space-between" pt="1">
              <Text fontSize="md" fontWeight="600">
                {t('Total', { nb: 1 })}
              </Text>
              <Text fontSize="md" fontWeight="600">
                {formatNumberCurrency(amountDue)}
              </Text>
            </HStack>
          </CardBody>
        </Card>
      </>
    );
  };

  if (renderAlone) {
    return <Component />;
  }

  return (
    <AccordionItem>
      <AccordionButton justifyContent="space-between">
        <Text fontWeight="500">{t('TransferFundsViaSEPA')}</Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <Stack>
          <Text color="gray.500" fontSize="sm" fontWeight="600">
            {t('TransferFundsViaSEPADescription')}
          </Text>
          <Component />;
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  );
}
