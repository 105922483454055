import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { ISubscription, getBrand, getHostName } from '@blockpulse3/data/shared';
import {
  OperationInvestStatus,
  OperationStatus,
  useGetAllSubscriptionsByIndividualIdentityQuery,
  useGetSubscriptionsByCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { BrandIcon } from '@blockpulse3/ui/brand-theme';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';
import { useManagedIndividual } from '@blockpulse3/web-client/auth';

import { InvitationsTable } from './InvitationsTable';
import { concatUserSubscriptions } from './utils';

/**
 * InvitationList.
 * Dashboard section of subscription invitation tables (STARTED, PASSED).
 *
 * @returns {JSX.Element}
 */
export function InvitationList(): JSX.Element {
  const t = useTranslations();

  const { individual } = useManagedIndividual();
  const { companyId = '' } = useParams();

  const {
    data: userReq,
    loading: subscriptionsUserLoading,
    error: subscriptionsUserError,
  } = useGetAllSubscriptionsByIndividualIdentityQuery({
    variables: { individualIdentityId: individual?.id || '' },
    fetchPolicy: 'cache-and-network',
    skip: !individual?.id || !!companyId,
  });
  const {
    data: companyReq,
    loading: subscriptionsCompanyLoading,
    error: subscriptionsCompanyError,
  } = useGetSubscriptionsByCompanyQuery({
    variables: { companyId: companyId || '' },
    fetchPolicy: 'cache-and-network',
    skip: !companyId,
  });

  if ((!companyId && !individual) || subscriptionsCompanyLoading || subscriptionsUserLoading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (subscriptionsUserError || subscriptionsCompanyError) {
    return <ErrorQueryCard h="200px" />;
  }

  const subscriptions = userReq?.individualIdentity
    ? concatUserSubscriptions(userReq?.individualIdentity)
    : companyReq?.getSubscriptionsByCompany;

  /* ** Sort subscriptions by end of subscription date ** */
  const sortSubscriptions = (a: ISubscription, b: ISubscription): number =>
    dayjs(a.operation.endSubscriptionDate).diff(dayjs(b.operation.endSubscriptionDate));

  /* ** Split elements of the getSubscriptions request according to subscriptions status ** */
  const subscriptionsCurrent = (subscriptions || [])
    .filter(
      (subscription) =>
        [OperationStatus.STARTED, OperationStatus.CLOSED, OperationStatus.REVIEWED].includes(
          subscription.operation.status,
        ) && subscription.investStatus !== OperationInvestStatus.DECLINED,
    )
    .sort(sortSubscriptions);
  const subscriptionsPassed = (subscriptions || [])
    .filter(
      (subscription) =>
        subscription.operation.status === OperationStatus.FINALIZED &&
        subscription.investStatus !== OperationInvestStatus.DECLINED,
    )
    .sort(sortSubscriptions);

  const hostname = getHostName();
  const brand = getBrand(hostname);

  const isAxerealSection =
    brand === 'axereal' &&
    dayjs().isBefore('2023-09-12') &&
    subscriptionsCurrent.length === 0 &&
    subscriptionsPassed.length === 0;

  return (
    <Card>
      <CardHeader>
        <Heading size="md">{t('MyInvitations')}</Heading>
      </CardHeader>
      <CardBody>
        {isAxerealSection ? (
          <Stack layerStyle="emptyState" spacing="0">
            <Stack h="65px">
              <BrandIcon bgTheme="light" h="full" w="156px" />
            </Stack>
            <Text>
              {t.rich('AxerealInvestOperationStartsOnSeptember12', {
                important: (chunks) => <Text as="b">{chunks}</Text>,
              })}
            </Text>
            <Text>{t('RetrieveInvitationLater')}</Text>
          </Stack>
        ) : (
          <Tabs defaultIndex={0}>
            <TabList>
              <Tab>
                {t('InProgress')}
                {subscriptionsCurrent.length > 0 && (
                  <Badge ml="2">{subscriptionsCurrent.length}</Badge>
                )}
              </Tab>
              <Tab>
                {t('Past')}
                {subscriptionsPassed.length > 0 && (
                  <Badge ml="2">{subscriptionsPassed.length}</Badge>
                )}
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <InvitationsTable values={subscriptionsCurrent} />
              </TabPanel>
              <TabPanel>
                <InvitationsTable values={subscriptionsPassed} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </CardBody>
    </Card>
  );
}
