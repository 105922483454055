import { HStack, Hide, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  AssetType,
  IdentityType,
  MovementType,
  useGetMovementQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import {
  AssetBadge,
  ErrorQueryCard,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';

export function MovementPanelMovementDetail(): JSX.Element {
  const t = useTranslations();
  const i18nMovementType = useTranslations('MovementTypeValues');

  const { companyId = '', movementId = '' } = useParams();

  const { data, loading, error } = useGetMovementQuery({
    variables: {
      companyId,
      movementId,
    },
    skip: !companyId || !movementId,
  });

  if (loading || !data || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('NatureOfMovement')}
        </Text>
        <Skeleton h="40px" />
        <Skeleton h="40px" />
      </Stack>
    );
  }

  if (loading) {
    return <Skeleton />;
  }

  if (!data || error) {
    return <ErrorQueryCard px="4" />;
  }

  const { movement } = data;
  const shareCount = (movement?.amount || 0) * (movement?.type === MovementType.BURN ? -1 : 1);
  const assetType = movement?.asset?.type;
  const date = movement?.createdAt;
  const buyer = movement?.type === MovementType.TRANSFER ? movement.toWallet?.identity : undefined;
  const showAssetName = ![AssetType.ORDINARY_SHARE, AssetType.PREFERRED_SHARE].includes(assetType);

  return (
    <Stack px="4" spacing="2">
      <Text color="gray.600" fontWeight="600">
        {movement?.type ? i18nMovementType(movement?.type) : ''}
      </Text>
      <Stack
        alignItems={{ base: 'flex-start', md: 'center' }}
        direction={{ base: 'column', md: 'row' }}
        flexWrap="wrap"
      >
        <AssetBadge size="xl" type={assetType || AssetType.ORDINARY_SHARE} value={shareCount} />
        {showAssetName && (
          <>
            <Hide below="md">
              <Text fontSize="lg" fontWeight="500">
                -
              </Text>
            </Hide>
            <Text fontSize="lg" fontWeight="500">
              {movement.asset.name}
            </Text>
          </>
        )}
      </Stack>
      {buyer && (
        <Stack spacing="2">
          <Text color="gray.500" fontSize="sm" fontWeight="500">
            {t('Buyer', { nb: 1 })}
          </Text>
          <IdentityCard background="gray.50" layerStyle="border" p="2" rounded="md">
            <IdentityAvatar boxSize="12" src={buyer.profilePicture} type={buyer.type} />
            <Stack spacing="0">
              <IdentityCardTitle isChecked>
                <Text fontWeight="600">{buyer.name}</Text>
                {buyer.type === IdentityType.COMPANY && (
                  <Text fontWeight="500">({buyer.identifier})</Text>
                )}
              </IdentityCardTitle>
              <IdentityCardDescription>{buyer.email}</IdentityCardDescription>
            </Stack>
          </IdentityCard>
        </Stack>
      )}
      <HStack fontSize="sm" justifyContent="space-between">
        <Text color="gray.500" fontWeight="500">
          {t('RegistrationDate')}
        </Text>
        <Text fontWeight="500">{date ? formatDate(date, 'D MMMM YYYY') : '-'}</Text>
      </HStack>
    </Stack>
  );
}
