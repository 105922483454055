import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { ArrowNarrowRightIcon, CashIcon, PlusIcon } from '@heroicons/react/outline';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import {
  useGetBondAssetsQuery,
  useGetCompanyQuery,
  useGetOptionAssetsQuery,
  useGetStockAssetsQuery,
} from '@blockpulse3/graphql/hooks';
import { AssetBadge, ErrorQueryCard, TableContainer } from '@blockpulse3/ui/commons';
import { useManagerRole } from '@blockpulse3/web-client/auth';

type Props = unknown;

export function AssetAndOptionList(): JSX.Element | null {
  const t = useTranslations();

  const navigate = useNavigate();
  const { companyId = '' } = useParams();
  const isFeatureOptionPool = isFeatureEnabled('optionPool');
  const isRepatriationAuthorized = useManagerRole({ companyId });

  const { data: companyData } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const hasCaptable =
    !!companyData?.company?.capTable?.stockTotalSupply &&
    !!companyData?.company?.capTable?.fullyDilutedTotalSupply;

  const {
    data: stockData,
    loading: stockLoading,
    error: stockError,
  } = useGetStockAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId || !hasCaptable,
  });

  const {
    data: optionData,
    loading: optionLoading,
    error: optionError,
  } = useGetOptionAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId || !isFeatureOptionPool || !hasCaptable,
  });

  const {
    data: bondData,
    loading: bondLoading,
    error: bondError,
  } = useGetBondAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId || !hasCaptable,
  });

  if (
    !isFeatureOptionPool ||
    (!stockData?.getStockAssets?.length &&
      !optionData?.getOptionAssets?.length &&
      !isRepatriationAuthorized)
  ) {
    return null;
  }

  if (stockError || optionError || bondError) {
    return <ErrorQueryCard h="200px" />;
  }

  if (stockLoading || optionLoading || bondLoading || (hasCaptable && !stockData)) {
    return <Skeleton h="200px" />;
  }

  const currentStocks = stockData?.getStockAssets || [];
  const currentOptions = optionData?.getOptionAssets || [];
  const currentBonds = bondData?.getBondAssets || [];
  const isEmpty = !hasCaptable && currentStocks.length === 0 && currentOptions.length === 0;

  const handleManageClick = (): void => {
    navigate(routes.company.captable.href);
  };

  const handleRepatriationClick = (): void => {
    navigate(routes.company.repatriation.href);
  };

  const handleOptionClick = (assetId: string): void => {
    if (companyId) {
      navigate(generatePath(routes.company.option.href, { assetId }));
    }
  };

  return (
    <Card>
      <CardHeader
        alignItems={{ base: 'flex-start', md: 'center' }}
        as={Stack}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Heading size="md">{t('AssetsAndPools')}</Heading>
        {!isEmpty && (
          <Button size="sm" variant="secondary" onClick={handleManageClick}>
            {t('ManageShareholding')}
          </Button>
        )}
      </CardHeader>
      <CardBody>
        {isEmpty ? (
          <Stack h="150px" layerStyle="emptyState">
            <Icon as={CashIcon} boxSize="40px" color="gray.500" />
            <Text>{t('NoAsset')}</Text>
            <Stack>
              <Button
                leftIcon={<Icon as={PlusIcon} boxSize="16px" />}
                size="sm"
                variant="secondary"
                w="fit-content"
                onClick={handleRepatriationClick}
              >
                {t('RepatriateShareholding')}
              </Button>
            </Stack>
          </Stack>
        ) : (
          <TableContainer>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>{t('Name')}</Th>
                  <Th>{t('Quantity')}</Th>
                  <Th w="200px" />
                </Tr>
              </Thead>
              <Tbody>
                {currentStocks.map((row) => (
                  <Tr key={row.asset.id} role="button" onClick={handleManageClick}>
                    <Td fontWeight="600">{row.name}</Td>
                    <Td fontWeight="600">
                      <AssetBadge type={row.assetType} value={row.totalSupply} />
                    </Td>
                    <Td textAlign="right">
                      <Button
                        rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                        size="sm"
                        variant="secondary"
                      >
                        {t('ShowDetails')}
                      </Button>
                    </Td>
                  </Tr>
                ))}
                {currentOptions.map((row) => (
                  <Tr
                    key={row.asset.id}
                    role="button"
                    onClick={(): void => handleOptionClick(row.asset.id)}
                  >
                    <Td fontWeight="600">{row.name}</Td>
                    <Td fontWeight="600">
                      <AssetBadge
                        assetCount={row.grants?.length}
                        type={row.assetType}
                        value={row.initialSupply}
                      />
                    </Td>
                    <Td textAlign="right">
                      <Button
                        rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                        size="sm"
                        variant="secondary"
                      >
                        {t('ShowDetails')}
                      </Button>
                    </Td>
                  </Tr>
                ))}
                {currentBonds.map((row) => (
                  <Tr key={row.asset.id}>
                    <Td fontWeight="600">{row.name}</Td>
                    <Td fontWeight="600">
                      <AssetBadge type={row.assetType} value={row.totalSupply} />
                    </Td>
                    <Td textAlign="right">
                      {/* <Button
                        rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                        size="sm"
                        variant="secondary"
                      >
                        {t('ShowDetails')}
                      </Button> */}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
      </CardBody>
    </Card>
  );
}

export type AssetAndOptionListProps = Props;
