import {
  Button,
  HStack,
  Icon,
  IconButton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { DocumentTextIcon, DownloadIcon, TrashIcon } from '@heroicons/react/outline';
import { useRef, useState } from 'react';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import { noop } from '@blockpulse3/data/shared';
import {
  CompanyDocumentType,
  DocumentInfosFragment,
  IndividualDocumentType,
  useDeleteKybDocumentMutation,
  useDeleteKycDocumentMutation,
  useGetDocumentPdfUrlLazyQuery,
  useGetIdentityQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import { DeleteConfirmModal, TableContainer } from '@blockpulse3/ui/commons';
import { useManagerRole } from '@blockpulse3/web-client/auth';

import { DocumentTableEmpty } from './DocumentTableEmpty';

type Props = {
  identityId: string;
  values?: DocumentInfosFragment[];
  onDeleteCompleted?: () => void;
};

/**
 * DocumentTable.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function DocumentTable({
  identityId,
  values = [],
  onDeleteCompleted = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const i18nDocumentValues = useTranslations('DocumentValues');

  const [documentType, setDocumentType] = useState<IndividualDocumentType | CompanyDocumentType>();
  const confirmRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const isDeleteAuthorized = useManagerRole({ identityId });

  const { data: identityData } = useGetIdentityQuery({
    variables: {
      identityId,
    },
    skip: !identityId,
  });

  const companyId = identityData?.identity.companyIdentity?.id;
  const individualIdentityId = identityData?.identity.individualIdentity?.id;

  /* ** Fetch pdfUrl documents ** */
  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();

  /* ** Delete document mutation ** */
  const [deleteKycDocument, { loading: loadingKycDelete }] = useDeleteKycDocumentMutation();
  const [deleteKybDocument, { loading: loadingKybDelete }] = useDeleteKybDocumentMutation();

  const isLoadingDelete = loadingKycDelete || loadingKybDelete;

  const handleFileDownload = async (documentId: string): Promise<void> => {
    const documentPdfUrl = await getDocumentPdfUrl({
      variables: {
        documentId,
      },
    });

    const links = documentPdfUrl?.data?.getDocumentPdfUrl;
    window.open(links, '_blank');
  };

  if (values?.length === 0) {
    return <DocumentTableEmpty />;
  }

  const onDeleteClick = (docType: IndividualDocumentType | CompanyDocumentType): void => {
    setDocumentType(docType);
    onOpen();
  };

  const handleDelete = (): void => {
    if (individualIdentityId) {
      deleteKycDocument({
        variables: {
          deleteKycDocumentInput: {
            individualIdentityId,
            documentType: documentType as IndividualDocumentType,
          },
        },
        onCompleted: () => {
          onDeleteCompleted();
          onClose();
        },
      });
    } else if (companyId) {
      deleteKybDocument({
        variables: {
          deleteKybDocumentInput: {
            companyId,
            documentType: documentType as CompanyDocumentType,
          },
        },
        onCompleted: () => {
          onDeleteCompleted();
          onClose();
        },
      });
    }
  };

  return (
    <TableContainer maxHeight="none">
      <Table size="md" variant="striped">
        <Thead>
          <Tr>
            <Th>{t('DocumentName')}</Th>
            <Th>{t('CreatedThe')}</Th>
            <Th>{t('LastUpdatedOn')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {values?.map((item) => (
            <Tr key={item.id}>
              <Td>
                <Stack align="center" alignItems="center" direction="row">
                  <Icon as={DocumentTextIcon} boxSize="18px" color="gray.500" />
                  <Text fontWeight="600">
                    {i18nDocumentValues(item?.type as IntlDocumentValues)}
                  </Text>
                </Stack>
              </Td>
              <Td fontWeight="600">{formatDate(item?.createdAt, 'DD MMMM YYYY')}</Td>
              <Td fontWeight="600">{formatDate(item?.updatedAt, 'DD MMMM YYYY')}</Td>
              <Td textAlign="right">
                <HStack justifyContent="flex-end" spacing="2">
                  <Button
                    rightIcon={<Icon as={DownloadIcon} boxSize="5" />}
                    variant="secondary"
                    onClick={(): Promise<void> => handleFileDownload(item.id || '')}
                  >
                    {t('Download')}
                  </Button>
                  {isDeleteAuthorized && (
                    <IconButton
                      aria-label={t('Delete')}
                      icon={<Icon as={TrashIcon} boxSize="5" />}
                      isLoading={isLoadingDelete}
                      variant="icon-delete"
                      onClick={(): void =>
                        onDeleteClick(item.type as IndividualDocumentType | CompanyDocumentType)
                      }
                    />
                  )}
                </HStack>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <DeleteConfirmModal
        isOpen={isOpen}
        leastDestructiveRef={confirmRef}
        subtitle={t('DefinitiveAction')}
        title={t('DeleteDocumentQuestion')}
        onClose={onClose}
        onDelete={handleDelete}
      />
    </TableContainer>
  );
}

export type DocumentTableProps = Props;
