import { generatePath, resolvePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  useCreateSecondaryOperationMutation,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import { useErrorToast } from '@blockpulse3/ui/commons';

import { NewSecondaryOperationInformations } from './NewSecondaryOperationInformations';
import { ISecondaryOperationInformationsForm } from './types';
import { getOperationParametersDefaults } from './utils';

type Props = unknown;

/**
 * NewSecondaryOperationCreate.
 *
 * @returns {JSX.Element}
 */
export function NewSecondaryOperationCreate(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const { companyId = '', opportunityId = null } = useParams();
  const navigate = useNavigate();

  const opportunityReq = useGetOperationQuery({
    variables: { operationId: opportunityId || '' },
    skip: !opportunityId,
  });
  const opportunity = opportunityReq.data?.operation;

  const [createSecondaryOperation, { loading: isCreateLoading }] =
    useCreateSecondaryOperationMutation();

  const defaultValues =
    opportunity &&
    getOperationParametersDefaults({
      asset: opportunity.asset,
      pricePerShare: opportunity.pricePerShare,
    });

  const handleFormSubmit = (data: ISecondaryOperationInformationsForm): void => {
    const redirectPrefix = opportunityId ? '../../' : '';

    createSecondaryOperation({
      variables: {
        createSecondaryOperationInput: {
          companyId,
          name: data.name,
          startSubscriptionDate: data.startSubscriptionDate,
          endSubscriptionDate: data.endSubscriptionDate,
          allowedFiscalAdvantages: [],
          assetId: data.asset?.value,
          pricePerShare: data.pricePerShare,
          isPromiseToSell: data.isPromiseToSell,
          usePivotFiscalAccount: data.usePivotFiscalAccount,
          opportunityId,
        },
      },
      onCompleted: (data) => {
        window.history.replaceState(
          null,
          '',
          generatePath(routes.company.newSecondary.edit.full, {
            companyId,
            operationId: data.createSecondary.id,
          }),
        );
        navigate(
          redirectPrefix +
            resolvePath(
              routes.company.newSecondary.edit.sellers.href,
              generatePath(routes.company.newSecondary.edit.href, {
                operationId: data.createSecondary.id,
              }),
            ).pathname,
          { relative: 'path' },
        );
      },
      onError: (e) => {
        errorToast({ title: t('OperationCreationError') });
      },
    });
  };

  return (
    <NewSecondaryOperationInformations
      defaultValues={defaultValues}
      isLoading={isCreateLoading}
      isRelatedToOpportunity={!!opportunityId}
      onSubmit={handleFormSubmit}
    />
  );
}

export type NewSecondaryOperationCreateProps = Props;
