import { Card, CardBody, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { HoldingMethod } from '@blockpulse3/data/shared';
import {
  useCreateFiscalAccountMutation,
  useGetFiscalAccountsQuery,
  useUpdateFiscalAccountMutation,
} from '@blockpulse3/graphql/hooks';
import {
  FiscalAccountFormModal,
  HoldingMethodSelect,
  IFiscalAccountForm,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { useManagedIndividual } from '../../../providers';

type Props = unknown;

export function FiscalAccountFormView(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { individual } = useManagedIndividual();

  const [fiscalAccountPartial, setFiscalAccountPartial] = useState<
    Partial<IFiscalAccountForm> | undefined
  >();

  const { refetch } = useGetFiscalAccountsQuery({
    variables: {
      individualIdentityId: individual?.id || '',
    },
    skip: !individual?.id,
  });
  const [createFiscalAccount] = useCreateFiscalAccountMutation();
  const [updateFiscalAccount] = useUpdateFiscalAccountMutation();

  const createFormModal = useDisclosure();
  const updateFormModal = useDisclosure();

  const handleFiscalAccountCreate = (data: IFiscalAccountForm): void => {
    if (!individual?.id) return;

    createFiscalAccount({
      variables: {
        createFiscalAccountInput: {
          individualIdentityId: individual.id,
          accountNumber: data.accountNumber,
          holdingMethod: data.holdingMethod,
          bankName: data.bankName,
          bankCode: data.bankCode,
          bankEmail: data.bankEmail || null,
        },
      },
      onCompleted: () => {
        createFormModal.onClose();
        successToast({ title: t('HoldingModeAdded') });
        refetch();
      },
      onError: () => {
        createFormModal.onClose();
        errorToast({ title: t('ErrorAddingOwnershipMode') });
      },
    });
  };

  const handleFiscalAccountUpdate = async (data: IFiscalAccountForm): Promise<void> => {
    if (!individual?.id || !data.id) return;

    updateFiscalAccount({
      variables: {
        updateFiscalAccountInput: {
          fiscalAccountId: data.id,
          individualIdentityId: individual.id,
          accountNumber: data.accountNumber,
          bankName: data.bankName,
          bankCode: data.bankCode,
          bankEmail: data.bankEmail || null,
        },
      },
      onCompleted: () => {
        refetch();
        updateFormModal.onClose();
      },
      onError: () => {
        errorToast({ title: t('ErrorRecordingOwnershipMode') });
      },
    });
  };

  const handleFiscalAccountCreateModalOpening = (holdingMethod: HoldingMethod): void => {
    setFiscalAccountPartial({ holdingMethod });
    createFormModal.onOpen();
  };

  const handleFiscalAccountUpdateModalOpening = (fiscalAccount: IFiscalAccountForm): void => {
    setFiscalAccountPartial(fiscalAccount);
    updateFormModal.onOpen();
  };

  return (
    <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '5', lg: '8' }}>
      <Text flexBasis={{ lg: 250 }} fontSize="lg" fontWeight="medium">
        {t('MyHoldingModes')}
      </Text>
      <Card maxW={{ lg: '3xl' }} w="full">
        <CardBody>
          <Stack spacing="4">
            <HoldingMethodSelect
              individualIdentityId={individual?.id || ''}
              isReading={true}
              onFiscalAccountCreate={handleFiscalAccountCreateModalOpening}
              onFiscalAccountUpdate={handleFiscalAccountUpdateModalOpening}
            />
          </Stack>
        </CardBody>
      </Card>
      {createFormModal.isOpen && (
        <FiscalAccountFormModal
          formPartialValues={fiscalAccountPartial}
          isOpen={createFormModal.isOpen}
          onClose={createFormModal.onClose}
          onSubmit={handleFiscalAccountCreate}
        />
      )}
      {updateFormModal.isOpen && (
        <FiscalAccountFormModal
          formPartialValues={fiscalAccountPartial}
          isOpen={updateFormModal.isOpen}
          onClose={updateFormModal.onClose}
          onSubmit={handleFiscalAccountUpdate}
        />
      )}
    </Stack>
  );
}

export type FiscalAccountFormViewProps = Props;
