import {
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { DocumentAddIcon, DownloadIcon, SearchIcon, XCircleIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  SubscriptionTab,
  SubscriptionsTabsStatus,
  isFeatureEnabled,
} from '@blockpulse3/data/shared';
import {
  SubscriptionsFilterInput,
  useGetTotalFilteredSubscriptionsCountAndAmountQuery,
} from '@blockpulse3/graphql/hooks';
import { downloadFile, formatNumberCurrency } from '@blockpulse3/helpers';
import { useErrorToast } from '@blockpulse3/ui/commons';

import { FundraisingArchiveImportModal } from '../../commons';
import { FundraisingSubscribersTableFilters } from './FundraisingSubscribersTableFilters';

type Props = {
  /* ** List of tab names and other properties ** */
  tabs: SubscriptionTab[];
  /* ** Controlled active tab index ** */
  activeTabIndex: number;
  /* ** Active filters ** */
  filters: SubscriptionsFilterInput[];
  /* ** Callback on filter update ** */
  onFiltersChange: (value: SubscriptionsFilterInput[]) => void;
  /* ** Callback on search submit ** */
  onSearchInputSubmit: (value: string) => void;
};

/**
 * FundraisingSubscribersControls.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function FundraisingSubscribersControls({
  tabs,
  activeTabIndex,
  filters,
  onFiltersChange,
  onSearchInputSubmit,
}: Props): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const { operationId = '' } = useParams();

  const [searchInput, setSearchInput] = useState<string>('');
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);
  const documentsImportModal = useDisclosure();

  const { data: countData, loading: countLoading } =
    useGetTotalFilteredSubscriptionsCountAndAmountQuery({
      variables: {
        operationId,
        filterBy: filters,
      },
      skip: !filters.length,
    });

  /* ** Filter modal button condition ** */
  const showFilters = [
    SubscriptionsTabsStatus.ALL_STATUS,
    SubscriptionsTabsStatus.PENDING,
  ].includes(tabs[activeTabIndex].name);

  const isFeatureDocumentImport = isFeatureEnabled('documentImport');
  const showImportDocument =
    isFeatureDocumentImport &&
    [
      SubscriptionsTabsStatus.APPROVED,
      SubscriptionsTabsStatus.FINALIZED,
      SubscriptionsTabsStatus.REFUSED,
    ].includes(tabs[activeTabIndex].name);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputReset = (): void => {
    setSearchInput('');
    onSearchInputSubmit('');
  };

  const handleSearchInputSubmit = (): void => {
    onSearchInputSubmit(searchInput);
  };

  const handleFiltersChange = (value: SubscriptionsFilterInput[]): void => {
    onFiltersChange(value);
  };

  const handleSubscribersExport = async (): Promise<void> => {
    setIsExportLoading(true);
    try {
      await axios
        .post(
          `${process.env['NX_API_CONTROLLER_ENDPOINT']}/operations/exportSubscriptions`,
          { operationId, filterBy: filters },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            responseType: 'blob',
          },
        )
        .then((response) => {
          downloadFile(response.data, 'export-souscriptions.csv');
        });
    } catch (err) {
      errorToast({ title: t('ExportCSVError') });
    } finally {
      setIsExportLoading(false);
    }
  };

  const totalSubscription: number =
    countData?.getTotalFilteredSubscriptionsCountAndAmount.totalSubscription || 0;

  const totalInvestAmount: number =
    countData?.getTotalFilteredSubscriptionsCountAndAmount.totalInvestAmount || 0;

  return (
    <Stack direction={{ base: 'column-reverse', md: 'row' }} justifyContent="space-between">
      <Stack direction={{ base: 'column', md: 'row' }}>
        <InputGroup width={{ base: 'full', md: '300px' }}>
          <Input
            paddingRight="80px"
            placeholder={t('Search')}
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={(e): void => {
              if (e.key === 'Enter') {
                handleSearchInputSubmit();
              }
            }}
          />
          <InputRightElement
            flexDirection="row-reverse"
            justifyContent="space-between"
            paddingX="1"
            width="80px"
          >
            <IconButton
              aria-label="search-database"
              icon={<Icon as={SearchIcon} />}
              size="sm"
              variant="secondary"
              onClick={handleSearchInputSubmit}
            />
            {!!searchInput && (
              <IconButton
                aria-label="reset"
                icon={<Icon as={XCircleIcon} />}
                size="sm"
                variant="ghost"
                onClick={handleSearchInputReset}
              />
            )}
          </InputRightElement>
        </InputGroup>
        {showFilters && (
          <FundraisingSubscribersTableFilters
            defaultValue={filters}
            onSubmit={handleFiltersChange}
          />
        )}
        {filters.length > 0 && !countLoading && (
          <HStack>
            <Text
              color="gray.800"
              fontSize="sm"
              px={{ base: '0', md: '3' }}
              rounded="md"
              textAlign="center"
            >
              {t.rich('SubscriptionCount', {
                nb: totalSubscription,
                important: (chunk) => (
                  <Text as="span" fontWeight="bold">
                    {chunk}
                  </Text>
                ),
              })}{' '}
              -{' '}
              {t.rich('AmountInvested', {
                nb: totalInvestAmount,
                nbFormatted: formatNumberCurrency(totalInvestAmount),
                important: (chunk) => (
                  <Text as="span" fontWeight="bold">
                    {chunk}
                  </Text>
                ),
              })}
            </Text>
          </HStack>
        )}
      </Stack>
      <HStack alignItems="flex-start">
        {showImportDocument && (
          <>
            <Button
              isDisabled={!tabs[activeTabIndex].count}
              rightIcon={<Icon as={DocumentAddIcon} />}
              variant="secondary"
              onClick={documentsImportModal.onOpen}
            >
              {t('ImportDocumentsInBatch')}
            </Button>
            <FundraisingArchiveImportModal
              isOpen={documentsImportModal.isOpen}
              onClose={documentsImportModal.onClose}
            />
          </>
        )}
        <Button
          isDisabled={!tabs[activeTabIndex].count}
          isLoading={isExportLoading}
          loadingText={t('FileGeneration')}
          rightIcon={<Icon as={DownloadIcon} />}
          variant="secondary"
          onClick={handleSubscribersExport}
        >
          {t('ExportAction')}
        </Button>
      </HStack>
    </Stack>
  );
}

export type FundraisingSubscribersControlsProps = Props;
