import {
  Badge,
  Button,
  Card,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ArrowNarrowRightIcon,
  BadgeCheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CurrencyEuroIcon,
  DocumentDuplicateIcon,
  TemplateIcon,
} from '@heroicons/react/outline';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  CompanyDocumentType,
  CompanyStatus,
  GetSpvRegistrationsQuery,
  SpvStatus,
  useGetSpvRegistrationsQuery,
  usePaySpvMutation,
} from '@blockpulse3/graphql/hooks';
import {
  ConfirmModal,
  IconButtonWithTooltip,
  TableContainer,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { Filter, fuzzyFilter } from '../TableUtils';
import { UploadSPVDocumentsModal } from './UploadSPVDocumentsModal';

type Props = unknown;

/**
 * AdminRegistrationsTable.
 * Display SVP's in a Table
 *
 * @returns {JSX.Element}
 */
export function AdminRegistrationsTable(): JSX.Element {
  const t = useTranslations();
  const [companyId, setCompanyId] = useState<string>('');

  const confirmRef = useRef(null);
  const navigate = useNavigate();

  const { data, loading, refetch } = useGetSpvRegistrationsQuery();
  const { onCopy, value, setValue, hasCopied } = useClipboard('');

  const [paySpv] = usePaySpvMutation();

  const successToast = useSuccessToast();
  const confirmModal = useDisclosure();
  const registerExtractModal = useDisclosure();
  const statutsModal = useDisclosure();

  const companyName = useMemo(
    () => data?.getSPVRegistrations?.find((spv) => spv.id === companyId)?.name || '',
    [data, companyId],
  );

  useEffect(() => {
    if (!value) return;

    onCopy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!hasCopied || !value) return;

    successToast({ title: `Id: ${value} copié` });
    setValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCopied, value]);

  const handleCopy = useCallback(
    (id: string): void => {
      setValue(id);
    },
    [setValue],
  );

  const getColorBadgeStatus = useMemo(
    () =>
      (status: SpvStatus | CompanyStatus): { color: string; label: string } => {
        switch (status) {
          case 'DRAFT':
            return { color: 'gray', label: status };
          case 'LOCKED':
            return { color: 'red', label: status };
          case 'EDITING':
            return { color: 'yellow', label: status };
          case 'PAID':
            return { color: 'pink', label: status };
          case 'VALIDATED':
          case 'VERIFIED':
          case 'SIGNED':
            return { color: 'blue', label: status };
          case 'CREATING':
            return { color: 'green', label: status };
          default:
            return { color: 'gray', label: t('None') };
        }
      },
    [],
  );

  const handleOpenModalRow = (
    companyId: string,
    modalType: 'confirmModal' | 'registerExtractModal' | 'statutsModal',
  ): void => {
    setCompanyId(companyId);
    if (modalType === 'confirmModal') {
      confirmModal.onOpen();
    } else if (modalType === 'registerExtractModal') {
      registerExtractModal.onOpen();
    } else {
      statutsModal.onOpen();
    }
  };

  const handleConfirmSPVPayment = async (): Promise<void> => {
    if (!companyId) return;

    await paySpv({
      variables: {
        paySPVInput: {
          companyId,
        },
      },
      onCompleted: () => {
        refetch();
        successToast({ title: t('StatusUpdated') });
        confirmModal.onClose();
      },
    });
  };

  const columnHelper = useMemo(
    () => createColumnHelper<GetSpvRegistrationsQuery['getSPVRegistrations'][0]>(),
    [],
  );
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('CompanyName'),
        enableSorting: true,
        enableColumnFilter: false,
        cell: (row) => (
          <Td align="right">
            <Text as="b" maxW="100px">
              {row.getValue()}
            </Text>
          </Td>
        ),
      }),
      columnHelper.accessor('status', {
        header: t('Status', { nb: 1 }),
        enableSorting: true,
        enableColumnFilter: false,
        cell: (row) => {
          const badge = getColorBadgeStatus(row.getValue());
          return (
            <Td align="right">
              <Badge colorScheme={badge.color}>{badge.label}</Badge>
            </Td>
          );
        },
      }),
      columnHelper.accessor(() => 'actions', {
        header: 'Actions',
        enableSorting: false,
        enableColumnFilter: false,
        cell: (info) => {
          const data = info.row.original;

          const canUpdatePaidStatus = data.spvStatus === SpvStatus.EDITING;

          const canUploadStatut =
            data.spvStatus === SpvStatus.EDITING ||
            data.spvStatus === SpvStatus.PAID ||
            data.spvStatus === SpvStatus.VALIDATED;

          return (
            <Td>
              <HStack spacing="1">
                <IconButtonWithTooltip
                  aria-label="update spvStatus to PAID"
                  icon={<Icon as={CurrencyEuroIcon} boxSize="18px" color="gray.700" />}
                  isDisabled={!canUpdatePaidStatus || data.spvStatus === SpvStatus.PAID}
                  label={t('ConfirmSPVPayment')}
                  variant="secondary"
                  onClick={(): void => handleOpenModalRow(data.id, 'confirmModal')}
                />
                <IconButtonWithTooltip
                  aria-label="upload template statut"
                  icon={<Icon as={TemplateIcon} boxSize="18px" color="gray.700" />}
                  isDisabled={!canUploadStatut}
                  label={t('ImportStatutsTemplate')}
                  variant="secondary"
                  onClick={(): void => handleOpenModalRow(data.id, 'statutsModal')}
                />
                <IconButtonWithTooltip
                  aria-label="upload register extract"
                  icon={<Icon as={BadgeCheckIcon} boxSize="18px" color="gray.700" />}
                  isDisabled={data.spvStatus !== SpvStatus.CREATING}
                  label={t('UploadRegisterExtract')}
                  variant="secondary"
                  onClick={(): void => handleOpenModalRow(data.id, 'registerExtractModal')}
                />
              </HStack>
            </Td>
          );
        },
      }),
      columnHelper.accessor('spvStatus', {
        header: t('SPVStatus'),
        enableSorting: true,
        enableColumnFilter: false,
        cell: (row) => {
          const badge = getColorBadgeStatus(row.getValue() as SpvStatus);
          return (
            <Td align="right">
              <Badge colorScheme={badge.color}>{badge.label}</Badge>
            </Td>
          );
        },
      }),
      columnHelper.accessor('id', {
        header: 'id',
        enableSorting: false,
        enableColumnFilter: false,
        cell: (row) => (
          <Td>
            <HStack maxWidth="250px" spacing="1">
              <Text isTruncated color="gray.500" maxW="100px">
                {row.getValue()}
              </Text>
              <Icon
                as={DocumentDuplicateIcon}
                boxSize="20px"
                cursor="pointer"
                onClick={(): void => handleCopy(row.getValue())}
              />
            </HStack>
          </Td>
        ),
      }),
      columnHelper.accessor('registrationNumber', {
        header: '',
        enableSorting: true,
        enableColumnFilter: false,
        cell: (info) => (
          <Td textAlign="right">
            <Button
              rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
              variant="secondary"
              onClick={(): void => {
                const companyId = info.row.original.id;
                const matriculationPath = generatePath(
                  routes.onboardings.spv.edit.matriculation.full,
                  { companyId },
                );
                const spacePath = routes.space.registrations.full;

                navigate(`${spacePath}${matriculationPath}`);
              }}
            >
              {t('Access')}
            </Button>
          </Td>
        ),
      }),
    ],
    [t, columnHelper],
  );

  const table = useReactTable({
    data: data?.getSPVRegistrations?.length ? data?.getSPVRegistrations : [],
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="400px" />
      </Skeleton>
    );
  }

  const handleUploadCompleted = (): void => {
    registerExtractModal.onClose();
    refetch();
  };

  return (
    <Stack spacing="4">
      <TableContainer maxH="none">
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    <HStack justifyContent="space-between" spacing="2">
                      <HStack
                        cursor={header.column.getCanSort() ? 'pointer' : 'initial'}
                        spacing="0"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Text>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </Text>
                        {header.column.getIsSorted() && (
                          <Icon
                            boxSize="16px"
                            as={
                              header.column.getIsSorted() === 'asc'
                                ? ChevronUpIcon
                                : ChevronDownIcon
                            }
                          />
                        )}
                      </HStack>
                      {header.column.getCanFilter() && (
                        <Filter column={header.column} table={table} />
                      )}
                    </HStack>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) =>
                  flexRender(cell.column.columnDef.cell, {
                    ...cell.getContext(),
                    key: cell.id,
                  }),
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <ConfirmModal
        isOpen={confirmModal.isOpen}
        leastDestructiveRef={confirmRef}
        title={t('ConfirmSPVPayment')}
        onClose={confirmModal.onClose}
        onConfirm={async (): Promise<void> => await handleConfirmSPVPayment()}
      />
      <UploadSPVDocumentsModal
        companyId={companyId}
        documentType={CompanyDocumentType.REGISTER_EXTRACT}
        isOpen={registerExtractModal.isOpen}
        title={t('UploadRegisterExtractOf', { companyName })}
        onClose={handleUploadCompleted}
      />
      <UploadSPVDocumentsModal
        companyId={companyId}
        documentType={CompanyDocumentType.STATUTS}
        isOpen={statutsModal.isOpen}
        title={t('ImportStatutsTemplate')}
        onClose={statutsModal.onClose}
      />
    </Stack>
  );
}

export type AdminRegistrationsTableProps = Props;
