import {
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  useCreateAssetPriceByCompanyMutation,
  useGetAssetPricesByCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorMessage } from '@blockpulse3/ui/commons';
import { useStepFormContext } from '@blockpulse3/web-client/onboardings';

import { assetPriceFormSchema } from './schema';
import { IAssetPrice } from './type';

type Props = {
  onCancel?: () => void;
  onSubmit: () => void;
};

export function SettingsCompanyAssetPriceForm({ onSubmit, onCancel = noop }: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const { register, formState, handleSubmit } = useForm<IAssetPrice>({
    resolver: yupResolver(assetPriceFormSchema),
  });

  const getAssetPricesByCompanyReq = useGetAssetPricesByCompanyQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const { setCancelHandler, setSubmitHandler } = useStepFormContext();

  const [createAssetPrice] = useCreateAssetPriceByCompanyMutation();

  const handleFormSubmit: SubmitHandler<IAssetPrice> = useCallback(
    (data) => {
      createAssetPrice({
        variables: {
          createAssetPriceByCompanyInput: {
            companyId,
            date: data.date,
            price: data.price,
          },
        },
        onCompleted: () => {
          getAssetPricesByCompanyReq.refetch();
        },
      });
      onSubmit();
    },
    [onSubmit, companyId],
  );

  const handleStepSubmit = useCallback((): void => {
    handleSubmit(handleFormSubmit)();
  }, [handleSubmit, handleFormSubmit]);

  const handleStepCancel = useCallback((): void => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    setSubmitHandler(handleStepSubmit);
    setCancelHandler(handleStepCancel);
  }, [handleStepSubmit, handleStepCancel, setSubmitHandler, setCancelHandler]);

  return (
    <form id="asset-price" onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
        <FormControl isInvalid={!!formState.errors.date}>
          <FormLabel htmlFor="lastName">{t('Date', { nb: 1 })}</FormLabel>
          <Input id="date" type="date" {...register('date')} />
          <ErrorMessage error={formState.errors?.date} />
        </FormControl>
        <FormControl isInvalid={!!formState.errors?.price}>
          <HStack alignItems="flex-start" spacing="0">
            <FormLabel htmlFor="price">{t('PricePerShare')}</FormLabel>
          </HStack>
          <InputGroup>
            <Input id="price" step="0.01" type="number" {...register('price')} />
            <InputRightAddon>€</InputRightAddon>
          </InputGroup>
          <ErrorMessage error={formState.errors?.price} />
        </FormControl>
      </Stack>
    </form>
  );
}
