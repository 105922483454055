import {
  Badge,
  Card,
  CardBody,
  Flex,
  HStack,
  Heading,
  Hide,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  OptionTokenStatus,
  useGetCompanyQuery,
  useGetOptionAssetQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import { CompanyIdentityAvatar, ErrorQueryCard } from '@blockpulse3/ui/commons';
import { useBadge } from '@blockpulse3/ui/ui-hooks';

type Props = unknown;

/**
 * OptionHeader.
 *
 * @returns {JSX.Element}
 */
export function OptionHeader(): JSX.Element {
  const t = useTranslations();
  const i18nBadgeValues = useTranslations('BadgeValues');

  const { assetId = '', companyId = '' } = useParams();

  const { getBadge } = useBadge(
    null,
    [
      { value: OptionTokenStatus.LOCKED, label: i18nBadgeValues('Locked'), color: 'red' },
      { value: OptionTokenStatus.RUNNING, label: i18nBadgeValues('InProgress'), color: 'green' },
    ],
    { label: i18nBadgeValues('InProgress'), color: 'green' },
  );

  const { data, loading, error } = useGetOptionAssetQuery({
    variables: {
      getAssetInput: {
        assetId,
        companyId,
      },
    },
  });

  const {
    data: companyData,
    loading: companyLoading,
    error: companyError,
  } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  if (error || companyError) {
    return <ErrorQueryCard h="200px" />;
  }

  if (loading || companyLoading || !data) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  const option = data.getOptionAsset;
  const optionStatus = getBadge(option.data.status);
  const company = companyData?.company;

  return (
    <Card>
      <Flex as={CardBody} direction={{ base: 'column', md: 'row' }}>
        <Hide below="md">
          <CompanyIdentityAvatar boxSize="32" src={company?.identity?.profilePicture} />
        </Hide>
        <Stack
          alignItems="flex-start"
          justifyContent="center"
          pb={{ base: '4', md: '0' }}
          px={{ base: '0', md: '6' }}
          width="full"
        >
          <Text color="gray.500" fontSize="xs" fontWeight="semibold">
            {option.assetType.replace('_', ' ')}
          </Text>
          <Heading fontWeight="bold">{option.name}</Heading>
          <Badge colorScheme={optionStatus.color}>{optionStatus.label}</Badge>
        </Stack>
        <Flex
          alignItems="flex-end"
          bg="gray.50"
          direction="column"
          flexShrink="0"
          justifyContent="center"
          p="5"
          rounded="sm"
        >
          <HStack>
            <Text color="gray.600" fontWeight="500">
              {t('FinishOn')}
            </Text>
          </HStack>
          <Text color="gray.600" fontSize="2xl" fontWeight="600">
            {formatDate(dayjs.unix(option.expirationDate).toDate(), 'D MMMM YYYY')}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}

export type OptionHeaderProps = Props;
