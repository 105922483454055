/**
 * formatReferenceLabel.
 * Format the reference label for a transfer
 *
 * @param {string} identityName
 * @param {string} referenceNumber
 * @returns {string}
 */
export function formatReferenceLabel(identityName: string, referenceNumber: string): string {
  return `${formatReferenceInitials(identityName)}-${referenceNumber}`;
}

/**
 * formatReferenceInitials.
 * Format the reference label for a transfer
 *
 * @param {string} identityName
 * @param {string} referenceNumber
 * @returns {string}
 */
export function formatReferenceInitials(identityName: string): string {
  const nameWords = identityName.split(' ');
  const nameInitials =
    nameWords.length > 1
      ? nameWords
          .map((word) => word[0])
          .slice(0, 4)
          .join('')
      : nameWords[0].slice(0, 2);

  return nameInitials.toUpperCase();
}
