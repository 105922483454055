import { AssetInfosFragment } from '@blockpulse3/graphql/hooks';

import { IBondAssetDraft, bondAssetDraftDefaultValues } from '../CreateBondAssetDraftModal';
import { OperationAssetOption } from './types';

export function getBondAssetDraftInfos(draft?: AssetInfosFragment | null): IBondAssetDraft {
  if (!draft || !draft.data) {
    return bondAssetDraftDefaultValues;
  }

  return {
    assetId: draft.id,
    type: { label: draft.data.type, value: draft.data.type },
    durationUnit: { label: draft.data.durationUnit, value: draft.data.durationUnit },
    name: draft.data.name,
    code: draft.data.code,
    date: draft.data.date,
    period: draft.data.period,
    maturityDate: draft.data.maturityDate,
    nominalValue: draft.data.nominalValue,
    issuancePremium: draft.data.issuancePremium,
    redemptionPremium: draft.data.redemptionPremium,
    interestRate: draft.data.interestRate,
    interestPeriod: draft.data.interestPeriod,
    interestPaymentInterval: draft.data.interestPaymentInterval,
    reimbursementInterval: draft.data.reimbursementInterval,
    transferable: draft.data.transferable,
    accelerable: draft.data.accelerable,
    extendable: draft.data.extendable,
  };
}

/**
 * getBondAssetDraftsOptions.
 * Returns draft bonds asset options to fill configuration drop down.
 *
 * @param {BondAssetDraftInfosFragment[]} bondAssetDrafts
 * @returns {OperationAssetOption[]}
 */
export function getBondAssetDraftsOptions(
  bondAssetDrafts?: AssetInfosFragment[],
): OperationAssetOption[] {
  if (!bondAssetDrafts) {
    return [];
  }

  return bondAssetDrafts.map((draft) => ({
    label: draft.name,
    type: draft.type,
    value: draft.id,
    data: getBondAssetDraftInfos(draft),
  }));
}
