import { Route, Routes } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import {
  OnboardingSPVAccess,
  OnboardingSPVCreate,
  OnboardingSPVFinancialInformations,
  OnboardingSPVLayout,
  OnboardingSPVParameters,
  OnboardingSPVRepresentatives,
  OnboardingSPVShareholders,
  OnboardingSPVSummary,
} from '@blockpulse3/web-client/onboardings';
import { SPVView } from '@blockpulse3/web-client/spv-registry';

import { AppLayout, EmptyLayout } from '../layouts';

export function OnboardingSPVRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<AppLayout />} path={routes.onboardings.spv.edit.href + '/*'}>
        <Route element={<SPVView />} path={routes.onboardings.spv.edit.matriculation.href} />
      </Route>
      <Route element={<EmptyLayout />}>
        <Route element={<OnboardingSPVLayout />}>
          <Route index element={<OnboardingSPVCreate />} />
          <Route path={routes.onboardings.spv.edit.href + '/*'}>
            <Route index element={<OnboardingSPVParameters />} />
            <Route
              element={<OnboardingSPVFinancialInformations />}
              path={routes.onboardings.spv.edit.compensation.href}
            />
            <Route
              element={<OnboardingSPVRepresentatives />}
              path={routes.onboardings.spv.edit.representatives.href}
            />
            <Route
              element={<OnboardingSPVShareholders />}
              path={routes.onboardings.spv.edit.shareholders.href}
            />
            <Route
              element={<OnboardingSPVAccess />}
              path={routes.onboardings.spv.edit.access.href}
            />
            <Route
              element={<OnboardingSPVSummary />}
              path={routes.onboardings.spv.edit.summary.href}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}
