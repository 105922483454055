import { Card, CardBody, Stack, useDisclosure } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useTranslations } from 'use-intl';

import { Space, useDeleteSpaceMutation, useGetSpacesQuery } from '@blockpulse3/graphql/hooks';
import { ConfirmModal, useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';

import { NewSpaceFormModal, UpdateSpaceFormModal } from './SpaceFormModal';
import { SpaceListControls } from './SpaceListControls';
import { SpaceListTable } from './SpaceListTable';
import { PAGE_SIZE } from './utils';

type Props = unknown;

/**
 * SpaceList.
 *
 * @returns {JSX.Element}
 */
export function SpaceList(): JSX.Element {
  const t = useTranslations();

  const confirmRef = useRef(null);

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const createSpaceModal = useDisclosure();
  const updateSpaceModal = useDisclosure();
  const deleteSpaceModal = useDisclosure();

  const [updateSpaceId, setUpdateSpaceId] = useState<Space['id'] | null>(null);
  const [deleteSpaceId, setDeleteSpaceId] = useState<Space['id'] | null>(null);

  const { refetch } = useGetSpacesQuery({ variables: { first: PAGE_SIZE }, skip: true });

  const [deleteSpace] = useDeleteSpaceMutation();

  const handleSpaceAddModal = (): void => {
    createSpaceModal.onOpen();
  };

  const handleSpaceAddSuccess = (): void => {
    createSpaceModal.onClose();
    successToast({ title: t('CreateSpaceSuccess') });
    refetch();
  };

  const handleSpaceAddError = (): void => {
    createSpaceModal.onClose();
    errorToast({ title: t('CreateSpaceError') });
  };

  const handleSpaceUpdateModal = (spaceId: Space['id']): void => {
    setUpdateSpaceId(spaceId);
    updateSpaceModal.onOpen();
  };

  const handleSpaceUpdateSuccess = (): void => {
    setUpdateSpaceId(null);
    updateSpaceModal.onClose();
    refetch();
  };

  const handleSpaceUpdateError = (): void => {
    setUpdateSpaceId(null);
    updateSpaceModal.onClose();
  };

  const handleSpaceDeleteModal = (spaceId: Space['id']): void => {
    setDeleteSpaceId(spaceId);
    deleteSpaceModal.onOpen();
  };

  const handleSpaceDelete = (): void => {
    if (!deleteSpaceId) return;

    deleteSpace({
      variables: { spaceId: deleteSpaceId },
      onCompleted: () => {
        successToast({ title: t('DeleteSpaceSuccess') });
        setDeleteSpaceId(null);
        deleteSpaceModal.onClose();
        refetch();
      },
      onError: () => {
        errorToast({ title: t('DeleteSpaceError') });
        setDeleteSpaceId(null);
        deleteSpaceModal.onClose();
      },
    });
  };

  return (
    <Card>
      <CardBody as={Stack} spacing="4">
        <NewSpaceFormModal
          isOpen={createSpaceModal.isOpen}
          onClose={createSpaceModal.onClose}
          onSubmitError={handleSpaceAddError}
          onSubmitSuccess={handleSpaceAddSuccess}
        />
        {updateSpaceId && (
          <UpdateSpaceFormModal
            isOpen={updateSpaceModal.isOpen}
            spaceId={updateSpaceId}
            onClose={updateSpaceModal.onClose}
            onSubmitError={handleSpaceUpdateError}
            onSubmitSuccess={handleSpaceUpdateSuccess}
          />
        )}
        <SpaceListControls onSpaceAdd={handleSpaceAddModal} />
        <SpaceListTable
          onRowDeleteClick={handleSpaceDeleteModal}
          onRowUpdateClick={handleSpaceUpdateModal}
        />
        <ConfirmModal
          isOpen={deleteSpaceModal.isOpen}
          leastDestructiveRef={confirmRef}
          subtitle={t('DefinitiveAction')}
          title={t('ConfirmSpaceDeleteTitle')}
          onClose={deleteSpaceModal.onClose}
          onConfirm={handleSpaceDelete}
        />
      </CardBody>
    </Card>
  );
}

export type SpaceListProps = Props;
