import * as yup from 'yup';

import { requiredStringField } from '@blockpulse3/data/shared';

import { RepatriationShareTransferRegisterIdentitiesForm } from './types';

export const shareTransferRegisterIdentitiesSchema = yup.object({
  individualIdentitiesFile: requiredStringField,
  companyIdentitiesFile: requiredStringField,
}) as yup.ObjectSchema<RepatriationShareTransferRegisterIdentitiesForm>;
