import { InfoIcon } from '@chakra-ui/icons';
import {
  FormControl,
  HStack,
  Icon,
  IconButton,
  ListItem,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import { PhotographIcon, TrashIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { TranslationValues, useTranslations } from 'use-intl';

import { FormErrors, isFeatureEnabled } from '@blockpulse3/data/shared';
import {
  IdentityType,
  OperationDocumentType,
  useDeleteFundsSourceCertificateMutation,
  useGetDocumentPdfUrlLazyQuery,
  useGetSubscriptionDocumentsQuery,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import {
  CONTENT_LOADING,
  DocumentLink,
  DropzoneInput,
  ErrorMessage,
  PreviewDocumentModal,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionDetailsForm } from '../Steps/SubscriptionDetailsStep/SubscriptionDetailsModal/types';

type Props = {
  errorValues: TranslationValues | undefined;
};

export function FundsSourceUpload({ errorValues }: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentValues = useTranslations('DocumentValues');

  const { identityId } = useIdentity();

  const [documentPdfUrl, setDocumentPdfUrl] = useState<string | symbol | null>(CONTENT_LOADING);
  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { subscriptionId = '' } = useParams();
  const { data, refetch: subscriptionRefetch } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const subscription = data?.subscription;

  const {
    data: documentData,
    loading: documentLoading,
    refetch: documentRefetch,
  } = useGetSubscriptionDocumentsQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const fundsSourceCertificates =
    documentData?.getSubscriptionDocuments?.filter(
      (doc) => doc.type === OperationDocumentType.FUNDS_SOURCE_CERTIFICATE,
    ) || [];

  const [deleteDocument, { loading: isDeletionLoading }] =
    useDeleteFundsSourceCertificateMutation();
  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();

  /* ** Retrieve all hook methods ** */
  const { setError, clearErrors, control, formState } = useFormContext<SubscriptionDetailsForm>();

  /* ** Method to upload a file using axios ** */
  const handleFileUpload = async (acceptedFiles: File[]): Promise<void> => {
    if (!subscriptionId) return;

    const formData = new FormData();
    formData.append('subscriptionDocument', acceptedFiles[0]);
    formData.append('subscriptionId', subscriptionId);

    setIsFileLoading(true);
    await axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/subscriptions/uploadFundsSourceCertificate',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(() => {
        successToast({ title: t('SuccessfulFileUpload') });
      })
      .catch(() => {
        setError('fundsSourceCertificate', {
          type: 'custom',
          message: FormErrors.DropzoneDefault,
        });
      });

    await documentRefetch();
    setIsFileLoading(false);
  };

  /* ** Upload file handler ** */
  const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    clearErrors('fundsSourceCertificate');
    if (fileRejections.length === 0) {
      handleFileUpload(acceptedFiles);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError('fundsSourceCertificate', { type: 'custom', message: FormErrors.FileTooLarge });
          break;
        }
        default: {
          setError('fundsSourceCertificate', {
            type: 'custom',
            message: FormErrors.DropzoneDefault,
          });
          break;
        }
      }
    }
  };

  /* ** Delete file handler ** */
  const handleFileDelete = (documentId: string): void => {
    if (!documentId) return;

    deleteDocument({
      variables: {
        subscriptionId,
        documentId,
      },
      onCompleted: () => {
        documentRefetch();
        subscriptionRefetch();
      },
      onError: () => {
        errorToast({ title: t('FileDeleteError') });
      },
    });
  };

  const handleFilePreview = (documentId: string): void => {
    if (!documentId) return;
    setDocumentPdfUrl(CONTENT_LOADING);
    onOpen();

    getDocumentPdfUrl({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        setDocumentPdfUrl(pdfUrl);
      },
    });
  };

  const isIndividual = subscription?.buyerIdentity?.type === IdentityType.INDIVIDUAL;

  const isFeatureFinancingInfo = isFeatureEnabled('financingInfoBanner');

  return (
    <Stack>
      <HStack>
        <Text fontSize="md" fontWeight="600">
          {t('PleaseProvideProofOfFunds')}
        </Text>
        <Tooltip
          hasArrow
          placement="right"
          label={
            <UnorderedList>
              <ListItem>{t('ReinvestmentAssetTransfer')}</ListItem>
              <ListItem>
                {t('CreditOfferCopy')} {isFeatureFinancingInfo && t('FinancingInfoDetail')}
              </ListItem>
              {isIndividual ? (
                <>
                  <ListItem>{t('SavingsCopy')}</ListItem>
                  <ListItem>{t('InheritanceCertificateRequired')}</ListItem>
                  <ListItem>{t('DonationCopy')}</ListItem>
                </>
              ) : (
                <ListItem>{t('CompanyActivity')}</ListItem>
              )}
              <ListItem>{t('OtherJustificationDocument')}</ListItem>
            </UnorderedList>
          }
        >
          <Icon as={InfoIcon} color="gray.600" />
        </Tooltip>
      </HStack>
      {!documentLoading && fundsSourceCertificates?.length < 3 && (
        <Stack bgColor="gray.50" p="4">
          <Controller
            control={control}
            name="fundsSourceCertificate"
            render={(): JSX.Element => (
              <FormControl>
                <DropzoneInput
                  isPreviewEnabled
                  accept={{ 'application/pdf': [], 'image/png': [], 'image/jpeg': [] }}
                  files={{}}
                  isLoading={isFileLoading}
                  maxFiles={1}
                  subTitle={t('ImageUpTo5MB')}
                  icon={
                    <Icon as={PhotographIcon} boxSize="42px" color="gray.400" strokeWidth="1px" />
                  }
                  onDelete={handleFileDelete}
                  onDrop={handleFileDrop}
                />
                <ErrorMessage
                  error={formState.errors?.fundsSourceCertificate}
                  values={errorValues}
                />
              </FormControl>
            )}
          />
        </Stack>
      )}
      {fundsSourceCertificates &&
        fundsSourceCertificates.map((file) => {
          return (
            <HStack key={file.id} justify="space-between">
              <DocumentLink
                fileName={file.title}
                onClick={(): void => handleFilePreview(file.id)}
              />
              {file.parentDocumentType === 'SubscriptionDocument' && (
                <IconButton
                  aria-label="delete-document"
                  icon={<Icon as={TrashIcon} boxSize="4" color="red.500" />}
                  isDisabled={isDeletionLoading}
                  size="xs"
                  variant="ghost"
                  onClick={(): void => handleFileDelete(file.id)}
                />
              )}
            </HStack>
          );
        })}

      <PreviewDocumentModal
        isOpen={isOpen}
        src={documentPdfUrl}
        title={i18nDocumentValues('FUNDS_SOURCE_CERTIFICATE')}
        onClose={onClose}
      />
    </Stack>
  );
}
