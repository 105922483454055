import {
  Button,
  Card,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { HoldingMethod } from '@blockpulse3/data/shared';
import {
  OperationStatus,
  useCorrectSubscriptionHoldingMethodMutation,
  useCreateFiscalAccountByManagerMutation,
  useGetFiscalAccountsQuery,
  useGetSubscriptionQuery,
  useUpdateFiscalAccountByManagerMutation,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorQueryCard,
  FiscalAccountFormModal,
  HoldingMethodSelect,
  IFiscalAccountForm,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

type Props = {
  subscriptionId?: string;
  isSeller?: boolean;
} & Omit<ResponsiveModalProps, 'children'>;

export function ChangeHoldingMethodModal({
  subscriptionId: subscriptionIdProp = '',
  isSeller = false,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const { onClose } = props;

  const { subscriptionId = subscriptionIdProp } = useParams();

  const [selectedHoldingMethod, setSelectedHoldingMethod] = useState<HoldingMethod>();
  const [currentHoldingMethod, setCurrentHoldingMethod] = useState<HoldingMethod>();
  const [fiscalAccountPartial, setFiscalAccountPartial] = useState<
    Partial<IFiscalAccountForm> | undefined
  >();

  const createFormModal = useDisclosure();
  const updateFormModal = useDisclosure();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const [correctSubscriptionHoldingMethod] = useCorrectSubscriptionHoldingMethodMutation();
  const [createFiscalAccountByManager] = useCreateFiscalAccountByManagerMutation();
  const [updateFiscalAccountByManager] = useUpdateFiscalAccountByManagerMutation();

  const subscriptionReq = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const { subscription } = subscriptionReq.data || {};

  const allowedFiscalAdvantages = subscription?.operation?.allowedFiscalAdvantages || [];
  const subscriptionIdentity = isSeller
    ? subscription?.sellerIdentity
    : subscription?.buyerIdentity;
  const individualIdentityId = subscriptionIdentity?.individualIdentity?.id || '';

  const fiscalAccountReq = useGetFiscalAccountsQuery({
    variables: { individualIdentityId },
    skip: !individualIdentityId,
  });

  if (subscriptionReq.loading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (subscriptionReq.error) {
    return <ErrorQueryCard />;
  }

  if (!subscriptionReq.data || !subscription) {
    return <ErrorQueryCard />;
  }

  const isFundraisingFinalized = subscription?.operation.status === OperationStatus.FINALIZED;

  const handleFiscalAccountCreateModalOpening = (holdingMethod: HoldingMethod): void => {
    setFiscalAccountPartial({ holdingMethod });
    createFormModal.onOpen();
  };

  const handleFiscalAccountUpdateModalOpening = (fiscalAccount: IFiscalAccountForm): void => {
    setFiscalAccountPartial(fiscalAccount);
    updateFormModal.onOpen();
  };

  const handleHoldingMethodChange = (holdingMethod: HoldingMethod): void => {
    if (!holdingMethod) return;
    setSelectedHoldingMethod(holdingMethod);
  };

  const handleSubmit = (): void => {
    const hasMethodChanged =
      JSON.stringify(selectedHoldingMethod) !== JSON.stringify(currentHoldingMethod);

    if (hasMethodChanged && selectedHoldingMethod) {
      correctSubscriptionHoldingMethod({
        variables: {
          updateSubscriptionHoldingMethodInput: {
            subscriptionId,
            holdingMethod: selectedHoldingMethod,
            companyId: subscription.operation.company.id,
          },
        },
        onCompleted: () => {
          successToast({ title: t('HoldingMethodUpdated') });
          setCurrentHoldingMethod(selectedHoldingMethod);
          subscriptionReq.refetch();
          fiscalAccountReq.refetch();
          onClose();
        },
        onError: () => {
          errorToast({ title: t('ErrorRecordingOwnershipMode') });
        },
      });
    } else {
      onClose();
    }
  };

  const handleFiscalAccountCreate = async (data: IFiscalAccountForm): Promise<void> => {
    if (individualIdentityId) {
      createFiscalAccountByManager({
        variables: {
          createFiscalAccountInput: {
            individualIdentityId,
            accountNumber: data.accountNumber,
            holdingMethod: data.holdingMethod,
            bankName: data.bankName,
            bankCode: data.bankCode,
            bankEmail: data.bankEmail || null,
            companyId: subscription.operation.company.id,
            operationId: subscription.operation.id,
          },
        },
        onCompleted: (data) => {
          const fiscalAccount = data.createFiscalAccountByManager;
          fiscalAccountReq.refetch();
          setSelectedHoldingMethod(fiscalAccount.holdingMethod);
          createFormModal.onClose();
        },
        onError: () => {
          createFormModal.onClose();
          errorToast({ title: t('ErrorAddingOwnershipMode') });
          onClose();
        },
      });
    }
    createFormModal.onClose();
  };

  const handleFiscalAccountUpdate = async (data: IFiscalAccountForm): Promise<void> => {
    if (individualIdentityId && data.id) {
      updateFiscalAccountByManager({
        variables: {
          updateFiscalAccountInput: {
            fiscalAccountId: data.id,
            individualIdentityId,
            accountNumber: data.accountNumber,
            bankName: data.bankName,
            bankCode: data.bankCode,
            bankEmail: data.bankEmail || null,
            companyId: subscription.operation.company.id,
            operationId: subscription.operation.id,
          },
        },
        onCompleted: () => {
          successToast({ title: t('HoldingMethodRecorded') });
          fiscalAccountReq.refetch();
          updateFormModal.onClose();
        },
        onError: () => {
          errorToast({ title: t('ErrorRecordingOwnershipMode') });
        },
      });
    }
  };

  return (
    <>
      <ResponsiveModal {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('UpdateHoldingMethod')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="500">
            <HoldingMethodSelect
              allowedFiscalAdvantages={allowedFiscalAdvantages}
              defaultValue={subscription?.holdingMethod}
              individualIdentityId={individualIdentityId}
              isDisabled={isFundraisingFinalized}
              onFiscalAccountCreate={handleFiscalAccountCreateModalOpening}
              onFiscalAccountUpdate={handleFiscalAccountUpdateModalOpening}
              onHoldingMethodChange={handleHoldingMethodChange}
            />
          </ModalBody>
          <Divider />
          <ResponsiveModalFooter>
            <Button onClick={handleSubmit}>{t('Validate')}</Button>
          </ResponsiveModalFooter>
        </ModalContent>
      </ResponsiveModal>
      {createFormModal.isOpen && (
        <FiscalAccountFormModal
          formPartialValues={fiscalAccountPartial}
          isOpen={createFormModal.isOpen}
          onClose={createFormModal.onClose}
          onSubmit={handleFiscalAccountCreate}
        />
      )}
      {updateFormModal.isOpen && (
        <FiscalAccountFormModal
          formPartialValues={fiscalAccountPartial}
          isOpen={updateFormModal.isOpen}
          onClose={updateFormModal.onClose}
          onSubmit={handleFiscalAccountUpdate}
        />
      )}
    </>
  );
}
