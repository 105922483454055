import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { Exceptions } from '@blockpulse3/data/shared';
import {
  EmailVerificationStatus,
  useCreateUserMutation,
  useLoginMutation,
} from '@blockpulse3/graphql/hooks';
import { CheckEmail, useErrorToast } from '@blockpulse3/ui/commons';

import { useAuthUser, useIntl } from '../../providers';
import { ISignUpForm, SignUpForm } from '../SignUpForm';

type Props = unknown;

export function SignUpView(): JSX.Element {
  const t = useTranslations();

  const { locale } = useIntl();

  const { refetch } = useAuthUser();

  const errorToast = useErrorToast();

  const [createUser, { loading }] = useCreateUserMutation();
  const [login] = useLoginMutation();
  const [tokenType, setTokenType] = useState<string>('');
  const [signUpEmail, setSignUpEmail] = useState<string>('');

  /* Callbacks */
  const handleSignUpSubmit = (signupData: ISignUpForm): void => {
    if (loading) {
      return undefined;
    }

    createUser({
      variables: {
        createUserInput: {
          firstName: '',
          lastName: '',
          email: signupData.email,
          password: signupData.password,
          timezone: signupData.timezone,
          preferredLocale: locale,
        },
      },
      onCompleted: (result) => {
        setSignUpEmail(signupData.email);
        login({
          variables: {
            loginInput: {
              email: result.createUser.email,
              password: signupData.password,
              timezone: signupData.timezone,
            },
          },
          onCompleted: (data) => {
            setTokenType(data?.login?.process_step || '');
            if (data?.login?.access_token) {
              localStorage.setItem('token', data.login.access_token);
              refetch();
            }
          },
        });
      },
      onError: (err: unknown) => {
        const error = err as Error;
        if (error.message === Exceptions.AlreadyUsedEmail) {
          errorToast({ title: t('FieldErrorEmailAlreadyUsed') });
        } else {
          errorToast({ title: t('AccountCreationError') });
        }
      },
    });
  };

  if (tokenType === EmailVerificationStatus.UNVERIFIED) {
    return <CheckEmail email={signUpEmail} />;
  }

  return <SignUpForm loading={loading} onSubmit={handleSignUpSubmit} />;
}

export type SignUpViewProps = Props;
