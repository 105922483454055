import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useRecoverPasswordMutation } from '@blockpulse3/graphql/hooks';
import { BrandIcon } from '@blockpulse3/ui/brand-theme';

import { ForgotPasswordForm, IForgotPasswordForm } from './ForgotPasswordForm';
import { ForgotPasswordSuccessView } from './ForgotPasswordSuccess';

type Props = unknown;

/**
 * ForgotPasswordView.
 *
 * @returns {JSX.Element}
 */
export function ForgotPasswordView(): JSX.Element {
  const t = useTranslations();


  const navigate = useNavigate();

  const [success, setSuccess] = useState<boolean>(false);

  const [recoverPassword] = useRecoverPasswordMutation();

  const handleBackClick = (): void => {
    navigate(routes.login.href);
  };

  const handleEmailSubmit = (data: IForgotPasswordForm): void => {
    recoverPassword({
      variables: {
        recoverPasswordInput: {
          email: data.email,
        },
      },
    });
    setSuccess(true);
  };

  return (
    <Card variant="divider-top" w={{ base: 'full', sm: 380 }}>
      <CardHeader alignItems="center" as={Flex} justifyContent="center">
        <BrandIcon
          display="flex"
          h="full"
          justifyContent="center"
          objectFit="cover"
          width="200px"
        />
      </CardHeader>
      <Divider />
      <CardBody as={Stack} p="6" spacing="6">
        {success ? (
          <ForgotPasswordSuccessView />
        ) : (
          <Stack spacing="4">
            <Button
              alignSelf="start"
              leftIcon={<ChevronLeftIcon height="20" width="20" />}
              variant="link"
              onClick={handleBackClick}
            >
              {t('Back')}
            </Button>
            <Stack spacing="1">
              <Heading size="md">{t('ResetPassword')}</Heading>
              <Text>{t('ResetPasswordEmail')}</Text>
            </Stack>
            <ForgotPasswordForm onSubmit={handleEmailSubmit} />
          </Stack>
        )}
      </CardBody>
    </Card>
  );
}

export type ForgotPasswordViewProps = Props;
