import { useContext, useState } from 'react';

import { useMeQuery } from '@blockpulse3/graphql/hooks';

import { useIntl } from '../intl';
import { IUserContext, UserContext } from './context';

export type UserContextProviderProps = {
  children: React.ReactNode;
};

/**
 * UserProvider.
 *
 * Custom UserContext provider, exposing user state
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function UserContextProvider({ children }: UserContextProviderProps): JSX.Element {
  const { setLocale } = useIntl();

  const [status, setStatus] = useState<IUserContext['status']>('loading');

  const { data, ...rest } = useMeQuery({
    fetchPolicy: 'no-cache',
    onCompleted: ({ me }) => {
      setStatus('authenticated');
      /* Update the locale with the user preferredLocale, if not defined, fallback to the one
       * set by default, from navigator.languages. Cf intl/provider.tsx
       */
      if (me.preferredLocale) {
        setLocale(me.preferredLocale);
      }
    },
    onError: () => {
      setStatus('unauthenticated');
    },
  });

  const user = data?.me || null;
  const value = {
    user,
    spaceId: user?.members?.[0]?.space?.id,
    spaceName: user?.members?.[0]?.space?.name,
    ...rest,
    status,
    setStatus,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

/**
 * useAuthUser.
 * Retrieve UserContext, this is the authenticated user.
 *
 * @returns {IUserContext}
 */
export function useAuthUser(): IUserContext {
  return useContext(UserContext);
}
