import { Badge, Button, Icon, Stack, Td, Text, Tr } from '@chakra-ui/react';
import { ArrowNarrowRightIcon, UserGroupIcon } from '@heroicons/react/outline';
import { generatePath, resolvePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { OperationStatus } from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import { useBadge } from '@blockpulse3/ui/ui-hooks';

import { OperationRow } from '../types';

type Props = {
  row: OperationRow;
};

export function OperationTableOpportunityRow({ row }: Props): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const navigate = useNavigate();

  const isSpace = useMatch(routes.space.href + '/*');

  /* ** Operation status badge ** */
  const { badge } = useBadge(
    row.status,
    [
      {
        value: OperationStatus.CLOSED,
        label: t('ClosedF'),
        color: 'yellow',
      },
      {
        value: OperationStatus.STARTED,
        label: t('InProgress'),
        color: 'yellow',
      },
      {
        value: OperationStatus.REVIEWED,
        label: t('RevisedF'),
        color: 'blue',
      },
      {
        value: OperationStatus.FINALIZED,
        label: t('FinalizedF'),
        color: 'green',
      },
    ],
    { label: t('Draft', { nb: 1 }), color: 'gray' },
  );

  const handleOperationDraftClick = (): void => {
    if (isSpace || row.status !== OperationStatus.DRAFT) {
      return handleOperationCurrentClick();
    }

    if (!companyId) return;

    navigate(
      generatePath(
        routes.company.newOpportunity.href + '/' + routes.company.newOpportunity.edit.href,
        {
          companyId,
          operationId: row.id,
        },
      ),
    );
  };

  const handleOperationCurrentClick = (): void => {
    const rowCompanyId = isSpace && row.company?.id ? row.company.id : companyId;
    if (rowCompanyId) {
      const relPath = generatePath(routes.company.opportunity.href, {
        companyId: rowCompanyId,
        operationId: row.id,
      });
      navigate(
        isSpace
          ? resolvePath(
              relPath,
              generatePath(routes.space.company.full, { companyId: rowCompanyId }),
            ).pathname
          : relPath,
      );
    }

    return;
  };

  const handleRowClick = (): void => {
    switch (row.status) {
      case OperationStatus.DRAFT: {
        handleOperationDraftClick();
        break;
      }

      default: {
        handleOperationCurrentClick();
        break;
      }
    }
  };

  const endDate = row.closingDate || row.endSubscriptionDate;

  if (row.data?.__typename !== 'OpportunityData') return null;

  return (
    <Tr role="button" onClick={handleRowClick}>
      <Td>
        <Text fontWeight="600">{row.name}</Text>
        <Text color="gray.500" fontWeight="400">
          {t('PurchaseOpportunity')}
        </Text>
      </Td>
      {isSpace && (
        <>
          <Td></Td>
          <Td>
            <Badge colorScheme={badge.color}>{badge.label}</Badge>
          </Td>
          <Td>
            <Text fontWeight="600">{row.company?.name || '-'}</Text>
          </Td>
        </>
      )}
      <Td isNumeric fontWeight="600">
        {row.data.totalApprovedAmount ? formatNumberCurrency(row.data.totalApprovedAmount) : '-'}
      </Td>
      <Td fontWeight="600">
        <Stack alignItems="center" direction="row">
          <Icon as={UserGroupIcon} boxSize="18px" color="gray.900" />
          <Text>{row.subscriptionCount}</Text>
        </Stack>
      </Td>
      <Td fontWeight="600">{endDate ? formatDate(endDate, 'll') : '-'}</Td>
      <Td textAlign="right">
        <Button rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />} variant="secondary">
          {t('ShowDetails')}
        </Button>
      </Td>
    </Tr>
  );
}

export type OperationTableFundraisingRowProps = Props;
