import {
  Box,
  Progress as ChakraProgress,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef } from 'react';
import { Outlet, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  CompanyStatus,
  useDeleteCompanyMutation,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import {
  DeleteDraftConfirmModal,
  Progress,
  ProgressStep,
  STEP_HEADER_HEIGHT_BASE,
  STEP_HEADER_HEIGHT_MD,
  StepHeader,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { useOnboardingCompanyLayoutSteps } from './useOnboardingCompanyLayoutSteps';

type Props = unknown;

/**
 * OnboardingExistingCompanyLayout.
 * Layout wrapping private fundraising funnel steps and create components.
 *
 * @returns {JSX.Element}
 */
export function OnboardingExistingCompanyLayout(): JSX.Element {
  const t = useTranslations();

  const top = useBreakpointValue({ base: STEP_HEADER_HEIGHT_BASE, md: STEP_HEADER_HEIGHT_MD });

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const navigate = useNavigate();
  const { companyId = '' } = useParams();

  const { progress, activeSteps } = useOnboardingCompanyLayoutSteps();

  const draftConfirmRef = useRef(null);
  const draftConfirmModal = useDisclosure();

  const { data } = useGetCompanyQuery({ variables: { companyId }, skip: !companyId });

  const [deleteCompanyMutation] = useDeleteCompanyMutation();

  const isCreateActive = useMatch(routes.onboardings.company.href);

  const handleClose = (): void => {
    navigate(routes.me.href);
  };

  const handleDeleteExistingCompany = (): void => {
    if (companyId) {
      deleteCompanyMutation({
        variables: { id: companyId },
        onCompleted: (res) => {
          if (res.deleteCompany) {
            navigate(routes.me.href);
            successToast({ title: t('DeleteDraftSuccess') });
          } else {
            errorToast({ title: t('ErrorDeletingDraft') });
          }
        },
      });
    }
  };

  return (
    <Flex direction="column" minH="100vh">
      <StepHeader
        p="4"
        showDelete={!isCreateActive && data?.company.status !== CompanyStatus.RUNNING}
        onClose={handleClose}
        onDelete={draftConfirmModal.onOpen}
      >
        <Heading size="lg">{t('AddCompany')}</Heading>
        <Text color="gray.600" fontWeight="normal">
          {t('ImportCompanyInfo')}
        </Text>
      </StepHeader>
      <ChakraProgress
        height={1}
        max={activeSteps.length - 1}
        position="sticky"
        top={top}
        value={progress}
        zIndex="2"
        sx={{
          '&>div': {
            transition: 'width .7s ease',
          },
        }}
      />
      <Box bg="gray.50" flexGrow="1" h="full">
        <Stack direction={{ base: 'column', lg: 'row' }} p="6" spacing={{ base: 4, lg: 0 }}>
          <Flex flexBasis={{ base: 0, lg: 200 }} />
          <Progress>
            {activeSteps.map((step, i) => (
              <ProgressStep
                key={step.label}
                index={i}
                isActive={!!step.value}
                isCompleted={progress > i}
                title={step.label}
              />
            ))}
          </Progress>
          <Flex flexGrow="1" maxW="3xl">
            <Outlet />
          </Flex>
        </Stack>
      </Box>
      <DeleteDraftConfirmModal
        isOpen={draftConfirmModal.isOpen}
        leastDestructiveRef={draftConfirmRef}
        onClose={draftConfirmModal.onClose}
        onDelete={handleDeleteExistingCompany}
      />
    </Flex>
  );
}

export type OnboardingExistingCompanyLayoutProps = Props;
