import {
  Button,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IdentityType, SubscriptionSide, noop } from '@blockpulse3/data/shared';
import {
  IdentityVerificationStatus,
  IndividualDocumentType,
  useGetSubscriptionQuery,
  useValidateKycDocumentsMutation,
} from '@blockpulse3/graphql/hooks';
import { ResponsiveModalFooter } from '@blockpulse3/ui/commons';

import { useIdentity, useManagedIndividual } from '../../../../providers';
import { useVerificationStepContext } from '../../providers';
import { SelectVerificationType } from './SelectVerificationType';
import { UserVerificationApproved } from './UserVerificationApproved';
import { UserVerificationPending } from './UserVerificationPending';
import { DocumentUserVerification, UbbleUserVerification } from './VerificationType';
import { IVerificationType } from './types';

type Props = {
  onClose: () => void;
};

export function UserVerificationStep({ onClose = noop }: Props): JSX.Element {
  const t = useTranslations();

  /* ** Set the verification component after user selection ** */
  const [verificationType, setVerificationType] = useState<IVerificationType>(null);
  /* ** Check whether all documents are uploaded in the case of a desktop verification ** */
  const [isDesktopValidable, setIsDesktopValidable] = useState(false);

  const { individual } = useManagedIndividual();

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const { data } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const subscription = data?.subscription;
  const subscriptionIdentity =
    subscription?.side === SubscriptionSide.SELLER
      ? subscription?.sellerIdentity
      : subscription?.buyerIdentity;
  const individualIdentity =
    subscriptionIdentity?.individualIdentity ||
    subscriptionIdentity?.companyIdentity?.kybIndividualIdentity ||
    individual;

  const kycVerificationStatus = individualIdentity?.kycVerificationStatus;
  const identityVerified = kycVerificationStatus === IdentityVerificationStatus.APPROVED;
  const identityPending =
    kycVerificationStatus &&
    [IdentityVerificationStatus.PROCESSING, IdentityVerificationStatus.PENDING].includes(
      kycVerificationStatus,
    );

  const [validateKycDocuments] = useValidateKycDocumentsMutation();
  const { activeSteps, stepIndex } = useVerificationStepContext();

  useEffect(() => {
    const hasDesktopVerificationStarted = individualIdentity?.documents?.some((doc) =>
      [
        IndividualDocumentType.IDENTITY_DOCUMENT,
        IndividualDocumentType.IDENTITY_DOCUMENT_SECONDARY,
      ].includes(doc.type),
    );

    if (
      hasDesktopVerificationStarted &&
      kycVerificationStatus !== IdentityVerificationStatus.REFUSED
    ) {
      setVerificationType('document');
    }
  }, [individualIdentity, kycVerificationStatus]);

  const RenderVerificationModalContent = (): JSX.Element => {
    if (identityVerified) {
      return (
        <ModalBody>
          <UserVerificationApproved />
        </ModalBody>
      );
    }
    if (identityPending) {
      return (
        <ModalBody>
          <UserVerificationPending />
        </ModalBody>
      );
    }

    switch (verificationType) {
      case 'ubble':
        return (
          <ModalBody p="0">
            <UbbleUserVerification onClose={onClose} />
          </ModalBody>
        );
      case 'document':
        return (
          <ModalBody>
            <DocumentUserVerification setIsValidable={setIsDesktopValidable} onSubmit={onClose} />
          </ModalBody>
        );
      default:
        return (
          <ModalBody>
            <SelectVerificationType setVerificationType={setVerificationType} />
          </ModalBody>
        );
    }
  };

  const handleKycDocumentSubmit = (): void => {
    if (!individualIdentity) return;

    validateKycDocuments({
      variables: {
        validateKycDocumentsInput: {
          individualIdentityId: individualIdentity.id,
        },
      },
      onCompleted: () => {
        onClose();
      },
    });
  };

  return (
    <ModalContent>
      <ModalHeader>
        <Text>{t('VerifyMyIdentity')}</Text>
        {activeSteps > 1 && (
          <Text color="secondary" fontSize="sm">
            {t('StepInfo', {
              stepCount: stepIndex + 1,
              totalStepCount: activeSteps,
            })}
            {t(
              subscriptionIdentity?.type === IdentityType.COMPANY
                ? 'RepresentativeIdentity'
                : 'VerificationOfMyIdentity',
            )}
          </Text>
        )}
      </ModalHeader>
      <ModalCloseButton />
      <RenderVerificationModalContent />
      <Divider />
      <ResponsiveModalFooter>
        {verificationType === 'document' ? (
          <Button
            isDisabled={!isDesktopValidable && !identityVerified}
            width="full"
            onClick={handleKycDocumentSubmit}
          >
            {t('Validate')}
          </Button>
        ) : (
          <Button width="full" onClick={onClose}>
            {t(identityVerified ? 'Alright' : 'Close')}
          </Button>
        )}
      </ResponsiveModalFooter>
    </ModalContent>
  );
}
