import { InfoIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import dayjs from 'dayjs';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useCreateOptionAssetMutation } from '@blockpulse3/graphql/hooks';
import {
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useErrorToast,
} from '@blockpulse3/ui/commons';

import { defaultValues, durationUnitOptions, optionAssetTypeOptions, schema } from './schema';
import { IOptionAsset } from './types';

type Props = {
  onClose: () => void;
  onComplete: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

/**
 * AssetCreateModal.
 * Form to create a Stock Asset.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OptionCreateModal({ onClose, onComplete, ...props }: Props): JSX.Element {
  const t = useTranslations();
  const i18nAssetType = useTranslations('AssetTypeValues');

  const errorToast = useErrorToast();

  const { companyId = '' } = useParams();
  const [createOptionAsset, { loading: isCreateLoading }] = useCreateOptionAssetMutation();

  const { register, control, formState, handleSubmit, reset, watch } = useForm<IOptionAsset>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleModalClose = (): void => {
    onClose();
    reset({ ...defaultValues });
  };

  const handleFormSubmit: SubmitHandler<IOptionAsset> = (data): void => {
    if (!companyId) return;

    const { hasCurve, cliffDuration, vestingDuration, intervalDuration, ...optionData } = data;

    const optionAsset = {
      ...optionData,
      date: dayjs(data.date).unix(),
      expirationDate: dayjs(data.expirationDate).unix(),
      durationUnit: data.durationUnit.value,
      type: data.type.value,
      vestingCurve: {
        noCurve: !hasCurve,
        cliffDuration,
        vestingDuration,
        intervalDuration,
      },
    };

    createOptionAsset({
      variables: {
        companyId,
        createOptionAssetInput: {
          ...optionAsset,
        },
      },
      onCompleted: () => {
        onComplete();
        handleModalClose();
      },
      onError: () => {
        errorToast({ title: t('ErrorAddingPlan') });
        handleModalClose();
      },
    });
  };

  const optionType = watch('type');
  const durationUnit = watch('durationUnit');
  const hasCurve = watch('hasCurve');

  return (
    <ResponsiveModal onClose={handleModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('AddExistingPlan')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="add-option-asset" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <Controller
                control={control}
                name="type"
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.type}>
                    <FormLabel htmlFor="type">{t('PlanType')}</FormLabel>
                    <Select
                      id="type"
                      isSearchable={false}
                      menuPlacement="auto"
                      options={optionAssetTypeOptions}
                      getOptionLabel={(option): string =>
                        option.label ? i18nAssetType(option.label, { nb: 0 }) : ''
                      }
                      {...field}
                    />
                    <ErrorMessage error={formState.errors?.type?.label} />
                  </FormControl>
                )}
              />
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl flex="2" isInvalid={!!formState.errors?.name}>
                  <FormLabel htmlFor="name">{t('PlanName')}</FormLabel>
                  <Input id="name" type="string" {...register('name')} />
                  <ErrorMessage error={formState.errors?.name} />
                </FormControl>
                <FormControl flex="1" isInvalid={!!formState.errors?.code}>
                  <FormLabel htmlFor="code">
                    <HStack>
                      <Text>{t('Code')}</Text>
                      <Tooltip
                        hasArrow
                        label={t('ShortNameOfOptionForIdentification')}
                        placement="top"
                      >
                        <Icon as={InfoIcon} color="gray.600" />
                      </Tooltip>
                    </HStack>
                  </FormLabel>
                  <Input id="code" type="string" {...register('code')} />
                  <ErrorMessage error={formState.errors?.code} />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.initialSupply}>
                  <FormLabel htmlFor="initialSupply">{t('InitialQuantity')}</FormLabel>
                  <InputGroup>
                    <Input id="initialSupply" type="number" {...register('initialSupply')} />
                    <InputRightAddon>{i18nAssetType(optionType?.label, { nb: 1 })}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.initialSupply} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.remainingSupply}>
                  <FormLabel htmlFor="remainingSupply">{t('ReminderQuantity')}</FormLabel>
                  <InputGroup>
                    <Input id="remainingSupply" type="number" {...register('remainingSupply')} />
                    <InputRightAddon>{i18nAssetType(optionType?.label, { nb: 1 })}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.remainingSupply} />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.date}>
                  <FormLabel htmlFor="date">{t('CreationDate')}</FormLabel>
                  <Input id="date" type="date" {...register('date')} />
                  <ErrorMessage error={formState.errors?.date} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.expirationDate}>
                  <FormLabel htmlFor="expirationDate">{t('ExpirationDate')}</FormLabel>
                  <Input id="expirationDate" type="date" {...register('expirationDate')} />
                  <ErrorMessage error={formState.errors?.expirationDate} />
                </FormControl>
              </Stack>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing="4"
                w={{ base: '100%', md: '48%' }}
              >
                <Controller
                  control={control}
                  name="durationUnit"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors?.durationUnit}>
                      <FormLabel htmlFor="durationUnit">{t('DurationUnit')}</FormLabel>
                      <Select
                        id="durationUnit"
                        isSearchable={false}
                        menuPlacement="auto"
                        options={durationUnitOptions}
                        getOptionLabel={(option): string =>
                          option.label ? t(`DurationUnits.${option.label}`) : ''
                        }
                        {...field}
                      />
                      <ErrorMessage error={formState.errors?.durationUnit?.label} />
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.attributionPeriod}>
                  <FormLabel htmlFor="attributionPeriod">{t('AttributionPeriod')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="attributionPeriod"
                      type="number"
                      {...register('attributionPeriod')}
                    />
                    <InputRightAddon>{t(`DurationUnits.${durationUnit.label}`)}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.attributionPeriod} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.subscriptionPeriod}>
                  <FormLabel htmlFor="subscriptionPeriod">{t('SubscriptionPeriod')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="subscriptionPeriod"
                      type="number"
                      {...register('subscriptionPeriod')}
                    />
                    <InputRightAddon>{t(`DurationUnits.${durationUnit.label}`)}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.subscriptionPeriod} />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.subscriptionPrice}>
                  <FormLabel htmlFor="subscriptionPrice">{t('SubscriptionPrice')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="subscriptionPrice"
                      step="0.01"
                      type="number"
                      {...register('subscriptionPrice')}
                    />
                    <InputRightAddon>€</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.subscriptionPrice} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.exercisePrice}>
                  <FormLabel htmlFor="exercisePrice">{t('ExercisePrice')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="exercisePrice"
                      step="0.01"
                      type="number"
                      {...register('exercisePrice')}
                    />
                    <InputRightAddon>€</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.exercisePrice} />
                </FormControl>
              </Stack>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing="4"
                w={{ base: '100%', md: '48%' }}
              >
                <FormControl isInvalid={!!formState.errors?.conversionParity}>
                  <FormLabel htmlFor="conversionParity">{t('ConversionRatio')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="conversionParity"
                      step="0.01"
                      type="number"
                      {...register('conversionParity')}
                    />
                    <InputRightAddon>{t('OrdinaryShare', { nb: 3 })}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.conversionParity} />
                </FormControl>
              </Stack>
              <FormControl>
                <Checkbox {...register('delegated')}>
                  <Text>{t('AttributionDelegatedToPresident')}</Text>
                </Checkbox>
              </FormControl>
              <FormControl>
                <Checkbox {...register('transferable')}>
                  <Text>{t('TransferOfThisAssetAuthorized')}</Text>
                </Checkbox>
              </FormControl>
              <FormControl>
                <Checkbox {...register('accelerable')}>
                  <Text>{t('VestingCanBeAccelerated')}</Text>
                </Checkbox>
              </FormControl>
              <FormControl>
                <Checkbox {...register('approvable')}>
                  <Text>{t('AssetExerciseApprovalRequired')}</Text>
                </Checkbox>
              </FormControl>
              <Accordion
                allowToggle
                defaultIndex={hasCurve ? [0] : []}
                index={hasCurve ? [0] : []}
                variant="unstyled"
              >
                <AccordionItem>
                  <HStack>
                    <FormControl>
                      <Checkbox {...register('hasCurve')}>
                        <Text fontWeight="semibold">{t('ExerciseRightsFollowVestingCurve')}</Text>
                      </Checkbox>
                    </FormControl>
                    <AccordionButton maxW="20px">
                      <AccordionIcon />
                    </AccordionButton>
                  </HStack>
                  <AccordionPanel mt="24px" p="0">
                    <Stack spacing="4">
                      <Stack
                        direction={{ base: 'column', md: 'row' }}
                        spacing="4"
                        w={{ base: '100%', md: '48%' }}
                      >
                        <FormControl isInvalid={!!formState.errors?.cliffDuration}>
                          <FormLabel htmlFor="cliffDuration">{t('CliffDuration')}</FormLabel>
                          <InputGroup>
                            <Input
                              id="cliffDuration"
                              type="number"
                              {...register('cliffDuration')}
                            />
                            <InputRightAddon>
                              {t(`DurationUnits.${durationUnit.label}`)}
                            </InputRightAddon>
                          </InputGroup>
                          <ErrorMessage error={formState.errors?.cliffDuration} />
                        </FormControl>
                      </Stack>
                      <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                        <FormControl isInvalid={!!formState.errors?.vestingDuration}>
                          <FormLabel htmlFor="vestingDuration">
                            {t('TotalVestingDuration')}
                          </FormLabel>
                          <InputGroup>
                            <Input
                              id="vestingDuration"
                              type="number"
                              {...register('vestingDuration')}
                            />
                            <InputRightAddon>
                              {t(`DurationUnits.${durationUnit.label}`)}
                            </InputRightAddon>
                          </InputGroup>
                          <ErrorMessage error={formState.errors?.vestingDuration} />
                        </FormControl>
                        <FormControl isInvalid={!!formState.errors?.intervalDuration}>
                          <FormLabel htmlFor="intervalDuration">{t('IntervalDuration')}</FormLabel>
                          <InputGroup>
                            <Input
                              id="intervalDuration"
                              type="number"
                              {...register('intervalDuration')}
                            />
                            <InputRightAddon>
                              {t(`DurationUnits.${durationUnit.label}`)}
                            </InputRightAddon>
                          </InputGroup>
                          <ErrorMessage error={formState.errors?.intervalDuration} />
                        </FormControl>
                      </Stack>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button isDisabled={isCreateLoading} type="button" variant="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            data-cy="add-option"
            form="add-option-asset"
            isDisabled={isCreateLoading}
            isLoading={isCreateLoading}
            type="submit"
          >
            {t('AddThePlan')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type OptionCreateModalProps = Props;
