import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { relayStylePagination } from '@apollo/client/utilities';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import 'react-phone-number-input/style.css';
import { BrowserRouter } from 'react-router-dom';

import { ThemeContextProvider } from '@blockpulse3/theme';
import { RootIntlProvider, UserContextProvider } from '@blockpulse3/web-client/auth';

import App from './App';

dayjs.locale('fr');
BigNumber.config({ DECIMAL_PLACES: 2 });

const httpLink = new HttpLink({
  uri: process.env?.['NX_API_ENDPOINT'],
});

// Log Apollo errors on dev environment
if (process.env['NODE_ENV'] === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

const onInvalidApiTokenLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    response.errors?.forEach((error) => {
      if (
        error.message === '10 ABORTED: E_INVALID_API_TOKEN: Invalid API token' &&
        localStorage.getItem('token')
      ) {
        localStorage.removeItem('token');
        window.location.reload();
      }
    });
    return response;
  });
});

const authLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem('token')}` || null,
    },
  }));

  return forward(operation);
});

const cache = new InMemoryCache({
  typePolicies: {
    OperationSubscription: {
      keyFields: ['id', 'side'],
    },
    Query: {
      fields: {
        subscriptions: relayStylePagination(),
        individualIdentities: relayStylePagination(),
        allCompanies: relayStylePagination(),
        members: relayStylePagination(),
        spaces: relayStylePagination(),
        managers: relayStylePagination(),
      },
    },
  },
});

const client = new ApolloClient({
  cache,
  link: onInvalidApiTokenLink.concat(authLink).concat(httpLink),
});

Sentry.init({
  dsn: process.env['NX_SENTRY_DSN'],
  environment: process.env['NX_SENTRY_ENVIRONMENT'],
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: Number(process.env['NX_SENTRY_SAMPLE_RATE']),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <RootIntlProvider>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <UserContextProvider>
            <ThemeContextProvider>
              <App />
            </ThemeContextProvider>
          </UserContextProvider>
        </BrowserRouter>
      </ApolloProvider>
    </RootIntlProvider>
  </StrictMode>,
);
