import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  HStack,
  Heading,
  Icon,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { TemplateIcon } from '@heroicons/react/outline';
import { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  Asset,
  useDeleteAssetMutation,
  useGetOptionAssetsQuery,
  useGetStockAssetsQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';

import { RepatriationShareTransferRegisterAssetTable } from './RepatriationShareTransferRegisterAssetsTable';
import { RepatriationShareTransferRegisterAssetsTableControls } from './RepatriationShareTransferRegisterAssetsTableControls';
import { getOptionAssets, getStockAssets } from './utils';

type Props = unknown;

/**
 * RepatriationShareTransferRegisterAssets.
 * First step of the Share Transfer Register (RMT) repatriation. Blank asset(s) creation.
 * TODO:
 *  - loading indicator on delete (delay)
 *  - asset search ?
 *  - wording
 *
 * @returns {JSX.Element}
 */
export function RepatriationShareTransferRegisterAssets(): JSX.Element {
  const deleteRef = useRef(null);

  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const deleteAssetModal = useDisclosure();

  const { companyId = '' } = useParams();
  const navigate = useNavigate();

  const [assetId, setAssetId] = useState<Asset['id'] | null>(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const {
    data: stockData,
    loading: stockLoading,
    error: stockError,
    refetch: refetchStock,
  } = useGetStockAssetsQuery({ variables: { companyId } });
  const {
    data: optionData,
    loading: optionLoading,
    error: optionError,
    refetch: refetchOption,
  } = useGetOptionAssetsQuery({ variables: { companyId } });
  const [deleteAsset] = useDeleteAssetMutation();

  const handleConfirmDeleteAsset = (id: Asset['id']): void => {
    setAssetId(id);
    deleteAssetModal.onOpen();
  };

  const handleConfirmDeleteClose = (): void => {
    setAssetId(null);
    deleteAssetModal.onClose();
  };

  const handleDeleteAsset = (): void => {
    if (!assetId) {
      return;
    }

    setIsDeleteLoading(true);
    deleteAsset({
      variables: { deleteAssetInput: { companyId, assetId } },
      onCompleted: () => {
        setIsDeleteLoading(false);
        successToast({ title: t('AssetDeleted') });
        deleteAssetModal.onClose();
        setAssetId(null);
        refetchStock();
        refetchOption();
      },
      onError: () => {
        errorToast({ title: t('AssetDeletionError') });
        setIsDeleteLoading(false);
      },
    });
  };

  const handleCreateComplete = (): void => {
    refetchStock();
    refetchOption();
  };

  const handleStepSubmit = (): void => {
    navigate('../' + routes.company.repatriation.identities.href);
  };

  const handleStepCancel = (): void => {
    navigate('..');
  };

  if (stockError || optionError) {
    return <ErrorQueryCard h="350px" />;
  }

  const stocks = getStockAssets(stockData?.getStockAssets);
  const options = getOptionAssets(optionData?.getOptionAssets);
  const assets = [...stocks, ...options];
  const isAssetListEmpty = !assets.length;

  return (
    <Card variant="divider-top" width="full">
      <CardHeader as={Stack} spacing="2">
        <Heading size="lg">{t('AssetsSettings')}</Heading>
        <Text color="gray.600" fontSize="lg">
          {t('AddAssetsOfCompany')}
        </Text>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="4">
        {stockLoading || optionLoading ? (
          <Stack layerStyle="emptyState">
            <Spinner />
          </Stack>
        ) : (
          <>
            <RepatriationShareTransferRegisterAssetsTableControls
              onCreateComplete={handleCreateComplete}
            />
            {isAssetListEmpty ? (
              <Stack layerStyle="emptyState">
                <Icon as={TemplateIcon} boxSize="40px" color="gray.500" />
                <Text>{t('NoAsset')}</Text>
              </Stack>
            ) : (
              <RepatriationShareTransferRegisterAssetTable
                assets={assets}
                onDeleteAsset={handleConfirmDeleteAsset}
              />
            )}
          </>
        )}
      </CardBody>
      <AlertDialog
        isOpen={deleteAssetModal.isOpen}
        leastDestructiveRef={deleteRef}
        onClose={deleteAssetModal.onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="xl" fontWeight="500">
              {t('DeleteAsset')}
            </AlertDialogHeader>
            <AlertDialogBody>{t('DefinitiveAction')}</AlertDialogBody>
            <Divider />
            <AlertDialogFooter as={HStack} spacing="4">
              <Button variant="secondary" onClick={handleConfirmDeleteClose}>
                {t('Cancel')}
              </Button>
              <Button isLoading={isDeleteLoading} variant="delete" onClick={handleDeleteAsset}>
                {t('Delete')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <CardFooter as={Stack} direction={{ base: 'column', md: 'row' }} spacing="4">
        <Button variant="secondary" w="full" onClick={handleStepCancel}>
          {t('Back')}
        </Button>
        <Button isDisabled={isAssetListEmpty} w="full" onClick={handleStepSubmit}>
          {t('Next')}
        </Button>
      </CardFooter>
    </Card>
  );
}

export type RepatriationShareTransferRegisterAssetsProps = Props;
