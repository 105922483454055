import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Card,
  CardBody,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerProps,
  HStack,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Fragment, useEffect, useState } from 'react';
import { useTranslations } from 'use-intl';

import { IntlDocumentValues } from '@blockpulse3/data/locales/types';
import {
  AssetType,
  BalanceInfosFragment,
  Identity,
  IdentityType,
  MovementType,
  OperationDocumentType,
  OptionAssetGrantFragment,
  OptionAssetInfosFragment,
  useGetAssetDocumentsQuery,
  useGetDocumentPdfUrlLazyQuery,
  useGetGrantsFromAssetLazyQuery,
  useGetMovementsByAssetAndIdentityQuery,
  useGetOptionAssetLazyQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, formatNumberInt } from '@blockpulse3/helpers';
import {
  AssetBadge,
  DocumentLink,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';
import { SubscriptionPanelDocuments } from '@blockpulse3/web-client/operation/commons';

import { getOptionAssetGrantStatus, useGrantBadge } from '../../../utils';
import { CompanyValuationsCard } from '../CompanyValuationsCard';

type Props = {
  balance: BalanceInfosFragment;
  identityId: Identity['id'] | null;
} & Omit<DrawerProps, 'children'>;

export function OptionAssetSidePanel({ balance, identityId, ...props }: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentValue = useTranslations('DocumentValues');

  const { getBadge } = useGrantBadge();

  const [getOptionAssetQuery] = useGetOptionAssetLazyQuery();
  const [getGrantsFromAsset] = useGetGrantsFromAssetLazyQuery();
  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();

  const [grants, setGrants] = useState<OptionAssetGrantFragment[] | null>(null);
  const [optionAsset, setOptionAsset] = useState<OptionAssetInfosFragment | null>(null);

  const { data } = useGetMovementsByAssetAndIdentityQuery({
    variables: {
      assetId: balance.asset.id,
      identityId: identityId || '',
    },
    skip: !identityId || !balance.asset.id,
  });
  const movements = data?.getMovementsByAssetAndIdentity || [];

  const { data: assetDocumentsData } = useGetAssetDocumentsQuery({
    variables: {
      assetId: balance.asset.id,
      identityId: identityId || '',
    },
    skip: !identityId || !balance.asset.id,
  });
  const assetDocuments = assetDocumentsData?.getAssetDocuments || [];

  useEffect(() => {
    if (!identityId) return;

    getGrantsFromAsset({
      variables: {
        grantsFromAssetInput: {
          assetId: balance.asset.id,
          companyId: balance.asset.company.id,
          identityId,
        },
      },
      onCompleted: (data) => {
        setGrants(data.getGrantsFromAsset);
      },
    });

    getOptionAssetQuery({
      variables: {
        getAssetInput: {
          assetId: balance.asset.id,
          companyId: balance.asset.company.id,
        },
      },
      onCompleted: (data) => {
        setOptionAsset(data.getOptionAsset);
      },
    });
  }, [balance, identityId]);

  if (!movements || !grants || !optionAsset) {
    return (
      <Drawer {...props}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p="0">
            <Stack alignItems="center" h="full" justifyContent="center" py="4" spacing="4">
              <Spinner />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    );
  }

  const onDocumentClick = (documentId: string): void => {
    getDocumentPdfUrl({
      variables: {
        documentId,
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        window.open(pdfUrl, '_blank');
      },
    });
  };

  const { signer, contact } = balance.asset.company;
  const repIndividualIdentity = contact?.individualIdentity || signer.individualIdentity;

  const subscriptionPricePerShare =
    movements.length && movements[0]?.subscription?.operation?.pricePerShare;

  return (
    <Drawer {...props}>
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody p="0">
          <Stack py="4" spacing="4">
            <Card m="4" my="0" p="0" variant="rounded-gray">
              <CompanyValuationsCard
                balance={balance}
                m="0"
                subscriptionPricePerShare={subscriptionPricePerShare}
                underlyingAssetType={AssetType.ORDINARY_SHARE}
              />
              <CardBody fontSize="sm" px="4" py="1">
                <Accordion allowToggle variant="unstyled">
                  <AccordionItem>
                    <AccordionButton data-cy="expand-asset-details" justifyContent="flex-end">
                      <Text color="gray" fontSize="xs">
                        {t('ShowDetails')}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel as={Stack} px="0" py="3" spacing="2">
                      <HStack alignItems="start" justifyContent="space-between">
                        <Text color="gray.500" fontWeight="500">
                          {t('Asset', { nb: balance.total })}
                        </Text>
                        <Tooltip hasArrow label={balance.asset.name} placement="top">
                          <Stack>
                            <AssetBadge
                              assetCount={balance.grantsCount}
                              type={balance.asset.type}
                              value={balance.total}
                            />
                          </Stack>
                        </Tooltip>
                      </HStack>
                      {balance?.valuationAtGrant && (
                        <HStack alignItems="start" justifyContent="space-between">
                          <Text color="gray.500" fontWeight="500">
                            {t('ValuationAtGrant')}
                          </Text>

                          <Stack alignItems="flex-end" justifyContent="space-between" spacing="0">
                            <Text fontWeight="500">
                              {formatNumberCurrency(balance.valuationAtGrant)}
                            </Text>
                            {movements.length && movements[0]?.subscription?.operation && (
                              <Text>
                                {t('NbPricePerShare', {
                                  price: subscriptionPricePerShare
                                    ? formatNumberCurrency(subscriptionPricePerShare)
                                    : '-',
                                })}
                              </Text>
                            )}
                          </Stack>
                        </HStack>
                      )}
                      {![AssetType.AGA, AssetType.BSPCE].includes(balance.asset.type) && (
                        <HStack justifyContent="space-between">
                          <Text color="gray.500" fontWeight="500">
                            {t('SubscriptionPrice')}
                          </Text>
                          <Text fontWeight="500">
                            {formatNumberCurrency(optionAsset.subscriptionPrice)}
                          </Text>
                        </HStack>
                      )}
                      {balance.asset.type !== AssetType.AGA && (
                        <HStack justifyContent="space-between">
                          <Text color="gray.500" fontWeight="500">
                            {t('ExercisePrice')}
                          </Text>
                          <Text fontWeight="500">
                            {formatNumberCurrency(optionAsset.exercisePrice)}
                          </Text>
                        </HStack>
                      )}
                      {balance.asset.type === AssetType.BSA_AIR && (
                        <Text color="gray.400" fontSize="xs" fontWeight="500">
                          {t('PriceInfoBSA_AIR')}
                        </Text>
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </CardBody>
            </Card>
            <Divider />
            {movements.map((movement, index) => {
              if (movement.type === MovementType.BURN) return null;
              const grant = grants.find(
                (g) =>
                  dayjs.unix(g.date).format('YYYYMMDD') ===
                    dayjs(movement.createdAt).format('YYYYMMDD') ||
                  parseInt(dayjs.unix(g.date).format('YYYYMMDD')) ===
                    parseInt(dayjs(movement.createdAt).format('YYYYMMDD')) + 1,
              );
              const badge = getBadge(getOptionAssetGrantStatus(optionAsset, grant));
              return (
                <Fragment key={index}>
                  <Stack px="4" spacing="4">
                    <Stack spacing="2">
                      <HStack>
                        <Text fontSize="md" fontWeight="600">
                          {t('AssignedDate', {
                            date: dayjs(movement.createdAt).format('D MMMM YYYY'),
                          })}
                        </Text>
                        <Badge colorScheme={badge.color}>{badge.label}</Badge>
                      </HStack>
                      <HStack fontSize="sm" justifyContent="space-between">
                        <Text color="gray.500" fontWeight="500">
                          {t('AssetsCount')}
                        </Text>
                        <Stack alignItems="flex-end" flexShrink="0" spacing="0">
                          <AssetBadge type={movement.asset.type} value={movement.amount} />
                        </Stack>
                      </HStack>
                      <HStack fontSize="sm" justifyContent="space-between">
                        <Text color="gray.500" fontWeight="500">
                          {t('VestedQuantity')}
                        </Text>
                        <Text fontWeight="500">
                          {grant?.vestedAmount ? formatNumberInt(grant.vestedAmount || 0) : '-'}
                        </Text>
                      </HStack>
                      {balance.asset.type !== AssetType.AGA && (
                        <HStack fontSize="sm" justifyContent="space-between">
                          <Text color="gray.500" fontWeight="500">
                            {t('ExercisedQuantity')}
                          </Text>
                          <Text fontWeight="500">
                            {grant?.exercisedAmount ? formatNumberInt(grant.exercisedAmount) : '-'}
                          </Text>
                        </HStack>
                      )}
                    </Stack>
                    {movement.subscription && (
                      <Stack layerStyle="border" py="4" rounded="md">
                        <SubscriptionPanelDocuments subscriptionId={movement.subscription.id} />
                      </Stack>
                    )}
                  </Stack>
                  <Divider />
                </Fragment>
              );
            })}
            {assetDocuments.length && (
              <>
                <Stack px="4" spacing="2">
                  <Text color="gray.600" fontWeight="600">
                    {t('CompanyDocuments')}
                  </Text>
                  <Stack spacing="2">
                    {assetDocuments.map((document) => {
                      if (!document) return null;
                      return (
                        <DocumentLink
                          key={document.id}
                          fileName={
                            document.type === OperationDocumentType.CUSTOM_FILE
                              ? document.title
                              : i18nDocumentValue(document.type as IntlDocumentValues)
                          }
                          onClick={(): void => onDocumentClick(document.id)}
                        />
                      );
                    })}
                  </Stack>
                </Stack>
                <Divider />
              </>
            )}
            <Stack px="4" spacing="2">
              <Text color="gray.600" fontWeight="600">
                {t('Contact', { nb: 1 })}
              </Text>
              <IdentityCard bg="gray.50" p="3">
                <IdentityAvatar type={IdentityType.INDIVIDUAL} />
                <Stack spacing="0">
                  <IdentityCardTitle isChecked>
                    <Text>{repIndividualIdentity?.name}</Text>
                  </IdentityCardTitle>
                  <IdentityCardDescription>
                    <Text>{repIndividualIdentity?.email}</Text>
                  </IdentityCardDescription>
                </Stack>
              </IdentityCard>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export type OptionAssetSidePanelProps = Props;
