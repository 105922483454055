import { Route, Routes } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { FinalizeSwanOnboarding } from '@blockpulse3/web-client/onboardings';
import { InitiateRefund } from '@blockpulse3/web-client/operation/commons';
import {
  InitiatePaymentsView,
  QueuedPaymentsView,
} from '@blockpulse3/web-client/operation/payment';
import { SubscriptionView } from '@blockpulse3/web-client/subscription';

import { AppLayout, EmptyLayout } from '../layouts';
import { UserStateRedirects } from './UserStateRedirects';

type Props = unknown;

/**
 * SubscriptionAndPaymentRoutes.
 * Renders subscription and payment routes for individuals and companies.
 *
 * @returns {JSX.Element}
 */
export function SubscriptionAndPaymentRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<EmptyLayout />}>
        <Route element={<UserStateRedirects />}>
          <Route path={routes.payment.initiatePayments.href + '/*'}>
            <Route
              element={<InitiatePaymentsView />}
              path={routes.payment.initiatePayments.subscription.href}
            />
            <Route
              element={<InitiateRefund />}
              path={routes.payment.initiatePayments.refund.href}
            />
            <Route element={<InitiatePaymentsView />} path="*" />
          </Route>
          <Route
            element={<FinalizeSwanOnboarding />}
            path={routes.payment.finalizeSwanOnboarding.href}
          />
        </Route>
      </Route>
      <Route element={<AppLayout />}>
        <Route element={<QueuedPaymentsView />} path={routes.payment.queuedPayments.href} />
        <Route element={<SubscriptionView />} path={routes.subscription.href} />
      </Route>
    </Routes>
  );
}

export type SubscriptionAndPaymentRoutesProps = Props;
