import { Icon, Spinner, Stack, Text } from '@chakra-ui/react';
import { CubeTransparentIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { OperationStatus } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { useGetOperations } from '../hook/useGetOperations';
import { OperationTable } from './OperationTable';

type Props = unknown;

/**
 * OperationTablePassed.
 * Query and display PASSED Fundraisings.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationTablePassed(): JSX.Element {
  const t = useTranslations();

  const { operations, operationsError, operationsLoading } = useGetOperations(
    OperationStatus.FINALIZED,
  );

  if (operationsLoading) {
    return (
      <Stack h="170px" layerStyle="emptyState">
        <Spinner />
      </Stack>
    );
  }

  if (operationsError) {
    return <ErrorQueryCard h="170px" />;
  }

  const isEmpty = operations.length === 0;

  return isEmpty ? (
    <Stack layerStyle="emptyState">
      <Icon as={CubeTransparentIcon} boxSize="40px" color="gray.500" />
      <Text>{t('NoPassedOperation')}</Text>
    </Stack>
  ) : (
    <OperationTable values={operations} />
  );
}

export type OperationTablePassedProps = Props;
