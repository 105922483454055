import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useState } from 'react';
import { Accept, FileRejection } from 'react-dropzone';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { FormErrors } from '@blockpulse3/data/shared';
import { CompanyDocumentType } from '@blockpulse3/graphql/hooks';
import {
  DropzoneInput,
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { uploadRegisterExtractSchema, uploadSpvDocumentSchema } from './schema';
import { IUploadSpvDocumentForm } from './types';

type Props = {
  onClose: () => void;
  companyId: string;
  documentType: CompanyDocumentType.STATUTS | CompanyDocumentType.REGISTER_EXTRACT;
  title?: string;
} & Omit<ResponsiveModalProps, 'children'>;

export function UploadSPVDocumentsModal({
  onClose,
  documentType,
  companyId,
  title,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentValues = useTranslations('DocumentValues');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [companyDocumentFile, setCompanyDocumentFile] = useState<File>();
  const [companyDocumentFileName, setCompanyDocumentFileName] = useState<{ [key: string]: File }>(
    {},
  );

  const successToast = useSuccessToast();

  const isRegisterExtract = documentType === CompanyDocumentType.REGISTER_EXTRACT;

  /* ** Form ** */
  const { register, control, handleSubmit, formState, setError, setValue } =
    useForm<IUploadSpvDocumentForm>({
      defaultValues: { companyDocumentFile: '' },
      resolver: yupResolver(
        isRegisterExtract ? uploadRegisterExtractSchema : uploadSpvDocumentSchema,
      ),
    });

  /* ** Method to upload a file using axios ** */
  const handleFileUpload = async (data: IUploadSpvDocumentForm): Promise<void> => {
    if (!companyId || !companyDocumentFile) return;

    const formData = new FormData();
    formData.append('companyId', companyId);
    formData.append('companyDocument', companyDocumentFile);

    if (documentType === CompanyDocumentType.REGISTER_EXTRACT) {
      formData.append('registrationNumber', data.registrationNumber || '');
      formData.append('registrationEntity', data.registrationEntity || '');
      formData.append('creationDate', data.creationDate || '');
    } else {
      formData.append('documentType', documentType);
    }

    const endpoint =
      documentType === CompanyDocumentType.REGISTER_EXTRACT
        ? '/companies/uploadRegisterExtract'
        : '/companies/importSPVTemplate';

    setIsLoading(true);
    await axios
      .post(process.env['NX_API_CONTROLLER_ENDPOINT'] + endpoint, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        successToast({ title: t('SuccessfulFileUpload') });
      })
      .catch(() => {
        setError('companyDocumentFile', {
          type: 'custom',
          message: FormErrors.DropzoneDefault,
        });
        setIsLoading(false);
      });
    setIsLoading(false);
    onClose();
  };

  /* ** Upload file handler ** */
  const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    if (!companyId) return;

    if (fileRejections.length === 0) {
      setValue('companyDocumentFile', acceptedFiles[0].name);
      setCompanyDocumentFileName({
        1: new File([acceptedFiles[0].name], acceptedFiles[0].name),
      });
      setCompanyDocumentFile(acceptedFiles[0]);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError('companyDocumentFile', {
            type: 'custom',
            message: FormErrors.FileTooLarge,
          });
          break;
        }
        default: {
          setError('companyDocumentFile', {
            type: 'custom',
            message: FormErrors.DropzoneDefault,
          });
          break;
        }
      }
    }
  };

  const handleFileDelete = (): void => {
    setValue('companyDocumentFile', '');
    setCompanyDocumentFileName({});
    setCompanyDocumentFile(undefined);
  };

  const handleFormSubmit: SubmitHandler<IUploadSpvDocumentForm> = (data): void => {
    handleFileUpload(data);
  };

  const acceptedFileType: Accept = isRegisterExtract
    ? { 'application/pdf': [], 'image/png': [], 'image/jpeg': [] }
    : { 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [] };
  const acceptedFileSubtitle = isRegisterExtract ? t('ImageUpTo5MB') : t('DocxUpTo5MB');

  return (
    <ResponsiveModal onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {title ||
            t('DocumentToUpload', {
              documentType: i18nDocumentValues(documentType),
            })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="uploadSPVDocument" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              {documentType === CompanyDocumentType.REGISTER_EXTRACT && (
                <>
                  <FormControl isInvalid={!!formState.errors?.registrationNumber}>
                    <FormLabel htmlFor="registrationNumber">{t('RegistrationNumber')}</FormLabel>
                    <Input
                      id="registrationNumber"
                      type="text"
                      {...register('registrationNumber')}
                    />
                    <ErrorMessage error={formState.errors?.registrationNumber} />
                  </FormControl>
                  <FormControl isInvalid={!!formState.errors?.registrationEntity}>
                    <FormLabel htmlFor="registrationEntity">
                      {t('RegistrationEntityCity')}
                    </FormLabel>
                    <Input
                      id="registrationEntity"
                      type="text"
                      {...register('registrationEntity')}
                    />
                    <ErrorMessage error={formState.errors?.registrationEntity} />
                  </FormControl>
                  <FormControl isInvalid={!!formState.errors?.creationDate}>
                    <FormLabel htmlFor="creationDate">{t('CreationDate')}</FormLabel>
                    <Input id="creationDate" type="date" {...register('creationDate')} />
                    <ErrorMessage error={formState.errors?.creationDate} />
                  </FormControl>
                </>
              )}
              <Controller
                control={control}
                name="companyDocumentFile"
                render={(): JSX.Element => (
                  <FormControl>
                    <FormLabel>{t('SelectDocument')}</FormLabel>
                    <DropzoneInput
                      accept={acceptedFileType}
                      files={companyDocumentFileName}
                      isDisabled={isLoading}
                      isLoading={isLoading}
                      maxFiles={1}
                      subTitle={acceptedFileSubtitle}
                      onDelete={handleFileDelete}
                      onDrop={handleFileDrop}
                    />
                    <ErrorMessage error={formState.errors?.companyDocumentFile} />
                  </FormControl>
                )}
              />
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button
            form="uploadSPVDocument"
            isDisabled={isLoading}
            isLoading={isLoading}
            type="submit"
          >
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
