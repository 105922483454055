import { AddIcon } from '@chakra-ui/icons';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { SortingState, Updater } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { generatePath, resolvePath, useLocation, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { SubscriptionsTabsStatus, routes } from '@blockpulse3/data/shared';
import {
  CreateSubscriptionInput,
  CreateSubscriptionMutation,
  FundraisingType,
  FundraisingWorkflowType,
  GetSubscriptionsDocument,
  GetSubscriptionsQuery,
  GetSubscriptionsQueryVariables,
  OperationStatus,
  OrderBy,
  OrderByDirection,
  SubscriptionFilterType,
  SubscriptionInfosFragment,
  SubscriptionShortInfosFragment,
  SubscriptionsFilterInput,
  UpdateSubscriptionMutation,
  useGetOperationQuery,
  useGetSubscriptionsCountQuery,
} from '@blockpulse3/graphql/hooks';
import { useSuccessToast } from '@blockpulse3/ui/commons';
import { useDisclosureLink, usePagination } from '@blockpulse3/ui/ui-hooks';
import {
  OperationCSVType,
  OperationRemindersModal,
  OperationSubscribersCSVModal,
  OperationSubscribersModal,
  OperationSubscribersType,
  useOperationFilterContext,
} from '@blockpulse3/web-client/operation/commons';

import { FundraisingSubscribersControls } from './FundraisingSubscribersControls';
import { FundraisingSubscribersTable } from './FundraisingSubscribersTable';
import { getTabsWithCount } from './utils';

const PAGE_SIZE = 10;

type Props = {
  /* ** Subscription panel disclosure ** */
  subscriptionPanel: ReturnType<typeof useDisclosureLink>;
  /* ** Count of subscriptions by page ** */
  pageSize?: number;
};

/**
 * FundraisingSubscribersList.
 * Display title and filters of fundraising subscribers table.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function FundraisingSubscribersList({
  subscriptionPanel,
  pageSize = PAGE_SIZE,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nSubscriptionsTabsStatus = useTranslations('SubscriptionsTabsStatusValues');

  const location = useLocation();

  const { operationId = '' } = useParams();

  const [tabIndex, setTabIndex] = useState<number>(0);
  /* ** All filters ** */
  const { filters, setFilters } = useOperationFilterContext();
  const [sorting, setSorting] = useState<SortingState>([]);

  const reminderModal = useDisclosure();
  const csvModal = useDisclosure();
  const subscriberModal = useDisclosure();

  const successToast = useSuccessToast();

  const { data: operationData } = useGetOperationQuery({
    variables: {
      operationId,
    },
  });

  const subscriptionsCountsReq = useGetSubscriptionsCountQuery({
    variables: { operationId },
    fetchPolicy: 'cache-and-network',
  });

  // For now only handle unique column sorting
  const orderBy: OrderBy | undefined = useMemo(() => {
    if (!sorting.length) return undefined;
    return {
      direction: sorting[0].desc ? OrderByDirection.DESC : OrderByDirection.ASC,
      field: sorting[0].id,
    };
  }, [sorting]);

  const paginationProps = usePagination<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables,
    SubscriptionShortInfosFragment
  >({
    queryDocument: GetSubscriptionsDocument,
    queryOptions: {
      variables: {
        operationId,
        filterBy: filters,
        first: pageSize,
        orderBy,
      },
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'subscriptions',
    pageSize,
  });

  const { totalCount, refetch, reset } = paginationProps;

  let filteredTotalInvestAmount = 0;
  paginationProps.results.forEach((res) => (filteredTotalInvestAmount += res.investAmount));

  const operation = operationData?.operation;

  const fundraising = operation?.fundraising;
  const isStarted = operation?.status === OperationStatus.STARTED;
  const isReviewed = operation?.status === OperationStatus.REVIEWED;
  const isCrowdfunding = fundraising?.type === FundraisingType.CROWDFUNDING;
  const subscriptionsCounts = subscriptionsCountsReq.data?.getSubscriptionsCount;
  const mifidStep = operation?.subscriptionSteps?.find(
    (step) => step.__typename === 'MifidStepType',
  );
  const hasMifid = !!mifidStep;
  const isAllInSubscriptionWorkflow = fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;
  const tabs = getTabsWithCount(subscriptionsCounts, hasMifid, isAllInSubscriptionWorkflow);
  const allowedFiscalAdvantages = operation?.allowedFiscalAdvantages || [];

  const handleSubscriberModalComplete = (
    data: CreateSubscriptionMutation | UpdateSubscriptionMutation,
    subscription: CreateSubscriptionInput,
    isLegal: boolean,
  ): void => {
    successToast({ title: t('SubscriberInvited') });
    refetch();
    subscriptionsCountsReq.refetch();
  };

  /* ** Refetch subscriptions() on filter modal submit ** */
  const handleFiltersSubmit = (values: SubscriptionsFilterInput[]): void => {
    const searchValueFilter = filters.filter((filter) =>
      ['searchValue', 'investStatus'].includes(filter.name),
    );
    reset();
    setFilters([...values, ...searchValueFilter]);
  };

  const handleSearchInputSubmit = (value: string): void => {
    const isInputAndFilterSearchEmpty =
      value === '' && !filters.some((filter) => filter.name === 'searchValue');

    if (isInputAndFilterSearchEmpty) return;

    resetSpecificFilter('searchValue', SubscriptionFilterType.CUSTOM, value);
  };

  const resetSpecificFilter = (
    filterName: string,
    filterType: SubscriptionFilterType,
    value: string,
  ): void => {
    const currentFilterWithoutFilterName = filters.filter((filter) => filter.name !== filterName);
    const newFilter: SubscriptionsFilterInput = {
      name: filterName,
      type: filterType,
      value,
    };
    reset();
    setFilters([...currentFilterWithoutFilterName, newFilter]);
  };

  const handleTabsChange = (index: number): void => {
    setTabIndex(index);

    const newFilters: SubscriptionsFilterInput[] = [];
    if (tabs[index].name !== SubscriptionsTabsStatus.ALL_STATUS) {
      newFilters.push({
        name: 'investStatus',
        type: SubscriptionFilterType.STATUS,
        value: tabs[index].name,
      });
    }

    if (
      [SubscriptionsTabsStatus.PENDING, SubscriptionsTabsStatus.ALL_STATUS].includes(
        tabs[index].name,
      )
    ) {
      const resetFilterStatus = filters.filter((filter) => filter.name !== 'investStatus');
      setFilters([...newFilters, ...resetFilterStatus]);
    } else {
      const resetFilterStatus = filters.filter((filter) => filter.name === 'searchValue');
      setFilters([...newFilters, ...resetFilterStatus]);
    }
    refetch();
  };

  const handleSortingChange = (sortFn: Updater<SortingState>): void => {
    setSorting(sortFn);
    refetch();
  };

  const handleRowClick = (subscriptionId: SubscriptionInfosFragment['id']): void => {
    subscriptionPanel.onOpen(
      resolvePath(
        generatePath(routes.company.fundraising.subscription.href, { subscriptionId }),
        location.pathname,
      ).pathname,
    );
  };

  return (
    <Card>
      <CardHeader
        alignItems={{ base: 'flex-start', md: 'center' }}
        as={Stack}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        spacing="4"
      >
        <Heading size="md">{t('SubscribersList')}</Heading>
        <Stack alignSelf="stretch" direction={{ base: 'column', md: 'row' }}>
          {isCrowdfunding && (
            <>
              <Button
                isDisabled={!isStarted}
                leftIcon={<AddIcon />}
                onClick={subscriberModal.onOpen}
              >
                {t('InviteSubscriberAction')}
              </Button>
              <Button isDisabled={!isStarted} variant="secondary" onClick={csvModal.onOpen}>
                {t('CSVImport')}
              </Button>
            </>
          )}
          {(isStarted || isReviewed) && (
            <Button isDisabled={!totalCount} variant="secondary" onClick={reminderModal.onOpen}>
              {t('RemindSubscribers', {
                nb: totalCount,
              })}
            </Button>
          )}
        </Stack>
      </CardHeader>
      <CardBody>
        <Tabs defaultIndex={0} onChange={handleTabsChange}>
          <TabList>
            {tabs.map((tab, i) => (
              <Tab key={i}>
                <Text>
                  {tab.name === SubscriptionsTabsStatus.NOT_SIGNED_UP &&
                  [OperationStatus.DRAFT, OperationStatus.VALIDATED].includes(
                    operation?.status || OperationStatus.DRAFT,
                  )
                    ? i18nSubscriptionsTabsStatus(`${tab.name}_PENDING`)
                    : i18nSubscriptionsTabsStatus(tab.name)}
                </Text>
                <Badge ml="2">{tab.count}</Badge>
              </Tab>
            ))}
          </TabList>
          <TabPanels pt="4">
            <Stack spacing="4">
              <FundraisingSubscribersControls
                activeTabIndex={tabIndex}
                filters={filters}
                tabs={tabs}
                onFiltersChange={handleFiltersSubmit}
                onSearchInputSubmit={handleSearchInputSubmit}
              />
              <FundraisingSubscribersTable
                allowedFiscalAdvantages={allowedFiscalAdvantages}
                fundraisingStatus={operation?.status}
                sorting={sorting}
                tabName={tabs[tabIndex].name}
                onRowClick={handleRowClick}
                onSortingChange={handleSortingChange}
                {...paginationProps}
              />
            </Stack>
          </TabPanels>
        </Tabs>
      </CardBody>
      <OperationRemindersModal
        filters={filters}
        isOpen={reminderModal.isOpen}
        operationId={operationId}
        subscribersType={OperationSubscribersType.SUBSCRIBERS}
        totalCount={totalCount}
        onClose={reminderModal.onClose}
      />
      {isCrowdfunding && (
        <>
          <OperationSubscribersCSVModal
            isOpen={csvModal.isOpen}
            operationCsvType={OperationCSVType.FUNDRAISING_CROWDFUNDING}
            operationId={operationId}
            onClose={csvModal.onClose}
            onComplete={refetch}
          />
          <OperationSubscribersModal
            amountRangeRequired={true}
            amountRequired={false}
            feesRequired={true}
            isOpen={subscriberModal.isOpen}
            modalSubmitText={t('AddTheSubscriber')}
            modalTitle={t('AddSubscriber')}
            operationId={operationId}
            onClose={subscriberModal.onClose}
            onComplete={handleSubscriberModalComplete}
          />
        </>
      )}
    </Card>
  );
}
