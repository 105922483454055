import { Card, CardBody, CardHeader, Heading, Skeleton } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { useGetWalletQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { WalletTable } from './WalletTable';

type Props = unknown;

/**
 * Wallet.
 *
 * @returns {JSX.Element}
 */
export function Wallet(): JSX.Element | null {
  const t = useTranslations();

  const { identityId } = useIdentity();

  const { data, loading, error } = useGetWalletQuery({
    variables: {
      identityId: identityId || '',
    },
    skip: !identityId,
  });

  if (loading || !identityId) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="200px" />;
  }

  const walletBalances = data?.getWallet?.balances || [];

  if (!walletBalances?.length) return null;

  return (
    <Card>
      <CardHeader>
        <Heading size="md">{t('MyPortfolio')}</Heading>
      </CardHeader>
      <CardBody>
        <WalletTable balances={walletBalances} />
      </CardBody>
    </Card>
  );
}

export type WalletProps = Props;
