import { Avatar, AvatarProps, Icon } from '@chakra-ui/react';
import { OfficeBuildingIcon } from '@heroicons/react/outline';

type Props = {
  src?: string | null;
} & Omit<AvatarProps, 'src'>;

/**
 * CompanyIdentityAvatar.
 * Avatar for Identity Company. Fallback with OfficeBuildingIcon.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function CompanyIdentityAvatar({ src, ...props }: Props): JSX.Element {
  return (
    <Avatar
      boxSize="9"
      icon={<Icon as={OfficeBuildingIcon} boxSize="50%" />}
      loading="lazy"
      rounded="sm"
      src={src || undefined}
      sx={{ img: { objectFit: 'contain', rounded: 'sm' } }}
      {...props}
    />
  );
}

export type CompanyIdentityAvatarProps = Props;
