import { Stack } from '@chakra-ui/react';

import { AssetList } from './AssetList';
import { OptionList } from './OptionList';

type Props = unknown;

/**
 * CapTableView.
 * Main captable view, rendering specific captable informations and the list of shareholders.
 *
 * @returns {JSX.Element}
 */
export function AssetsAndPoolsView(): JSX.Element {
  return (
    <Stack spacing="4">
      <AssetList />
      <OptionList />
    </Stack>
  );
}

export type AssetsAndPoolsViewProps = Props;
