import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  HStack,
  Heading,
  Skeleton,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  GetSubscriptionsDocument,
  GetSubscriptionsQuery,
  GetSubscriptionsQueryVariables,
  Identity,
  SubscriptionInfosFragment,
  SubscriptionSide,
  useDeleteAuthorizedIdentityMutation,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import {
  DeleteConfirmModal,
  ErrorQueryCard,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { usePagination } from '@blockpulse3/ui/ui-hooks';

import { NewSecondaryOperationBuyerTable } from './NewSecondaryOperationBuyerTable';
import { NewSecondaryOperationBuyerTableControls } from './NewSecondaryOperationBuyerTableControls';
import { parseBuyerFromIdentity, parseBuyerFromSubscription } from './utils';

type Props = unknown;

/**
 * NewSecondaryOperationBuyers.
 * Buyer step of the secondary operation workflow.
 *
 * @returns {JSX.Element}
 */
export function NewSecondaryOperationBuyers(): JSX.Element {
  const t = useTranslations();

  const deleteModalRef = useRef(null);

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const buyerDeleteModal = useDisclosure();

  const { operationId = '' } = useParams();

  const navigate = useNavigate();

  const { data, loading, error, refetch } = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });
  const operation = data?.operation;
  const hasUniqueSeller = operation?.authorizedSellers?.length === 1;

  const paginationProps = usePagination<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables,
    SubscriptionInfosFragment
  >({
    queryDocument: GetSubscriptionsDocument,
    queryOptions: {
      variables: {
        operationId,
        first: 150,
      },
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'subscriptions',
    pageSize: 150,
  });

  const { results: subscriptions, refetch: refetchSubscriptions } = paginationProps;

  useEffect(() => {
    refetchSubscriptions();
  }, [operationId, refetchSubscriptions]);

  const [deleteAuthorizedIdentity] = useDeleteAuthorizedIdentityMutation();

  const [searchInput, setSearchInput] = useState<string>('');
  const [deletedIdentityId, setDeletedIdentityId] = useState<Identity['id'] | null>(null);

  const buyers = useMemo(() => {
    // Subscriptions are created on opportunity related secondary operations
    if (subscriptions.length) {
      return subscriptions.map((subscription) => parseBuyerFromSubscription(subscription));
    }

    const authorizedBuyers = data?.operation.authorizedBuyers || [];
    return authorizedBuyers.map((authorizedBuyer) => parseBuyerFromIdentity(authorizedBuyer));
  }, [data?.operation.authorizedBuyers, subscriptions]);

  const filteredBuyers = useMemo(() => {
    if (searchInput === '') {
      return buyers;
    }
    const searchInputLowerCase = searchInput.toLowerCase();
    return buyers.filter(
      (buyer) =>
        buyer.name.toLowerCase().includes(searchInputLowerCase) ||
        buyer.email?.toLowerCase().includes(searchInputLowerCase) ||
        buyer.identifier?.toLowerCase().includes(searchInputLowerCase),
    );
  }, [searchInput, buyers]);

  const handleSearchInputChange = (input: string): void => {
    setSearchInput(input);
  };

  const handleFormCancel = (): void => {
    navigate('../' + routes.company.newSecondary.edit.sellers.href);
  };

  const handleFormSubmit = (): void => {
    navigate('../' + routes.company.newSecondary.edit.summary.href);
  };

  const handleBuyerDelete = (id: Identity['id']): void => {
    buyerDeleteModal.onOpen();
    setDeletedIdentityId(id);
  };

  const handleBuyerDeleteConfirm = (): void => {
    if (!deletedIdentityId) return;

    deleteAuthorizedIdentity({
      variables: {
        deleteAuthorizedIdentityInput: {
          operationId,
          identityId: deletedIdentityId,
          side: SubscriptionSide.BUYER,
        },
      },
      onCompleted: () => {
        successToast({ title: t('BuyerRemoved', { nb: 1 }) });
        setDeletedIdentityId(null);
        buyerDeleteModal.onClose();
        refetch();
        if (hasUniqueSeller) refetchSubscriptions();
      },
      onError: () => {
        errorToast({ title: t('ErrorDeletingBuyer') });
        setDeletedIdentityId(null);
        buyerDeleteModal.onClose();
      },
    });
  };

  if (loading) {
    return <Skeleton height="350px" width="full" />;
  }

  if (!data || error) {
    return <ErrorQueryCard height="350px" width="full" />;
  }

  const isNextDisabled = buyers.length === 0;

  return (
    <Card variant="divider-top" width="full">
      <CardHeader>
        <Heading size="lg">{t('BuyersConfiguration')}</Heading>
      </CardHeader>
      <Divider />
      <CardBody as={Stack} spacing="4">
        <NewSecondaryOperationBuyerTableControls onSearchInputChange={handleSearchInputChange} />
        <NewSecondaryOperationBuyerTable
          displayAmount={hasUniqueSeller}
          values={filteredBuyers}
          onDeleteBuyer={handleBuyerDelete}
        />
      </CardBody>
      <CardFooter as={HStack} spacing="4">
        <Button type="button" variant="secondary" w="full" onClick={handleFormCancel}>
          {t('Back')}
        </Button>
        <Button isDisabled={isNextDisabled} type="submit" w="full" onClick={handleFormSubmit}>
          {t('Next')}
        </Button>
      </CardFooter>
      <DeleteConfirmModal
        isOpen={buyerDeleteModal.isOpen}
        leastDestructiveRef={deleteModalRef}
        subtitle={t('DefinitiveAction')}
        title={t('DeleteBuyerQuestion', { nb: 1 })}
        onClose={buyerDeleteModal.onClose}
        onDelete={handleBuyerDeleteConfirm}
      />
    </Card>
  );
}

export type NewSecondaryOperationBuyersProps = Props;
