import { InfoIcon } from '@chakra-ui/icons';
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useCreateStockAssetMutation } from '@blockpulse3/graphql/hooks';
import {
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useErrorToast,
} from '@blockpulse3/ui/commons';

import { defaultValues, schema, stockAssetTypeOptions } from './schema';
import { IStockAsset } from './types';

type Props = {
  onClose: () => void;
  onComplete: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

/**
 * AssetCreateModal.
 * Form to create a Stock Asset.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function AssetCreateModal({ onClose, onComplete, ...props }: Props): JSX.Element {
  const t = useTranslations();
  const i18nAssetType = useTranslations('AssetTypeValues');

  const errorToast = useErrorToast();

  const { companyId = '' } = useParams();
  const [createStockAsset, { loading: isCreateLoading }] = useCreateStockAssetMutation();

  const { register, control, formState, handleSubmit, reset } = useForm<IStockAsset>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleModalClose = (): void => {
    onClose();
    reset({ ...defaultValues });
  };

  const handleFormSubmit: SubmitHandler<IStockAsset> = (data): void => {
    if (!companyId) return;

    const stockAsset = {
      ...data,
      type: data.type.value,
      value: parseFloat(data.value.toString()),
    };

    createStockAsset({
      variables: {
        companyId,
        createStockAssetInput: { ...stockAsset },
      },
      onCompleted: () => {
        onComplete();
        handleModalClose();
      },
      onError: () => {
        errorToast({ title: t('AddAssetError') });
        handleModalClose();
      },
    });
  };

  return (
    <ResponsiveModal onClose={handleModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('AddExistingAsset')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="add-stock-asset" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <Controller
                control={control}
                name="type"
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.type}>
                    <FormLabel htmlFor="type">{t('AssetType')}</FormLabel>
                    <Select
                      id="type"
                      isSearchable={false}
                      menuPlacement="auto"
                      options={stockAssetTypeOptions}
                      getOptionLabel={(option): string =>
                        option.label ? i18nAssetType(option.label, { nb: 0 }) : ''
                      }
                      {...field}
                    />
                    <ErrorMessage error={formState.errors?.type?.label} />
                  </FormControl>
                )}
              />
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl flex="2" isInvalid={!!formState.errors?.name}>
                  <FormLabel htmlFor="name">{t('AssetName')}</FormLabel>
                  <Input id="name" type="string" {...register('name')} />
                  <ErrorMessage error={formState.errors?.name} />
                </FormControl>
                <FormControl flex="1" isInvalid={!!formState.errors?.code}>
                  <FormLabel htmlFor="code">
                    <HStack>
                      <Text>{t('Code')}</Text>
                      <Tooltip
                        hasArrow
                        label={t('ShortNameOfAssetForIdentification')}
                        placement="top"
                      >
                        <Icon as={InfoIcon} color="gray.600" />
                      </Tooltip>
                    </HStack>
                  </FormLabel>
                  <Input id="code" type="string" {...register('code')} />
                  <ErrorMessage error={formState.errors?.code} />
                </FormControl>
              </Stack>
              <FormControl isInvalid={!!formState.errors?.value}>
                <FormLabel htmlFor="value">{t('NominalValue')}</FormLabel>
                <InputGroup>
                  <Input id="value" step="0.01" type="number" {...register('value')} />
                  <InputRightAddon>€</InputRightAddon>
                </InputGroup>
                <ErrorMessage error={formState.errors?.value} />
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button
            isDisabled={isCreateLoading}
            type="button"
            variant="secondary"
            onClick={handleModalClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            data-cy="add-asset"
            form="add-stock-asset"
            isDisabled={isCreateLoading}
            isLoading={isCreateLoading}
            type="submit"
          >
            {t('AddTheAsset')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type AssetCreateModalProps = Props;
