import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/outline';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import { useGetOptionAssetsQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { OptionTableCurrent } from '../AssetsAndOptions/OptionTable';
import { OptionCreateModal } from '../OptionCreateModal';

type Props = unknown;

export function OptionList(): JSX.Element | null {
  const t = useTranslations();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  const { user } = useAuthUser();
  const { companyId = '' } = useParams();
  const isFeatureOptionPool = isFeatureEnabled('optionPool');

  const { data, loading, error, refetch } = useGetOptionAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId && !isFeatureOptionPool,
  });

  const handleOptionNavigation = (assetId: string): void => {
    if (companyId) {
      navigate(generatePath(routes.company.option.href, { assetId }));
    }
  };

  if (loading) {
    return (
      <Skeleton>
        <Card height="330px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="300px" />;
  }

  const currentOptions = data?.getOptionAssets || [];
  const isSuperAdmin = user?.isSuperAdmin;
  if (!isFeatureOptionPool || (!currentOptions?.length && !isSuperAdmin)) return null;

  const handleOpenModal = (): void => {
    onOpen();
  };

  const handleCreateOptionComplete = (): void => {
    refetch();
  };

  return (
    <>
      <Card>
        <CardHeader
          alignItems={{ base: 'flex-start', md: 'center' }}
          as={Stack}
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
        >
          <Heading size="md">{t('MyPlans')}</Heading>
          {isSuperAdmin && (
            <Button
              leftIcon={<Icon as={PlusIcon} boxSize="18px" />}
              variant="secondary"
              onClick={handleOpenModal}
            >
              {t('AddPool')}
            </Button>
          )}
        </CardHeader>
        <CardBody>
          <Tabs isLazy defaultIndex={0}>
            <TabList>
              <Tab>
                <Text>{t('InProgress')}</Text>
                {/*<Badge ml="2">{started}</Badge>*/}
              </Tab>
              <Tab isDisabled>
                <Text>{t('Past')}</Text>
                {/*<Badge ml="2">{passed}</Badge>*/}
              </Tab>
              <Tab isDisabled>
                <Text>{t('Draft', { nb: 1 })}</Text>
                {/*<Badge ml="2">{drafts}</Badge>*/}
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel px="0">
                <OptionTableCurrent onClick={handleOptionNavigation} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
      <OptionCreateModal
        isOpen={isOpen}
        onClose={onClose}
        onComplete={handleCreateOptionComplete}
      />
    </>
  );
}

export type OptionListProps = Props;
