import { Button, Icon, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { OptionAssetListInfosFragment } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import { AssetBadge, TableContainer } from '@blockpulse3/ui/commons';

type Props = {
  /* ** Option list array ** */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  values: OptionAssetListInfosFragment[];
  /* ** Callback on table row click ** */
  onClick?: (assetId: string) => void;
};

export function OptionTable({ values, onClick = noop }: Props): JSX.Element {
  const t = useTranslations();

  const handleOptionClick = (assetId: string): void => {
    onClick(assetId);
  };

  return (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>{t('Name')}</Th>
            <Th>{t('Quantity')}</Th>
            <Th>{t('EndDate')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {values.map((row) => (
            <Tr
              key={row.asset.id}
              role="button"
              onClick={(): void => handleOptionClick(row.asset.id)}
            >
              <Td>
                <Text fontWeight="600">{row.name}</Text>
                <Text color="gray.500" fontSize="xs" fontWeight="400">
                  {row.assetType}
                </Text>
              </Td>
              <Td fontWeight="600">
                <AssetBadge type={row.assetType} value={row.initialSupply} />
              </Td>
              <Td fontWeight="600">
                {formatDate(dayjs.unix(row.expirationDate).toDate(), 'DD MMMM YYYY')}
              </Td>
              <Td textAlign="right">
                <Button
                  rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                  variant="secondary"
                >
                  {t('ShowDetails')}
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export type OptionTableProps = Props;
