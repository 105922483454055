import { FiscalAdvantage } from './enums';
import { HoldingMethod } from './enums/HoldingMethod';
import { OperationDocumentType } from './enums/OperationDocumentType';

const holdingMethodCertificate: Record<HoldingMethod, OperationDocumentType> = {
  [HoldingMethod.DIRECT]: OperationDocumentType.OWNERSHIP_CERTIFICATE,
  [HoldingMethod.PEA]: OperationDocumentType.PEA_CERTIFICATE,
  [HoldingMethod.PEA_PME]: OperationDocumentType.PEA_PME_CERTIFICATE,
};

export function getHoldingMethodCertificate(
  holdingMethod: HoldingMethod,
  allowedFiscalAdvantages?: FiscalAdvantage[],
  isCompany = false,
): OperationDocumentType {
  if (isCompany || !allowedFiscalAdvantages || !allowedFiscalAdvantages.length) {
    return OperationDocumentType.OWNERSHIP_CERTIFICATE;
  }

  if (
    holdingMethod === HoldingMethod.DIRECT &&
    allowedFiscalAdvantages.includes(FiscalAdvantage.IR_PME)
  ) {
    return OperationDocumentType.IR_PME_CERTIFICATE;
  }

  return holdingMethodCertificate[holdingMethod];
}
