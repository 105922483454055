import * as yup from 'yup';

import { HoldingMethod, requiredStringField } from '@blockpulse3/data/shared';

import { IFiscalAccountForm } from './types';

export const fiscalAccountSchema = yup.object({
  holdingMethod: requiredStringField,
  accountNumber: requiredStringField,
  bankName: requiredStringField,
  bankCode: requiredStringField,
  bankEmail: yup.string().email().notRequired(),
}) as yup.ObjectSchema<IFiscalAccountForm>;

export const fiscalAccountDefaults: IFiscalAccountForm = {
  holdingMethod: HoldingMethod.PEA,
  accountNumber: '',
  bankName: '',
  bankCode: '',
  bankEmail: '',
};
