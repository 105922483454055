import { Flex, HStack, Stack, Text } from '@chakra-ui/react';

import { noop } from '@blockpulse3/data/shared';
import { IdentityType } from '@blockpulse3/graphql/hooks';

import { IdentityAvatar } from './IdentityAvatar';

type Props = {
  /* ** Identity type ** */
  type?: IdentityType;
  /* ** Card identity title, ie. firstName - lastName ** */
  title: string;
  /* ** Card identity subTitle, ie. email ** */
  subTitle?: string;
  /* ** Profile picture ** */
  src?: string | null;
  /* ** Additional right node ** */
  rightNode?: React.ReactNode;
  /* ** Callback on card click ** */
  onClick?: () => void;
};

/**
 * CardIdentity.
 * Simple container displaying an identity with legal or natural icon.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function CardIdentity({
  type = IdentityType.INDIVIDUAL,
  title,
  subTitle = '',
  rightNode = null,
  src = '',
  onClick = noop,
}: Props): JSX.Element {
  return (
    <Flex
      _hover={{ bg: 'gray.50', cursor: 'pointer' }}
      bg="white"
      borderColor="gray.300"
      borderStyle="solid"
      borderWidth="1px"
      p="4"
      rounded="md"
      onClick={onClick}
    >
      <Stack direction={{ base: 'column', md: 'row' }} w="full">
        <HStack flexGrow="1" spacing="4">
          <IdentityAvatar src={src} type={type} />
          <Stack spacing="0" w="full">
            <Text fontSize="lg" fontWeight="bold">
              {title}
            </Text>
            <Text>{subTitle}</Text>
          </Stack>
        </HStack>
        <Flex alignSelf={{ base: 'flex-end', md: 'center' }}>{rightNode}</Flex>
      </Stack>
    </Flex>
  );
}

export type CardIdentityProps = Props;
