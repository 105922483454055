import { Text, useDisclosure } from '@chakra-ui/react';
import { CreditCardIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlHoldingMethodTypeValues } from '@blockpulse3/data/locales/types';
import { Exceptions, noop } from '@blockpulse3/data/shared';
import {
  MergedHoldingMethodStep,
  SubscriptionStepStatus,
  useGetSubscriptionQuery,
  useValidateHoldingMethodMutation,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency, isDocumentTypeSubscription } from '@blockpulse3/helpers';
import { useErrorToast } from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionStep } from '../../SubscriptionStep';
import { HoldingMethodModal } from './HoldingMethodModal';

type Props = {
  subscriptionStep: MergedHoldingMethodStep;
  isDisabled: boolean;
};

export function HoldingMethodStep({ subscriptionStep, isDisabled }: Props): JSX.Element {
  const t = useTranslations();
  const i18nHoldingMethodType = useTranslations('HoldingMethodTypeValues');

  const errorToast = useErrorToast();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { identityId } = useIdentity();

  const { subscriptionId = '' } = useParams();

  const [validateHoldingMethod] = useValidateHoldingMethodMutation();

  const { data, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const subscription = data?.subscription;
  const holdingMethod = subscription?.holdingMethod;

  const isStepValidated = subscriptionStep.status === SubscriptionStepStatus.VALID;
  const investAmount = subscription?.investAmount || 0;

  const subscriptionDocument = subscription?.data.mergedSteps?.find(
    (step) =>
      step.__typename === 'MergedDocumentSignatureStep' &&
      isDocumentTypeSubscription(step.documentType),
  );
  const isSubscriptionDocumentGenerated =
    subscriptionDocument && subscriptionDocument.status !== SubscriptionStepStatus.NONE;

  const handleModalOpen = (): void => {
    // Prevent modal from opening once subscription document is generated
    if (!isSubscriptionDocumentGenerated) {
      onOpen();
    }
  };

  const handleModalSubmit = (): void => {
    if (!subscriptionId) return;

    validateHoldingMethod({
      variables: {
        validateHoldingMethodInput: {
          subscriptionId,
        },
      },
      onCompleted: () => {
        refetch();
        onClose();
      },
      onError: (err) => {
        if (err.message === Exceptions.FiscalAccountIncomplete) {
          errorToast({ title: t('HoldingMethodMissingInformations') });
        }
      },
    });
  };

  return (
    <>
      <SubscriptionStep
        icon={CreditCardIcon}
        isDisabled={isDisabled}
        status={subscriptionStep.status}
        title={t('ChooseHoldingMethod')}
        type={subscriptionStep.type}
        onClick={!isDisabled ? handleModalOpen : noop}
      >
        {isStepValidated && (
          <Text fontSize="sm">
            {t.rich('SubscribeForAmountAndMethod', {
              investAmount: formatNumberCurrency(investAmount),
              holdingMethod: i18nHoldingMethodType(holdingMethod as IntlHoldingMethodTypeValues),
              important: (chunks) => <Text as="b">{chunks}</Text>,
            })}
          </Text>
        )}
      </SubscriptionStep>
      <HoldingMethodModal
        isOpen={isOpen}
        subscriptionStep={subscriptionStep}
        onClose={onClose}
        onSubmit={handleModalSubmit}
      />
    </>
  );
}
