import { HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@heroicons/react/solid';

import { IdentityVerificationStatus } from '@blockpulse3/data/shared';
import { IdentityShortInfosFragment } from '@blockpulse3/graphql/hooks';

import { IdentityAvatar } from '../../IdentityAvatar';

type Props = {
  identity: IdentityShortInfosFragment;
};

export function IdentitySelectBadge({ identity }: Props): JSX.Element {
  const { type, profilePicture, verificationStatus, identifier, name } = identity;

  return (
    <HStack p="1">
      <IdentityAvatar boxSize="9" src={profilePicture} type={type} />
      <Stack spacing="0">
        <HStack>
          <Text fontWeight="600">{name}</Text>
          {verificationStatus === IdentityVerificationStatus.APPROVED && (
            <Icon as={CheckCircleIcon} boxSize="15px" color="green.400" />
          )}
        </HStack>
        <Text color="gray.500" fontWeight="400">
          {identifier}
        </Text>
      </Stack>
    </HStack>
  );
}
