import { FiscalAdvantage, HoldingMethod } from '@blockpulse3/data/shared';
import {
  GetBalanceByAssetAndIdentityQuery,
  GetSubscriptionInfosFragment,
  GetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';

import { SaleStepForm } from './types';

function getShareCount(subscription: GetSubscriptionInfosFragment | undefined): number {
  const investAmount = subscription?.investAmount || 0;
  const pricePerShare = subscription?.operation.pricePerShare || 1;

  let shareCount = 0;
  if (investAmount && pricePerShare) {
    shareCount = Math.round(investAmount / pricePerShare);
  }

  return shareCount;
}

export function getSaleStepValues(
  subscription: GetSubscriptionInfosFragment | undefined,
): SaleStepForm {
  const shareCount = getShareCount(subscription);
  const investAmount = subscription?.investAmount || 0;
  const buyerIdentity = subscription?.buyerIdentity;

  return {
    shareCount,
    investAmount,
    buyerIdentity: {
      label: buyerIdentity?.name || '',
      value: buyerIdentity?.id || '',
      identity: buyerIdentity,
    },
  };
}

function parseAvailableShares(
  balanceData: GetBalanceByAssetAndIdentityQuery | undefined,
): Record<HoldingMethod, number> {
  const {
    availableDirectShares = 0,
    availablePeaShares = 0,
    availablePeaPmeShares = 0,
  } = balanceData?.getBalanceByAssetAndIdentity || {};

  return {
    [HoldingMethod.DIRECT]: availableDirectShares,
    [HoldingMethod.PEA]: availablePeaShares,
    [HoldingMethod.PEA_PME]: availablePeaPmeShares,
  };
}

export function getAvailableSharesByHoldingMethod(
  subscriptionData: GetSubscriptionQuery | undefined,
  balanceData: GetBalanceByAssetAndIdentityQuery | undefined,
): number {
  const availableSharesByHoldingMethod = parseAvailableShares(balanceData);

  const subscription = subscriptionData?.subscription;
  const availableShares =
    availableSharesByHoldingMethod[subscription?.holdingMethod ?? HoldingMethod.DIRECT];

  // Compute validated share amount to add it back to total available shares
  const subscriptionShareCount = getShareCount(subscription);

  return availableShares + subscriptionShareCount;
}

/**
 * getAllowedFiscalAdvantages.
 * Retrieves the allowed fiscal advantages based on the balance data.
 *
 * @param {GetBalanceByAssetAndIdentityQuery | undefined} balanceData
 * @returns {FiscalAdvantage[]}
 */
export function getAllowedFiscalAdvantages(
  balanceData: GetBalanceByAssetAndIdentityQuery | undefined,
): FiscalAdvantage[] {
  const availableSharesByHoldingMethod = parseAvailableShares(balanceData);

  const fiscalAdvantages: FiscalAdvantage[] = [];
  for (const key in availableSharesByHoldingMethod) {
    if (availableSharesByHoldingMethod[key as HoldingMethod] > 0) {
      fiscalAdvantages.push(key as FiscalAdvantage);
    }
  }

  return fiscalAdvantages;
}
