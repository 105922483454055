import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  useGetSubscriptionFundraisingDocumentsQuery,
  useQueueCreditTransferTransactionMutation,
} from '@blockpulse3/graphql/hooks';
import { useIdentity } from '@blockpulse3/web-client/auth';

type Props = {
  onClose: () => void;
  renderAlone?: boolean;
};

export function QueueCreditTransferMethod({ onClose, renderAlone }: Props): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const { refetch } = useGetSubscriptionFundraisingDocumentsQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });

  const [queueCreditTransferTransaction, { loading }] = useQueueCreditTransferTransactionMutation();

  const handleQueueDirectDebit = (): void => {
    queueCreditTransferTransaction({
      variables: {
        queueCreditTransferTransactionInput: {
          subscriptionId,
        },
      },
      onCompleted: () => {
        refetch();
        onClose();
      },
    });
  };

  const Component = (): JSX.Element => {
    return (
      <Stack>
        <Text color="gray.500" fontSize="sm" fontWeight="600">
          {t('AddTransactionToQueueDescription')}
        </Text>
        <Button isLoading={loading} onClick={handleQueueDirectDebit}>
          {t('AddToQueue')}
        </Button>
      </Stack>
    );
  };

  if (renderAlone) {
    return <Component />;
  }

  return (
    <AccordionItem>
      <AccordionButton justifyContent="space-between">
        <Text fontWeight="500">{t('AddTransactionToQueue')}</Text>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <Component />
      </AccordionPanel>
    </AccordionItem>
  );
}
