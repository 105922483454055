import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { BrandTheme } from '@blockpulse3/ui/brand-theme';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  cardAnatomy.keys,
);

const baseStyle = definePartsStyle({
  container: {
    background: 'white',
    borderRadius: BrandTheme.borderRadius.card,
    boxShadow: 'md',
  },
  header: {
    paddingBottom: 0,
  },
  footer: {
    paddingTop: 0,
  },
});

const dividerTopVariant = definePartsStyle({
  container: {
    background: 'white',
    borderRadius: BrandTheme.borderRadius.card,
    boxShadow: 'md',
  },
  header: {
    paddingBottom: 5,
  },
  footer: {
    paddingTop: 0,
  },
});

const dividerBottomVariant = definePartsStyle({
  container: {
    background: 'white',
    borderRadius: BrandTheme.borderRadius.card,
    boxShadow: 'md',
  },
  header: {
    paddingBottom: 0,
  },
  footer: {
    paddingTop: 5,
  },
});

const dividerTopBottomVariant = definePartsStyle({
  container: {
    background: 'white',
    borderRadius: BrandTheme.borderRadius.card,
    boxShadow: 'md',
  },
  header: {
    paddingBottom: 5,
  },
  footer: {
    paddingTop: 5,
  },
});

const roundedGrayVariant = definePartsStyle({
  container: {
    background: 'gray.50',
    boxShadow: 0,
    rounded: 'md',
    margin: 5,
  },
  footer: {
    paddingTop: 5,
  },
});

const roundedPrimaryVariant = definePartsStyle({
  container: {
    background: 'primary',
    boxShadow: 0,
    rounded: 'md',
    margin: 5,
    color: 'white',
  },
  footer: {
    paddingTop: 5,
  },
});

const variants = {
  'divider-top': dividerTopVariant,
  'divider-bottom': dividerBottomVariant,
  'divider-top-bottom': dividerTopBottomVariant,
  'rounded-gray': roundedGrayVariant,
  'rounded-primary': roundedPrimaryVariant,
};

export const cardTheme = defineMultiStyleConfig({ baseStyle, variants });
