import { InfoIcon } from '@chakra-ui/icons';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  CreateOrUpdateAssetDraftInput,
  useCreateOrUpdateAssetDraftMutation,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { bondAssetDraftDefaultValues, durationUnitOptions, schema } from './schema';
import { IBondAssetDraft } from './types';

type Props = {
  /* ** Default draft bond asset values from previous draft ** */
  defaultValues?: IBondAssetDraft;
  /* ** ResponsiveModal `onClose` callback ** */
  onClose: () => void;
  /* ** Callback on form submit ** */
  onSubmit?: (assetDraftInput: CreateOrUpdateAssetDraftInput) => void;
  /* ** Callback on form submit success ** */
  onSubmitSuccess?: (data: IBondAssetDraft) => void;
  /* ** Callback on form submit error ** */
  onSubmitError?: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

/**
 * CreateBondAssetDraftModal.
 * Create new bond asset draft. Save config as a JSON.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function CreateBondAssetDraftModal({
  defaultValues = bondAssetDraftDefaultValues,
  onClose,
  onSubmit = noop,
  onSubmitSuccess = noop,
  onSubmitError = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { companyId = '', operationId = '' } = useParams();

  const [createOrUpdateAssetDraft] = useCreateOrUpdateAssetDraftMutation();

  const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false);

  const { register, control, formState, handleSubmit, reset, watch } = useForm<IBondAssetDraft>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleModalClose = (): void => {
    onClose();
  };

  const handleFormSubmit: SubmitHandler<IBondAssetDraft> = (data) => {
    const { name, type, durationUnit, ...createOrUpdateAssetDraftInput } = data;

    const assetDraftInput: CreateOrUpdateAssetDraftInput = {
      companyId,
      name,
      type: type.value,
      durationUnit: durationUnit.value,
      ...createOrUpdateAssetDraftInput,
    };

    if (operationId) {
      setIsCreateLoading(true);
      createOrUpdateAssetDraft({
        variables: {
          createOrUpdateAssetDraftInput: {
            ...assetDraftInput,
            operationId,
          },
        },
        onCompleted: (res) => {
          successToast({ title: t('DraftBondCreateSuccess') });
          setIsCreateLoading(false);
          onSubmitSuccess({ ...data, assetId: res?.createOrUpdateAssetDraft?.id || null });
        },
        onError: () => {
          errorToast({ title: t('DraftBondCreateError') });
          setIsCreateLoading(false);
          onSubmitError();
        },
      });
    } else {
      onSubmit(assetDraftInput);
    }
  };

  /* ** Keep track of duration unit in right element groups ** */
  const durationUnit = watch('durationUnit');

  useEffect(
    function resetForm() {
      reset({ ...defaultValues });
    },
    [reset, defaultValues],
  );

  const header = defaultValues.assetId ? t('ModifyBond') : t('NewBond');
  const submit = defaultValues.assetId ? t('UpdateBond') : t('AddBond');

  return (
    <ResponsiveModal onClose={handleModalClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="add-bond-asset" onSubmit={handleSubmit(handleFormSubmit)}>
            <Stack spacing="4">
              <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl flex="2" isInvalid={!!formState.errors?.name}>
                  <FormLabel htmlFor="name">{t('BondName')}</FormLabel>
                  <Input id="name" type="string" {...register('name')} />
                  <ErrorMessage error={formState.errors?.name} />
                </FormControl>
                <FormControl flex="1" isInvalid={!!formState.errors?.code}>
                  <FormLabel htmlFor="code">
                    <HStack>
                      <Text>{t('Code')}</Text>
                      <Tooltip
                        hasArrow
                        label={t('ShortNameOfOptionForIdentification')}
                        placement="top"
                      >
                        <Icon as={InfoIcon} color="gray.600" />
                      </Tooltip>
                    </HStack>
                  </FormLabel>
                  <Input id="code" type="string" {...register('code')} />
                  <ErrorMessage error={formState.errors?.code} />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <Controller
                  control={control}
                  name="durationUnit"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors?.durationUnit}>
                      <FormLabel htmlFor="durationUnit">{t('DurationUnit')}</FormLabel>
                      <Select
                        id="durationUnit"
                        isSearchable={false}
                        menuPlacement="auto"
                        options={durationUnitOptions}
                        getOptionLabel={(option): string =>
                          option.label ? t(`DurationUnits.${option.label}`) : ''
                        }
                        {...field}
                      />
                      <ErrorMessage error={formState.errors?.durationUnit?.label} />
                    </FormControl>
                  )}
                />
                <FormControl isInvalid={!!formState.errors?.period}>
                  <FormLabel htmlFor="period">{t('Maturity')}</FormLabel>
                  <InputGroup>
                    <Input id="period" type="number" {...register('period')} />
                    <InputRightAddon>{t(`DurationUnits.${durationUnit.label}`)}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.period} />
                </FormControl>
              </Stack>
              <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.nominalValue}>
                  <FormLabel htmlFor="nominalValue">{t('NominalValue')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="nominalValue"
                      step="0.01"
                      type="number"
                      {...register('nominalValue')}
                    />
                    <InputRightAddon>€</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.nominalValue} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.reimbursementInterval}>
                  <FormLabel htmlFor="reimbursementInterval">
                    {t('ReimbursementInterval')}
                  </FormLabel>
                  <InputGroup>
                    <Input
                      id="reimbursementInterval"
                      type="number"
                      {...register('reimbursementInterval')}
                    />
                    <InputRightAddon>{t(`DurationUnits.${durationUnit.label}`)}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.reimbursementInterval} />
                </FormControl>
              </Stack>
              <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.interestRate}>
                  <FormLabel htmlFor="interestRate">{t('InterestRate')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="interestRate"
                      step="0.01"
                      type="number"
                      {...register('interestRate')}
                    />
                    <InputRightAddon>%</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.interestRate} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors.interestPeriod}>
                  <FormLabel htmlFor="interestPeriod">{t('InterestPeriod')}</FormLabel>
                  <InputGroup>
                    <Input id="interestPeriod" type="number" {...register('interestPeriod')} />
                    <InputRightAddon>{t(`DurationUnits.${durationUnit.label}`)}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.interestPeriod} />
                </FormControl>
              </Stack>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing="4"
                w={{ base: '100%', md: '48%' }}
              >
                <FormControl isInvalid={!!formState.errors?.interestPaymentInterval}>
                  <FormLabel htmlFor="interestPaymentInterval">
                    {t('InterestPaymentInterval')}
                  </FormLabel>
                  <InputGroup>
                    <Input
                      id="interestPaymentInterval"
                      type="number"
                      {...register('interestPaymentInterval')}
                    />
                    <InputRightAddon>{t(`DurationUnits.${durationUnit.label}`)}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.interestPaymentInterval} />
                </FormControl>
              </Stack>
              <Stack alignItems="flex-end" direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.issuancePremium}>
                  <FormLabel htmlFor="issuancePremium">{t('IssuancePremium')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="issuancePremium"
                      step="0.01"
                      type="number"
                      {...register('issuancePremium')}
                    />
                    <InputRightAddon>€</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.issuancePremium} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.redemptionPremium}>
                  <FormLabel htmlFor="redemptionPremium">{t('RedemptionPremium')}</FormLabel>
                  <InputGroup>
                    <Input
                      id="redemptionPremium"
                      step="0.01"
                      type="number"
                      {...register('redemptionPremium')}
                    />
                    <InputRightAddon>€</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.redemptionPremium} />
                </FormControl>
              </Stack>
              <FormControl>
                <Checkbox {...register('accelerable')}>
                  <Text>{t('BondReimbursementCanBeAccelerated')}</Text>
                </Checkbox>
              </FormControl>
              <FormControl>
                <Checkbox {...register('extendable')}>
                  <Text>{t('BondDurationCanBeExtended')}</Text>
                </Checkbox>
              </FormControl>
              <FormControl>
                <Checkbox {...register('transferable')}>
                  <Text>{t('TransferOfThisAssetAuthorized')}</Text>
                </Checkbox>
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button isDisabled={isCreateLoading} type="button" variant="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            form="add-bond-asset"
            isDisabled={isCreateLoading}
            isLoading={isCreateLoading}
            type="submit"
          >
            {submit}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type CreateBondAssetDraftModalProps = Props;
