import { Box, Drawer, DrawerContent, DrawerOverlay, Flex, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import { BrandIcon } from '@blockpulse3/ui/brand-theme';
import { ToggleButton } from '@blockpulse3/ui/commons';

import { SidebarDesktop } from './Desktop';

type Props = unknown;

export function SidebarMobile(): JSX.Element {
  const location = useLocation();

  const { isOpen, onToggle, onClose } = useDisclosure();

  /**
   * Close the drawer when the location changes
   * This way, there is no need to prop drill `onClick`
   * callbacks on sidebar menus. Only on mobile devices.
   */
  useEffect(() => {
    onClose();
  }, [location, onClose]);

  return (
    <Box
      bg="primary"
      position="sticky"
      px={{ base: '4', md: '8' }}
      py="4"
      top="0"
      w="full"
      zIndex="2"
    >
      <Flex align="center" justify="space-between">
        <Link to={routes.me.href}>
          <BrandIcon bgTheme="dark" h="32px" />
        </Link>
        <ToggleButton aria-label="Open Menu" isOpen={isOpen} onClick={onToggle} />
        <Drawer
          isFullHeight
          preserveScrollBarGap
          isOpen={isOpen}
          placement="left"
          size="xs"
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent>
            <SidebarDesktop w="full" />
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
}

export type SidebarMobileProps = Props;
