import { Heading, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { ManagementType } from '@blockpulse3/graphql/hooks';

import { SpaceManagements } from './SpaceManagements';

type Props = unknown;

export function SpaceControlsView(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack spacing="6">
      <Heading>{t('ControlledCompanies')}</Heading>
      <SpaceManagements type={ManagementType.OWNER} />
    </Stack>
  );
}

export type SpaceControlsViewProps = Props;
