import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { BrandIcon } from '@blockpulse3/ui/brand-theme';

import { ResetPasswordForm } from './ResetPasswordForm';

type Props = unknown;

export function ResetPasswordView(): JSX.Element {
  const t = useTranslations();


  const navigate = useNavigate();

  const handleBackClick = (): void => {
    navigate(routes.forgotPassword.href);
  };

  return (
    <Card variant="divider-top" w={{ base: 'full', sm: 380 }}>
      <CardHeader alignItems="center" as={Flex} justifyContent="center">
        <BrandIcon
          display="flex"
          h="full"
          justifyContent="center"
          objectFit="cover"
          width="200px"
        />
      </CardHeader>
      <Divider />
      <CardBody as={Stack} p="6" spacing="6">
        <Button
          alignSelf="start"
          leftIcon={<ChevronLeftIcon height="20" width="20" />}
          variant="link"
          onClick={handleBackClick}
        >
          {t('Back')}
        </Button>
        <Stack spacing="1">
          <Heading size="md">{t('ChooseNewPassword')}</Heading>
          <Text>{t('ChangePasswordDifferentFromOld')}</Text>
        </Stack>
        <ResetPasswordForm />
      </CardBody>
    </Card>
  );
}

export type ResetPasswordViewProps = Props;
