import {
  Box,
  HStack,
  Icon,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { InboxInIcon, TrendingUpIcon } from '@heroicons/react/outline';
import { UserGroupIcon, UsersIcon } from '@heroicons/react/solid';
import { Link, generatePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import { IconBoxWrapper, ResponsiveModal, ResponsiveModalProps } from '@blockpulse3/ui/commons';

type Props = {
  opportunityId?: string;
  hasCrowdfunding?: boolean;
  hasSecondary?: boolean;
  hasOpportunity?: boolean;
} & Omit<ResponsiveModalProps, 'children'>;

/**
 * OperationTypeModal.
 * Links to operation workflows.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationTypeModal({
  opportunityId,
  hasCrowdfunding = true,
  hasSecondary = true,
  hasOpportunity = true,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const isFeatureFundraisingCrowdfunding = isFeatureEnabled('fundraisingCrowdfunding');
  const isFeatureSecondary = isFeatureEnabled('secondaryMarket');

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg">{t('OperationTypeQuestion')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="4" w="full">
            <Link
              to={
                opportunityId
                  ? generatePath(routes.company.newFundraising.private.fromOpportunity.full, {
                      companyId,
                      opportunityId,
                    })
                  : routes.company.newFundraising.private.href
              }
            >
              <Box data-cy="create-private-fundraising" layerStyle="container" pl="24px">
                <IconBoxWrapper mr="24px">
                  <Icon as={UsersIcon} boxSize="20px" color="gray.700" />
                </IconBoxWrapper>
                <Stack spacing="1">
                  <Text fontWeight="semibold">{t('PrivateFundraising')}</Text>
                  <UnorderedList color="gray.700" fontSize="sm" fontWeight="light" pl="1.5">
                    <ListItem>{t('KnowSubscribersAction')}</ListItem>
                    <ListItem>{t('TheirSubscriptionAmounts')}</ListItem>
                    <ListItem>{t('LessThan150Subscribers')}</ListItem>
                  </UnorderedList>
                </Stack>
              </Box>
            </Link>
            {hasCrowdfunding && isFeatureFundraisingCrowdfunding && (
              <Link to={routes.company.newFundraising.crowdfunding.href}>
                <Box data-cy="create-crowdfunding-fundraising" layerStyle="container" pl="24px">
                  <IconBoxWrapper mr="24px">
                    <Icon as={UserGroupIcon} boxSize="20px" color="gray.700" />
                  </IconBoxWrapper>
                  <Stack spacing="1">
                    <Text fontWeight="semibold">{t('CommunityFundraising')}</Text>
                    <UnorderedList color="gray.700" fontSize="sm" fontWeight="light" pl="1.5">
                      <ListItem>{t('MoreThan150Persons')}</ListItem>
                      <ListItem>{t('AmountsAndSubscribersUnknown')}</ListItem>
                    </UnorderedList>
                  </Stack>
                </Box>
              </Link>
            )}
            {hasSecondary && isFeatureSecondary && (
              <Link
                to={
                  opportunityId
                    ? generatePath(routes.company.newSecondary.fromOpportunity.full, {
                        companyId,
                        opportunityId,
                      })
                    : routes.company.newSecondary.href
                }
              >
                <HStack
                  data-cy="create-secondary-operation"
                  layerStyle="container"
                  pl="24px"
                  spacing="0"
                >
                  <IconBoxWrapper mr="24px">
                    <Icon as={TrendingUpIcon} boxSize="20px" color="gray.700" />
                  </IconBoxWrapper>
                  <Stack spacing="1">
                    <Text fontWeight="semibold">{t('SecondaryMarket')}</Text>
                    <Text color="gray.700" fontSize="sm" fontWeight="light">
                      {t('OfferLiquidityWindowsToShareholders')}
                    </Text>
                  </Stack>
                </HStack>
              </Link>
            )}
            {hasOpportunity && (
              <Link to={routes.company.newOpportunity.href}>
                <Box data-cy="create-opportunity" layerStyle="container" pl="24px">
                  <IconBoxWrapper mr="24px">
                    <Icon as={InboxInIcon} boxSize="20px" color="gray.700" />
                  </IconBoxWrapper>
                  <Stack spacing="1">
                    <Text fontWeight="semibold">{t('PurchaseOpportunity')}</Text>
                    <Text color="gray.700" fontSize="sm" fontWeight="light">
                      {t('PurchaseIntentCollection')}
                    </Text>
                  </Stack>
                </Box>
              </Link>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </ResponsiveModal>
  );
}
