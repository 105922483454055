import { Skeleton, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { IdentityType, noop } from '@blockpulse3/data/shared';
import {
  RepresentativeRole,
  useCreateDefaultShareholderRepresentativeMutation,
  useGetSpvQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, SwitchIdentityType } from '@blockpulse3/ui/commons';

import { LegalShareholderInformationsForm } from './LegalShareholderInformationsForm';
import { NaturalShareholderInformationsForm } from './NaturalShareholderInformationsForm';
import { getSPVShareholders } from './utils';

type Props = {
  /* ** Step submitted callback ** */
  onSubmit: () => void;
  /* ** Step cancelled callback ** */
  onCancel: () => void;
};

export function SPVShareholders({ onSubmit = noop, onCancel = noop }: Props): JSX.Element {
  const { companyId = '' } = useParams();

  const { data, loading, error, refetch } = useGetSpvQuery({ variables: { companyId } });
  const [createDefaultShareholder, { loading: createLoading }] =
    useCreateDefaultShareholderRepresentativeMutation();

  /* ** Look for representative with SHAREHOLDER role ** */
  const shareholder = data?.company?.companyRepresentative.find(
    (rep) => rep.role === RepresentativeRole.SHAREHOLDER,
  );
  const shareholderIdentity = shareholder?.representativeIdentity;
  const president = data?.company?.companyRepresentative.find(
    (rep) => rep.role === RepresentativeRole.PRESIDENT,
  );
  const presidentIdentity = president?.representativeIdentity;

  useEffect(() => {
    if ((shareholder && shareholderIdentity?.id === presidentIdentity?.id) || createLoading) return;
    createDefaultShareholder({
      variables: { companyId },
      onCompleted: () => {
        refetch();
      },
      onError: (err) => {
        const error: Error = err;
        console.log('Error creating default shareholder', error.stack || error.message);
      },
    });
  }, [shareholder, createLoading]);

  const isShareholderLegal = shareholderIdentity?.type === IdentityType.COMPANY;
  const presidentCompanyId = shareholderIdentity?.companyIdentity?.id || '';

  if (loading || createLoading || !shareholder) {
    return <Skeleton height="350px" width="full" />;
  }

  if (error || !data) {
    return <ErrorQueryCard width="full" />;
  }

  const defaultValues = getSPVShareholders(data.company);

  return (
    <Stack spacing="4">
      <SwitchIdentityType
        defaultValue={shareholderIdentity?.type}
        direction={{ base: 'column', md: 'row' }}
        disabled={true}
        onChange={noop}
      />
      {isShareholderLegal ? (
        <LegalShareholderInformationsForm
          defaultValues={defaultValues}
          isDisabled={true}
          targetCompanyId={presidentCompanyId}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      ) : (
        <NaturalShareholderInformationsForm
          defaultValues={defaultValues}
          isDisabled={true}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
    </Stack>
  );
}
