import { Skeleton, Stack, Text } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { OperationDocumentType, SubscriptionStepStatus } from '@blockpulse3/data/shared';
import {
  OperationStatus,
  SubscriptionStepType,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { Progress, ProgressStep } from '@blockpulse3/ui/commons';
import { useStepStatusMap } from '@blockpulse3/ui/ui-hooks';

import { ConsultIdentityVerification } from '../OperationPanel/OperationPanelProgressSteps/IdentityVerificationStep/ConsultIdentityVerification';
import { ResetDocumentSignature } from '../SubscriptionPanel/SubscriptionPanelProgressSteps/DocumentSignatureStep/ResetDocumentSignature';

/**
 * IntentPanelProgressSteps.
 * Progress step logic section of the intent side panel.
 *
 * @returns {JSX.Element}
 */
export function IntentPanelProgressSteps(): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();

  const { data, loading, error } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });
  const subscription = data?.subscription;

  const [stepStatusMap, steps] = useStepStatusMap(subscription?.data?.mergedSteps);

  const IdentityVerificationStep = useCallback(
    (): JSX.Element | null => <ConsultIdentityVerification subscriptionId={subscriptionId} />,
    [subscriptionId],
  );

  const isDocumentStepStarted =
    stepStatusMap[SubscriptionStepType.DOCUMENT_SIGNATURE] !== SubscriptionStepStatus.NONE;

  const DocumentSignatureStep = (): JSX.Element | null => {
    if (
      isDocumentStepStarted &&
      subscription &&
      ![OperationStatus.CLOSED, OperationStatus.REVIEWED, OperationStatus.FINALIZED].includes(
        subscription.operation.status,
      )
    ) {
      return (
        <ResetDocumentSignature
          operationDocumentType={OperationDocumentType.TERM_SHEET}
          subscriptionId={subscriptionId}
        />
      );
    }
    return null;
  };

  if (loading || error || !subscription?.operation) {
    return (
      <Stack px="4" spacing="4">
        <Text color="gray.600" fontWeight="600">
          {t('StepsToValidate')}
        </Text>
        <Stack spacing="2">
          <Skeleton h="30px" w="60%" />
          <Skeleton h="30px" w="80%" />
          <Skeleton h="30px" w="65%" />
          <Skeleton h="30px" w="70%" />
        </Stack>
      </Stack>
    );
  }

  const subscriptionStepTypes =
    subscription?.data.mergedSteps?.map((step) => {
      return step.type;
    }) || [];

  return (
    <Stack px="4" spacing="4">
      <Text color="gray.600" fontWeight="600">
        {t('StepsToValidate')}
      </Text>
      <Progress direction="column">
        <ProgressStep
          key={0}
          index={0}
          textProps={{ fontSize: 'sm', fontWeight: '500', color: 'gray.500' }}
          title={t('AccountCreation')}
          status={
            subscription?.buyerIdentity?.isSignedUp
              ? SubscriptionStepStatus.VALID
              : SubscriptionStepStatus.NONE
          }
        />
        <>
          {steps
            .filter((step) => subscriptionStepTypes.includes(step.type))
            .map((step) => (
              <ProgressStep
                key={step.index}
                index={step.index}
                status={step.status}
                textProps={{ fontSize: 'sm', fontWeight: '500', color: 'gray.500' }}
                title={step.title}
              >
                <>
                  {step.type === SubscriptionStepType.IDENTITY_VERIFICATION && (
                    <IdentityVerificationStep />
                  )}
                  {step.type === SubscriptionStepType.DOCUMENT_SIGNATURE && (
                    <DocumentSignatureStep />
                  )}
                </>
              </ProgressStep>
            ))}
        </>
      </Progress>
    </Stack>
  );
}
