import {
  Avatar,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  ScaleFade,
  Skeleton,
  Stack,
  Step,
  StepIndicator,
  StepSeparator,
  StepStatus,
  Stepper,
} from '@chakra-ui/react';
import { PaperAirplaneIcon } from '@heroicons/react/outline';
import { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useGetCommentsBySubscriptionIdQuery,
} from '@blockpulse3/graphql/hooks';
import { Comment, useErrorToast } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type Props = unknown;

/**
 * OperationPanelComments.
 * Comment logic section of the transaction side panel.
 *
 * @returns {JSX.Element}
 */
export function OperationPanelComments(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const { user } = useAuthUser();

  const { subscriptionId = '' } = useParams();

  const { data, loading, error, refetch } = useGetCommentsBySubscriptionIdQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const [createComment] = useCreateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();

  const [inputValue, setInputValue] = useState<string>();

  if (loading || !data || error) {
    return (
      <Stack spacing="4">
        <Skeleton h="30px" w="75%" />
        <Skeleton h="30px" w="65%" />
        <Skeleton h="30px" w="80%" />
        <Skeleton h="30px" w="70%" />
      </Stack>
    );
  }

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      handleCommentSubmit();
    }
  };

  const handleCommentSubmit = (): void => {
    if (!inputValue) {
      return;
    }

    createComment({
      variables: {
        createCommentInput: {
          subscriptionId,
          content: inputValue,
        },
      },
      onCompleted: () => {
        setInputValue('');
        refetch();
      },
      onError: () => {
        errorToast({ title: t('CommentCreationError') });
      },
    });
  };

  const handleCommentDelete = (commentId: string): void => {
    deleteComment({
      variables: { commentId },
      onCompleted: () => {
        refetch();
      },
      onError: () => {
        errorToast({ title: t('CommentDeletionError') });
      },
    });
  };

  const comments = data.comments;

  return (
    <Stack spacing="2">
      <Stepper gap="0" index={-1} orientation="vertical" w="100%">
        {comments.map((comment) => {
          const isAuthor = user?.id === comment.user.id;
          return (
            <ScaleFade key={comment.id} in initialScale={0.7}>
              <Step style={{ width: '100%' }}>
                <StepIndicator>
                  <StepStatus incomplete={<Avatar boxSize="8" />} />
                </StepIndicator>
                <Comment
                  date={comment.createdAt}
                  isDeletable={isAuthor}
                  title={comment.user.firstName + ' ' + comment.user.lastName}
                  onDelete={(): void => handleCommentDelete(comment.id)}
                >
                  {comment.content}
                </Comment>
                <StepSeparator />
              </Step>
            </ScaleFade>
          );
        })}
      </Stepper>
      <InputGroup size="md">
        <Input
          placeholder={t('AddComment')}
          pr="10"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
        />
        <InputRightElement>
          <IconButton
            aria-label="add-comment"
            icon={<Icon as={PaperAirplaneIcon} boxSize="5" color="gray.500" />}
            size="sm"
            transform="rotate(90deg)"
            variant="ghost"
            onClick={handleCommentSubmit}
          />
        </InputRightElement>
      </InputGroup>
    </Stack>
  );
}

export type OperationPanelCommentsProps = Props;
