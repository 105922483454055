import {
  BoxProps,
  Card,
  CardBody,
  CardHeader,
  Divider,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { CreditCardIcon } from '@heroicons/react/solid';
import { useTranslations } from 'use-intl';

import { formatIBAN } from '@blockpulse3/helpers';

import { ContentCopy } from './ContentCopy';

type Props = {
  /* ** Title of the card ** */
  title: string;
  /* ** SWIFT key ** */
  swift?: string;
  /* ** IBAN address ** */
  iban?: string;
  /* ** Optional transaction reference ** */
  referenceLabel?: string | null;
  /* ** Name of the recipient ** */
  recipientName?: string;
  /* ** Address of the recipient ** */
  recipientAddress?: string;
  /* ** Name of the bank ** */
  bankName?: string;
} & BoxProps;

/**
 * BankDetailsCard.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function BankDetailsCard({
  title,
  swift,
  iban,
  referenceLabel,
  recipientName,
  recipientAddress,
  bankName,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  return (
    <Card bg="gray.50" boxShadow="none" {...props}>
      <CardHeader as={HStack}>
        <Icon alignSelf="center" as={CreditCardIcon} boxSize="24px" color="gray.900" />
        <Text fontSize="md" fontWeight="600">
          {title}
        </Text>
      </CardHeader>
      <CardBody as={Stack} divider={<Divider />} spacing="2">
        {swift && (
          <Stack>
            <Stack direction="row" justify="space-between" py="2" spacing="0">
              <Stack direction="column" spacing="0">
                <Text color="gray.900" fontSize="sm" fontWeight="500">
                  {t('SWIFTBIC')}
                </Text>
                <Text
                  backgroundColor="indigo.100"
                  borderRadius="3"
                  color="indigo.600"
                  fontSize="sm"
                  fontWeight="600"
                  px="1"
                  py="0.5"
                  width="fit-content"
                >
                  {swift}
                </Text>
              </Stack>
              <Stack justify="center" spacing="0">
                <ContentCopy textToCopy={swift} />
              </Stack>
            </Stack>
          </Stack>
        )}
        {iban && (
          <Stack>
            <Stack direction="row" justify="space-between" py="2" spacing="0">
              <Stack direction="column" spacing="0">
                <Text color="gray.900" fontSize="sm" fontWeight="500">
                  {t('IBAN')}
                </Text>
                <Text
                  backgroundColor="indigo.100"
                  borderRadius="3"
                  color="indigo.600"
                  fontSize="sm"
                  fontWeight="600"
                  px="1"
                  py="0.5"
                  width="fit-content"
                >
                  {formatIBAN(iban)}
                </Text>
              </Stack>
              <Stack justify="center" spacing="0">
                <ContentCopy textToCopy={formatIBAN(iban)} />
              </Stack>
            </Stack>
          </Stack>
        )}
        {recipientName && (
          <Stack>
            <Stack direction="row" justify="space-between" py="2" spacing="0">
              <Stack direction="column" spacing="0">
                <Text color="gray.900" fontSize="sm" fontWeight="500">
                  {t('RecipientName')}
                </Text>
                <Text
                  backgroundColor="indigo.100"
                  borderRadius="3"
                  color="indigo.600"
                  fontSize="sm"
                  fontWeight="600"
                  px="1"
                  py="0.5"
                  width="fit-content"
                >
                  {recipientName}
                </Text>
              </Stack>
              <Stack justify="center" spacing="0">
                <ContentCopy textToCopy={recipientName} />
              </Stack>
            </Stack>
          </Stack>
        )}
        {referenceLabel && (
          <Stack>
            <Stack direction="row" justify="space-between" py="2" spacing="0">
              <Stack direction="column" spacing="0">
                <Text color="gray.900" fontSize="sm" fontWeight="500">
                  {t('TransferReferenceForTransfer')}
                </Text>
                <Text
                  backgroundColor="indigo.100"
                  borderRadius="3"
                  color="indigo.600"
                  fontSize="sm"
                  fontWeight="600"
                  px="1"
                  py="0.5"
                  width="fit-content"
                >
                  {referenceLabel}
                </Text>
              </Stack>
              <Stack justify="center" spacing="0">
                <ContentCopy textToCopy={referenceLabel} />
              </Stack>
            </Stack>
          </Stack>
        )}
        {recipientAddress && (
          <Stack>
            <Stack direction="row" justify="space-between" py="2" spacing="0">
              <Stack direction="column" spacing="0">
                <Text color="gray.900" fontSize="sm" fontWeight="500">
                  {t('RecipientAddress')}
                </Text>
                <Text
                  backgroundColor="indigo.100"
                  borderRadius="3"
                  color="indigo.600"
                  fontSize="sm"
                  fontWeight="600"
                  px="1"
                  py="0.5"
                  width="fit-content"
                >
                  {recipientAddress}
                </Text>
              </Stack>
              <Stack justify="center" spacing="0">
                <ContentCopy textToCopy={recipientAddress} />
              </Stack>
            </Stack>
          </Stack>
        )}
        {bankName && (
          <Stack>
            <Stack direction="row" justify="space-between" py="2" spacing="0">
              <Stack direction="column" spacing="0">
                <Text color="gray.900" fontSize="sm" fontWeight="500">
                  {t('BankName')}
                </Text>
                <Text
                  backgroundColor="indigo.100"
                  borderRadius="3"
                  color="indigo.600"
                  fontSize="sm"
                  fontWeight="600"
                  px="1"
                  py="0.5"
                  width="fit-content"
                >
                  {bankName}
                </Text>
              </Stack>
              <Stack justify="center" spacing="0">
                <ContentCopy textToCopy={bankName} />
              </Stack>
            </Stack>
          </Stack>
        )}
      </CardBody>
    </Card>
  );
}

export type BankDetailsCardProps = Props;
