import { Button, Icon, Stack, Text } from '@chakra-ui/react';
import { PlusIcon, TemplateIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

type Props = unknown;

export function OptionSummarySubscribersTableEmpty(): JSX.Element {
  const t = useTranslations();


  return (
    <Stack layerStyle="emptyState">
      <Icon as={TemplateIcon} boxSize="40px" color="gray.500" />
      <Text>{t('NoAttributionYet')}</Text>
      <Button isDisabled leftIcon={<Icon as={PlusIcon} boxSize="18px" />}>
        {t('Assign')}
      </Button>
    </Stack>
  );
}

export type OptionSummarySubscribersTableEmptyProps = Props;
