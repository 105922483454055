import {
  Button,
  Divider,
  HStack,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  Stack,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import { OfficeBuildingIcon, UserIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import {
  IdentityType,
  useCreateCompanyAuthorizedBuyerMutation,
  useCreateIndividualAuthorizedBuyerMutation,
} from '@blockpulse3/graphql/hooks';
import {
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';

import {
  INewSecondaryOperationLegalBuyer,
  NewSecondaryOperationLegalBuyerForm,
} from './NewSecondaryOperationLegalBuyerForm';
import {
  INewSecondaryOperationNaturalBuyer,
  NewSecondaryOperationNaturalBuyerForm,
} from './NewSecondaryOperationNaturalBuyerForm';

type Props = {
  /* ** Callback executed on both submit complete ** */
  onSubmitComplete?: () => void;
  /* ** Callback executed on both submit error ** */
  onSubmitError?: () => void;
  /* ** Boolean for whether the form should ask for invest amount or not ** */
  amountRequired?: boolean;
} & Omit<ResponsiveModalProps, 'children'>;

/**
 * NewSecondaryOperationBuyerModal.
 * Modal combining the two versions of the buyer creation forms.
 * Use a dynamic value on the `form=` props to switch between submit callbacks.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function NewSecondaryOperationBuyerModal({
  onSubmitComplete = noop,
  onSubmitError = noop,
  amountRequired = false,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const { value, getRadioProps, getRootProps } = useRadioGroup({
    name: 'identityType',
    defaultValue: IdentityType.INDIVIDUAL,
  });

  const [createCompanyAuthorizedBuyer] = useCreateCompanyAuthorizedBuyerMutation();
  const [createIndividualAuthorizedBuyer] = useCreateIndividualAuthorizedBuyerMutation();

  const handleCreateCompanyAuthorizedBuyer = (data: INewSecondaryOperationLegalBuyer): void => {
    createCompanyAuthorizedBuyer({
      variables: {
        createCompanyAuthorizedBuyerInput: {
          operationId,
          name: data.name,
          registrationNumber: data.identificationNumber,
          representativeEmail: data.email,
          investAmount: data.investAmount || undefined,
        },
      },
      onCompleted: () => {
        onSubmitComplete();
      },
      onError: () => {
        onSubmitError();
      },
    });
  };

  const handleCreateIndividualAuthorizedBuyer = (
    data: INewSecondaryOperationNaturalBuyer,
  ): void => {
    createIndividualAuthorizedBuyer({
      variables: {
        createIndividualAuthorizedBuyerInput: {
          operationId,
          investAmount: data.investAmount || undefined,
          ...data,
        },
      },
      onCompleted: () => {
        onSubmitComplete();
      },
      onError: () => {
        onSubmitError();
      },
    });
  };

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('AddBuyer')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="4">
            <Stack
              direction={{ base: 'column', md: 'row' }}
              spacing="4"
              w="full"
              {...getRootProps()}
            >
              <Radio variant="identity" {...getRadioProps({ value: IdentityType.COMPANY })}>
                <HStack alignItems="center" spacing="3">
                  <Icon as={OfficeBuildingIcon} boxSize="24px" />
                  <Text>{t('ItIsACompany')}</Text>
                </HStack>
              </Radio>
              <Radio variant="identity" {...getRadioProps({ value: IdentityType.INDIVIDUAL })}>
                <HStack alignItems="center" spacing="3">
                  <Icon as={UserIcon} boxSize="24px" />
                  <Text>{t('IndividualPersonType')}</Text>
                </HStack>
              </Radio>
            </Stack>
            {value === IdentityType.INDIVIDUAL ? (
              <NewSecondaryOperationNaturalBuyerForm
                amountRequired={amountRequired}
                onSubmit={handleCreateIndividualAuthorizedBuyer}
              />
            ) : (
              <NewSecondaryOperationLegalBuyerForm
                amountRequired={amountRequired}
                onSubmit={handleCreateCompanyAuthorizedBuyer}
              />
            )}
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button form={value.toString()} type="submit">
            {t('AddTheBuyer')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type NewSecondaryOperationBuyerModalProps = Props;
