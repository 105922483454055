export enum CsvCellErrorCode {
  REQUIRED = 'REQUIRED',
  INVALID_DATE = 'INVALID_DATE',
  IDENTITY_NOT_FOUND = 'IDENTITY_NOT_FOUND',
  REPRESENTATIVE_NOT_FOUND = 'REPRESENTATIVE_NOT_FOUND',
  ASSET_NOT_FOUND = 'ASSET_NOT_FOUND',
  INVALID_HOLDING_METHOD = 'INVALID_HOLDING_METHOD',
}

export type CsvCellError = {
  row: number;
  column: number;
  columnKey: string;
  code: CsvCellErrorCode;
};
