import { useDisclosure } from '@chakra-ui/react';
import { IdentificationIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslations } from 'use-intl';

import { HoldingMethod, SubscriptionStepStatus } from '@blockpulse3/data/shared';
import { MergedSubscriptionDetailsStep, useGetSubscriptionQuery } from '@blockpulse3/graphql/hooks';
import { IdentityVerificationModal, useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionStep } from '../../SubscriptionStep';

type Props = {
  subscriptionStep: MergedSubscriptionDetailsStep;
  isDisabled: boolean;
};

let timeoutId: NodeJS.Timeout | null = null;

export function SwanOnboardingStep({ subscriptionStep, isDisabled }: Props): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();

  const [isPollingStarted, setIsPollingStarted] = useState<boolean>(false);
  const [isPollingLoading, setIsPollingLoading] = useState<boolean>(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { identityId } = useIdentity();

  const { data, refetch, startPolling, stopPolling } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const stepStatus = subscriptionStep.status;
  const subscription = data?.subscription;

  useEffect(() => {
    if (!isPollingStarted && stepStatus === SubscriptionStepStatus.PENDING) {
      setIsPollingStarted(true);
      setIsPollingLoading(true);
      startPolling(2000);

      if (timeoutId) clearTimeout(timeoutId);

      /* ** Stop polling after 2min ** */
      timeoutId = setTimeout(() => {
        stopPolling();
        setIsPollingLoading(false);
      }, 120_000);
    }

    /* ** Stop polling when step status is VALID ** */
    if (isPollingStarted && stepStatus !== SubscriptionStepStatus.PENDING) {
      stopPolling();
      setIsPollingStarted(false);
      setIsPollingLoading(false);
      refetch();
    }
  }, [isPollingStarted, stepStatus, startPolling, stopPolling, refetch]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const handleModalOpen = (): void => {
    if (![SubscriptionStepStatus.VALID, SubscriptionStepStatus.PENDING].includes(stepStatus)) {
      onOpen();
    }
  };

  const isIdentityOnboarding =
    subscription?.operation?.usePivotFiscalAccount &&
    [HoldingMethod.PEA, HoldingMethod.PEA_PME].includes(subscription.holdingMethod) &&
    identityId === subscription?.sellerIdentity?.id;

  return (
    <>
      <SubscriptionStep
        icon={IdentificationIcon}
        isBadgeLoading={isPollingLoading}
        isDisabled={isDisabled}
        status={stepStatus}
        title={t('IdentityVerification')}
        type={subscriptionStep.type}
        onClick={handleModalOpen}
      />
      {isOpen && (
        <IdentityVerificationModal
          isOpen={isOpen}
          isSwanOnboarding={!isIdentityOnboarding}
          refetchData={refetch}
          onClose={onClose}
        />
      )}
    </>
  );
}
