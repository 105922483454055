import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useValidateSpaceInvitationMutation } from '@blockpulse3/graphql/hooks';
import { LoadingPage, useErrorToast } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type Props = unknown;

/**
 * SpaceInvitationConnected.
 * Accept invitation for connected user and add the user to the space.
 *
 * @returns {JSX.Element}
 */
export function SpaceInvitationConnected(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const [searchParams] = useSearchParams();
  const { user, refetch } = useAuthUser();
  const navigate = useNavigate();

  const [validateSpaceInvitation] = useValidateSpaceInvitationMutation();

  const spaceToken = searchParams.get('spaceToken') || '';

  useEffect(() => {
    if (!user) return;

    validateSpaceInvitation({
      variables: {
        validateSpaceInvitationInput: { token: spaceToken },
      },
      onCompleted: () => {
        refetch();
        navigate(routes.me.href);
      },
      onError: () => {
        errorToast({ title: t('ValidateSpaceInvitationError') });
        refetch();
        navigate(routes.me.href);
      },
    });
  }, [user]);

  return <LoadingPage />;
}

export type SpaceInvitationConnectedProps = Props;
