import { OperationInfosFragment } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';

import { ISecondaryOperationInformationsForm } from './types';

export function getOperationParametersDefaults(
  operation: Partial<OperationInfosFragment>,
): ISecondaryOperationInformationsForm {
  return {
    name: operation.name || '',
    startSubscriptionDate: formatDate(operation.startSubscriptionDate, 'YYYY-MM-DD' || ''),
    endSubscriptionDate: formatDate(operation.endSubscriptionDate, 'YYYY-MM-DD' || ''),
    asset: { label: operation.asset?.name || '', value: operation.asset?.id || '' },
    pricePerShare: operation.pricePerShare || 0,
    isPromiseToSell: operation.isPromiseToSell || false,
    usePivotFiscalAccount: operation.usePivotFiscalAccount || false,
  };
}
