import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { ManagementType } from '@blockpulse3/graphql/hooks';

import { SpaceHeader } from './SpaceHeader';
import { SpaceManagements } from './SpaceManagements';
import { SpaceMembers } from './SpaceMembers';

type Props = unknown;

/**
 * SpaceAdminView.
 * Space admin view with member table.
 *
 * @returns {JSX.Element}
 */
export function SpaceAdminView(): JSX.Element {
  const t = useTranslations();
  return (
    <Stack spacing="6">
      <SpaceHeader />
      <Tabs isLazy defaultIndex={0}>
        <TabList>
          <Tab>{t('Administrator', { nb: 2 })}</Tab>
          <Tab>{t('ControlledCompanies')}</Tab>
          <Tab>{t('ManagementIdentities')}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel as={Stack} gap="6">
            <SpaceMembers />
          </TabPanel>
          <TabPanel as={Stack} gap="6">
            <SpaceManagements type={ManagementType.OWNER} />
          </TabPanel>
          <TabPanel as={Stack} gap="6">
            <SpaceManagements type={ManagementType.MANAGER} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

export type SpaceAdminViewProps = Props;
