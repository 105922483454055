import {
  Badge,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/outline';
import { useEffect } from 'react';
import { generatePath, resolvePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  SpvStatus,
  useGetCompaniesQuery,
  useGetPartialCompaniesQuery,
} from '@blockpulse3/graphql/hooks';
import {
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';
import { useBadge } from '@blockpulse3/ui/ui-hooks';
import { useManagedIndividual } from '@blockpulse3/web-client/auth';

import { SwitcherItem } from './SwitcherItem';
import { useWorkspaceSwitcher } from './provider';

type Props = Omit<ResponsiveModalProps, 'children' | 'isOpen' | 'onClose'>;

/**
 * SwitcherModal.
 * Select another workspace or create a new one.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SwitcherModal({ ...props }: Props): JSX.Element | null {
  const t = useTranslations();

  const navigate = useNavigate();
  const isManaging = useMatch(routes.manage.href + '/*');

  /* ** Current workspace ** */
  const { individual } = useManagedIndividual();
  const { companyId = '' } = useParams();

  /* ** Get items ** */
  const companiesReq = useGetCompaniesQuery({
    variables: {
      individualIdentityId: individual?.id,
    },
    skip: !individual?.id,
  });
  const companies = companiesReq.data?.companies
    ? [...companiesReq.data.companies].sort((a, b) => a.name.localeCompare(b.name))
    : [];

  const partialsReq = useGetPartialCompaniesQuery({
    variables: {
      individualIdentityId: individual?.id,
    },
    skip: !individual?.id,
  });
  const partials = partialsReq.data?.partials
    ? [...partialsReq.data.partials].sort((a, b) => a.name.localeCompare(b.name))
    : [];

  /* ** Get workspace switcher display status and method ** */
  const { isSwitcherOpen, onSwitcherClose } = useWorkspaceSwitcher();

  /* ** Dynamic badge getter ** */
  const { getBadge } = useBadge(
    null,
    [
      {
        value: SpvStatus.VALIDATED,
        color: 'yellow',
        label: t('InCreation'),
      },
      {
        value: SpvStatus.SIGNED,
        color: 'yellow',
        label: t('InCreation'),
      },
      {
        value: SpvStatus.VERIFIED,
        color: 'yellow',
        label: t('InCreation'),
      },
      {
        value: SpvStatus.CREATING,
        color: 'blue',
        label: t('RegistrationInProgress'),
      },
    ],
    { color: 'gray', label: t('Draft', { nb: 1 }) },
  );

  const handlePartialClick = (id: string): void => {
    const partial = partials.find((d) => d.id === id);
    if (!partial) return;

    const companyId = partial?.id || '';
    const relPath = generatePath(routes.manage.company.href, { companyId });
    navigate(
      isManaging
        ? resolvePath(
            relPath,
            generatePath(routes.manage.individual.full, { individualIdentityId: individual?.id }),
          ).pathname
        : relPath,
    );
    onSwitcherClose();
  };

  /* ** Redirect to clicked workspace dashboard ** */
  const handleWorkspaceClick = (id: string): void => {
    const selectedCompany = companies.find((w) => w.id === id);
    if (selectedCompany) {
      const relPath = generatePath(routes.manage.company.href, { companyId: selectedCompany.id });
      navigate(
        isManaging
          ? resolvePath(
              relPath,
              generatePath(routes.manage.individual.full, { individualIdentityId: individual?.id }),
            ).pathname
          : relPath,
      );
    }
    onSwitcherClose();
  };

  /* ** Callback on user workspace click ** */
  const handleUserWorkspaceClick = (): void => {
    navigate(
      isManaging && individual?.id
        ? generatePath(routes.manage.individual.full, { individualIdentityId: individual?.id })
        : routes.me.href,
    );
    onSwitcherClose();
  };

  const handleAddExistingCompany = (): void => {
    navigate(routes.onboardings.company.href);
    onSwitcherClose();
  };

  const handleAddSPV = (): void => {
    navigate(routes.onboardings.spv.href);
    onSwitcherClose();
  };

  useEffect(() => {
    if (isSwitcherOpen) {
      companiesReq.refetch();
      partialsReq.refetch();
    }
  }, [isSwitcherOpen]);

  if (!isSwitcherOpen) return null;

  return (
    <ResponsiveModal
      isCentered
      isOpen={isSwitcherOpen}
      size="xl"
      onClose={onSwitcherClose}
      {...props}
    >
      <ModalOverlay />
      <ModalContent data-cy="switcher-modal">
        <ModalHeader>{t('SwitchWorkspace')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody maxH="500" p="0">
          <Stack spacing="0" w="full">
            {individual && (
              <SwitcherItem
                key={individual?.id}
                isActive={!companyId}
                isUser={true}
                name={`${individual?.firstName} ${individual?.lastName}`}
                profilePicture={individual?.identity?.profilePicture}
                workspaceId={individual?.id}
                onClick={handleUserWorkspaceClick}
              >
                <Text fontSize="sm" fontWeight="light" variant="ellipsis">
                  {t('PersonalSpace')}
                </Text>
              </SwitcherItem>
            )}
            {companies.map((curCompany) => (
              <SwitcherItem
                key={curCompany.id}
                isActive={curCompany.id === companyId}
                isUser={false}
                name={curCompany.name}
                profilePicture={curCompany.identity?.profilePicture}
                workspaceId={curCompany.id}
                onClick={handleWorkspaceClick}
              >
                <Text fontSize="sm" fontWeight="light" variant="ellipsis">
                  {curCompany.registrationNumber}
                </Text>
              </SwitcherItem>
            ))}
            {partials.map((partial) => {
              /* ** Draft based badge if a company_draft is associated to the partial company ** */
              const status = partial?.spvStatus || SpvStatus.EDITING;
              const badge = getBadge(status);
              return (
                <SwitcherItem
                  key={partial.id}
                  isActive={partial.id === companyId}
                  isUser={false}
                  profilePicture={partial.identity?.profilePicture}
                  workspaceId={partial.id}
                  name={
                    partial.name ||
                    t('NewCompanyID', {
                      id: partial.id.toString(),
                    })
                  }
                  onClick={handlePartialClick}
                >
                  <Badge alignSelf="flex-start" colorScheme={badge.color}>
                    {badge.label}
                  </Badge>
                </SwitcherItem>
              );
            })}
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter p="4">
          <Stack direction={['column', 'row']} spacing="5" w="full">
            <Button
              data-cy="add-existing-company"
              variant="container"
              onClick={handleAddExistingCompany}
            >
              <HStack alignItems="flex-start">
                <Flex
                  alignItems="center"
                  bg="gray.100"
                  boxSize="32px"
                  flexShrink="0"
                  justifyContent="center"
                  mr="2"
                  rounded="sm"
                >
                  <Icon as={PlusIcon} boxSize="18px" />
                </Flex>
                <Text>{t('ExistingCompanyImportAction')}</Text>
              </HStack>
            </Button>
            <Button variant="container" onClick={handleAddSPV}>
              <HStack alignItems="flex-start" data-cy="create-spv-company">
                <Flex
                  alignItems="center"
                  bg="gray.100"
                  boxSize="32px"
                  flexShrink="0"
                  justifyContent="center"
                  mr="2"
                  rounded="sm"
                >
                  <Icon as={PlusIcon} boxSize="18px" />
                </Flex>
                <Text>{t('CreateSPVLong')}</Text>
              </HStack>
            </Button>
          </Stack>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type SwitcherModalProps = Props;
