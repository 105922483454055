import { Route, Routes } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import {
  OnboardingExistingCompanyAccess,
  OnboardingExistingCompanyCreate,
  OnboardingExistingCompanyLayout,
  OnboardingExistingCompanyParameters,
  OnboardingExistingCompanyRepresentatives,
  OnboardingExistingCompanySummary,
  OnboardingExistingCompanyUbos,
} from '@blockpulse3/web-client/onboardings';

import { EmptyLayout } from '../layouts';

export function OnboardingCompanyRoutes(): JSX.Element {
  return (
    <Routes>
      <Route element={<EmptyLayout />}>
        <Route element={<OnboardingExistingCompanyLayout />}>
          <Route path={routes.onboardings.company.edit.href + '/*'}>
            <Route
              element={<OnboardingExistingCompanyRepresentatives />}
              path={routes.onboardings.company.edit.representatives.href}
            />
            <Route
              element={<OnboardingExistingCompanyUbos />}
              path={routes.onboardings.company.edit.ubos.href}
            />
            <Route
              element={<OnboardingExistingCompanyAccess />}
              path={routes.onboardings.company.edit.access.href}
            />
            <Route
              element={<OnboardingExistingCompanySummary />}
              path={routes.onboardings.company.edit.summary.href}
            />
            <Route element={<OnboardingExistingCompanyParameters />} path="*" />
          </Route>
          <Route element={<OnboardingExistingCompanyCreate />} path="*" />
        </Route>
      </Route>
    </Routes>
  );
}
