import { Icon, Stack, Text } from '@chakra-ui/react';
import { TemplateIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

/**
 * WalletEmptyTable
 * Wallet card component. Display empty state for now.
 *
 * @returns {JSX.Element}
 */
export function WalletEmptyTable(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack layerStyle="emptyState">
      <Icon as={TemplateIcon} boxSize="40px" color="gray.500" />
      <Stack alignItems="center" spacing="0">
        <Text>{t('NoSharesHeldYet')}</Text>
        <Text>{t('AssetsWillAppearHere')}</Text>
      </Stack>
    </Stack>
  );
}
