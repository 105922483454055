import { useTranslations } from 'use-intl';

import { OperationDocumentType } from '@blockpulse3/data/shared';
import {
  Maybe,
  MergedSubscriptionStepUnion,
  SubscriptionSide,
  SubscriptionStepStatus,
  SubscriptionStepType,
} from '@blockpulse3/graphql/hooks';

type SubscriptionStep = {
  index: number;
  status: SubscriptionStepStatus | undefined;
  title: string;
  type: SubscriptionStepType;
  side?: Maybe<SubscriptionSide> | undefined;
};

type SignatureStepTitlesType =
  | OperationDocumentType.SUBSCRIPTION
  | OperationDocumentType.SUBSCRIPTION_BOND
  | OperationDocumentType.ODM
  | OperationDocumentType.TRANSFER_DECLARATION
  | OperationDocumentType.TERM_SHEET;

type StepTitlesType = Exclude<SubscriptionStepType, SubscriptionStepType.DOCUMENT_SIGNATURE>;

export function useStepStatusMap(
  mergedSteps: MergedSubscriptionStepUnion[] | null | undefined,
): [Record<SubscriptionStepType, SubscriptionStepStatus | undefined>, SubscriptionStep[]] {
  const t = useTranslations();

  if (!mergedSteps) {
    return [{} as Record<SubscriptionStepType, SubscriptionStepStatus | undefined>, []];
  }

  const stepStatusMap = mergedSteps.reduce(
    (acc, step) => {
      if ('type' in step) {
        acc[step.type] = step.status;
      }
      return acc;
    },
    {} as Record<SubscriptionStepType, SubscriptionStepStatus | undefined>,
  );

  const stepTitles: Partial<
    Record<StepTitlesType | `${SubscriptionStepType}_${SubscriptionSide}`, string>
  > = {
    [SubscriptionStepType.MIFID]: t('ProfileInvestor'),
    [SubscriptionStepType.IDENTITY_VERIFICATION]: t('IdentityVerification'),
    [SubscriptionStepType.IDENTITY_CHOICE]: t('ChooseIdentity'),
    [SubscriptionStepType.HOLDING_METHOD]: t('ChooseHoldingMethod'),
    [SubscriptionStepType.DETAILS]: t('ChooseAmountAndHoldingMethod'),
    [SubscriptionStepType.PAYMENT]: t('FundsReceived'),
    [`${SubscriptionStepType.PAYMENT}_${SubscriptionSide.BUYER}`]: t('FundsReceived'),
    [SubscriptionStepType.SALE]: t('TermsOfSale'),
    [`${SubscriptionStepType.SALE}_${SubscriptionSide.SELLER}`]: t('TermsOfSale'),
    [SubscriptionStepType.SWAN_ONBOARDING]: t('IdentityVerification'),
    [`${SubscriptionStepType.SWAN_ONBOARDING}_${SubscriptionSide.BUYER}`]: t(
      'IdentityVerificationOfBuyer',
    ),
    [`${SubscriptionStepType.SWAN_ONBOARDING}_${SubscriptionSide.SELLER}`]: t(
      'IdentityVerificationOfSeller',
    ),
    [SubscriptionStepType.PURCHASE_INTENT]: t('PurchaseIntentInput'),
  };

  const signatureStepTitles: Record<SignatureStepTitlesType, string> = {
    [OperationDocumentType.SUBSCRIPTION]: t('SubscriptionBulletinSignature'),
    [OperationDocumentType.SUBSCRIPTION_BOND]: t('SubscriptionBulletinSignature'),
    [OperationDocumentType.ODM]: t('SubscriptionODMSignature'),
    [OperationDocumentType.TRANSFER_DECLARATION]: t('SubscriptionTransferDeclarationSignature'),
    [OperationDocumentType.TERM_SHEET]: t('SubscriptionTermSheetSignature'),
  };

  const steps = mergedSteps
    .filter(({ type }) => type !== SubscriptionStepType.HOLDING_METHOD)
    .map(({ status, type, side, ...step }, index) => ({
      index,
      status,
      title:
        step.__typename === 'MergedDocumentSignatureStep'
          ? signatureStepTitles[step.documentType as SignatureStepTitlesType]
          : (side ? stepTitles[`${type}_${side}`] : stepTitles[type as StepTitlesType]) || '',
      type,
      side,
    }));

  return [stepStatusMap, steps];
}

export function useStepStatusMapLazy(): (
  mergedSteps: MergedSubscriptionStepUnion[] | null | undefined,
) => [Record<SubscriptionStepType, SubscriptionStepStatus | undefined>, SubscriptionStep[]] {
  return useStepStatusMap;
}
