import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetStockAssetsQuery } from '@blockpulse3/graphql/hooks';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { AssetCreateModal } from '../AssetCreateModal';
import { AssetTable } from './AssetTable';

type Props = unknown;

/**
 * CapTableView.
 * Main captable view, rendering specific captable informations and the list of shareholders.
 *
 * @returns {JSX.Element}
 */
export function AssetList(): JSX.Element {
  const t = useTranslations();

  const { user } = useAuthUser();

  const { companyId = '' } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { refetch } = useGetStockAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId,
  });

  const handleOpenModal = (): void => {
    onOpen();
  };

  const handleCreateAssetComplete = (): void => {
    refetch();
  };

  const isSuperAdmin = user?.isSuperAdmin;

  return (
    <>
      <Card>
        <CardHeader
          alignItems={{ base: 'flex-start', md: 'center' }}
          as={Stack}
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
        >
          <Heading size="md">{t('MyAssets')}</Heading>
          {isSuperAdmin && (
            <Button
              leftIcon={<Icon as={PlusIcon} boxSize="18px" />}
              variant="secondary"
              onClick={handleOpenModal}
            >
              {t('AddAsset')}
            </Button>
          )}
        </CardHeader>
        <CardBody>
          <AssetTable />
        </CardBody>
      </Card>
      <AssetCreateModal isOpen={isOpen} onClose={onClose} onComplete={handleCreateAssetComplete} />
    </>
  );
}

export type AssetListProps = Props;
