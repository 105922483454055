import { ApolloError } from '@apollo/client';
import { useEffect } from 'react';
import { useMatch, useParams } from 'react-router-dom';

import { routes } from '@blockpulse3/data/shared';
import {
  GetAllOperationsQuery,
  GetOperationsQuery,
  OperationStatus,
  useGetAllOperationsLazyQuery,
  useGetOperationsLazyQuery,
} from '@blockpulse3/graphql/hooks';

type OperationsQuery = {
  operations: GetAllOperationsQuery['operations'] | GetOperationsQuery['operations'];
  operationsLoading: boolean;
  operationsError: ApolloError | undefined;
  operationsRefetch: () => void;
};

export function useGetOperations(operationStatus: OperationStatus): OperationsQuery {
  const { companyId = '' } = useParams();

  const isSpace = useMatch(routes.space.href + '/*');

  const [
    getAllOperations,
    {
      data: allOperations,
      loading: allOperationsLoading,
      error: allOperationsError,
      refetch: allOperationsRefetch,
    },
  ] = useGetAllOperationsLazyQuery({ fetchPolicy: 'cache-and-network' });

  const [getOperations, { data, loading, error, refetch }] = useGetOperationsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (isSpace) {
      getAllOperations({
        variables: {
          status: operationStatus,
        },
      });
    } else if (companyId) {
      getOperations({
        variables: {
          companyId,
          status: operationStatus,
        },
      });
    }
  }, [getAllOperations, getOperations, isSpace, companyId, operationStatus]);

  const operations = allOperations?.operations || data?.operations || [];
  const operationsLoading = loading || allOperationsLoading;
  const operationsError = error || allOperationsError;
  const operationsRefetch = refetch || allOperationsRefetch;

  return { operations, operationsLoading, operationsError, operationsRefetch };
}
