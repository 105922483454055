import { Button } from '@chakra-ui/react';
import { generatePath, resolvePath, useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Exceptions, routes } from '@blockpulse3/data/shared';
import {
  CompanyCapitalType,
  CompanyStatus,
  CompanyType,
  useCreateCompanyMutation,
  useGetPappersExtractInpiDocumentLazyQuery,
  useGetPappersRegisterExtractDocumentLazyQuery,
  useGetPappersStatutsDocumentLazyQuery,
} from '@blockpulse3/graphql/hooks';
import { useErrorToast } from '@blockpulse3/ui/commons';

import { StepFormContextProvider } from '../../provider';
import { OnboardingStep } from '../OnboardingStep';
import { ExistingCompanyInformations } from '../forms';
import { CompanyInformationsForm } from '../forms/ExistingCompanyInformations/types';

/**
 * OnboardingExistingCompanyCreate.
 * Existing company informations, create step.
 *
 * @returns {JSX.Element}
 */
export function OnboardingExistingCompanyCreate(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const navigate = useNavigate();

  const [createCompany] = useCreateCompanyMutation();

  const [getPappersExtractInpiDocument] = useGetPappersExtractInpiDocumentLazyQuery();
  const [getPappersStatutsDocument] = useGetPappersStatutsDocumentLazyQuery();
  const [getPappersRegisterExtractDocument] = useGetPappersRegisterExtractDocumentLazyQuery();

  const handleCreateExistingCompany = (data: CompanyInformationsForm): void => {
    const { address, country, capitalType, ...rest } = data;

    createCompany({
      variables: {
        createCompanyInput: {
          status: CompanyStatus.DRAFT,
          type: CompanyType.COMPANY,
          address: {
            line: address.line,
            city: address.city,
            postalCode: address.postalCode,
            country: address.country.value,
          },
          country: country.value,
          capitalType: capitalType?.value as CompanyCapitalType,
          ...rest,
        },
      },
      onCompleted: (data) => {
        const companyId = data.createCompany.id;
        if (data.createCompany.registrationNumber) {
          const args = {
            variables: {
              companyId,
            },
          };
          getPappersExtractInpiDocument(args);
          getPappersStatutsDocument(args);
          getPappersRegisterExtractDocument(args);
        }
        window.history.replaceState(
          null,
          '',
          generatePath(routes.onboardings.company.edit.href, { companyId }),
        );
        navigate(
          resolvePath(
            routes.onboardings.company.edit.representatives.href,
            generatePath(routes.onboardings.company.edit.href, { companyId }),
          ).pathname,
        );
      },
      onError: (error) => {
        error.message === Exceptions.AlreadyExistingCompany
          ? errorToast({
              title: t('ExistingSIRENNumberCompany'),
            })
          : errorToast({ title: t('CompanyUpdateError') });
      },
    });
  };

  return (
    <StepFormContextProvider>
      <OnboardingStep subTitle={t('AllTheseInfoInCompanyBylaws')} title={t('GeneralInformation')}>
        <ExistingCompanyInformations onSubmit={handleCreateExistingCompany} />
        <Button form="company-informations" type="submit" w="full">
          {t('Validate')}
        </Button>
      </OnboardingStep>
    </StepFormContextProvider>
  );
}
