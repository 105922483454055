import { FiscalAdvantage } from '@blockpulse3/data/shared';

/**
 *  isFiscalAdvantageAllowed.
 *  Check if allowed fiscal advantages includes `PEA` or `PEA_PME`
 *
 * @param {FiscalAdvantage[]} allowedFiscalAdvantages
 * @returns {boolean}
 */
export const isFiscalAdvantageAllowed = (
  allowedFiscalAdvantages: FiscalAdvantage[] | null | undefined,
): boolean => {
  if (!allowedFiscalAdvantages) {
    return false;
  }

  const hasFiscalAdvantage =
    allowedFiscalAdvantages.includes(FiscalAdvantage.PEA) ||
    allowedFiscalAdvantages.includes(FiscalAdvantage.PEA_PME);

  return hasFiscalAdvantage;
};
