import { InfoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { formatNumberInt, formatNumberPercentage } from '@blockpulse3/helpers';
import { ErrorMessage } from '@blockpulse3/ui/commons';
import { useCompanyShares } from '@blockpulse3/ui/ui-hooks';

import { useStepFormContext } from '../../../provider';
import { financialInformationsSchema } from './schema';
import { FinancialInformationsForm } from './types';

const DEFAULT_PREFERRED_SHARES_PERCENTAGE = 50;

type Props = {
  /* ** Default form values ** */
  defaultValues?: FinancialInformationsForm;
  /* ** Callback of the Cancel button ** */
  onCancel: () => void;
  /* ** Callback on submit complete ** */
  onSubmit: (data: FinancialInformationsForm) => void;
};

/**
 * FinancialInformationsForm.
 * Form for the financial informations of a SPV.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SPVFinancialInformationsForm({
  defaultValues,
  onCancel = noop,
  onSubmit = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const { register, formState, watch, handleSubmit, setValue } = useForm<FinancialInformationsForm>(
    {
      defaultValues,
      resolver: yupResolver(financialInformationsSchema),
    },
  );

  const handleFormSubmit: SubmitHandler<FinancialInformationsForm> = useCallback(
    (data) => {
      onSubmit(data);
    },
    [onSubmit],
  );

  const { setCancelHandler, setSubmitHandler } = useStepFormContext();
  const handleStepSubmit = useCallback((): void => {
    handleSubmit(handleFormSubmit)();
  }, [handleSubmit, handleFormSubmit]);

  const handleStepCancel = useCallback((): void => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    setSubmitHandler(handleStepSubmit);
    setCancelHandler(handleStepCancel);
  }, [handleStepSubmit, handleStepCancel, setSubmitHandler, setCancelHandler]);

  const carriedInterest = watch('carriedInterest');
  const compensation = watch('compensation');
  const shareCapital = watch('shareCapital');
  const preferredSharesPercentage = watch('preferredSharesPercentage');

  const isCarriedChecked = carriedInterest > 0;
  const isPresidentChecked = compensation > 0;

  /* ** Compute share counts ** */
  const { preferredSharesCount } = useCompanyShares({
    shareCapital,
    preferredSharesPercentage,
  });

  const prevCarriedInterest = useRef<number>(0);
  useEffect(() => {
    /* ** Set preferredSharesPercentage to 50 by default if carried interest case is checked ** */
    if (carriedInterest > 0 && !prevCarriedInterest.current && !preferredSharesPercentage) {
      setValue('preferredSharesPercentage', DEFAULT_PREFERRED_SHARES_PERCENTAGE);
    }

    /* ** Reset preferredSharesPercentage when carried interest is 0 or undefined ** */
    if (!carriedInterest) {
      setValue('preferredSharesPercentage', 0);
    }

    prevCarriedInterest.current = carriedInterest;
  }, [carriedInterest, preferredSharesPercentage, setValue]);

  return (
    <form id="financial-informations" onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing="4">
        <HStack alignItems="space-betwwen" bg="gray.50" p="3" rounded="md">
          <FormControl isInvalid={!!formState.errors?.shareCapital}>
            <FormLabel htmlFor="shareCapital">{t('DepositedCapital')}</FormLabel>
            <Stack direction={{ base: 'column', md: 'row' }}>
              <InputGroup w={{ base: 'full', md: '49%' }}>
                <Input id="shareCapital" type="number" {...register('shareCapital')} />
                <InputRightAddon>€</InputRightAddon>
              </InputGroup>
              <Text marginLeft="3" w={{ base: 'full', md: '51%' }}>
                {carriedInterest > 0
                  ? t.rich('IncludingPreferredSharesDetails', {
                      preferredSharesPercentages: formatNumberPercentage(preferredSharesPercentage),
                      preferredSharesCount: formatNumberInt(preferredSharesCount),
                      important: (chunks) => <b>{chunks}</b>,
                    })
                  : null}
              </Text>
            </Stack>
            <ErrorMessage error={formState.errors?.shareCapital} />
          </FormControl>
        </HStack>
        <HStack alignItems="space-betwwen" bg="gray.50" p="3" rounded="md">
          <HStack alignItems="flex-start" flexGrow="1">
            <Checkbox bg="white" isChecked={isPresidentChecked} mt="1" />
            <FormControl isInvalid={!!formState.errors?.compensation}>
              <FormLabel htmlFor="compensation">{t('PresidentRemuneration')}</FormLabel>
              <InputGroup w={{ base: 'full', md: '49%' }}>
                <Input id="compensation" step="0.01" type="number" {...register('compensation')} />
                <InputRightAddon>€</InputRightAddon>
              </InputGroup>
              <ErrorMessage error={formState.errors.compensation} />
            </FormControl>
          </HStack>
          <Tooltip hasArrow label={t('AnnualCompensationPresidentManagement')} placement="top">
            <Icon as={InfoIcon} color="gray.500" />
          </Tooltip>
        </HStack>
        <Stack bg="gray.50" p="3" rounded="md">
          <HStack alignItems="space-between">
            <Box verticalAlign="top">
              <Checkbox bg="white" isChecked={isCarriedChecked} mt="1" />
            </Box>
            <Stack alignItems="flex-start" flexGrow="1" spacing="4">
              <FormControl isInvalid={!!formState.errors?.carriedInterest}>
                <FormLabel htmlFor="carriedInterest">{t('PerformanceRemuneration')}</FormLabel>
                <InputGroup w={{ base: 'full', md: '49%' }}>
                  <Input
                    id="carriedInterest"
                    step="0.01"
                    type="number"
                    {...register('carriedInterest', { valueAsNumber: true })}
                  />
                  <InputRightAddon>%</InputRightAddon>
                </InputGroup>
                <ErrorMessage error={formState.errors.carriedInterest} />
              </FormControl>
              {carriedInterest > 0 && (
                <FormControl isInvalid={!!formState.errors.preferredSharesPercentage}>
                  <FormLabel htmlFor="preferredSharesPercentages">
                    {t('PreferredSharesPercentageToCreate')}
                  </FormLabel>
                  <InputGroup w={{ base: 'full', md: '49%' }}>
                    <Input
                      id="preferredSharesPercentages"
                      step="0.01"
                      type="number"
                      {...register('preferredSharesPercentage')}
                    />
                    <InputRightAddon>%</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors.preferredSharesPercentage} />
                </FormControl>
              )}
            </Stack>
            <Tooltip hasArrow label={t('CarriedInterestDefinition')} placement="top">
              <Icon as={InfoIcon} color="gray.500" />
            </Tooltip>
          </HStack>
        </Stack>
        <Stack bg="gray.50" p="3" rounded="md">
          <HStack alignItems="space-between">
            <FormControl alignItems="flex-start" as={HStack}>
              <Checkbox disabled mt="1" />
              <FormLabel mb="0">{t('SubscriberInvestmentFees')}</FormLabel>
            </FormControl>
            <Tooltip hasArrow label={t('FeesDescription')} placement="top">
              <Icon as={InfoIcon} color="gray.500" />
            </Tooltip>
          </HStack>
          <Alert status="info" w="full">
            <AlertIcon />
            <AlertTitle>{t('EntranceFeesConfigurationHint')}</AlertTitle>
          </Alert>
        </Stack>
      </Stack>
    </form>
  );
}
