import {
  As,
  Badge,
  Link as ChakraLink,
  HStack,
  Icon,
  IconButton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import {
  BriefcaseIcon,
  GlobeAltIcon,
  LibraryIcon,
  OfficeBuildingIcon,
} from '@heroicons/react/solid';
import { Link, generatePath } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop, routes } from '@blockpulse3/data/shared';
import {
  GetSpacesDocument,
  GetSpacesQuery,
  GetSpacesQueryVariables,
  Space,
  SpaceEntityInfosFragment,
  SpaceType,
} from '@blockpulse3/graphql/hooks';
import { capitalizeFirstLetter } from '@blockpulse3/helpers';
import { ErrorQueryCard, PaginationButtons, TableContainer } from '@blockpulse3/ui/commons';
import { usePagination } from '@blockpulse3/ui/ui-hooks';

import { PAGE_SIZE } from './utils';

type Props = {
  /* ** Callback on update button click ** */
  onRowUpdateClick?: (spaceId: Space['id']) => void;
  /* ** Callback on delete button click ** */
  onRowDeleteClick?: (spaceId: Space['id']) => void;
};

/**
 * SpaceListTable.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SpaceListTable({
  onRowUpdateClick = noop,
  onRowDeleteClick = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const paginationProps = usePagination<
    GetSpacesQuery,
    GetSpacesQueryVariables,
    SpaceEntityInfosFragment
  >({
    queryDocument: GetSpacesDocument,
    queryOptions: {
      variables: {
        first: PAGE_SIZE,
      },
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'spaces',
    pageSize: PAGE_SIZE,
  });

  if (paginationProps.loading) {
    return (
      <Stack layerStyle="emptyState">
        <Spinner />
      </Stack>
    );
  }

  if (!paginationProps.results) {
    return <ErrorQueryCard />;
  }

  const handleRowUpdateClick = (id: Space['id']): void => {
    onRowUpdateClick(id);
  };

  const handleRowDeleteClick = (id: Space['id']): void => {
    onRowDeleteClick(id);
  };

  const { results } = paginationProps;

  const typeIcons: Record<SpaceType, As> = {
    [SpaceType.GLOBAL]: GlobeAltIcon,
    [SpaceType.PROVIDER]: LibraryIcon,
    [SpaceType.GROUP]: OfficeBuildingIcon,
    [SpaceType.ADVISOR]: BriefcaseIcon,
  };

  return (
    <Stack>
      <TableContainer maxH="none">
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>{t('Name')}</Th>
              <Th>{t('Brand')}</Th>
              <Th>{t('Type')}</Th>
              <Th>{t('Share', { nb: 2 })}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {results.map((space) => (
              <Tr key={space.id}>
                <Td>
                  <ChakraLink
                    as={Link}
                    relative="path"
                    to={'../' + generatePath(routes.space.space.href, { spaceId: space.id })}
                  >
                    <Text fontWeight="600">{space.name}</Text>
                  </ChakraLink>
                </Td>
                <Td>{capitalizeFirstLetter(space.brand)}</Td>
                <Td>
                  <Badge colorScheme="blackAlpha">
                    <HStack spacing="1">
                      <Icon as={typeIcons[space.type]} boxSize="14px" color="gray.500" />
                      <Text>{space.type}</Text>
                    </HStack>
                  </Badge>
                </Td>
                <Td>
                  <HStack spacing="1">
                    <IconButton
                      aria-label="edit"
                      icon={<Icon as={PencilIcon} boxSize="18px" color="gray.700" />}
                      variant="secondary"
                      onClick={(): void => handleRowUpdateClick(space.id)}
                    />
                    <IconButton
                      aria-label="delete"
                      icon={<Icon as={TrashIcon} boxSize="18px" color="gray.700" />}
                      variant="secondary"
                      onClick={(): void => handleRowDeleteClick(space.id)}
                    />
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationButtons
        currentPage={paginationProps.currentPage}
        hasNextPage={paginationProps.pageInfo.hasNextPage}
        hasPreviousPage={paginationProps.pageInfo.hasPreviousPage}
        loading={paginationProps.isLoadingMore}
        pageCount={paginationProps.pageCount}
        onNextPage={paginationProps.handleNextPage}
        onPreviousPage={paginationProps.handlePreviousPage}
      />
    </Stack>
  );
}

export type SpaceListTableProps = Props;
