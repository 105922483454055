import { Icon, IconButton, Stack, Table, Tbody, Td, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import { TrashIcon } from '@heroicons/react/outline';

import { noop } from '@blockpulse3/data/shared';
import { Asset } from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import { AssetBadge, TableContainer } from '@blockpulse3/ui/commons';

import { IStockAssetRow } from './types';

type Props = {
  /* ** List of previously created assets ** */
  assets: IStockAssetRow[];
  /* ** On delete asset callback ** */
  onDeleteAsset?: (id: Asset['id']) => void;
};

export function RepatriationShareTransferRegisterAssetTable({
  assets = [],
  onDeleteAsset = noop,
}: Props): JSX.Element {
  const handleDeleteAsset = (id: Asset['id']): void => {
    onDeleteAsset(id);
  };

  return (
    <Stack spacing="4">
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Nom du titre</Th>
              <Th>Type</Th>
              <Th>Code</Th>
              <Th isNumeric>Valeur nominale</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {assets.map((asset) => (
              <Tr key={asset.id}>
                <Td fontWeight="600">{asset.name}</Td>
                <Td fontWeight="600">
                  <AssetBadge type={asset.type.value} value={null} />
                </Td>
                <Td fontWeight="600">{asset.code}</Td>
                <Td isNumeric fontWeight="600">
                  {formatNumberCurrency(asset.value)}
                </Td>
                <Td p="4">
                  <IconButton
                    aria-label="delete"
                    icon={<Icon as={TrashIcon} boxSize="4" />}
                    size="sm"
                    variant="icon-delete"
                    onClick={(): void => handleDeleteAsset(asset.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr>
              <Th fontWeight="bold">{`${assets.length} titres`}</Th>
              <Th />
              <Th />
              <Th />
              <Th />
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export type RepatriationShareTransferRegisterAssetTableProps = Props;
