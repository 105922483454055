import { Button, Icon, Stack, Td, Text, Tr } from '@chakra-ui/react';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { SwitchVerticalIcon } from '@heroicons/react/solid';
import { generatePath, resolvePath, useMatch, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { ISubscription, routes } from '@blockpulse3/data/shared';
import {
  AssetType,
  CompanyStatus,
  IdentityShortInfosFragment,
  IdentityType,
  OperationType,
  SubscriptionSide,
  SubscriptionWithCompanyInfosFragment,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import { CompanyIdentityAvatar, IdentityAvatar, SubscriptionBadge } from '@blockpulse3/ui/commons';

type Props = {
  /* ** Subscription entity ** */
  subscription: SubscriptionWithCompanyInfosFragment;
};

export function InvitationRow({ subscription }: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const isManaging = useMatch(routes.manage.href + '/*');

  const navigate = useNavigate();

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const handleSubscriptionClick = (
    subscription: ISubscription,
    subscriptionSide: SubscriptionSide,
    subCompanyId?: string,
  ): void => {
    const subscriberIdentity =
      subscriptionSide === SubscriptionSide.SELLER
        ? subscription.sellerIdentity
        : subscription.buyerIdentity;

    const subCompany =
      subCompanyId && (!companyId || subCompanyId !== companyId)
        ? subscriberIdentity?.companyIdentity
        : company;
    if (subCompany) {
      if (subCompany?.status === CompanyStatus.RUNNING) {
        navigate(
          resolvePath(
            generatePath(routes.subscription.href, { subscriptionId: subscription.id }),
            generatePath(isManaging ? routes.manage.company.href : routes.company.href, {
              companyId: subCompany.id,
            }),
          ).pathname,
        );
      } else {
        navigate(
          resolvePath(
            generatePath(routes.onboardings.company.edit.href, { companyId: subCompany.id }),
            routes.onboardings.company.href,
          ).pathname,
        );
      }
    } else {
      navigate(generatePath(routes.subscription.href, { subscriptionId: subscription.id }));
    }
  };

  const subscriptionSide = subscription.side || SubscriptionSide.BUYER;
  const subscriberIdentity =
    subscriptionSide === SubscriptionSide.SELLER
      ? subscription.sellerIdentity
      : subscription.buyerIdentity;
  const isSubscriberCompany = subscriberIdentity?.type === IdentityType.COMPANY;

  let otherSideIdentity: IdentityShortInfosFragment | undefined | null = null;
  let isOtherSideCompany = false;
  if (subscription.operation.type === OperationType.SECONDARY) {
    otherSideIdentity =
      subscriptionSide === SubscriptionSide.SELLER
        ? subscription.buyerIdentity
        : subscription.sellerIdentity;
    isOtherSideCompany = otherSideIdentity?.type === IdentityType.COMPANY;
  }

  return (
    <Tr
      role="button"
      onClick={(): void =>
        handleSubscriptionClick(
          subscription,
          subscriptionSide,
          isSubscriberCompany ? subscriberIdentity?.companyIdentity?.id : undefined,
        )
      }
    >
      <Td textAlign="right" width="1%">
        <Button rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />} variant="secondary">
          {t('AccessInvitation')}
        </Button>
      </Td>
      <Td>
        <Text fontWeight="600">{subscription.operation.name}</Text>
        <Text color="gray.500" fontWeight="400">
          {subscription.operation.type === OperationType.FUNDRAISING &&
            (subscription.operation.assetType === AssetType.BOND
              ? t('BondIssuance')
              : t('CapitalIncrease'))}
          {subscription.operation.type === OperationType.SECONDARY && t('TransferOfShare')}
          {subscription.operation.type === OperationType.OPPORTUNITY && t('IntentCollection')}
        </Text>
      </Td>
      <Td>
        <SubscriptionBadge
          operationType={subscription.operation.type}
          subscriptionSide={subscription.side || SubscriptionSide.BUYER}
        />
      </Td>
      <Td fontWeight="600">
        <Stack alignItems="center" direction="row">
          <CompanyIdentityAvatar src={subscription.operation.company.identity?.profilePicture} />
          <Text>{subscription.operation.company.name}</Text>
        </Stack>
      </Td>
      <Td fontWeight="600">
        <Stack alignItems="center" direction="row">
          <IdentityAvatar identity={subscriberIdentity || undefined} />
          <Text>
            {isSubscriberCompany
              ? subscriberIdentity?.companyIdentity?.name
              : subscriberIdentity?.individualIdentity?.name}
          </Text>
        </Stack>
        {otherSideIdentity && (
          <Stack alignItems="center" direction="row" mb=".5" ml="5">
            <Icon as={SwitchVerticalIcon} boxSize="4" fill="gray.600" />
            <Text color="gray.600" fontSize="xs">
              {isOtherSideCompany
                ? otherSideIdentity?.companyIdentity?.name
                : otherSideIdentity?.individualIdentity?.name}
            </Text>
          </Stack>
        )}
      </Td>
      <Td isNumeric fontWeight="600">
        {subscription.investAmount === 0 ? '-' : formatNumberCurrency(subscription.investAmount)}
      </Td>
      <Td fontWeight="600">
        {subscription.operation.endSubscriptionDate
          ? formatDate(subscription.operation.endSubscriptionDate, 'D MMMM YYYY')
          : t('Continuous')}
      </Td>
    </Tr>
  );
}
