import { ListItem, Text, UnorderedList, useDisclosure } from '@chakra-ui/react';
import { ExclamationIcon, IdentificationIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlUbbleResponseCodes } from '@blockpulse3/data/locales/types';
import {
  IdentityType,
  IdentityVerificationOrigin,
  SubscriptionStepStatus,
} from '@blockpulse3/data/shared';
import {
  MergedIdentityVerificationStep,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { IdentityVerificationModal, useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionStep } from '../../SubscriptionStep';

type Props = {
  isDisabled: boolean;
  subscriptionStep: MergedIdentityVerificationStep;
};

let timeoutId: NodeJS.Timeout | null = null;

export function IdentityVerificationStep({ subscriptionStep, isDisabled }: Props): JSX.Element {
  const t = useTranslations();
  const i18nUbbleResponseCodes = useTranslations('UbbleResponseCodes');

  const [isPollingStarted, setIsPollingStarted] = useState<boolean>(false);
  const [isPollingLoading, setIsPollingLoading] = useState<boolean>(false);
  const [isPollingDone, setIsPollingDone] = useState(false);

  const { subscriptionId = '' } = useParams();

  const { identityId } = useIdentity();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data, refetch, startPolling, stopPolling } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const subscription = data?.subscription;
  const identity =
    subscription?.sellerIdentity?.id === identityId
      ? subscription.sellerIdentity
      : subscription?.buyerIdentity;

  const handleVerificationModalOpen = (status: SubscriptionStepStatus): void => {
    if ([SubscriptionStepStatus.ERROR, SubscriptionStepStatus.NONE].includes(status)) {
      onOpen();
    }
  };

  useEffect(() => {
    if (!isPollingStarted && subscriptionStep.status === SubscriptionStepStatus.IN_PROGRESS) {
      setIsPollingStarted(true);
      setIsPollingLoading(true);
      setIsPollingDone(false);
      startPolling(2000);

      if (timeoutId) clearTimeout(timeoutId);

      /* ** Stop polling after 2min ** */
      timeoutId = setTimeout(() => {
        stopPolling();
        setIsPollingLoading(false);
        setIsPollingDone(true);
      }, 120_000);
    }

    /* ** Stop polling when step status is VALID ** */
    if (isPollingStarted && subscriptionStep.status !== SubscriptionStepStatus.IN_PROGRESS) {
      stopPolling();
      setIsPollingStarted(false);
      setIsPollingLoading(false);
      setIsPollingDone(true);
      refetch();
    }
  }, [isPollingStarted, startPolling, stopPolling, refetch, subscriptionStep.status]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  const individualIdentity =
    identity?.type === IdentityType.INDIVIDUAL
      ? identity?.individualIdentity
      : identity?.companyIdentity?.kybIndividualIdentity;

  const ubbleResponseCodes = individualIdentity?.ubbleResponseCodes || [];
  const hasWarningMessage =
    !!ubbleResponseCodes.length &&
    individualIdentity?.kycOrigin === IdentityVerificationOrigin.UBBLE &&
    [SubscriptionStepStatus.NONE, SubscriptionStepStatus.ERROR].includes(subscriptionStep.status);
  const isVerificationStalled =
    subscriptionStep.status === SubscriptionStepStatus.IN_PROGRESS && isPollingDone;

  return (
    <>
      <SubscriptionStep
        icon={hasWarningMessage ? ExclamationIcon : IdentificationIcon}
        iconColor={hasWarningMessage ? 'yellow.400' : undefined}
        isBadgeLoading={isPollingLoading}
        isDisabled={isDisabled}
        status={subscriptionStep.status}
        title={t('IdentityVerification')}
        type={subscriptionStep.type}
        onClick={handleVerificationModalOpen}
      >
        {(isVerificationStalled || hasWarningMessage) && (
          <>
            {isVerificationStalled && (
              <Text fontSize="sm">{t('ProcessAbnormallyLongContactUs')}</Text>
            )}
            {!isVerificationStalled && hasWarningMessage && (
              <UnorderedList>
                {ubbleResponseCodes.map((responseCode) => {
                  return (
                    <ListItem key={responseCode.code} fontSize="sm">
                      {i18nUbbleResponseCodes(
                        responseCode.summary.toUpperCase() as IntlUbbleResponseCodes,
                      )}
                    </ListItem>
                  );
                })}
              </UnorderedList>
            )}
          </>
        )}
      </SubscriptionStep>
      {isOpen && (
        <IdentityVerificationModal
          isOpen={isOpen}
          refetchData={refetch}
          shouldVerifyInformation={true}
          onClose={onClose}
        />
      )}
    </>
  );
}
