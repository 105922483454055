import { Button, HStack, Heading, Icon, Stack, useDisclosure } from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

import { AssetCreateModal } from '../../AssetCreateModal';
import { OptionCreateModal } from '../../OptionCreateModal';

type Props = {
  onCreateComplete: () => void;
};

export function RepatriationShareTransferRegisterAssetsTableControls({
  onCreateComplete,
}: Props): JSX.Element {
  const t = useTranslations();

  const createAssetModal = useDisclosure();
  const createOptionModal = useDisclosure();

  const handleCreateAsset = (): void => {
    createAssetModal.onOpen();
  };

  const handleCreateOption = (): void => {
    createOptionModal.onOpen();
  };

  const handleCreateComplete = (): void => {
    onCreateComplete();
  };

  return (
    <>
      <Stack
        alignItems="flex-end"
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        spacing="4"
      >
        <Heading size="md">{t('AssetsList')}</Heading>
        <HStack spacing="4">
          <Button
            leftIcon={<Icon as={PlusIcon} boxSize="18px" />}
            size="sm"
            onClick={handleCreateAsset}
          >
            {t('AddAsset')}
          </Button>
          <Button
            leftIcon={<Icon as={PlusIcon} boxSize="18px" />}
            size="sm"
            onClick={handleCreateOption}
          >
            {t('AddPool')}
          </Button>
        </HStack>
      </Stack>
      <AssetCreateModal
        isOpen={createAssetModal.isOpen}
        onClose={createAssetModal.onClose}
        onComplete={handleCreateComplete}
      />
      <OptionCreateModal
        isOpen={createOptionModal.isOpen}
        onClose={createOptionModal.onClose}
        onComplete={handleCreateComplete}
      />
    </>
  );
}

export type RepatriationShareTransferRegisterAssetsTableControlsProps = Props;
