import { Icon, InfoIcon } from '@chakra-ui/icons';
import { HStack, Stack, Table, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslations } from 'use-intl';

import { CsvCellError } from '@blockpulse3/data/shared';
import { TableContainer } from '@blockpulse3/ui/commons';

type Props = {
  csvData: Record<string, string>[];
  cellErrors?: CsvCellError[];
};

export function CsvDataTable({ csvData, cellErrors }: Props): JSX.Element | null {
  const t = useTranslations();
  const i18nCSVParseError = useTranslations('CSVParseError');

  if (!csvData?.length) return null;

  const cols = Object.keys(csvData[0]);

  const header = (): ReactNode => {
    return cols.map((col) => (
      <Th key={col} align="right">
        {col}
      </Th>
    ));
  };

  return (
    <Stack spacing="2">
      {!!cellErrors?.length && (
        <Text color="red" fontWeight={500}>
          {t('ErrorCount', { nb: cellErrors.length })}
        </Text>
      )}
      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>{header()}</Tr>
          </Thead>
          <Tbody>
            {csvData.map((row: { [x: string]: string }, rowIndex: number) => (
              <Tr key={rowIndex}>
                {cols.map((col, colIndex) => {
                  const cellError = cellErrors?.find(
                    (err) => err.row === rowIndex && err.column === colIndex,
                  );

                  const hasError = !!cellError;
                  return (
                    <Td
                      key={col}
                      align="right"
                      backgroundColor={hasError ? 'red.50 !important' : ''}
                    >
                      <HStack justifyContent="space-between">
                        <Text color={hasError ? 'red' : undefined}>{row[col]}</Text>

                        {hasError && cellError.code && (
                          <Tooltip
                            hasArrow
                            label={i18nCSVParseError(cellError.code)}
                            placement="top"
                          >
                            <Icon as={InfoIcon} color="red" />
                          </Tooltip>
                        )}
                      </HStack>
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export type CsvDataTableProps = Props;
