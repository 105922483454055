import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import BigNumber from 'bignumber.js';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { TranslationValues, useTranslations } from 'use-intl';

import {
  Exceptions,
  FormErrors,
  OperationDocumentType,
  isFeatureEnabled,
} from '@blockpulse3/data/shared';
import {
  AssetType,
  IdentityType,
  MergedPurchaseIntentStep,
  useGetSubscriptionDocumentsQuery,
  useGetSubscriptionQuery,
  useValidatePurchaseIntentStepMutation,
} from '@blockpulse3/graphql/hooks';
import { formatNumber, formatNumberCurrency, getNearestMultiple } from '@blockpulse3/helpers';
import {
  CompanyIdentityAvatar,
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useErrorToast,
} from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { SubscriptionHoldingMethod } from '../../../SubscriptionHoldingMethod';
import { FundsSourceUpload } from '../../commons';
import { schema } from './schema';
import { PurchaseIntentForm } from './types';

type Props = {
  subscriptionStep: MergedPurchaseIntentStep;
  onClose: () => void;
} & Omit<ResponsiveModalProps, 'children'>;

export function PurchaseIntentModal({ subscriptionStep, onClose, ...props }: Props): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const [errorValues, setErrorValues] = useState<TranslationValues | undefined>(undefined);
  const [isHoldingMethodLoading, setIsHoldingMethodLoading] = useState(false);

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const [validatePurchaseIntentStep, { loading: validateStepLoading }] =
    useValidatePurchaseIntentStepMutation();

  const { data: documentData } = useGetSubscriptionDocumentsQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const fundsSourceCertificates =
    documentData?.getSubscriptionDocuments?.filter(
      (doc) => doc.type === OperationDocumentType.FUNDS_SOURCE_CERTIFICATE,
    ) || [];

  const { data, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });
  const subscription = data?.subscription;
  const operation = subscription?.operation;
  const isIndividual = subscription?.buyerIdentity?.type === IdentityType.INDIVIDUAL;
  const company = operation?.company;
  const asset = operation?.asset;

  const defaultValues: PurchaseIntentForm = useMemo(() => {
    return {
      investAmount: subscription?.investAmount || 0,
    };
  }, [subscription]);

  const methods = useForm<PurchaseIntentForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { register, formState, reset, handleSubmit, watch, setError } = methods;

  /* ** Reset form values with default parameters ** */
  useEffect(() => {
    if (defaultValues.investAmount === 0) return;
    reset({ ...defaultValues }, { keepErrors: true });
  }, [defaultValues, reset]);

  /* ** Compute amount to a multiple of the pricePerShare ** */
  const pricePerShare = subscription?.operation.pricePerShare || 1;
  const investAmount = new BigNumber(watch('investAmount'));
  const nearestMultiple = getNearestMultiple(investAmount, pricePerShare);
  const fixedAmount = nearestMultiple.isZero() ? 0 : nearestMultiple.toNumber();
  const isNotFixed = !investAmount.isEqualTo(fixedAmount);
  const amountSharesToBuy = Math.round(fixedAmount / pricePerShare);
  const isNull = nearestMultiple.isNaN();

  const min =
    subscription?.minimalAmount || subscriptionStep?.minimalAmount || operation?.pricePerShare;
  const max = subscription?.maximalAmount || subscriptionStep?.maximalAmount;

  const minCheck = min ? investAmount.isGreaterThanOrEqualTo(min) : true;
  const maxCheck = max ? investAmount.isLessThanOrEqualTo(max) : true;

  const showAlert = useMemo(
    () => minCheck && maxCheck && (isNotFixed || isNull) && amountSharesToBuy > 0,
    [minCheck, maxCheck, isNotFixed, isNull, amountSharesToBuy],
  );

  const proofOfFundsSourceThreshold = subscriptionStep?.proofOfFundsSourceThreshold;
  const needsFundsSourceCertificate =
    proofOfFundsSourceThreshold && fixedAmount >= proofOfFundsSourceThreshold;

  const handleFormSubmit: SubmitHandler<PurchaseIntentForm> = (): void => {
    const proofOfFundsSourceThresholdFormatted = formatNumberCurrency(
      proofOfFundsSourceThreshold || 0,
    );

    // Show error and continue form validation
    if (needsFundsSourceCertificate && !fundsSourceCertificates.length) {
      setErrorValues({
        proofOfFundsSourceThreshold: proofOfFundsSourceThresholdFormatted,
      });
      setError('fundsSourceCertificate', {
        type: 'custom',
        message: FormErrors.FundsSourceCertificateMissing,
      });
    }

    if (min && investAmount.isLessThan(min)) {
      setErrorValues({
        minimalAmount: formatNumberCurrency(min),
        proofOfFundsSourceThreshold: proofOfFundsSourceThresholdFormatted,
      });
      setError('investAmount', { type: 'custom', message: FormErrors.MinimalAmount });
    } else if (max && (fixedAmount > max || investAmount.isGreaterThan(max))) {
      setErrorValues({
        maximalAmount: formatNumberCurrency(max),
        proofOfFundsSourceThreshold: proofOfFundsSourceThresholdFormatted,
      });
      setError('investAmount', { type: 'custom', message: FormErrors.MaximalAmount });
    } else {
      if (!subscriptionId) return;

      validatePurchaseIntentStep({
        variables: {
          validatePurchaseIntentStepInput: {
            subscriptionId,
            investAmount: fixedAmount,
          },
        },
        onCompleted: ({ validatePurchaseIntentStep }) => {
          refetch();

          // Only close modal if step is validated
          if (validatePurchaseIntentStep) {
            onClose();
          }
        },
        onError: (err) => {
          if (err.message === Exceptions.FiscalAccountIncomplete) {
            errorToast({ title: t('HoldingMethodMissingInformations') });
          } else {
            errorToast({ title: t('InvalidSubscriptionAmount') });
          }
        },
      });
    }
  };

  const isFeatureFinancingInfo = isFeatureEnabled('financingInfoBanner');

  const fixedAmountLabel =
    operation?.assetType === AssetType.BOND
      ? t('SubscriptionAmountRoundedBond', { fixedAmount: formatNumberCurrency(fixedAmount) })
      : t('SubscriptionAmountRounded', { fixedAmount: formatNumberCurrency(fixedAmount) });

  const investAmountText = ((): string => {
    if (min && max) {
      return t('SelectAmountRange', {
        minimalAmount: formatNumber(min),
        maximalAmount: formatNumber(max),
      });
    } else if (min && !max) {
      return t('InvestAmountShouldBeMoreThan', {
        minimalAmount: formatNumber(min),
      });
    } else if (max && !min) {
      return t('InvestAmountShouldBeMoreThan', {
        maximalAmount: formatNumber(max),
      });
    } else {
      return t('InvestAmountIsOpen');
    }
  })();

  return (
    <ResponsiveModal onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="xl" p="4">
          {t('ChooseInvestAmount')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack}>
          <Stack spacing="4">
            <FormProvider {...methods}>
              <form id="purchase-intent" onSubmit={handleSubmit(handleFormSubmit)}>
                <Stack spacing="2">
                  <FormControl isInvalid={!!formState.errors.investAmount} padding="0">
                    <Text fontSize="lg" fontWeight="600">
                      {t('HowMuchInvest')}
                    </Text>
                    <Text color="grey.500" fontWeight="400" mb="2">
                      {investAmountText}
                    </Text>
                    <HStack
                      backgroundColor="gray.50"
                      border="2px"
                      borderColor="gray.100"
                      borderRadius="lg"
                      spacing="0"
                    >
                      <Stack
                        borderRightColor="gray.100"
                        borderRightWidth="2px"
                        p="4"
                        spacing="0"
                        w="50%"
                      >
                        <FormLabel fontWeight="500" htmlFor="investAmount" m="0">
                          {t('WantToInvest')}
                        </FormLabel>
                        <InputGroup>
                          <Input
                            id="investAmount"
                            step="0.01"
                            type="number"
                            {...register('investAmount')}
                          />
                          <InputRightAddon>€</InputRightAddon>
                        </InputGroup>
                      </Stack>
                      <HStack m="auto">
                        <CompanyIdentityAvatar src={company?.identity?.profilePicture} />
                        <Stack spacing="0">
                          <Text fontWeight="600">{company?.name}</Text>
                          <Text fontWeight="400">{asset?.name}</Text>
                        </Stack>
                      </HStack>
                    </HStack>
                    <ErrorMessage error={formState.errors.investAmount} values={errorValues} />
                  </FormControl>
                  {showAlert && (
                    <Alert status="warning">
                      <AlertIcon as={ExclamationIcon} />
                      <Stack spacing="1">
                        <AlertTitle>{t('CorrectedAmount')}</AlertTitle>
                        <AlertDescription>{fixedAmountLabel}</AlertDescription>
                      </Stack>
                    </Alert>
                  )}
                  {isFeatureFinancingInfo && (
                    <Alert status="info">
                      <AlertIcon />
                      <Stack spacing="0">
                        <AlertTitle>{t('FinancingInfoBanner')}</AlertTitle>
                      </Stack>
                    </Alert>
                  )}
                  <HStack
                    backgroundColor="gray.50"
                    border="2px"
                    borderColor="gray.100"
                    borderRadius="lg"
                    spacing="0"
                  >
                    <Stack
                      borderRightColor="gray.100"
                      borderRightWidth="2px"
                      p="4"
                      spacing="0"
                      w="50%"
                    >
                      <Text color="gray.500" fontWeight="500">
                        {t('PricePerShare')}
                      </Text>
                      <Text fontSize="large" fontWeight="700">
                        {formatNumberCurrency(pricePerShare)}
                      </Text>
                    </Stack>
                    <Stack p="4" spacing="0" w="50%">
                      <Text color="gray.500" fontWeight="500">
                        {t('AssetsCount')}
                      </Text>
                      <Text fontSize="large" fontWeight="700">
                        {amountSharesToBuy || '-'}
                      </Text>
                    </Stack>
                  </HStack>
                  {needsFundsSourceCertificate && <FundsSourceUpload errorValues={errorValues} />}
                </Stack>
              </form>
            </FormProvider>
            {isIndividual && <SubscriptionHoldingMethod setIsLoading={setIsHoldingMethodLoading} />}
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button
            form="purchase-intent"
            isDisabled={isHoldingMethodLoading}
            isLoading={validateStepLoading}
            type="submit"
          >
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
