import {
  Badge,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { CollectionIcon } from '@heroicons/react/outline';
import { useEffect, useRef, useState } from 'react';
import { generatePath, resolvePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  SubscriptionStepStatus,
  SubscriptionStepType,
  TransferStatus,
  noop,
  routes,
} from '@blockpulse3/data/shared';
import {
  AssetType,
  OperationType,
  useGenerateSwanAuthUrlLazyQuery,
  useGetBankAccountByIdentityIdQuery,
  useGetBankAccountMemberQuery,
  useGetQueuedCreditTransferTransactionsQuery,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  AssetBadge,
  ErrorQueryCard,
  IdentityAvatar,
  LinkButton,
  TableContainer,
} from '@blockpulse3/ui/commons';
import { useBadge } from '@blockpulse3/ui/ui-hooks';
import { useAuthUser, useIdentity } from '@blockpulse3/web-client/auth';

export function QueuedPaymentsView(): JSX.Element {
  const t = useTranslations();

  const popupWindowRef = useRef<Window | null>(null);

  const { companyId = '' } = useParams();
  const { user } = useAuthUser();
  const { identityId } = useIdentity();

  const [isTransferInProgress, setIsTransferInProgress] = useState(false);
  const [isPollingStarted, setIsPollingStarted] = useState(false);
  const [targetedSubscriptionId, setTargetedSubscriptionId] = useState<string>('');
  const [executedSubscriptionIds, setExecutedSubscriptionIds] = useState<string[]>([]);

  const [generateSwanAuthUrl] = useGenerateSwanAuthUrlLazyQuery();

  const { data, loading, error, startPolling, stopPolling } =
    useGetQueuedCreditTransferTransactionsQuery({
      variables: { identityId },
      fetchPolicy: 'cache-and-network',
      skip: !identityId,
    });

  const { data: bankAccountData, refetch: refetchBankAccount } = useGetBankAccountByIdentityIdQuery(
    {
      variables: {
        identityId,
      },
      skip: !identityId,
    },
  );
  const bankAccountId = bankAccountData?.getBankAccountByIdentityId?.id;

  const bankAccountMemberReq = useGetBankAccountMemberQuery({
    variables: {
      bankAccountId: bankAccountId || '',
      individualIdentityId: user?.individualIdentity?.id || '',
    },
    skip: !bankAccountId || !user?.individualIdentity,
  });
  const isBankMember = !!bankAccountMemberReq.data?.getBankAccountMember?.bankDashboardUrl;

  useEffect(() => {
    if (isPollingStarted) {
      const subscriptions = data?.getQueuedCreditTransferTransactions;

      const allPaymentStepHandled = subscriptions?.every((subscription) => {
        // Filter by targeted subscription if any
        if (!executedSubscriptionIds.includes(subscription.id)) {
          return true;
        }

        // Look out for processed bank transfer
        return subscription.bankVirtualIban?.bankTransfers?.some(
          (bankTransfer) => bankTransfer.status === TransferStatus.PENDING,
        );
      });

      if (!isTransferInProgress) {
        setIsTransferInProgress(true);
      }

      const timeout = setTimeout(() => {
        stopPolling();
        setIsPollingStarted(false);
        setIsTransferInProgress(false);
        setTargetedSubscriptionId('');
        setExecutedSubscriptionIds([]);
        refetchBankAccount();
      }, 120_000);

      if (allPaymentStepHandled) {
        stopPolling();
        setIsPollingStarted(false);
        setIsTransferInProgress(false);
        setTargetedSubscriptionId('');
        setExecutedSubscriptionIds([]);
        refetchBankAccount();
      }

      return (): void => clearTimeout(timeout);
    }
    return noop;
  }, [
    data,
    isPollingStarted,
    isTransferInProgress,
    targetedSubscriptionId,
    stopPolling,
    refetchBankAccount,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      // Reset loader when popup window is closed
      if (popupWindowRef?.current?.closed && !isPollingStarted) {
        popupWindowRef.current = null;
        setIsTransferInProgress(false);
      }
    }, 500);

    return (): void => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getBadge } = useBadge(
    null,
    [
      { value: SubscriptionStepStatus.PENDING, label: t('Pending'), color: 'yellow' },
      { value: SubscriptionStepStatus.IN_PROGRESS, label: t('Emitted'), color: 'blue' },
    ],
    { label: t('Pending'), color: 'yellow' },
  );

  if (loading) {
    return <Skeleton h="65px" />;
  }

  if (error) {
    return <ErrorQueryCard h="65px" />;
  }

  const subscriptions = data?.getQueuedCreditTransferTransactions || [];
  const pendingQueuedSubscriptions = subscriptions.filter(
    (subscription) =>
      // Remove subscriptions with pending credit transfer from array
      subscription.bankVirtualIban?.bankTransfers?.every(
        (bankTransfer) => bankTransfer.status !== TransferStatus.PENDING,
      ),
  );
  const hasPendingCreditTransfer = !!pendingQueuedSubscriptions.length;
  const pendingTransferTotalAmount = pendingQueuedSubscriptions.reduce(
    (acc, subscription) => acc + subscription.investAmount,
    0,
  );

  const TRANSFER_LIMIT = 2;
  const transferLimitExceeded = pendingQueuedSubscriptions.length > TRANSFER_LIMIT;

  // Function triggered by child popup window on close
  window.closePopup = async function (): Promise<void> {
    if (!popupWindowRef.current) return;
    setIsPollingStarted(true);
    startPolling(1000);
    popupWindowRef.current.close();
  };

  const handleInitiateQueuedPayments = (subscriptionId?: string): void => {
    if (popupWindowRef?.current && !popupWindowRef?.current?.closed) return;

    // Set or reset targeted subscription id
    setTargetedSubscriptionId(subscriptionId || '');

    if (subscriptionId) {
      setExecutedSubscriptionIds([subscriptionId]);
    } else {
      setExecutedSubscriptionIds(
        pendingQueuedSubscriptions.slice(0, TRANSFER_LIMIT).map((sub) => sub.id),
      );
    }

    const redirectUrlState = resolvePath(
      subscriptionId
        ? generatePath(routes.payment.initiatePayments.subscription.full, { subscriptionId })
        : routes.payment.initiatePayments.href,
      companyId ? generatePath(routes.company.href, { companyId }) : routes.me.href,
    ).pathname;
    generateSwanAuthUrl({
      variables: {
        identityId,
        redirectUrlState,
      },
      onCompleted: (data) => {
        setIsTransferInProgress(true);
        const swanAuthorizationUrl = data.generateSwanAuthUrl;

        const popupWidth = 500;
        const popupHeight = 700;

        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width =
          window.innerWidth || document.documentElement.clientWidth || window.screen.width;
        const height =
          window.innerHeight || document.documentElement.clientHeight || window.screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - popupWidth) / 2 / systemZoom + dualScreenLeft;
        const top = (height - popupHeight) / 2 / systemZoom + dualScreenTop;

        popupWindowRef.current = window.open(
          swanAuthorizationUrl,
          'SwanOAuthPopup',
          `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`,
        );
      },
    });
  };

  const { bookedBalance = 0, availableBalance = 0 } =
    bankAccountData?.getBankAccountByIdentityId || {};
  const pendingAmountGreaterThanBalance = pendingTransferTotalAmount > availableBalance;

  const workspaceRoute = companyId
    ? generatePath(routes.company.href, { companyId })
    : routes.me.href;

  return (
    <Stack spacing="6">
      <LinkButton label={t('ReturnToDashboard')} route={workspaceRoute} />
      <Heading>{t('PendingQueuedPayments')}</Heading>
      <Card>
        <CardBody>
          <Stack direction={{ base: 'column', md: 'row' }} spacing={{ base: '4', md: '8' }}>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('TotalAvailableAmountOnAccount')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberCurrency(availableBalance)}
              </Text>
            </Box>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('TotalBookedAmountOnAccount')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberCurrency(bookedBalance)}
              </Text>
            </Box>
            <Box bg="gray.50" color="white" p="4" w="full">
              <Text color="gray.500" fontSize="md" fontWeight="500">
                {t('TotalAmountOfPendingTransfers')}
              </Text>
              <Text color="gray.800" fontSize="lg" fontWeight="700">
                {formatNumberCurrency(pendingTransferTotalAmount)}
              </Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>
      <Card>
        <CardHeader
          as={Stack}
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
        >
          <Heading size="md">{t('PendingQueued')}</Heading>
          <Button
            isLoading={isTransferInProgress && !targetedSubscriptionId}
            variant="secondary"
            width={{ base: 'fit-content' }}
            isDisabled={
              !isBankMember ||
              !hasPendingCreditTransfer ||
              pendingAmountGreaterThanBalance ||
              (isTransferInProgress && !!targetedSubscriptionId)
            }
            onClick={(): void => handleInitiateQueuedPayments()}
          >
            <Text>
              {transferLimitExceeded
                ? t('ExecuteHundredFirstPayments')
                : t('ExecuteAllQueuedPayments')}
            </Text>
          </Button>
        </CardHeader>
        <CardBody>
          {subscriptions.length === 0 ? (
            <Stack h="100px" layerStyle="emptyState">
              <Icon as={CollectionIcon} boxSize="40px" color="gray.500" />
              <Text>{t('NoPendingQueuedPayments')}</Text>
            </Stack>
          ) : (
            <TableContainer maxHeight="none">
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>{t('OperationName')}</Th>
                    <Th>{t('RecipientName')}</Th>
                    <Th>{t('AssetsCount')}</Th>
                    <Th>{t('Amount', { nb: 1 })}</Th>
                    <Th>{t('TransferStatus')}</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {subscriptions.map((subscription) => {
                    const paymentStep = subscription?.data.mergedSteps.find(
                      (el) => el.type === SubscriptionStepType.PAYMENT,
                    );
                    const isPaymentPending = subscription.bankVirtualIban?.bankTransfers?.some(
                      (bankTransfer) => bankTransfer.status === TransferStatus.PENDING,
                    );

                    // Overwrite status if a standard payment is pending
                    const paymentStatus = !isPaymentPending
                      ? paymentStep?.status
                      : SubscriptionStepStatus.IN_PROGRESS;

                    const paymentBadge = getBadge(paymentStatus);
                    const hasPendingStatus = paymentStatus === SubscriptionStepStatus.PENDING;
                    const amountGreaterThanBalance = subscription.investAmount > availableBalance;

                    const assetCount =
                      subscription.investAmount / (subscription.operation.pricePerShare || 1);

                    const isTargetedSubscription = targetedSubscriptionId === subscription.id;
                    return (
                      <Tr key={subscription.id} role="button">
                        <Td>
                          <Text fontWeight="600">{subscription.operation.name}</Text>
                          <Text color="gray.500" fontWeight="400">
                            {subscription.operation.type === OperationType.FUNDRAISING &&
                              t('CapitalIncrease')}
                            {subscription.operation.type === OperationType.SECONDARY &&
                              t('TransferOfShare')}
                          </Text>
                        </Td>
                        <Td>
                          {subscription.sellerIdentity ? (
                            <HStack spacing="2.5">
                              <IdentityAvatar identity={subscription.sellerIdentity} />
                              <Stack spacing="1">
                                <Text fontWeight="600">{subscription.sellerIdentity?.name}</Text>
                                {subscription.sellerIdentity?.identifier && (
                                  <Text color="gray.500" fontWeight="400">
                                    {subscription.sellerIdentity?.identifier}
                                  </Text>
                                )}
                              </Stack>
                            </HStack>
                          ) : (
                            <Text fontWeight="600" textAlign="left">
                              -
                            </Text>
                          )}
                        </Td>
                        <Td>
                          <AssetBadge
                            type={subscription.operation?.asset?.type || AssetType.ORDINARY_SHARE}
                            value={assetCount}
                          />
                        </Td>
                        <Td fontWeight="600">{formatNumberCurrency(subscription.investAmount)}</Td>
                        <Td>
                          <Badge colorScheme={paymentBadge.color}>{paymentBadge.label}</Badge>
                        </Td>
                        <Td textAlign="right">
                          <Button
                            isLoading={isTransferInProgress && isTargetedSubscription}
                            variant="secondary"
                            isDisabled={
                              !isBankMember ||
                              !hasPendingStatus ||
                              amountGreaterThanBalance ||
                              (isTransferInProgress && !isTargetedSubscription)
                            }
                            onClick={(): void => handleInitiateQueuedPayments(subscription.id)}
                          >
                            {t('ExecuteBankTransfer')}
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </CardBody>
      </Card>
    </Stack>
  );
}
