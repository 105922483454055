import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { useGetCompanyQuery } from '@blockpulse3/graphql/hooks';
import { formatDate, formatNationality } from '@blockpulse3/helpers';
import { ErrorQueryCard, ResponsiveModalFooter } from '@blockpulse3/ui/commons';

import { useManagedIndividual } from '../../../../providers';
import { useVerificationStepContext } from '../../providers';

type Props = {
  onSubmit: () => void;
  onModify: () => void;
  isLoading?: boolean;
  isIssuer?: boolean;
};

export function IdentityInformationVerificationStep({
  onModify = noop,
  onSubmit = noop,
  isLoading = false,
  isIssuer = false,
}: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const { activeSteps, stepIndex } = useVerificationStepContext();

  const { individual } = useManagedIndividual();
  const { data, loading, error } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  if (loading) {
    return <Skeleton h="65px" />;
  }

  if (error) {
    return <ErrorQueryCard h="65px" />;
  }

  const { company } = data || {};

  return (
    <ModalContent>
      <ModalHeader>
        <Text>{t('IdentityVerification')}</Text>
        <Text color="secondary" fontSize="sm">
          {t('StepInfo', {
            stepCount: stepIndex + 1,
            totalStepCount: activeSteps,
          })}
          {company ? t('CompanyInformation') : t('IndividualIdentityInformation')}
        </Text>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody as={Stack} spacing="4">
        <Stack fontSize="sm" spacing="0">
          <Text fontWeight="500">{t('VerifyInformation')}</Text>
        </Stack>
        <Card bg="gray.50" boxShadow="none">
          <CardHeader>
            <Text color="gray.700" fontWeight="600">
              {t(isIssuer ? 'AboutToCreateBankAccount' : 'AboutToInvest')}
            </Text>
          </CardHeader>
          <CardBody as={Stack} spacing="4">
            <Divider />
            {company ? (
              <>
                <Stack fontSize="sm" spacing="0">
                  <Text fontWeight="500">{t('AsLegalIdentity')}</Text>
                  <Text fontWeight="600">{company.name}</Text>
                </Stack>
                <Divider />
                <Stack fontSize="sm" spacing="0">
                  <Text fontWeight="500">{t('RegisteredAtRCS')}</Text>
                  <Text fontWeight="600">{company.registrationEntity}</Text>
                </Stack>
                <Divider />
                <Stack fontSize="sm" spacing="0">
                  <Text fontWeight="500">{t('HeadquartersIn')}</Text>
                  <Text fontWeight="600">{company.address?.formatted}</Text>
                </Stack>
                <Divider />
                <Stack fontSize="sm" spacing="0">
                  <Text fontWeight="500">{t('RepresentedBy')}</Text>
                  <Text fontWeight="600">{company.signer?.individualIdentity?.name}</Text>
                </Stack>
              </>
            ) : (
              <>
                <Stack fontSize="sm" spacing="0">
                  <Text fontWeight="500">{t('AsNaturalIdentity')}</Text>
                  <Text fontWeight="600">{individual?.name}</Text>
                </Stack>
                <Divider />
                <Stack fontSize="sm" spacing="0">
                  <Text fontWeight="500">{t('BornOn')}</Text>
                  <Text fontWeight="600">{formatDate(individual?.birthdate, 'D MMMM YYYY')}</Text>
                </Stack>
                <Divider />
                <Stack fontSize="sm" spacing="0">
                  <Text fontWeight="500">{t('At')}</Text>
                  <Text fontWeight="600">{individual?.birthplace}</Text>
                </Stack>
                <Divider />
                <Stack fontSize="sm" spacing="0">
                  <Text fontWeight="500">{t('ResidesIn')}</Text>
                  <Text fontWeight="600">{individual?.address?.formatted}</Text>
                </Stack>
                <Divider />
                <Stack fontSize="sm" spacing="0">
                  <Text fontWeight="500">{t('OfNationality')}</Text>
                  <Text fontWeight="600">{formatNationality(individual?.nationality || '')}</Text>
                </Stack>
              </>
            )}
          </CardBody>
        </Card>
      </ModalBody>
      <Divider />
      <ResponsiveModalFooter>
        <Button isDisabled={isLoading} variant="secondary" onClick={onModify}>
          {t('Modify')}
        </Button>
        <Button isLoading={isLoading} onClick={onSubmit}>
          {t('Next')}
        </Button>
      </ResponsiveModalFooter>
    </ModalContent>
  );
}
