import { EnabledBy, MergedSubscriptionStepUnion } from '@blockpulse3/graphql/hooks';

/**
 * isStepDisabled.
 * Go through other steps to determine if current step should be disabled
 *
 * @param {MergedSubscriptionStepUnion[]} subscriptionSteps
 * @param {number} currentStepIndex
 * @returns {boolean}
 */
export function isStepDisabled(
  subscriptionSteps: MergedSubscriptionStepUnion[],
  currentStepIndex: number,
): boolean {
  // Skip if index for current step does not exist
  if (!subscriptionSteps[currentStepIndex]) return false;

  const isEnabled = subscriptionSteps[currentStepIndex].enabledBy.every(
    (stepEnabledBy: EnabledBy) => {
      const targetIndex = currentStepIndex + stepEnabledBy.index;
      // Skip if no step found for index
      if (!subscriptionSteps[targetIndex]) return false;

      const targetedStep = subscriptionSteps[targetIndex];
      return stepEnabledBy.statuses.includes(targetedStep.status);
    },
  );
  return !isEnabled;
}
