import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { generatePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  LinkButton,
  TabPanelOperationDocuments,
  TabPanelOperationVideoLink,
} from '@blockpulse3/ui/commons';
import { useDisclosureLink } from '@blockpulse3/ui/ui-hooks';
import { SubscriptionPanel } from '@blockpulse3/web-client/operation/commons';

import { FundraisingHeader } from './FundraisingHeader';
import { FundraisingInformations } from './FundraisingInformations';
import { FundraisingProgression } from './FundraisingProgression';
import { FundraisingStatusAlert } from './FundraisingStatusAlert';
import { FundraisingSubscribersList } from './FundraisingSubscribersList/FundraisingSubscribersList';
import { FundraisingUploadCertificate } from './FundraisingUploadCertificate';

type Props = {
  backHref?: string;
};

/**
 * FundraisingView.
 * View of a Fundraising.
 *
 * @returns {JSX.Element}
 */
export function FundraisingView({ backHref }: Props): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const fundraisingRoutes = routes.company.fundraising;
  const subscriptionPanel = useDisclosureLink({
    hiddenHref: '',
    visibleHref: fundraisingRoutes.subscription.href,
  });

  const workspaceRoute = companyId
    ? generatePath(routes.company.href, { companyId })
    : routes.me.href;
  const routeBack = backHref ? backHref : workspaceRoute;

  return (
    <Stack spacing="6">
      <LinkButton label={t('ReturnToDashboard')} route={routeBack} />
      <FundraisingHeader />
      <Tabs isLazy defaultIndex={0}>
        <TabList>
          <Tab>{t('Monitoring')}</Tab>
          <Tab>{t('Document', { nb: 2 })}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel as={Stack} gap="6">
            <FundraisingUploadCertificate />
            <FundraisingStatusAlert />
            <FundraisingProgression />
            <FundraisingInformations />
            <FundraisingSubscribersList subscriptionPanel={subscriptionPanel} />
            <SubscriptionPanel
              isOpen={subscriptionPanel.isOpen}
              onClose={subscriptionPanel.onClose}
            />
          </TabPanel>
          <TabPanel as={Stack} gap="6">
            <TabPanelOperationDocuments />
            <TabPanelOperationVideoLink />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

export type FundraisingViewProps = Props;
