import { AddIcon } from '@chakra-ui/icons';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { SortingState, Updater } from '@tanstack/react-table';
import { useState } from 'react';
import { generatePath, resolvePath, useLocation, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { SubscriptionFilterType, SubscriptionsTabsStatus, routes } from '@blockpulse3/data/shared';
import {
  CreateSubscriptionInput,
  CreateSubscriptionMutation,
  GetSubscriptionsDocument,
  GetSubscriptionsQuery,
  GetSubscriptionsQueryVariables,
  OperationStatus,
  OrderBy,
  OrderByDirection,
  SubscriptionShortInfosFragment,
  SubscriptionsFilterInput,
  UpdateSubscriptionMutation,
  useGetOperationQuery,
  useGetSubscriptionsCountQuery,
} from '@blockpulse3/graphql/hooks';
import { useSuccessToast } from '@blockpulse3/ui/commons';
import { useDisclosureLink, usePagination } from '@blockpulse3/ui/ui-hooks';
import {
  OperationRemindersModal,
  OperationSubscribersModal,
  OperationSubscribersType,
  useOperationFilterContext,
} from '@blockpulse3/web-client/operation/commons';

import { OpportunityOperationIntentsControls } from './OpportunityOperationIntentsControls';
import { OpportunityOperationIntentsTable } from './OpportunityOperationIntentsTable';
import { OpportunityOperationIntentsTabs } from './OpportunityOperationIntentsTabs';
import { getTabsWithCount } from './utils';

const PAGE_SIZE = 10;

type Props = {
  intentPanel: ReturnType<typeof useDisclosureLink>;
};

/**
 * OpportunityOperationIntentsList.
 *
 * @returns {JSX.Element}
 */
export function OpportunityOperationIntentsList({ intentPanel }: Props): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const location = useLocation();

  const successToast = useSuccessToast();

  const reminderModal = useDisclosure();
  const subscriberModal = useDisclosure();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { filters, setFilters } = useOperationFilterContext();

  const orderBy: OrderBy | undefined = !sorting.length
    ? undefined
    : {
        direction: sorting[0].desc ? OrderByDirection.DESC : OrderByDirection.ASC,
        field: sorting[0].id,
      };

  const { data: operationData } = useGetOperationQuery({
    variables: {
      operationId,
    },
  });

  const subscriptionsCountsReq = useGetSubscriptionsCountQuery({
    variables: { operationId },
    fetchPolicy: 'cache-and-network',
  });

  const paginationProps = usePagination<
    GetSubscriptionsQuery,
    GetSubscriptionsQueryVariables,
    SubscriptionShortInfosFragment
  >({
    queryDocument: GetSubscriptionsDocument,
    queryOptions: {
      variables: {
        operationId,
        first: PAGE_SIZE,
        filterBy: filters,
        orderBy,
      },
      fetchPolicy: 'cache-and-network',
    },
    dataName: 'subscriptions',
    pageSize: PAGE_SIZE,
  });
  const { totalCount, refetch, reset } = paginationProps;

  const operation = operationData?.operation;
  const isStarted = operation?.status === OperationStatus.STARTED;
  const subscriptionsCounts = subscriptionsCountsReq.data?.getSubscriptionsCount;
  const tabs = getTabsWithCount(subscriptionsCounts);

  const handleTabChange = (index: number): void => {
    setTabIndex(index);

    const newFilters: SubscriptionsFilterInput[] = [];
    if (tabs[index].name !== SubscriptionsTabsStatus.ALL_STATUS) {
      newFilters.push({
        name: 'investStatus',
        type: SubscriptionFilterType.STATUS,
        value: tabs[index].name,
      });
    }

    if (
      [SubscriptionsTabsStatus.PENDING, SubscriptionsTabsStatus.ALL_STATUS].includes(
        tabs[index].name,
      )
    ) {
      const resetFilterStatus = filters.filter((filter) => filter.name !== 'investStatus');
      setFilters([...newFilters, ...resetFilterStatus]);
    } else {
      const resetFilterStatus = filters.filter((filter) => filter.name === 'searchValue');
      setFilters([...newFilters, ...resetFilterStatus]);
    }
    refetch();
  };

  /* ** Refetch subscriptions() on filter modal submit ** */
  const handleFiltersSubmit = (values: SubscriptionsFilterInput[]): void => {
    const searchValueFilter = filters.filter((filter) =>
      ['searchValue', 'investStatus'].includes(filter.name),
    );
    reset();
    setFilters([...values, ...searchValueFilter]);
  };

  const handleSearchInputSubmit = (value: string): void => {
    const isInputAndFilterSearchEmpty =
      value === '' && !filters.some((filter) => filter.name === 'searchValue');

    if (isInputAndFilterSearchEmpty) return;

    resetSpecificFilter('searchValue', SubscriptionFilterType.CUSTOM, value);
  };

  const resetSpecificFilter = (
    filterName: string,
    filterType: SubscriptionFilterType,
    value: string,
  ): void => {
    const currentFilterWithoutFilterName = filters.filter((filter) => filter.name !== filterName);
    const newFilter: SubscriptionsFilterInput = {
      name: filterName,
      type: filterType,
      value,
    };
    reset();
    setFilters([...currentFilterWithoutFilterName, newFilter]);
  };

  const handleSortingChange = (sortFn: Updater<SortingState>): void => {
    setSorting(sortFn);
    refetch();
  };

  const handleRowClick = (subscriptionId: string): void => {
    intentPanel.onOpen(
      resolvePath(
        generatePath(routes.company.opportunity.subscription.href, { subscriptionId }),
        location.pathname,
      ).pathname,
    );
  };

  const handleSubscriberModalComplete = (
    data: CreateSubscriptionMutation | UpdateSubscriptionMutation,
    subscription: CreateSubscriptionInput,
    isLegal: boolean,
  ): void => {
    successToast({ title: t('PotentialBuyerInvited') });
    refetch();
    subscriptionsCountsReq.refetch();
  };

  return (
    <Card>
      <CardHeader
        alignItems={{ base: 'flex-start', md: 'center' }}
        as={Stack}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Heading size="md">{t('IntentList')}</Heading>
        <Stack alignSelf="stretch" direction={{ base: 'column', md: 'row' }}>
          <Button isDisabled={!isStarted} leftIcon={<AddIcon />} onClick={subscriberModal.onOpen}>
            {t('InvitePotentialBuyerAction')}
          </Button>
          <Button variant="secondary" onClick={reminderModal.onOpen}>
            {t('RemindPotentialBuyer', {
              nb: totalCount,
            })}
          </Button>
        </Stack>
      </CardHeader>
      <CardBody as={Stack} spacing="4">
        <OpportunityOperationIntentsTabs handleTabChange={handleTabChange} tabs={tabs} />
        <OpportunityOperationIntentsControls
          activeTabIndex={tabIndex}
          filters={filters}
          tabs={tabs}
          onFiltersChange={handleFiltersSubmit}
          onSearchInputSubmit={handleSearchInputSubmit}
        />
        <OpportunityOperationIntentsTable
          operationStatus={operation?.status}
          sorting={sorting}
          tabName={tabs[tabIndex].name}
          onRowClick={handleRowClick}
          onSortingChange={handleSortingChange}
          {...paginationProps}
        />
      </CardBody>
      <OperationRemindersModal
        filters={filters}
        isOpen={reminderModal.isOpen}
        operationId={operationId}
        subscribersType={OperationSubscribersType.INTENTS}
        totalCount={totalCount}
        onClose={reminderModal.onClose}
      />
      <OperationSubscribersModal
        amountRequired={false}
        amountRangeRequired={true}
        feesRequired={false}
        modalSubmitText={t('AddThePotentialBuyer')}
        modalTitle={t('AddPotentialBuyer')}
        isOpen={subscriberModal.isOpen}
        operationId={operationId}
        onClose={subscriberModal.onClose}
        onComplete={handleSubscriberModalComplete}
      />
    </Card>
  );
}
