import dayjs from 'dayjs';

import { OptionAssetGrantFragment, OptionAssetInfosFragment } from '@blockpulse3/graphql/hooks';

export enum OptionGrantStatus {
  GRANTED = 'GRANTED',
  SUBSCRIBED = 'SUBSCRIBED',
  VESTING = 'VESTING',
  VESTED = 'VESTED',
  EXERCISED = 'EXERCISED',
  OBSOLETE = 'OBSOLETE',
}

export function getOptionAssetGrantStatus(
  option: OptionAssetInfosFragment,
  grant?: OptionAssetGrantFragment,
): string {
  if (!grant) {
    return OptionGrantStatus.GRANTED;
  }

  const endSubscriptionDate = dayjs
    .unix(grant.date)
    .add(option.subscriptionPeriod, option.durationUnit);
  const endVestingDate = dayjs
    .unix(grant.date)
    .add(option.vestingCurve.vestingDuration, option.durationUnit);
  const cancellationDate = !!grant.cancellationDate && dayjs.unix(grant.cancellationDate);

  const optionExpirationDate = dayjs.unix(option.expirationDate);

  const today = dayjs();

  if (
    (cancellationDate && today.isAfter(cancellationDate)) ||
    today.isAfter(optionExpirationDate)
  ) {
    return OptionGrantStatus.OBSOLETE;
  }

  if (grant.exercisedAmount === grant.amount) {
    return OptionGrantStatus.EXERCISED;
  }

  if (grant.subscribedAmount) {
    if (grant.subscribedAmount === grant.amount && today.isBefore(endSubscriptionDate)) {
      return OptionGrantStatus.SUBSCRIBED;
    }

    if (today.isBefore(endVestingDate)) {
      return OptionGrantStatus.VESTING;
    }

    return OptionGrantStatus.VESTED;
  }

  return OptionGrantStatus.GRANTED;
}
