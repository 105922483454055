import { Card, Skeleton, Stack } from '@chakra-ui/react';
import { createRef, useEffect } from 'react';

import { noop } from '@blockpulse3/data/shared';
import { useGenerateUbbleUrlMutation } from '@blockpulse3/graphql/hooks';

import { useAuthUser } from '../../../../../providers';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Ubble: any;

type Props = {
  onClose: () => void;
};

let ubbleUrl: string | null = null;

export function UbbleUserVerification({ onClose = noop }: Props): JSX.Element {
  const ref = createRef<HTMLDivElement>();

  const [generateUbbleUrl] = useGenerateUbbleUrlMutation({ fetchPolicy: 'no-cache' });
  const { user } = useAuthUser();
  const individualIdentity = user?.individualIdentity;

  useEffect(() => {
    if (individualIdentity?.id && ubbleUrl === null) {
      ubbleUrl = '';
      generateUbbleUrl({
        variables: { individualIdentityId: individualIdentity.id },
        onCompleted: (data) => {
          ubbleUrl = data?.generateUbbleURL;
        },
      });
    }
  }, [generateUbbleUrl, individualIdentity]);

  useEffect(() => {
    if (!ubbleUrl) return;

    const ubble = new Ubble.IDV('ubble-iframe', {
      identificationUrl: ubbleUrl,
      height: '520',
      width: '100%',
      allowCamera: true,
      scrolling: 'no',
      events: {
        onComplete: (): void => {
          onClose();
          ubble.destroy();
        },
        onAbort: (): void => {
          ubbleUrl = null;
          onClose();
          ubble.destroy();
        },
        onExpired(): void {
          ubbleUrl = null;
          onClose();
          ubble.destroy();
        },
        onRefused(): void {
          ubbleUrl = null;
          onClose();
          ubble.destroy();
        },
      },
    });
  }, [ref, onClose]);

  return (
    <Stack>
      {ubbleUrl ? (
        <iframe
          allow="camera"
          height="450px"
          id="ubble-iframe"
          src={ubbleUrl}
          title="userVerification"
          width="100%"
        />
      ) : (
        <Skeleton>
          <Card height="450px" />
        </Skeleton>
      )}
    </Stack>
  );
}
