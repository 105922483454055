import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  Skeleton,
  Stack,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, resolvePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IdentityType, routes } from '@blockpulse3/data/shared';
import {
  CompanyAuthorizationStatus,
  CompanyStatus,
  IdentityEntityInfosFragment,
  useGetCompaniesWithDetailQuery,
  useGetCompanyAuthorizationStatusLazyQuery,
  useGetSubscriptionQuery,
  useUpdateSubscriptionBuyerIdentityMutation,
} from '@blockpulse3/graphql/hooks';
import { formatNationality } from '@blockpulse3/helpers';
import {
  ErrorMessage,
  IdentityAvatar,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useErrorToast,
} from '@blockpulse3/ui/commons';
import { useAuthUser, useIdentity } from '@blockpulse3/web-client/auth';

import { schema } from './schema';
import { ISmallCompanyInfos } from './types';

type Props = {
  onClose: () => void;
} & Omit<ResponsiveModalProps, 'children' | 'onClose'>;

export function IdentityChoiceStepModal({ onClose, ...props }: Props): JSX.Element {
  const t = useTranslations();

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);

  const { subscriptionId = '', companyId = '' } = useParams();
  const navigate = useNavigate();

  const { user } = useAuthUser();
  const { identityId: identityIdState } = useIdentity();

  const errorToast = useErrorToast();

  const { value, getRadioProps, getRootProps, setValue } = useRadioGroup({
    defaultValue: '',
  });

  const { register, formState, handleSubmit } = useForm<ISmallCompanyInfos>({
    resolver: yupResolver(schema),
  });

  const [updateSubscriptionBuyerIdentity, { loading: isUpdating }] =
    useUpdateSubscriptionBuyerIdentityMutation();

  const [getCompanyAuthorizationStatus] = useGetCompanyAuthorizationStatusLazyQuery();

  const getSubscriptionReq = useGetSubscriptionQuery({
    variables: {
      subscriptionId,
      identityId: identityIdState,
    },
    fetchPolicy: 'network-only',
    skip: !subscriptionId,
    onError: () => {
      if (companyId) {
        navigate(generatePath(routes.company.href, { companyId }));
      } else {
        navigate(routes.me.href);
      }
      errorToast({ title: t('SubscriptionRetrievalError') });
    },
  });
  const subscription = getSubscriptionReq.data?.subscription;

  const getCompaniesReq = useGetCompaniesWithDetailQuery();
  const companies = getCompaniesReq.data?.companies;

  // set default radio select by matching identityId
  useEffect(() => {
    if (!subscription) return;

    const subscriptionIdentityId = subscription?.buyerIdentity?.id;
    if (!value && subscriptionIdentityId) {
      setValue(subscriptionIdentityId);
    }
  }, [subscription, companies, value, setValue]);

  const handleSubmitRadio = async (): Promise<void> => {
    if (!value) return;

    const identityId = value.toString();
    const companyId = companies?.find((company) => company.identity?.id === identityId)?.id;

    if (!identityId) return;

    await updateSubscriptionBuyerIdentity({
      variables: {
        updateSubscriptionBuyerIdentityInput: {
          identityId,
          subscriptionId,
        },
      },
      onCompleted: async (data) => {
        if (identityId === subscription?.buyerIdentity?.id) {
          await getSubscriptionReq.refetch();
          onClose();
          return;
        }

        const newSubId = data.updateSubscriptionBuyerIdentity.id;
        const subCompanyId =
          data.updateSubscriptionBuyerIdentity.buyerIdentity?.companyIdentity?.id || companyId;

        navigate(
          resolvePath(
            generatePath(routes.subscription.href, { subscriptionId: newSubId }),
            subCompanyId
              ? generatePath(routes.company.href, { companyId: subCompanyId })
              : routes.me.href,
          ).pathname,
        );
      },
    });
  };

  const updateSubscriptionAndRedirect = async (formData: ISmallCompanyInfos): Promise<void> => {
    await updateSubscriptionBuyerIdentity({
      variables: {
        updateSubscriptionBuyerIdentityInput: {
          subscriptionId,
          companyInfo: {
            name: formData.name,
            registrationNumber: formData.registrationNumber,
            email: formData.email,
          },
        },
      },
      onCompleted: (data) => {
        const companyIdentity = data.updateSubscriptionBuyerIdentity.buyerIdentity?.companyIdentity;
        const isRepresentative = companyIdentity?.companyRepresentative?.find(
          (rep) => rep.representativeIdentity?.email === user?.email,
        );
        if (!isRepresentative || !companyIdentity) {
          navigate(routes.me.href);
          return;
        }

        const newSubId = data.updateSubscriptionBuyerIdentity.id;
        const subCompanyId = companyIdentity?.id || '';

        navigate(
          [CompanyStatus.DRAFT, CompanyStatus.EDITING].includes(companyIdentity.status)
            ? generatePath(routes.onboardings.company.edit.full, { companyId: subCompanyId })
            : resolvePath(
                generatePath(routes.subscription.href, { subscriptionId: newSubId }),
                generatePath(routes.company.href, { companyId: subCompanyId }),
              ).pathname,
        );
      },
    });
  };

  const handleCreateCompany = async (formData: ISmallCompanyInfos): Promise<void> => {
    const { data } = await getCompanyAuthorizationStatus({
      variables: {
        registrationNumber: formData.registrationNumber,
      },
    });
    const authorizationStatus = data?.getCompanyAuthorizationStatus;

    if (authorizationStatus !== CompanyAuthorizationStatus.UNKNOWN && !showAlert) {
      const isRepresentative = authorizationStatus === CompanyAuthorizationStatus.AUTHORIZED;
      if (isRepresentative) {
        await updateSubscriptionAndRedirect(formData);
      }

      setShowAlert(!isRepresentative);
    } else {
      await updateSubscriptionAndRedirect(formData);
    }
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLFormElement>): void => {
    if (showAlert && (e.target.id === 'email' || e.target.id === 'registrationNumber')) {
      setShowAlert(false);
    }
  };

  const RadioHeader = ({
    identity,
  }: {
    identity: IdentityEntityInfosFragment | null | undefined;
  }): JSX.Element | null =>
    identity ? (
      <HStack pb="2">
        <Radio {...getRadioProps({ value: identity.id })} />
        <HStack justifyContent="space-between" w="full">
          <Text>
            {identity.type === IdentityType.INDIVIDUAL
              ? t('AsNaturalIdentity')
              : t('AsLegalIdentity')}
          </Text>
          <HStack>
            <IdentityAvatar boxSize="8" src={identity.profilePicture} type={identity.type} />
            <Text fontWeight={600} textAlign="end">
              {identity.name}
            </Text>
          </HStack>
        </HStack>
      </HStack>
    ) : null;

  const RadioDetail = ({ label, value }: { label: string; value: string }): JSX.Element => (
    <HStack alignItems="flex-start" fontSize="sm" justifyContent="space-between" pb="2">
      <Text fontWeight={500} whiteSpace="nowrap">
        {label}
      </Text>
      <Text fontWeight={600} textAlign="end">
        {value}
      </Text>
    </HStack>
  );

  const individual = user?.individualIdentity;
  return (
    <ResponsiveModal onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{t('ChooseIdentity')}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack} spacing="4">
          <Text fontSize="lg" fontWeight="600" size="md">
            {t('HowDoYouWantToInvest')}
          </Text>
          <Stack {...getRootProps()} spacing="4">
            {individual && (
              <Box
                key={individual.identity?.id}
                as="label"
                bg="gray.50"
                borderColor={value === individual.identity?.id ? 'black' : 'gray.200'}
                borderStyle="solid"
                borderWidth="1px"
                boxShadow="unset"
                display="unset"
                layerStyle="container"
                _hover={{
                  cursor: 'pointer',
                }}
              >
                <RadioHeader identity={individual.identity} />
                <RadioDetail label={t('BornOn')} value={individual.birthdate || '-'} />
                <RadioDetail label={t('At')} value={individual.birthplace || '-'} />
                <RadioDetail label={t('ResidesIn')} value={individual.address?.formatted || '-'} />
                <RadioDetail
                  label={t('OfNationality')}
                  value={individual.nationality ? formatNationality(individual.nationality) : '-'}
                />
              </Box>
            )}
            {getCompaniesReq.loading && <Skeleton height="200px" />}
            {companies?.map((company) => (
              <Box
                key={company.identity?.id}
                as="label"
                bg="gray.50"
                borderColor={value === company.identity?.id ? 'black' : 'gray.200'}
                borderStyle="solid"
                borderWidth="1px"
                boxShadow="unset"
                display="unset"
                layerStyle="container"
                _hover={{
                  cursor: 'pointer',
                }}
              >
                <RadioHeader identity={company.identity} />
                <RadioDetail
                  label={t('RegisteredAtRCS')}
                  value={company.registrationEntity || '-'}
                />
                <RadioDetail
                  label={t('HeadquartersIn')}
                  value={company.address?.formatted || '-'}
                />
                <RadioDetail
                  label={t('RepresentedBy')}
                  value={company.signer?.individualIdentity?.name || '-'}
                />
              </Box>
            ))}
          </Stack>
          <Stack spacing="4">
            <HStack
              _hover={{ cursor: 'pointer' }}
              w="fit-content"
              onClick={(): void => {
                setShowForm((crr) => !crr);
                setShowAlert(false);
              }}
            >
              <Icon as={PlusIcon} boxSize="5" color="secondary" />
              <Text color="secondary" fontWeight={600}>
                {t('AddCompany')}
              </Text>
            </HStack>
            {showForm && (
              <form onChange={handleFormChange}>
                <Stack spacing="4">
                  <FormControl isInvalid={!!formState.errors.email}>
                    <FormLabel htmlFor="email">{t('RepresentativeEmail')}</FormLabel>
                    <Input id="email" type="string" {...register('email')} />
                    <ErrorMessage error={formState.errors.email} />
                  </FormControl>
                  <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                    <FormControl isInvalid={!!formState.errors.name}>
                      <FormLabel htmlFor="name">{t('Denomination')}</FormLabel>
                      <Input id="name" type="string" {...register('name')} />
                      <ErrorMessage error={formState.errors.name} />
                    </FormControl>
                    <FormControl isInvalid={!!formState.errors.registrationNumber}>
                      <HStack alignItems="flex-start" spacing="0">
                        <FormLabel htmlFor="registrationNumber">
                          {t('RegistrationNumber')}
                        </FormLabel>
                      </HStack>
                      <Input
                        id="registrationNumber"
                        type="string"
                        {...register('registrationNumber')}
                      />
                      <ErrorMessage error={formState.errors.registrationNumber} />
                    </FormControl>
                  </Stack>
                  {showAlert && (
                    <Alert status="warning">
                      <AlertIcon />
                      <Stack spacing="3">
                        <AlertTitle>{t('AlertUpdateSubscriptionIdentity')}</AlertTitle>
                      </Stack>
                    </Alert>
                  )}
                  <Button
                    isDisabled={isUpdating}
                    w="full"
                    onClick={handleSubmit(handleCreateCompany)}
                  >
                    {showAlert ? t('ValidateAndProposeSubscription') : t('CreateThisCompany')}
                  </Button>
                </Stack>
              </form>
            )}
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button variant="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button isDisabled={showForm} isLoading={isUpdating} onClick={handleSubmitRadio}>
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
