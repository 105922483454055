import { Spinner, Stack, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useInitiateCreditTransferTransactionsMutation } from '@blockpulse3/graphql/hooks';
import { useIdentity } from '@blockpulse3/web-client/auth';

let isPaymentInitiated = false;

export function InitiatePaymentsView(): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const navigate = useNavigate();

  const [initiateCreditTransferTransactions] = useInitiateCreditTransferTransactionsMutation();

  useEffect(() => {
    if (identityId && !isPaymentInitiated) {
      isPaymentInitiated = true;
      initiateCreditTransferTransactions({
        variables: {
          initiateCreditTransferTransactionsInput: {
            identityId,
            subscriptionId,
          },
        },
        onCompleted: (data) => {
          setTimeout(() => {
            const initiatePaymentUrl = data.initiateCreditTransferTransactions;
            window.location.href = initiatePaymentUrl;
          }, 2000);
        },
        onError: () => {
          navigate(routes.closePopup.href);
        },
      });
    }
  }, [identityId, subscriptionId, navigate, initiateCreditTransferTransactions]);

  return (
    <Stack alignItems="center" h="full" justifyContent="center" py="4" spacing="4">
      <Stack
        alignItems="center"
        borderColor="gray.200"
        borderRadius="xl"
        borderStyle="solid"
        borderWidth="2px"
        justifyContent="center"
        p="4"
      >
        <Text color="gray.600" fontWeight="600">
          {t('PreparingCreditTransfer', { nb: subscriptionId ? 1 : 2 })}
        </Text>
        <Spinner color="gray.600" />
      </Stack>
    </Stack>
  );
}
