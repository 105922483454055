import {
  IdentityType,
  SubscriptionFilterType,
  SubscriptionSide,
  SubscriptionStepStatus,
  SubscriptionStepType,
  SubscriptionTab,
  SubscriptionsTabsStatus,
} from '@blockpulse3/data/shared';
import {
  OperationInfosFragment,
  SubscriptionsCountInfosFragment,
  SubscriptionsFilterInput,
} from '@blockpulse3/graphql/hooks';

/* ** STEP Filters ** */
export const subscriptionFilterStep: SubscriptionsFilterInput[] = [
  {
    type: SubscriptionFilterType.STEP,
    name: 'KycKybMissing',
    value: 'KycKybMissing',
    stepType: SubscriptionStepType.IDENTITY_VERIFICATION,
    stepStatuses: [SubscriptionStepStatus.NONE, SubscriptionStepStatus.IN_PROGRESS],
  },
  {
    type: SubscriptionFilterType.STEP,
    name: 'KycKybPending',
    value: 'KycKybPending',
    stepType: SubscriptionStepType.IDENTITY_VERIFICATION,
    stepStatuses: [SubscriptionStepStatus.PENDING],
  },
  {
    type: SubscriptionFilterType.STEP,
    name: 'KycKybRefused',
    value: 'KycKybRefused',
    stepType: SubscriptionStepType.IDENTITY_VERIFICATION,
    stepStatuses: [SubscriptionStepStatus.ERROR],
  },
];

/* ** IDENTITY_TYPE Filters ** */
export const subscriptionFilterIdentity: SubscriptionsFilterInput[] = [
  {
    type: SubscriptionFilterType.IDENTITY_TYPE,
    name: 'IndividualIdentity',
    value: `${IdentityType.INDIVIDUAL}_${SubscriptionSide.BUYER}`,
  },
  {
    type: SubscriptionFilterType.IDENTITY_TYPE,
    name: 'CompanyIdentity',
    value: `${IdentityType.COMPANY}_${SubscriptionSide.BUYER}`,
  },
];

export function getTabsWithCount(count?: SubscriptionsCountInfosFragment): SubscriptionTab[] {
  return [
    {
      name: SubscriptionsTabsStatus.ALL_STATUS,
      count: count?.total || 0,
    },
    {
      name: SubscriptionsTabsStatus.NOT_SIGNED_UP,
      count: count?.invitationSent || 0,
    },
    {
      name: SubscriptionsTabsStatus.SIGNED_UP,
      count: count?.signedUp || 0,
    },
    {
      name: SubscriptionsTabsStatus.PENDING,
      count: count?.pending || 0,
    },
    {
      name: SubscriptionsTabsStatus.COMPLETED,
      count: count?.completed || 0,
    },
    {
      name: SubscriptionsTabsStatus.APPROVED,
      count: count?.approved || 0,
    },
  ].filter((tab) => tab) as SubscriptionTab[];
}

export function getSubscriptionFilterTags(
  operation?: OperationInfosFragment,
): SubscriptionsFilterInput[] {
  if (!operation || !operation?.company?.tags) {
    return [];
  }

  return operation.company.tags.map((tag) => ({
    type: SubscriptionFilterType.TAGS,
    name: tag.name,
    value: tag.id,
  }));
}
