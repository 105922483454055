import { useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { Exceptions, routes } from '@blockpulse3/data/shared';
import {
  useCreateUserMutation,
  useLoginMutation,
  useValidateSpaceInvitationMutation,
  useVerifyEmailMutation,
} from '@blockpulse3/graphql/hooks';
import { CheckEmail, useErrorToast } from '@blockpulse3/ui/commons';
import {
  ISignUpForm,
  SignUpForm,
  signUpFormDefaults,
  useAuthUser,
} from '@blockpulse3/web-client/auth';

type Props = unknown;

/**
 * SpaceInvitationView.
 * Basic user creation form. On submit, handle default mutations then add the user to the space.
 *
 * @returns {JSX.Element}
 */
export function SpaceInvitationView(): JSX.Element {
  const t = useTranslations();

  const [searchParams] = useSearchParams();

  const errorToast = useErrorToast();

  const { refetch } = useAuthUser();

  const [login] = useLoginMutation();
  const [createUser] = useCreateUserMutation();
  const [verifyEmail] = useVerifyEmailMutation();
  const [validateSpaceInvitation] = useValidateSpaceInvitationMutation();

  const [shouldVerifyEmail, setShouldVerifyEmail] = useState<boolean>(false);

  const memberId = searchParams.get('memberId') || '';
  const redirectEmail = searchParams.get('email') || '';
  const loginToken = searchParams.get('loginToken') || '';
  const spaceToken = searchParams.get('spaceToken') || '';

  const isEmailDisabled = !!redirectEmail;

  const handleFormSubmit = async (data: ISignUpForm): Promise<void> => {
    const { email, password, timezone } = data;

    let createUserData = null;

    try {
      const createUserReq = await createUser({
        variables: {
          createUserInput: { email, firstName: '', lastName: '', password, timezone },
        },
      });
      createUserData = createUserReq.data?.createUser;
    } catch (err) {
      const error = err as Error;
      if (error.message === Exceptions.AlreadyUsedEmail) {
        errorToast({ title: t('FieldErrorEmailAlreadyUsed') });
      } else {
        errorToast({ title: t('AccountCreationError') });
      }
      createUserData = null;
    }

    if (!createUserData) {
      return;
    }

    try {
      await verifyEmail({
        variables: {
          verifyEmailInput: { email: createUserData.email, token: loginToken },
        },
      });
    } catch {
      setShouldVerifyEmail(true);
      errorToast({ title: t('AccountCreationError') });
      return;
    }

    try {
      await login({
        variables: {
          loginInput: {
            password,
            timezone,
            email: createUserData.email,
          },
        },
        onCompleted: (res) => {
          if (res.login.access_token) {
            localStorage.setItem('token', res.login.access_token);
          }
        },
      });
    } catch {
      errorToast({ title: 'LoginError' });
      return;
    }

    try {
      await validateSpaceInvitation({
        variables: {
          validateSpaceInvitationInput: { token: spaceToken },
        },
        onCompleted: () => {
          refetch();
        },
      });
    } catch {
      errorToast({ title: t('ValidateSpaceInvitationError') });
      refetch();
      return;
    }
  };

  if (shouldVerifyEmail) {
    return <CheckEmail email={redirectEmail} />;
  }

  if (!redirectEmail || !memberId) {
    /* ** Redirect to default signup if no parameters ** */
    return <Navigate to={routes.signup.href} />;
  }

  return (
    <SignUpForm
      defaultValues={{ ...signUpFormDefaults, email: redirectEmail }}
      isDisabled={isEmailDisabled}
      loading={false}
      onSubmit={handleFormSubmit}
    />
  );
}

export type SpaceInvitationViewProps = Props;
