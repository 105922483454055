import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { FormErrors } from '@blockpulse3/data/shared';
import { useGetOperationLazyQuery } from '@blockpulse3/graphql/hooks';
import {
  DropzoneInput,
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { extendOperationSchema } from './schema';
import { IExtendOperationForm } from './type';

type Props = {
  operationId: string;
} & Omit<ResponsiveModalProps, 'children'>;

export function ExtendFundraisingModal({ operationId, ...props }: Props): JSX.Element {
  const t = useTranslations();
  const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(false);
  const [pvDocumentFile, setPvDocumentFile] = useState<File>();
  const [pvDocumentFileName, setPvDocumentFileName] = useState<{ [key: string]: File }>({});

  const successToast = useSuccessToast();

  const { onClose } = props;

  const [getOperation] = useGetOperationLazyQuery();

  const { register, control, formState, handleSubmit, setError, setValue } =
    useForm<IExtendOperationForm>({
      defaultValues: { duration: 0, pvExerciceFile: '' },
      resolver: yupResolver(extendOperationSchema),
    });

  /* ** Upload file handler ** */
  const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    if (fileRejections.length === 0) {
      setValue('pvExerciceFile', acceptedFiles[0].name);
      setPvDocumentFileName({
        1: new File([acceptedFiles[0].name], acceptedFiles[0].name),
      });
      setPvDocumentFile(acceptedFiles[0]);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError('pvExerciceFile', {
            type: 'custom',
            message: FormErrors.FileTooLarge,
          });
          break;
        }
        default: {
          setError('pvExerciceFile', {
            type: 'custom',
            message: FormErrors.DropzoneDefault,
          });
          break;
        }
      }
    }
  };

  /* ** Method to upload a file using axios ** */
  const handleFileUpload = async (data: IExtendOperationForm): Promise<void> => {
    if (!pvDocumentFile) return;

    const formData = new FormData();
    formData.append('operationId', operationId);
    formData.append('extensionDuration', `${data.duration}`);
    formData.append('document', pvDocumentFile);

    const endpoint = `/fundraisings/${operationId}/extendFundraising`;

    setIsDocumentLoading(true);
    await axios
      .post(process.env['NX_API_CONTROLLER_ENDPOINT'] + endpoint, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        successToast({ title: t('SuccessfulFileUpload') });
        setIsDocumentLoading(false);
        getOperation({
          variables: {
            operationId,
          },
          fetchPolicy: 'network-only',
        });
        onClose();
      })
      .catch(() => {
        setError('pvExerciceFile', {
          type: 'custom',
          message: FormErrors.DropzoneDefault,
        });
        setIsDocumentLoading(false);
      });
  };

  const handleDocumentDelete = (): void => {
    setValue('pvExerciceFile', '');
    setPvDocumentFileName({});
  };

  const handleFormSubmit: SubmitHandler<IExtendOperationForm> = (data: IExtendOperationForm) => {
    handleFileUpload(data);
  };

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent overflow="hidden">
        <ModalHeader>{t('ExtendOperation')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Alert status="info">
              <AlertIcon />
              <Stack spacing="2">
                <AlertTitle>{t('ExtendOperationReminder')}</AlertTitle>
              </Stack>
            </Alert>
            <form id="extend-operation-form" onSubmit={handleSubmit(handleFormSubmit)}>
              <Stack>
                <FormControl isInvalid={!!formState.errors?.duration}>
                  <FormLabel htmlFor="extendDuration">{t('ExtendOperationDuration')}</FormLabel>
                  <InputGroup>
                    <Input id="extendDuration" type="number" {...register('duration')} />
                    <InputRightAddon>{t('DurationUnits.day')}</InputRightAddon>
                  </InputGroup>
                  <ErrorMessage error={formState.errors?.duration} />
                </FormControl>
                <Controller
                  control={control}
                  name="pvExerciceFile"
                  render={(): JSX.Element => (
                    <FormControl>
                      <FormLabel>{t('UploadPVExercice')}</FormLabel>
                      <DropzoneInput
                        accept={{ 'application/pdf': [], 'image/png': [], 'image/jpeg': [] }}
                        files={pvDocumentFileName}
                        isLoading={isDocumentLoading}
                        maxFiles={1}
                        subTitle={t('ImageUpTo5MB')}
                        onDelete={handleDocumentDelete}
                        onDrop={handleFileDrop}
                      />
                      <ErrorMessage error={formState.errors?.pvExerciceFile} />
                    </FormControl>
                  )}
                />
              </Stack>
            </form>
          </Stack>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button form="extend-operation-form" isLoading={isDocumentLoading} type="submit">
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
