import { FormControl, FormLabel, Input, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Select } from 'chakra-react-select';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  CompanyDocumentType,
  FormErrors,
  nationalityOptions,
  noop,
} from '@blockpulse3/data/shared';
import {
  useDeleteCompanyDocumentMutation,
  useGetCompanyDocumentQuery,
  useGetDocumentPdfUrlLazyQuery,
  useGetSpvQuery,
} from '@blockpulse3/graphql/hooks';
import { alpha3CountryOptions } from '@blockpulse3/helpers';
import {
  CONTENT_LOADING,
  DropzoneFiles,
  DropzoneInput,
  ErrorMessage,
  PreviewDocumentModal,
} from '@blockpulse3/ui/commons';

import { useStepFormContext } from '../../../../provider';
import { schemaNaturalShareholder } from '../schema';
import { INaturalShareholderForm } from '../types';

const DEFAULT_PREFERRED_SHARES_PERCENTAGE = 50;

type Props = {
  /* ** Are fields disabled ** */
  isDisabled?: boolean;
  /* ** Default form values, Natural form version ** */
  defaultValues?: INaturalShareholderForm;
  /* ** Callback of the Cancel button ** */
  onCancel: () => void;
  /* ** Callback of the Submit button ** */
  onSubmit: () => void;
};

/**
 * NaturalShareholderInformationsForm.
 * Natural version of the ShareholderForm.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function NaturalShareholderInformationsForm({
  isDisabled = false,
  defaultValues,
  onCancel = noop,
  onSubmit,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nDocumentValues = useTranslations('DocumentValues');

  const [documentPdfUrl, setDocumentPdfUrl] = useState<string | symbol | null>(CONTENT_LOADING);
  const [isLoading, setIsLoading] = useState(false);

  const { companyId = '' } = useParams();
  const { data } = useGetSpvQuery({ variables: { companyId } });

  const previewModal = useDisclosure();

  const company = data?.company;
  const carriedInterest = company?.carriedInterest || 0;

  const [getDocumentPdfUrl] = useGetDocumentPdfUrlLazyQuery();
  /* ** Delete file mutation ** */
  const [deleteDocument] = useDeleteCompanyDocumentMutation();

  /* ** Natural owner form ** */
  const { register, control, formState, setValue, setError, clearErrors, handleSubmit, watch } =
    useForm<INaturalShareholderForm>({
      defaultValues,
      resolver: yupResolver(schemaNaturalShareholder),
    });

  /* ** Natural owner form submit ** */
  const handleFormSubmit: SubmitHandler<INaturalShareholderForm> = useCallback(() => {
    onSubmit();
  }, [onSubmit]);

  const { setCancelHandler, setSubmitHandler } = useStepFormContext();
  const handleStepSubmit = useCallback((): void => {
    handleSubmit(handleFormSubmit)();
  }, [handleSubmit, handleFormSubmit]);

  const handleStepCancel = useCallback((): void => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    setSubmitHandler(handleStepSubmit);
    setCancelHandler(handleStepCancel);
  }, [handleStepSubmit, handleStepCancel, setSubmitHandler, setCancelHandler]);

  /* ** Watcher for the array of UBOs ** */
  const preferredSharesPercentage = watch('preferredSharesPercentages');

  useEffect(() => {
    /* ** Set preferredSharesPercentage to 5 by default if carried interest case is checked ** */
    if (carriedInterest > 0 && !preferredSharesPercentage) {
      setValue('preferredSharesPercentages', DEFAULT_PREFERRED_SHARES_PERCENTAGE);
    }
  }, [carriedInterest, preferredSharesPercentage, setValue]);

  /* ** Fetch previous uploaded residence certificate ** */
  const certificateDocumentReq = useGetCompanyDocumentQuery({
    variables: {
      companyId,
      documentType: CompanyDocumentType.RESIDENCE_CERTIFICATE,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const documentId = data?.getCompanyDocument?.document.id;
      setValue('residenceCertificate', documentId || '');
    },
  });

  /* ** Create file record for the file picker ** */
  const files = useMemo<DropzoneFiles>(() => {
    const certificateDocument = certificateDocumentReq.data?.getCompanyDocument?.document;
    if (certificateDocument) {
      const file = new File([certificateDocument.title], certificateDocument.title);
      return { [certificateDocument.id]: file };
    }
    return {};
  }, [certificateDocumentReq.data]);

  /* ** On input change click or drop ** */
  const handleFileUpload = async (
    files: File[],
    fileRejections: FileRejection[],
  ): Promise<void> => {
    clearErrors('residenceCertificate');
    if (fileRejections.length === 0) {
      const formData = new FormData();
      const certificate = files[0];

      /*
       * Fixed file name for validation
       * See: https://www.rfc-editor.org/rfc/rfc2183#section-2.3
       */
      // setValue('residenceCertificate', 'file.pdf');

      formData.append('document', certificate);
      formData.append('companyId', companyId);
      formData.append('documentType', CompanyDocumentType.RESIDENCE_CERTIFICATE);
      setIsLoading(true);
      await axios
        .post(
          process.env['NX_API_CONTROLLER_ENDPOINT'] + '/companies/uploadCompanyDocument',
          formData,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .catch(() => {
          setError('residenceCertificate', { type: 'custom', message: FormErrors.DropzoneDefault });
          return;
        });
      await certificateDocumentReq.refetch();
      setIsLoading(false);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError('residenceCertificate', { type: 'custom', message: FormErrors.FileTooLarge });
          break;
        }
        default: {
          setError('residenceCertificate', { type: 'custom', message: FormErrors.DropzoneDefault });
          break;
        }
      }
    }
  };

  /* ** On file deletion ** */
  const handleFileDelete = (): void => {
    deleteDocument({
      variables: {
        deleteCompanyDocumentInput: {
          companyId,
          documentType: CompanyDocumentType.RESIDENCE_CERTIFICATE,
        },
      },
      onCompleted: () => {
        certificateDocumentReq.refetch();
        setValue('residenceCertificate', '');
      },
    });
  };

  const handleFilePreview = (): void => {
    if (!files) return;

    setDocumentPdfUrl(CONTENT_LOADING);
    previewModal.onOpen();

    getDocumentPdfUrl({
      variables: {
        documentId: Object.keys(files)[0],
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getDocumentPdfUrl: pdfUrl }) => {
        setDocumentPdfUrl(pdfUrl);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing="8">
        <Stack spacing="4">
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.firstName}>
              <FormLabel htmlFor="firstName">{t('FirstName')}</FormLabel>
              <Input
                id="firstName"
                isDisabled={isDisabled}
                type="text"
                {...register('firstName')}
              />
              <ErrorMessage error={formState.errors?.firstName} />
            </FormControl>
            <FormControl isInvalid={!!formState.errors?.lastName}>
              <FormLabel htmlFor="lastName">{t('LastName')}</FormLabel>
              <Input id="lastName" isDisabled={isDisabled} type="text" {...register('lastName')} />
              <ErrorMessage error={formState.errors?.lastName} />
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.email}>
              <FormLabel htmlFor="email">{t('Email', { nb: 1 })}</FormLabel>
              <Input id="email" isDisabled={isDisabled} type="text" {...register('email')} />
              <ErrorMessage error={formState.errors?.email} />
            </FormControl>
            <Controller
              control={control}
              name="nationality"
              render={({ field }): JSX.Element => (
                <FormControl isInvalid={!!formState.errors?.nationality}>
                  <FormLabel htmlFor="nationalityData">{t('Nationality')}</FormLabel>
                  <Select
                    id="nationalityData"
                    isDisabled={isDisabled}
                    menuPlacement="auto"
                    options={nationalityOptions}
                    {...field}
                  />
                  <ErrorMessage error={formState.errors?.nationality?.value} />
                </FormControl>
              )}
            />
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.birthdate}>
              <FormLabel htmlFor="birthdate">{t('BirthDate')}</FormLabel>
              <Input
                id="birthdate"
                isDisabled={isDisabled}
                type="date"
                {...register('birthdate')}
              />
              <ErrorMessage error={formState.errors?.birthdate} />
            </FormControl>
            <FormControl isInvalid={!!formState.errors?.birthplace}>
              <FormLabel htmlFor="birthplace">{t('BirthCity')}</FormLabel>
              <Input
                id="birthplace"
                isDisabled={isDisabled}
                type="text"
                {...register('birthplace')}
              />
              <ErrorMessage error={formState.errors?.birthplace} />
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.birthCityPostalCode}>
              <FormLabel htmlFor="birthCityPostalCode">{t('BirthPostalCode')}</FormLabel>
              <Input
                id="birthCityPostalCode"
                isDisabled={isDisabled}
                type="string"
                {...register('birthCityPostalCode')}
              />
              <ErrorMessage error={formState.errors?.birthCityPostalCode} />
            </FormControl>
            <Controller
              control={control}
              name={'birthCountry'}
              render={({ field }): JSX.Element => (
                <FormControl isInvalid={!!formState.errors.birthCountry}>
                  <FormLabel htmlFor="birthCountry">{t('BirthCountry')}</FormLabel>
                  <Select
                    id="birthCountry"
                    isDisabled={isDisabled}
                    menuPlacement="auto"
                    options={alpha3CountryOptions}
                    {...field}
                  />
                  <ErrorMessage error={formState.errors?.birthCountry?.value} />
                </FormControl>
              )}
            />
          </Stack>
          <Stack bg="gray.50" borderRadius="md" p="3" spacing="2">
            <Text fontSize="lg" fontWeight="bold">
              {t('Residence')}
            </Text>
            <Stack spacing="4">
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.address?.line}>
                  <FormLabel htmlFor="line">{t('Address', { nb: 1 })}</FormLabel>
                  <Input
                    id="line"
                    isDisabled={isDisabled}
                    type="string"
                    {...register('address.line')}
                  />
                  <ErrorMessage error={formState.errors?.address?.line} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.address?.city}>
                  <FormLabel htmlFor="city">{t('City')}</FormLabel>
                  <Input
                    id="city"
                    isDisabled={isDisabled}
                    type="string"
                    {...register('address.city')}
                  />
                  <ErrorMessage error={formState.errors?.address?.city} />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.address?.postalCode}>
                  <FormLabel htmlFor="zipcode">{t('PostalCode', { nb: 1 })}</FormLabel>
                  <Input
                    id="zipcode"
                    isDisabled={isDisabled}
                    type="string"
                    {...register('address.postalCode')}
                  />
                  <ErrorMessage error={formState.errors?.address?.postalCode} />
                </FormControl>
                <Controller
                  control={control}
                  name="address.country"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors.address?.country}>
                      <FormLabel htmlFor="country">{t('Country')}</FormLabel>
                      <Select isDisabled id="country" menuPlacement="auto" {...field} />
                      <ErrorMessage error={formState.errors.address?.country?.label} />
                    </FormControl>
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack bg="gray.50" borderRadius="md" p="3" spacing="2">
            <Text fontSize="lg" fontWeight="bold">
              {t('Lineage')}
            </Text>
            <Stack spacing="4">
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.fatherFirstName}>
                  <FormLabel htmlFor="fatherFirstName">{t('FatherFirstName')}</FormLabel>
                  <Input
                    id="fatherFirstName"
                    isDisabled={isDisabled}
                    type="text"
                    {...register('fatherFirstName')}
                  />
                  <ErrorMessage error={formState.errors?.fatherFirstName} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.fatherLastName}>
                  <FormLabel htmlFor="fatherLastName">{t('FatherLastName')}</FormLabel>
                  <Input
                    id="fatherLastName"
                    isDisabled={isDisabled}
                    type="text"
                    {...register('fatherLastName')}
                  />
                  <ErrorMessage error={formState.errors?.fatherLastName} />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.motherFirstName}>
                  <FormLabel htmlFor="motherFirstName">{t('MotherFirstName')}</FormLabel>
                  <Input
                    id="motherFirstName"
                    isDisabled={isDisabled}
                    type="text"
                    {...register('motherFirstName')}
                  />
                  <ErrorMessage error={formState.errors?.motherFirstName} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.motherLastName}>
                  <FormLabel htmlFor="motherLastName">{t('MotherMaidenName')}</FormLabel>
                  <Input
                    id="motherLastName"
                    isDisabled={isDisabled}
                    type="text"
                    {...register('motherLastName')}
                  />
                  <ErrorMessage error={formState.errors?.motherLastName} />
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
          <Controller
            control={control}
            name="residenceCertificate"
            render={(): JSX.Element => (
              <FormControl isInvalid={!!formState.errors?.residenceCertificate}>
                <FormLabel>{t('RecentDomicileProof')}</FormLabel>
                <DropzoneInput
                  isPreviewEnabled
                  accept={{ 'application/pdf': [], 'image/png': [], 'image/jpeg': [] }}
                  files={files}
                  isLoading={isLoading}
                  subTitle={t('ImageUpTo5MB')}
                  onDelete={handleFileDelete}
                  onDrop={handleFileUpload}
                  onPreview={handleFilePreview}
                />
                <ErrorMessage error={formState.errors?.residenceCertificate} />
              </FormControl>
            )}
          />
        </Stack>
      </Stack>
      <PreviewDocumentModal
        isOpen={previewModal.isOpen}
        src={documentPdfUrl}
        title={i18nDocumentValues('RESIDENCE_CERTIFICATE')}
        onClose={previewModal.onClose}
      />
    </form>
  );
}
