import { HStack, StackProps } from '@chakra-ui/react';
import React from 'react';

type Props = {
  /* ** Content ** */
  children: React.ReactNode;
} & StackProps;

/**
 * IdentityCard.
 * Simple Stack wrapper to render identity infos.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function IdentityCard({ children, ...props }: Props): JSX.Element {
  return (
    <HStack alignItems="center" spacing="3" {...props}>
      {children}
    </HStack>
  );
}

export type IdentityCardProps = Props;
