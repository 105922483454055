import { DeleteIcon } from '@chakra-ui/icons';
import {
  IconButton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  useDeleteAssetPriceMutation,
  useGetAssetPricesByCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import { DeleteConfirmModal } from '@blockpulse3/ui/commons';

export function SettingsCompanyAssetPriceTable(): JSX.Element {
  const t = useTranslations();
  const { companyId = '' } = useParams();

  const [selectedAssetPriceId, setSelectedAssetPriceId] = useState<string | null>(null);

  const getAssetPricesByCompanyReq = useGetAssetPricesByCompanyQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const [deleteAssetPrice] = useDeleteAssetPriceMutation();

  const confirmRef = useRef(null);
  const deleteModal = useDisclosure();

  const assetsPriceLoading = getAssetPricesByCompanyReq.loading;

  const assetsPrice = getAssetPricesByCompanyReq?.data?.getAssetPricesByCompany;

  const handleDelete = (): void => {
    if (!selectedAssetPriceId) return;

    deleteAssetPrice({
      variables: {
        deleteAssetPriceInput: {
          companyId,
          assetPriceId: selectedAssetPriceId,
        },
      },
      onCompleted: () => {
        getAssetPricesByCompanyReq.refetch();
        setSelectedAssetPriceId(null);
        deleteModal.onClose();
      },
    });
  };

  const handleRowClick = (assetPriceId: string): void => {
    setSelectedAssetPriceId(assetPriceId);
    deleteModal.onOpen();
  };

  return (
    <Stack spacing="2">
      {!assetsPriceLoading && assetsPrice && (
        <Table variant="light">
          <Thead>
            <Tr>
              <Th>{t('Date', { nb: 1 })}</Th>
              <Th>{t('PricePerShare')}</Th>
              <Th>{t('OperationName')}</Th>
              <Th w="10"></Th>
            </Tr>
          </Thead>
          <Tbody>
            {assetsPrice.map((assetPrice) => (
              <Tr
                key={assetPrice.id}
                _hover={{
                  '*>.ibtn-delete svg': { color: 'red.500' },
                }}
              >
                <Td>
                  <Text>{formatDate(assetPrice.date, 'ddd D MMMM YYYY')}</Text>
                </Td>
                <Td isNumeric fontWeight="600" whiteSpace="nowrap">
                  {formatNumberCurrency(assetPrice.price)}
                </Td>
                <Td isNumeric fontWeight="600" whiteSpace="nowrap">
                  {assetPrice?.operation?.name ? assetPrice?.operation?.name : '-'}
                </Td>
                <Td align="right">
                  <IconButton
                    aria-label="add-comment"
                    className="ibtn-delete"
                    icon={<DeleteIcon color="gray.300" />}
                    size="xs"
                    variant="ghost"
                    onClick={(): void => handleRowClick(assetPrice.id)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
      <DeleteConfirmModal
        isOpen={deleteModal.isOpen}
        leastDestructiveRef={confirmRef}
        subtitle={t('DefinitiveAction')}
        title={t('DeleteAssetPrice')}
        onClose={deleteModal.onClose}
        onDelete={handleDelete}
      />
    </Stack>
  );
}
