import { Button, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { ErrorMessage } from '@blockpulse3/ui/commons';

import { schema } from './schema';
import { IForgotPasswordForm } from './types';

type Props = {
  onSubmit: (data: IForgotPasswordForm) => void;
};

/**
 * ForgotPasswordForm.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function ForgotPasswordForm({ onSubmit }: Props): JSX.Element {
  const t = useTranslations();

  const { register, handleSubmit, formState } = useForm<IForgotPasswordForm>({
    resolver: yupResolver(schema),
  });

  const handleFormSubmit: SubmitHandler<IForgotPasswordForm> = (data: IForgotPasswordForm) => {
    onSubmit(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing="4">
        <FormControl isInvalid={!!formState.errors?.email}>
          <FormLabel htmlFor="email">{t('EmailAddress')}</FormLabel>
          <Input id="email" type="text" {...register('email')} />
          <ErrorMessage error={formState.errors?.email} />
        </FormControl>
        <Button type="submit" w="full">
          {t('Send')}
        </Button>
      </Stack>
    </form>
  );
}

export type ForgotPasswordFormProps = Props;
