import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { generatePath, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { LinkButton } from '@blockpulse3/ui/commons';

import { OptionHeader } from './OptionHeader';
import { OptionSummary } from './OptionSummary';

type Props = unknown;

export function OptionView(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  return (
    <Stack spacing="6">
      <LinkButton
        label={t('ReturnToDashboard')}
        route={generatePath(routes.company.href, { companyId })}
      />
      <OptionHeader />
      <Tabs isLazy defaultIndex={0}>
        <TabList>
          <Tab>{t('Summary')}</Tab>
          <Tab isDisabled>{t('Details', { nb: 1 })}</Tab>
          <Tab isDisabled>{t('Document', { nb: 1 })}</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px="0">
            <OptionSummary />
          </TabPanel>
          <TabPanel px="0"></TabPanel>
          <TabPanel px="0"></TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  );
}

export type OptionViewProps = Props;
