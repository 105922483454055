import {
  Badge,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { PencilIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  FundraisingType,
  FundraisingWorkflowType,
  OperationStatus,
  useCorrectSubscriptionInvestAmountMutation,
  useGetSubscriptionQuery,
  useUpdateSubscriptionInvestAmountMutation,
} from '@blockpulse3/graphql/hooks';
import { formatNumberCurrency } from '@blockpulse3/helpers';
import {
  ErrorMessage,
  ResponsiveModal,
  ResponsiveModalFooter,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useInvestStatusBadge } from '@blockpulse3/ui/ui-hooks';
import { useManagerRole } from '@blockpulse3/web-client/auth';

import { schema } from './schema';
import { SubscriptionPanelInvestmentForm } from './types';

type Props = unknown;

/**
 * SubscriptionPanelInvestment.
 * Update invest amount section of the subscription side panel.
 *
 * @returns {JSX.Element}
 */
export function SubscriptionPanelInvestment(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const { subscriptionId = '' } = useParams();
  const isUserAuthorized = useManagerRole({ subscriptionId });

  const { data, loading, error, refetch } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });
  const [updateSubscriptionInvestAmount] = useUpdateSubscriptionInvestAmountMutation();
  const [correctSubscriptionInvestAmount] = useCorrectSubscriptionInvestAmountMutation();

  const { isOpen, onClose, onToggle } = useDisclosure();
  const investStatus = useInvestStatusBadge();

  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);

  const { register, formState, handleSubmit, reset } = useForm<SubscriptionPanelInvestmentForm>({
    resolver: yupResolver(schema),
  });

  if (loading || !data || error) {
    return (
      <Stack px="4" spacing="2">
        <Text color="gray.600" fontWeight="600">
          {t('Investment', { nb: 1 })}
        </Text>
        <Skeleton h="40px" />
      </Stack>
    );
  }

  const handleModalOpen = (): void => {
    reset({ amount: data?.subscription.investAmount });
    onToggle();
  };

  const handleFormSubmit: SubmitHandler<SubscriptionPanelInvestmentForm> = (data): void => {
    setIsUpdateLoading(true);
    const updateMethod = isUserAuthorized
      ? correctSubscriptionInvestAmount
      : updateSubscriptionInvestAmount;
    updateMethod({
      variables: {
        updateSubscriptionInvestAmountInput: {
          subscriptionId,
          amount: data.amount,
        },
      },
      onCompleted: (response) => {
        const updateResponse =
          'correctSubscriptionInvestAmount' in response
            ? response.correctSubscriptionInvestAmount
            : response.updateSubscriptionInvestAmount;
        if (updateResponse) {
          successToast({
            title: t('AmountUpdated'),
          });
          refetch();
          onClose();
        }
        setIsUpdateLoading(false);
      },
      onError: () => {
        errorToast({ title: t('AmountUpdateError') });
        setIsUpdateLoading(false);
      },
    });
  };

  const subscription = data.subscription;

  const investStatusBadge = investStatus(subscription, subscription.operation.status);
  const amount = subscription.investAmount ? formatNumberCurrency(subscription.investAmount) : '-';
  const isAllInSubscriptionWorkflow =
    subscription?.operation?.fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;
  const isCrowdfunding =
    subscription?.operation?.fundraising?.type === FundraisingType.CROWDFUNDING;

  const isEditAmountPossible = isAllInSubscriptionWorkflow || (isUserAuthorized && isCrowdfunding);
  const isEditAmountDisabled =
    (!isUserAuthorized && subscription?.operation.status !== OperationStatus.CLOSED) ||
    (isUserAuthorized && subscription?.operation.status === OperationStatus.FINALIZED);

  return (
    <Stack px="4" spacing="2">
      <HStack>
        <Text color="gray.600" fontWeight="600">
          {t('Investment', { nb: 1 })}
        </Text>
        <Badge colorScheme={investStatusBadge.color} fontSize="xs">
          {investStatusBadge.label}
        </Badge>
      </HStack>
      <Stack spacing="1">
        <HStack alignItems="center" spacing="2">
          <Text fontSize="xl" fontWeight="700">
            {amount}
          </Text>
          {isEditAmountPossible && (
            <>
              <Tooltip hasArrow label={t('ModifyAmount')} placement="top">
                <IconButton
                  aria-label="edit amount"
                  icon={<Icon as={PencilIcon} />}
                  isDisabled={isEditAmountDisabled}
                  size="sm"
                  variant="secondary"
                  onClick={handleModalOpen}
                />
              </Tooltip>
              <ResponsiveModal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{t('ModifyInvestmentAmount')}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <form id="update-invest-amount" onSubmit={handleSubmit(handleFormSubmit)}>
                      <FormControl isInvalid={!!formState.errors.amount}>
                        <FormLabel htmlFor="investAmount">{t('NewAmount')}</FormLabel>
                        <InputGroup id="investAmount">
                          <Input step="0.01" type="number" {...register('amount')} />
                          <InputRightAddon>€</InputRightAddon>
                        </InputGroup>
                        <ErrorMessage error={formState.errors.amount} />
                      </FormControl>
                    </form>
                  </ModalBody>
                  <Divider />
                  <ResponsiveModalFooter>
                    <Button type="button" variant="secondary" onClick={onClose}>
                      {t('Cancel')}
                    </Button>
                    <Button
                      form="update-invest-amount"
                      isLoading={isUpdateLoading}
                      type="submit"
                      variant="primary"
                    >
                      {t('Validate')}
                    </Button>
                  </ResponsiveModalFooter>
                </ModalContent>
              </ResponsiveModal>
            </>
          )}
        </HStack>
        {(subscription?.minimalAmount || subscription?.maximalAmount) && (
          <HStack
            alignSelf="flex-start"
            bgColor="gray.100"
            color="gray.500"
            fontSize="xs"
            px="2"
            py="1"
            rounded="md"
          >
            {!!subscription?.minimalAmount && (
              <>
                <Text fontWeight="600">{t('Minimum')}</Text>
                <Text>{formatNumberCurrency(subscription.minimalAmount)}</Text>
                {!!subscription.maximalAmount && <Text>-</Text>}
              </>
            )}
            {!!subscription?.maximalAmount && (
              <>
                <Text fontWeight="600">{t('Maximum')}</Text>
                <Text>{formatNumberCurrency(subscription.maximalAmount)}</Text>
              </>
            )}
          </HStack>
        )}
      </Stack>
    </Stack>
  );
}

export type SubscriptionPanelInvestmentProps = Props;
