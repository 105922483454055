import { createContext } from 'react';

import { noop } from '@blockpulse3/data/shared';
import { MeQueryHookResult, UserInfosFragment } from '@blockpulse3/graphql/hooks';

export type IUserStatus = 'loading' | 'authenticated' | 'unauthenticated';

/**
 * IUserContext.
 *
 * User context type, providing the user data
 * and a setter
 */
export type IUserContext = Pick<MeQueryHookResult, 'loading' | 'error'> & {
  user: UserInfosFragment | null;
  spaceId?: string;
  spaceName?: string;
  status: IUserStatus;
  setStatus: React.Dispatch<React.SetStateAction<IUserStatus>>;
  refetch: MeQueryHookResult['refetch'] | typeof noop;
};

export const UserContext = createContext<IUserContext>({
  user: null,
  spaceId: undefined,
  spaceName: undefined,
  status: 'loading',
  setStatus: noop,
  error: undefined,
  loading: false,
  refetch: noop,
});
