import {
  Button,
  HStack,
  Icon,
  ResponsiveValue,
  Text,
  UseRadioProps,
  chakra,
  useRadio,
} from '@chakra-ui/react';
import { LibraryIcon } from '@heroicons/react/outline';
import { CheckIcon, PencilIcon, PlusIcon } from '@heroicons/react/solid';
import { useTranslations } from 'use-intl';

import { IntlHoldingMethodTypeValues } from '@blockpulse3/data/locales/types';
import { HoldingMethod, noop } from '@blockpulse3/data/shared';
import { FiscalAccountInfosFragment } from '@blockpulse3/graphql/hooks';

type Props = {
  fiscalAccount?: FiscalAccountInfosFragment;
  isReading?: boolean;
  hasIrPme?: boolean;
  onAddFiscalAccount?: () => void;
  onUpdateFiscalAccount?: () => void;
} & UseRadioProps;

/**
 * HoldingMethodEntry.
 * Simple presentation component, to display holding method informations or to be used as a Radio.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function HoldingMethodEntry({
  fiscalAccount,
  isReading = false,
  hasIrPme = false,
  onAddFiscalAccount = noop,
  onUpdateFiscalAccount = noop,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nHoldingMethodType = useTranslations('HoldingMethodTypeValues');

  const { getInputProps, getRadioProps, htmlProps } = useRadio(props);

  const holdingMethod = props.value;
  const shouldDisplayIrPme = holdingMethod === HoldingMethod.DIRECT && hasIrPme;

  const fiscalAccountType = [HoldingMethod.PEA, HoldingMethod.PEA_PME].includes(
    holdingMethod as HoldingMethod,
  )
    ? holdingMethod
    : undefined;

  const { bankName = '', accountNumber = '', id = '', isComplete = true } = fiscalAccount || {};

  const hasFiscalAccount = !!id;
  const canAddFiscalAccount = fiscalAccountType && !hasFiscalAccount;

  let cursor: ResponsiveValue<'pointer' | 'not-allowed' | 'default'> = 'pointer';
  if (isReading) {
    cursor = 'default';
  } else if (props.isDisabled || canAddFiscalAccount || !isComplete) {
    cursor = 'not-allowed';
  }

  return (
    <chakra.label {...htmlProps} cursor={cursor}>
      <input
        {...getInputProps({})}
        hidden
        disabled={canAddFiscalAccount || isReading || !isComplete}
      />
      <HStack
        background="gray.50"
        boxShadow="base"
        h="50px"
        justifyContent="space-between"
        px="4"
        rounded="md"
        _checked={{
          backgroundColor: 'white',
          borderColor: 'secondary',
          borderWidth: '2px',
        }}
        _disabled={{
          backgroundColor: 'gray.100',
          color: 'gray.500',
        }}
        _hover={{
          backgroundColor: 'gray.100',
        }}
        {...getRadioProps()}
      >
        <HStack spacing="4">
          <Text fontWeight="600">
            {shouldDisplayIrPme
              ? t('IR-PME')
              : i18nHoldingMethodType(holdingMethod as IntlHoldingMethodTypeValues)}
          </Text>
          <HStack spacing="2">
            {bankName && (
              <HStack spacing="1">
                <Icon as={LibraryIcon} boxSize="14px" color="gray.500" />
                <Text color="gray.500" fontSize="sm">
                  {bankName}
                </Text>
              </HStack>
            )}
            <Text fontSize="sm">{accountNumber}</Text>
          </HStack>
        </HStack>
        {canAddFiscalAccount && (
          <Button
            color="secondary"
            fontSize="sm"
            iconSpacing="0.5"
            leftIcon={<Icon as={PlusIcon} boxSize="14px" />}
            variant="link"
            w="fit-content"
            onClick={onAddFiscalAccount}
          >
            {t('AddInfoAboutHoldingMethod', {
              holdingMethod: i18nHoldingMethodType(
                fiscalAccountType as IntlHoldingMethodTypeValues,
              ),
            })}
          </Button>
        )}
        {hasFiscalAccount && !isComplete && (
          <Button
            color="secondary"
            fontSize="sm"
            iconSpacing="0.5"
            leftIcon={<Icon as={PencilIcon} boxSize="14px" />}
            variant="link"
            w="fit-content"
            onClick={onUpdateFiscalAccount}
          >
            {t('CompleteHoldingMethod')}
          </Button>
        )}
        {((hasFiscalAccount && isComplete) || props.isChecked) && (
          <HStack alignItems="baseline">
            {hasFiscalAccount && isComplete && (
              <Button boxSize="18px" minW="auto" variant="unstyled" onClick={onUpdateFiscalAccount}>
                <Icon as={PencilIcon} boxSize="18px" color="gray.500" float="right" />
              </Button>
            )}
            {props.isChecked && <Icon as={CheckIcon} boxSize="18px" />}
          </HStack>
        )}
      </HStack>
    </chakra.label>
  );
}

export type FiscalAccountEntryProps = Props;
