import {
  GetMemberRoleOnRessourceInput,
  MemberRole,
  useGetMemberRoleOnRessourceQuery,
} from '@blockpulse3/graphql/hooks';

import { useAuthUser } from './user';

/**
 * useManagerRole.
 * Perform query to get the user role from a managed identity by a space. Check if the role is
 * included in the ones passed in arguments.
 *
 * @param {GetMemberRoleOnRessourceInput} getMemberRoleOnRessourceInput
 * @param {MemberRole[]} roles
 * @returns {boolean}
 */
export function useManagerRole(
  getMemberRoleOnRessourceInput: GetMemberRoleOnRessourceInput,
  roles: MemberRole[] = [MemberRole.COMPLIANCE_OFFICER],
): boolean {
  const { user } = useAuthUser();

  const { data, loading, error } = useGetMemberRoleOnRessourceQuery({
    variables: { getMemberRoleOnRessourceInput },
    skip: !user || user?.isSuperAdmin,
  });

  if (user?.isSuperAdmin) {
    return true;
  }

  if (loading) {
    return false;
  }

  if (!data?.getMemberRoleOnRessource || error) {
    return false;
  }

  const role = data?.getMemberRoleOnRessource;

  return roles.includes(role);
}
