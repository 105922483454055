import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Skeleton,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Select } from 'chakra-react-select';
import { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMatch } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { nationalityOptions, routes } from '@blockpulse3/data/shared';
import { IdentityVerificationStatus } from '@blockpulse3/graphql/hooks';
import { alpha3CountryOptions } from '@blockpulse3/helpers';
import {
  ErrorMessage,
  InputImage,
  NotAllowedCard,
  UserIdentityAvatar,
  VerificationBadge,
  useErrorToast,
} from '@blockpulse3/ui/commons';

import { useAuthUser, useManagedIndividual } from '../../../providers';
import { IdentityVerification } from '../../IdentityVerification';
import { fmtProfile } from '../utils';
import { profileDefaultValues, schema } from './schema';
import { IUpdateProfileForm } from './types';

type Props = {
  onSubmit: (data: IUpdateProfileForm) => void;
};

/**
 * UpdateProfileForm.
 * Form to update the profile infos of the user.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function UpdateProfileForm({ onSubmit }: Props): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const { user: authUser } = useAuthUser();
  const { individual, refetch } = useManagedIndividual();
  const defaultValues = individual ? fmtProfile(individual) : profileDefaultValues;
  const isManaging = useMatch(routes.manage.href + '/*');

  /* ** Update profile form ** */
  const { register, control, formState, handleSubmit } = useForm<IUpdateProfileForm>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  /* ** Image upload state ** */
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  /* ** Control if form is "touched" (!= changed) ** */
  const [isTouched, setIsTouched] = useState<boolean>(false);

  /* ** Submit button disabled state ** */
  const isSubmitDisabled = useMemo(() => {
    return !formState.isValid || !isTouched;
  }, [isTouched, formState.isValid]);

  const verificationStatus = useMemo(() => {
    switch (individual?.kycVerificationStatus) {
      case IdentityVerificationStatus.APPROVED:
        return 'valid';
      case IdentityVerificationStatus.PROCESSING:
        return 'in-progress';
      case IdentityVerificationStatus.PENDING:
        return 'pending';
      case IdentityVerificationStatus.REFUSED:
        return 'error-identity';
      default:
        return 'none';
    }
  }, [individual?.kycVerificationStatus]);

  if (!authUser) {
    return <NotAllowedCard h="250px" />;
  }

  if (!individual) {
    return <Skeleton h="250px" />;
  }

  const handleFormTouched = (): void => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const handleFormSubmit: SubmitHandler<IUpdateProfileForm> = (data: IUpdateProfileForm) => {
    onSubmit(data);
  };

  const handleProfilePictureUpload = async (files: FileList): Promise<void> => {
    if (files.length > 0 && individual.identity?.id) {
      setIsImageUploading(true);

      const formData = new FormData();
      formData.append('identityId', individual.identity.id);
      formData.append('document', files[0]);

      await axios
        .post(
          process.env['NX_API_CONTROLLER_ENDPOINT'] + '/identities/uploadProfilePicture',
          formData,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then(() => {
          refetch();
          setIsImageUploading(false);
        })
        .catch(() => {
          errorToast({ title: t('FileUploadError') });
          setIsImageUploading(false);
        });
    }
  };

  const isUserIndividual = authUser.individualIdentity?.id === individual.id;
  const isVerified = individual.identity?.isVerified;
  const isFormDisabled = isManaging ? false : isVerified;

  return (
    <form onChange={handleFormTouched} onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '5', lg: '8' }}>
        <Stack
          alignItems={{ base: 'center', lg: 'flex-start' }}
          direction={{ base: 'row', lg: 'column' }}
          flexBasis={{ lg: 250 }}
        >
          <Text fontSize="lg" fontWeight="medium">
            {t('MyProfile')}
          </Text>
          {isVerified && <VerificationBadge status="valid" title={t('ProfileVerified')} />}
        </Stack>
        <Card maxW={{ lg: '3xl' }} variant="divider-bottom" w="full">
          <CardBody>
            <Stack spacing="6">
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.profilePicture}>
                  <FormLabel>{t('ProfilePicture')}</FormLabel>
                  <HStack spacing="4">
                    <Box position="relative">
                      {isImageUploading && (
                        <Spinner
                          color="whiteAlpha.700"
                          h="50%"
                          left="25%"
                          position="absolute"
                          speed="1s"
                          thickness="4px"
                          top="25%"
                          w="50%"
                          zIndex="1"
                        />
                      )}
                      <UserIdentityAvatar boxSize="16" src={individual.identity?.profilePicture} />
                    </Box>
                    <InputImage<IUpdateProfileForm>
                      control={control}
                      isDisabled={isImageUploading}
                      name="profilePicture"
                      onUpload={handleProfilePictureUpload}
                    />
                  </HStack>
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <Controller
                  control={control}
                  name="gender"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors?.gender}>
                      <FormLabel htmlFor="gender">{t('CivilStatus')}</FormLabel>
                      <RadioGroup
                        defaultValue={defaultValues.gender}
                        id="gender"
                        isDisabled={isFormDisabled}
                        {...field}
                      >
                        <Stack
                          direction={{ base: 'column', md: 'row' }}
                          spacing={{ base: 2, md: 4 }}
                        >
                          <Radio value="M">{t('Mr')}</Radio>
                          <Radio value="F">{t('Mrs')}</Radio>
                        </Stack>
                      </RadioGroup>
                      <ErrorMessage error={formState.errors?.gender} />
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.firstName}>
                  <FormLabel htmlFor="firstName">{t('FirstName')}</FormLabel>
                  <Input
                    id="firstName"
                    isDisabled={isFormDisabled}
                    type="text"
                    {...register('firstName')}
                  />
                  <ErrorMessage error={formState.errors?.firstName} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.lastName}>
                  <FormLabel htmlFor="lastName">{t('LastName')}</FormLabel>
                  <Input
                    id="lastName"
                    isDisabled={isFormDisabled}
                    type="text"
                    {...register('lastName')}
                  />
                  <ErrorMessage error={formState.errors?.lastName} />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors.birthdate}>
                  <FormLabel htmlFor="birthdate">{t('BirthDate')}</FormLabel>
                  <Input
                    id="birthdate"
                    type="date"
                    {...register('birthdate')}
                    isDisabled={isFormDisabled}
                  />
                  <ErrorMessage error={formState.errors?.birthdate} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.birthplace}>
                  <FormLabel htmlFor="birthplace">{t('BirthCity')}</FormLabel>
                  <Input
                    id="birthplace"
                    type="text"
                    {...register('birthplace')}
                    isDisabled={isFormDisabled}
                  />
                  <ErrorMessage error={formState.errors?.birthplace} />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.birthCityPostalCode}>
                  <FormLabel htmlFor="birthCityPostalCode">{t('BirthPostalCode')}</FormLabel>
                  <Input
                    id="birthCityPostalCode"
                    isDisabled={isFormDisabled && !!individual?.birthCityPostalCode}
                    type="string"
                    {...register('birthCityPostalCode')}
                  />
                  <ErrorMessage error={formState.errors?.birthCityPostalCode} />
                </FormControl>
                <Controller
                  control={control}
                  name={'birthCountry'}
                  render={({ field: { onChange, ...rest } }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors.birthCountry}>
                      <FormLabel htmlFor="birthCountry">{t('BirthCountry')}</FormLabel>
                      <Select
                        id="birthCountry"
                        isDisabled={isFormDisabled && !!individual?.birthCountry}
                        menuPlacement="auto"
                        options={alpha3CountryOptions}
                        onChange={(value): void => {
                          onChange(value);
                          handleFormTouched();
                        }}
                        {...rest}
                      />
                      <ErrorMessage error={formState.errors?.birthCountry?.value} />
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <Controller
                  control={control}
                  name="nationality"
                  render={({ field: { onChange, ...rest } }): JSX.Element => (
                    <FormControl
                      isInvalid={!!formState.errors?.nationality}
                      w={{ base: 'full', md: '49%' }}
                    >
                      <FormLabel htmlFor="nationality">{t('Nationality')}</FormLabel>
                      <Select
                        id="nationality"
                        isDisabled={isFormDisabled}
                        options={nationalityOptions}
                        onChange={(value): void => {
                          onChange(value);
                          handleFormTouched();
                        }}
                        {...rest}
                      />
                    </FormControl>
                  )}
                />
              </Stack>
            </Stack>
          </CardBody>
          <Divider />
          <CardFooter justifyContent="flex-end">
            <IdentityVerification
              isDisabled={!isUserIndividual}
              refetchData={refetch}
              status={verificationStatus}
            />
          </CardFooter>
          <Divider />
          <CardFooter justifyContent="flex-end">
            <Button disabled={isSubmitDisabled} type="submit">
              {t('Save')}
            </Button>
          </CardFooter>
        </Card>
      </Stack>
    </form>
  );
}

export type UpdateProfileFormProps = Props;
