import { Alert, AlertIcon, AlertTitle, Link, Stack } from '@chakra-ui/react';
import { Link as ReactRouterLink, generatePath, resolvePath } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { SpvStatus } from '@blockpulse3/graphql/hooks';

type Props = {
  companyId: string;
  spvStatus: SpvStatus | null | undefined;
};

/**
 * SPVMatriculationStatusAlert.
 *
 * @returns {JSX.Element}
 */
export function SPVMatriculationStatusAlert({ companyId, spvStatus }: Props): JSX.Element {
  const t = useTranslations();

  const isEditing = spvStatus === SpvStatus.EDITING;
  const editSpvPath = resolvePath(
    generatePath(routes.onboardings.spv.edit.href, { companyId }),
    routes.onboardings.spv.href,
  ).pathname;

  return (
    <Alert status="info">
      <AlertIcon />
      <Stack spacing="0">
        <AlertTitle>
          {isEditing
            ? t.rich('ResumeSpvConfigurationAlert', {
                link: (chunks) => (
                  <Link
                    as={ReactRouterLink}
                    fontWeight="600"
                    textDecoration="underline"
                    to={editSpvPath}
                  >
                    {chunks}
                  </Link>
                ),
              })
            : t.rich('ResumeSpvMatriculationAlert', {
                link: (chunks) => (
                  <Link
                    as={ReactRouterLink}
                    fontWeight="600"
                    textDecoration="underline"
                    to={
                      resolvePath(routes.onboardings.spv.edit.matriculation.href, editSpvPath)
                        .pathname
                    }
                  >
                    {chunks}
                  </Link>
                ),
              })}
        </AlertTitle>
      </Stack>
    </Alert>
  );
}

export type SPVMatriculationStatusAlertProps = Props;
