import * as yup from 'yup';

import { requiredEmailField, requiredStringField } from '@blockpulse3/data/shared';
import { MemberRole } from '@blockpulse3/graphql/hooks';

import { SpaceMemberForm } from './types';

export const schema: yup.ObjectSchema<SpaceMemberForm> = yup.object({
  firstName: requiredStringField,
  lastName: requiredStringField,
  email: requiredEmailField,
  role: yup.object({
    label: requiredStringField,
    value: yup.mixed<MemberRole>().oneOf(Object.values(MemberRole)).required(),
  }),
});

export const spaceMemberFormDefaultValues: SpaceMemberForm = {
  firstName: '',
  lastName: '',
  email: '',
  role: {
    label: MemberRole.COLLABORATOR,
    value: MemberRole.COLLABORATOR,
  },
};
