import { Icon, IconButton, Input, InputGroup, InputRightElement, Stack } from '@chakra-ui/react';
import { SearchIcon, XCircleIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { SubscriptionTab, SubscriptionsTabsStatus } from '@blockpulse3/data/shared';
import { SubscriptionsFilterInput } from '@blockpulse3/graphql/hooks';

import { SecondaryOperationTransactionTableFilters } from './SecondaryOperationTransactionTableFilters';

type Props = {
  /* ** List of tab names and other properties ** */
  tabs: SubscriptionTab[];
  /* ** Controlled active tab index ** */
  activeTabIndex: number;
  /* ** Active filters ** */
  filters: SubscriptionsFilterInput[];
  /* ** Callback on filter update ** */
  onFiltersChange: (value: SubscriptionsFilterInput[]) => void;
  /* ** Callback on search submit ** */
  onSearchInputSubmit: (value: string) => void;
};

export function SecondaryOperationTransactionControls({
  filters,
  tabs,
  activeTabIndex,
  onFiltersChange,
  onSearchInputSubmit,
}: Props): JSX.Element {
  const t = useTranslations();

  const [searchInput, setSearchInput] = useState<string>('');

  /* ** Filter modal button condition ** */
  const showFilters = [
    SubscriptionsTabsStatus.ALL_STATUS,
    SubscriptionsTabsStatus.PENDING,
  ].includes(tabs[activeTabIndex].name);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputReset = (): void => {
    setSearchInput('');
    onSearchInputSubmit('');
  };

  const handleSearchInputSubmit = (): void => {
    onSearchInputSubmit(searchInput);
  };

  const handleFiltersChange = (value: SubscriptionsFilterInput[]): void => {
    onFiltersChange(value);
  };

  return (
    <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between">
      <Stack direction={{ base: 'column', md: 'row' }}>
        <InputGroup width={{ base: 'full', md: '300px' }}>
          <Input
            paddingRight="80px"
            placeholder={t('Search')}
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={(e): void => {
              if (e.key === 'Enter') {
                handleSearchInputSubmit();
              }
            }}
          />
          <InputRightElement
            flexDirection="row-reverse"
            justifyContent="space-between"
            paddingX="1"
            width="80px"
          >
            <IconButton
              aria-label="search-database"
              icon={<Icon as={SearchIcon} />}
              size="sm"
              variant="secondary"
              onClick={handleSearchInputSubmit}
            />
            {!!searchInput && (
              <IconButton
                aria-label="reset"
                icon={<Icon as={XCircleIcon} />}
                size="sm"
                variant="ghost"
                onClick={handleSearchInputReset}
              />
            )}
          </InputRightElement>
        </InputGroup>
        {showFilters && (
          <SecondaryOperationTransactionTableFilters
            defaultValue={filters}
            onSubmit={handleFiltersChange}
          />
        )}
      </Stack>
    </Stack>
  );
}

export type SecondaryOperationTransactionControlsProps = Props;
