import { Spinner, Stack, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useFinalizeOnboardingMutation } from '@blockpulse3/graphql/hooks';
import { useIdentity } from '@blockpulse3/web-client/auth';

let isOnboardingFinalizationStarted = false;

export function FinalizeSwanOnboarding(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const { identityId } = useIdentity();

  const navigate = useNavigate();

  const [finalizeOnboarding] = useFinalizeOnboardingMutation();

  useEffect(() => {
    if (identityId && !isOnboardingFinalizationStarted) {
      isOnboardingFinalizationStarted = true;
      finalizeOnboarding({
        variables: {
          identityId,
        },
        onCompleted: () => {
          setTimeout(() => {
            navigate(routes.closePopup.href);
          }, 2000);
        },
        onError: () => {
          navigate(routes.closePopup.href);
        },
      });
    }
  }, [identityId, companyId, navigate, finalizeOnboarding]);

  return (
    <Stack alignItems="center" h="full" justifyContent="center" py="4" spacing="4">
      <Stack
        alignItems="center"
        borderColor="gray.200"
        borderRadius="xl"
        borderStyle="solid"
        borderWidth="2px"
        justifyContent="center"
        p="4"
      >
        <Text color="gray.600" fontWeight="600">
          {t('BankAccountFinalization')}
        </Text>
        <Spinner color="gray.600" />
      </Stack>
    </Stack>
  );
}
