import {
  Button,
  Divider,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { MergedHoldingMethodStep } from '@blockpulse3/graphql/hooks';
import {
  ResponsiveModal,
  ResponsiveModalFooter,
  ResponsiveModalProps,
} from '@blockpulse3/ui/commons';

import { SubscriptionHoldingMethod } from '../../../SubscriptionHoldingMethod';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
  subscriptionStep: MergedHoldingMethodStep;
} & Omit<ResponsiveModalProps, 'children'>;

export function HoldingMethodModal({
  onClose,
  onSubmit,
  subscriptionStep,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();

  const [isHoldingMethodLoading, setIsHoldingMethodLoading] = useState(false);

  return (
    <ResponsiveModal onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="xl" p="4">
          {t('ChooseHoldingMethod')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack}>
          <SubscriptionHoldingMethod setIsLoading={setIsHoldingMethodLoading} />
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button isDisabled={isHoldingMethodLoading} onClick={onSubmit}>
            {t('Validate')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}
