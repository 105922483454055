import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  EmailVerificationStatus,
  TwoFactorStatus,
  useLoginMutation,
} from '@blockpulse3/graphql/hooks';
import { CheckEmail, useErrorToast } from '@blockpulse3/ui/commons';

import { useAuthUser } from '../../providers';
import { Check2FA } from '../Check2FA';
import { ILoginForm, LoginForm, LoginFormDefaults } from './LoginForm';

type Props = unknown;

export function LoginView(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();

  const [loginMutation, { data, loading }] = useLoginMutation();

  const { refetch } = useAuthUser();
  const [searchParams] = useSearchParams();
  const [signUpEmail, setSignUpEmail] = useState<string>('');

  const [loginFormReset, setLoginFormReset] = useState<ILoginForm>(LoginFormDefaults);

  const redirectEmail = searchParams.get('email') || '';

  const handleLoginSubmit = (loginData: ILoginForm): void => {
    setLoginFormReset(loginData);
    loginMutation({
      variables: {
        loginInput: {
          email: loginData.email.toLowerCase(),
          password: loginData.password,
          timezone: loginData.timezone,
        },
      },
      onCompleted: (data) => {
        setSignUpEmail(loginData.email);
        if (data?.login?.access_token) {
          localStorage.setItem('token', data.login.access_token);
          refetch();
        }
      },
      onError: () => {
        errorToast({ title: t('IncorrectPasswordOrEmail') });
      },
    });
  };

  const handleResetLogin = (): void => {
    handleLoginSubmit(loginFormReset);
  };

  if (data?.login?.process_step === EmailVerificationStatus.UNVERIFIED) {
    return <CheckEmail email={signUpEmail} />;
  }

  if (data?.login?.two_factor_status === TwoFactorStatus.UNVERIFIED) {
    return <Check2FA email={signUpEmail} onReset={handleResetLogin} />;
  }

  return (
    <LoginForm
      defaultValues={{ ...LoginFormDefaults, email: redirectEmail }}
      loading={loading}
      onSubmit={handleLoginSubmit}
    />
  );
}

export type LoginViewProps = Props;
