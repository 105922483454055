import { Icon, Stack, Text } from '@chakra-ui/react';
import { MailIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

export function InvitationsTableEmpty(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack h="100px" layerStyle="emptyState">
      <Icon as={MailIcon} boxSize="40px" color="gray.500" />
      <Text>{t('NoInvitation')}</Text>
    </Stack>
  );
}
