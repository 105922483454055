import { Button, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Select } from 'chakra-react-select';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { alpha3CountryOptions } from '@blockpulse3/helpers';
import { ErrorMessage } from '@blockpulse3/ui/commons';

import { addressFormDefaultValues, schema } from './schema';
import { IAddressForm } from './types';

type Props = {
  defaultValues?: IAddressForm;
  onSubmit: (data: IAddressForm) => void;
};

export function AddressForm({
  defaultValues = addressFormDefaultValues,
  onSubmit,
}: Props): JSX.Element {
  const t = useTranslations();

  const { register, control, formState, handleSubmit, reset } = useForm<IAddressForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleFormSubmit: SubmitHandler<IAddressForm> = (data: IAddressForm) => {
    onSubmit(data);
  };

  useEffect(() => {
    reset({ ...defaultValues });
  }, [reset, defaultValues]);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing="6">
        <Stack spacing="4">
          <FormControl isInvalid={!!formState.errors?.line}>
            <FormLabel htmlFor="line">{t('Address', { nb: 1 })}</FormLabel>
            <Input id="line" type="text" {...register('line')} />
            <ErrorMessage error={formState.errors?.line} />
          </FormControl>
          <FormControl isInvalid={!!formState.errors?.city}>
            <FormLabel htmlFor="city">{t('City')}</FormLabel>
            <Input id="city" type="text" {...register('city')} />
            <ErrorMessage error={formState.errors?.city} />
          </FormControl>
          <FormControl isInvalid={!!formState.errors?.postalCode}>
            <FormLabel htmlFor="postalCode">{t('PostalCode', { nb: 1 })}</FormLabel>
            <Input id="postalCode" type="text" {...register('postalCode')} />
            <ErrorMessage error={formState.errors?.postalCode} />
          </FormControl>
          <Controller
            control={control}
            name="country"
            render={({ field }): JSX.Element => (
              <FormControl isInvalid={!!formState.errors.country}>
                <FormLabel htmlFor="country">{t('Country')}</FormLabel>
                <Select id="country" options={alpha3CountryOptions} {...field} />
                <ErrorMessage error={formState.errors.country?.value} />
              </FormControl>
            )}
          />
        </Stack>
        <Button type="submit">{t('Validate')}</Button>
      </Stack>
    </form>
  );
}

export type AddressFormProps = Props;
