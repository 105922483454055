import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DownloadIcon } from '@heroicons/react/solid';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  useGenerateCaptableUrlMutation,
  useGetCompanyCaptableQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { ShareholderTable } from '../ShareholderTable';

type Props = unknown;

/**
 * CapTableShareholders.
 *
 * @returns {JSX.Element}
 */
export function CapTableShareholders(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const captableReq = useGetCompanyCaptableQuery({ variables: { companyId } });

  const [generateCaptableUrl, { loading: isCaptableLoading }] = useGenerateCaptableUrlMutation();

  const handleDownloadCaptable = (): void => {
    if (!companyId) return;

    generateCaptableUrl({
      variables: { companyId },
      onCompleted: (data) => {
        window.open(data?.generateCaptableUrl, '_blank');
      },
    });
  };

  if (captableReq.loading) {
    return (
      <Skeleton>
        <Card h="270px" />
      </Skeleton>
    );
  }

  if (captableReq.error) {
    return <ErrorQueryCard />;
  }

  const capTable = captableReq.data?.company?.capTable;
  const shareholders = capTable?.shareholders || [];

  return (
    <Card>
      <CardHeader
        as={Stack}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Heading size="md">{t('Shareholder', { nb: shareholders.length })}</Heading>
        {companyId && shareholders.length > 0 && (
          <Button
            isLoading={isCaptableLoading}
            loadingText={t('DocumentGenerationInProgress')}
            variant="secondary"
            width={{ base: 'fit-content' }}
            onClick={(): void => handleDownloadCaptable()}
          >
            <HStack>
              <Text>{t('ExportAction')}</Text>
              <Icon as={DownloadIcon} boxSize="5" color="secondary" />
            </HStack>
          </Button>
        )}
      </CardHeader>
      <CardBody>
        <ShareholderTable capTable={capTable} />
      </CardBody>
    </Card>
  );
}

export type CapTableShareholdersProps = Props;
