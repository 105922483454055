import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { DocumentTextIcon } from '@heroicons/react/outline';
import { EyeIcon } from '@heroicons/react/solid';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { FormErrors, OperationDocumentType, noop } from '@blockpulse3/data/shared';
import {
  DocumentSignatureStepType,
  useDeleteSubscriptionTemplateMutation,
  useGetOperationQuery,
  usePreviewMockSubscriptionTemplateLazyQuery,
  useValidateSubscriptionTemplateMutation,
} from '@blockpulse3/graphql/hooks';
import { getURLObject } from '@blockpulse3/helpers';
import {
  CONTENT_LOADING,
  DropzoneInput,
  ErrorMessage,
  PreviewDocumentModal,
} from '@blockpulse3/ui/commons';

import { INewOpportunityOperationDocumentationForm, OpportunityDocumentationType } from './types';

type Props = {
  /* ** Callback on submit error ** */
  onSubmitError?: () => void;
  /* ** Callback on submit completed ** */
  onSubmitCompleted?: () => void;
  /* ** Callback on file upload cancel ** */
  onCancel: () => void;
};

/**
 * NewOpportunityOperationDocumentation.
 * Documentation fundraising step form used in both Private and Crowdfunding funnels.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function NewOpportunityOperationDocumentation({
  onSubmitCompleted = noop,
  onSubmitError = noop,
  onCancel = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const [isFileLoading, setIsFileLoading] = useState<boolean>(false);
  const [templateUrl, setTemplateUrl] = useState<string | symbol | null>(CONTENT_LOADING);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { operationId = '', companyId = '' } = useParams();

  const [validateSubscriptionTemplate] = useValidateSubscriptionTemplateMutation();

  const { data, refetch, loading } = useGetOperationQuery({
    variables: {
      operationId,
    },
    skip: !operationId,
  });

  const operation = data?.operation;

  /* ** Find the corresponding step ** */
  const documentSignatureStep = operation?.subscriptionSteps?.find(
    (step) => step.__typename === 'DocumentSignatureStepType',
  ) as DocumentSignatureStepType;

  const [deleteTemplate] = useDeleteSubscriptionTemplateMutation();
  const [previewTemplate] = usePreviewMockSubscriptionTemplateLazyQuery();

  const { control, handleSubmit, formState, setError, setValue, clearErrors } =
    useForm<INewOpportunityOperationDocumentationForm>({
      defaultValues: { documentationType: OpportunityDocumentationType.DEFAULT },
    });

  const fileName = documentSignatureStep?.templateName
    ? {
        [documentSignatureStep.id]: new File(
          [documentSignatureStep.templateName],
          documentSignatureStep.templateName,
        ),
      }
    : {};

  /* ** Method to upload a file using axios ** */
  const handleFileUpload = async (acceptedFiles: File[]): Promise<void> => {
    if (!operationId) return;

    const formData = new FormData();
    formData.append('operationId', operationId);
    formData.append('template', acceptedFiles[0]);
    formData.append('documentType', OperationDocumentType.TERM_SHEET);
    setIsFileLoading(true);
    await axios
      .post(
        process.env['NX_API_CONTROLLER_ENDPOINT'] + '/operations/importSubscriptionTemplate',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .catch(() => {
        setError('templateFile', { type: 'custom', message: FormErrors.DropzoneInvalidTemplate });
      });
    refetch();
    setIsFileLoading(false);
  };

  /* ** Upload file handler ** */
  const handleFileDrop = (acceptedFiles: File[], fileRejections: FileRejection[]): void => {
    clearErrors('templateFile');
    if (fileRejections.length === 0) {
      handleFileUpload(acceptedFiles);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError('templateFile', { type: 'custom', message: FormErrors.FileTooLarge });
          break;
        }
        default: {
          setError('templateFile', { type: 'custom', message: FormErrors.DropzoneDefault });
          break;
        }
      }
    }
  };

  /* ** Delete file handler ** */
  const handleFileDelete = (): void => {
    if (operationId) {
      deleteTemplate({
        variables: {
          deleteSubscriptionTemplateInput: {
            operationId,
            documentType: OperationDocumentType.TERM_SHEET,
          },
        },
        onCompleted: () => {
          setValue('templateFile', undefined);
          refetch();
        },
        onError: () => {
          setError('templateFile', { type: 'custom', message: FormErrors.DropzoneDefault });
        },
      });
    }
  };

  const handleFormSubmit: SubmitHandler<INewOpportunityOperationDocumentationForm> = (
    data,
  ): void => {
    if (data.documentationType === OpportunityDocumentationType.TEMPLATE && !data.templateFile) {
      setError('templateFile', { type: 'custom', message: FormErrors.FileRequired });
    } else {
      const isTemplateEnabled = data.documentationType === OpportunityDocumentationType.TEMPLATE;
      validateSubscriptionTemplate({
        variables: {
          validateSubscriptionTemplateInput: {
            operationId,
            isTemplateEnabled,
            documentType: OperationDocumentType.TERM_SHEET,
          },
        },
        onCompleted: () => {
          refetch();
          onSubmitCompleted();
        },
        onError: () => {
          onSubmitError();
        },
      });
    }
  };

  const handleFormCancel = (): void => {
    if (!companyId) {
      return;
    }
    onCancel();
  };

  const handleFilePreview = (mode: 'Default' | 'File'): void => {
    if (!operation) return;

    setTemplateUrl(CONTENT_LOADING);
    onOpen();

    const templateUrl = mode === 'File' ? null : operation.termSheetTemplatePath;

    previewTemplate({
      variables: {
        previewSubscriptionTemplateInput: {
          operationId: operation.id,
          documentType: OperationDocumentType.TERM_SHEET,
          templateUrl,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted({ previewMockSubscriptionTemplate: pdfURL }) {
        const objURL = getURLObject(pdfURL, 'application/pdf');
        setTemplateUrl(objURL);
      },
    });
  };

  /* ** Set value of template file if exist ** */
  useEffect(() => {
    if (documentSignatureStep?.templateName) {
      setValue('templateFile', documentSignatureStep.id);
    }
    if (documentSignatureStep?.isTemplateEnabled) {
      setValue('documentationType', OpportunityDocumentationType.TEMPLATE);
    }
  }, [documentSignatureStep, setValue]);

  return (
    <>
      <Card variant="divider-top">
        <CardHeader py="6">
          <Stack spacing="4">
            <Heading size="lg">{t('Documentation')}</Heading>
            <Text>{t('UseDefaultTermSheetDocs')}</Text>
          </Stack>
        </CardHeader>
        <Divider />
        <CardBody py="6">
          <form id="subscription-document-type" onSubmit={handleSubmit(handleFormSubmit)}>
            <Controller
              control={control}
              name="documentationType"
              render={({ field }): JSX.Element => (
                <FormControl isInvalid={!!formState.errors.documentationType} w="full">
                  <FormLabel fontSize="xl" pb="2">
                    {t('TermSheet')}
                  </FormLabel>
                  <RadioGroup as={Stack} spacing="4" {...field}>
                    <Radio
                      alignItems="center"
                      as={HStack}
                      value={OpportunityDocumentationType.DEFAULT}
                      variant="solid"
                    >
                      <Text as="label">
                        {t('UseDefaultModel')}
                        <IconButton
                          aria-label="preview"
                          icon={<Icon as={EyeIcon} boxSize="20px" color="gray.500" />}
                          variant="unstyled"
                          onClick={(): void => handleFilePreview('Default')}
                        />
                      </Text>
                    </Radio>
                    <Radio value={OpportunityDocumentationType.TEMPLATE} variant="solid">
                      {t('UseMyModel')}
                      <Stack pt="4">
                        <Controller
                          control={control}
                          name="templateFile"
                          render={(): JSX.Element => (
                            <FormControl>
                              <DropzoneInput
                                isPreviewEnabled
                                files={fileName}
                                isLoading={isFileLoading}
                                maxFiles={1}
                                subTitle=".DOCX"
                                accept={{
                                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                                    [],
                                }}
                                icon={
                                  <Icon
                                    as={DocumentTextIcon}
                                    boxSize="42px"
                                    color="gray.400"
                                    strokeWidth="1px"
                                  />
                                }
                                onDelete={handleFileDelete}
                                onDrop={handleFileDrop}
                                onPreview={(): void => handleFilePreview('File')}
                              />
                              <ErrorMessage error={formState.errors?.templateFile} />
                            </FormControl>
                          )}
                        />
                      </Stack>
                    </Radio>
                  </RadioGroup>
                </FormControl>
              )}
            />
          </form>
        </CardBody>
        <CardFooter as={HStack} spacing="4">
          <Button type="button" variant="secondary" w="full" onClick={handleFormCancel}>
            {t('Back')}
          </Button>
          <Button
            data-cy="next"
            form="subscription-document-type"
            isDisabled={isFileLoading || loading}
            type="submit"
            w="full"
          >
            {t('Next')}
          </Button>
        </CardFooter>
      </Card>
      <PreviewDocumentModal
        isOpen={isOpen}
        src={templateUrl}
        title={t('TermSheet')}
        onClose={onClose}
      />
    </>
  );
}

export type NewOpportunityOperationDocumentationProps = Props;
