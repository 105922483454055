import { Button, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useResetPasswordMutation } from '@blockpulse3/graphql/hooks';
import {
  ErrorMessage,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';

import { schema } from './schema';
import { IResetPasswordForm } from './types';

type Props = unknown;

export function ResetPasswordForm(): JSX.Element {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [resetPassword] = useResetPasswordMutation();

  const { register, handleSubmit, formState } = useForm<IResetPasswordForm>({
    resolver: yupResolver(schema),
  });

  const handleFormSubmit: SubmitHandler<IResetPasswordForm> = (data) => {
    const email = params.get('email');
    const token = params.get('code');

    if (email && token) {
      resetPassword({
        variables: {
          resetPasswordInput: {
            email,
            token,
            password: data.password,
          },
        },
        onCompleted: () => {
          successToast({ title: t('PasswordSuccessfullyChanged') });
          navigate(routes.login.href);
        },
        onError: () => {
          errorToast({ title: t('SettingsUpdateError') });
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack spacing="4">
        <FormControl isInvalid={!!formState.errors?.password}>
          <FormLabel htmlFor="password">{t('NewPassword')}</FormLabel>
          <Input id="password" type="password" {...register('password')} />
          <ErrorMessage error={formState.errors?.password} />
        </FormControl>
        <FormControl isInvalid={!!formState.errors?.confirmPassword}>
          <FormLabel htmlFor="confirmPassword">
            {t('PasswordConfirmation')}
          </FormLabel>
          <Input id="confirmPassword" type="password" {...register('confirmPassword')} />
          <ErrorMessage error={formState.errors?.confirmPassword} />
        </FormControl>
        <Button type="submit" w="full">
          {t('Reset')}
        </Button>
      </Stack>
    </form>
  );
}

export type ResetPasswordFormProps = Props;
