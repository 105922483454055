import { InfoIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormLabel,
  HStack,
  Icon,
  ListItem,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { FormErrors, noop } from '@blockpulse3/data/shared';
import {
  IndividualDocumentType,
  useDeleteKycDocumentMutation,
  useGetIndividualDocumentsQuery,
} from '@blockpulse3/graphql/hooks';
import { DropzoneInput, ErrorMessage, ErrorQueryModal } from '@blockpulse3/ui/commons';

import { useManagedIndividual } from '../../../../../providers';
import { kycSchema } from '../schema';
import { IVerificationUserForm } from '../types';

type Props = {
  onSubmit: () => void;
  setIsValidable: React.Dispatch<React.SetStateAction<boolean>>;
};

export function DocumentUserVerification({ onSubmit = noop, setIsValidable }: Props): JSX.Element {
  const t = useTranslations();

  const [isIdentityDocLoading, setIsIdentityDocLoading] = useState(false);
  const [isSecondaryDocLoading, setIsSecondaryDocLoading] = useState(false);

  const { individual } = useManagedIndividual();
  const individualIdentityId = individual?.id || '';

  /* ** Fetch individual documents ** */
  const individualDocumentsReq = useGetIndividualDocumentsQuery({
    variables: { individualIdentityId },
    skip: !individualIdentityId,
  });
  /* ** Delete document mutation ** */
  const [deleteDocument] = useDeleteKycDocumentMutation();

  /* ** Linked subscription individual documents to filter ** */
  const individualDocuments = individualDocumentsReq.data?.getIndividualDocuments || [];

  const identityDocument = individualDocuments.find(
    (doc) => doc.document.type === IndividualDocumentType.IDENTITY_DOCUMENT,
  );
  const identityDocumentSecondary = individualDocuments.find(
    (doc) => doc.document.type === IndividualDocumentType.IDENTITY_DOCUMENT_SECONDARY,
  );

  /* ** Documents file names for the `<DropzoneInput />` ** */
  const identityDocumentFileName = identityDocument
    ? {
        [identityDocument.document.id]: new File(
          [identityDocument.document.title],
          identityDocument.document.title,
        ),
      }
    : {};
  const identityDocumentSecondaryFileName = identityDocumentSecondary
    ? {
        [identityDocumentSecondary.document.id]: new File(
          [identityDocumentSecondary.document.title],
          identityDocumentSecondary.document.title,
        ),
      }
    : {};

  /* ** Form ** */
  const { control, handleSubmit, formState, setError, setValue, clearErrors } =
    useForm<IVerificationUserForm>({
      defaultValues: { identityDocumentFile: '', identityDocumentSecondaryFile: '' },
      resolver: yupResolver(kycSchema),
    });

  useEffect(() => {
    /* ** Set document in form on file change ** */
    if (identityDocument) {
      setValue('identityDocumentFile', identityDocument?.document.id || '');
    }
    if (identityDocumentSecondary) {
      setValue('identityDocumentSecondaryFile', identityDocumentSecondary?.document.id || '');
    }

    if (identityDocument && identityDocumentSecondary) {
      setIsValidable(true);
    } else {
      setIsValidable(false);
    }
  }, [identityDocument, identityDocumentSecondary, setValue, setIsValidable]);

  if (individualDocumentsReq.error) {
    return <ErrorQueryModal h="380px" />;
  }

  /* ** On Identity Document Secondary file upload ** */
  const handleIdentityDocumentSecondaryFileDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ): void => {
    clearErrors('identityDocumentSecondaryFile');
    if (fileRejections.length === 0) {
      setIsSecondaryDocLoading(true);
      handleFileUpload(acceptedFiles, IndividualDocumentType.IDENTITY_DOCUMENT_SECONDARY);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError('identityDocumentSecondaryFile', {
            type: 'custom',
            message: FormErrors.FileTooLarge,
          });
          break;
        }
        default: {
          setError('identityDocumentSecondaryFile', {
            type: 'custom',
            message: FormErrors.DropzoneDefault,
          });
          break;
        }
      }
    }
  };

  /* ** On Identity Document file upload ** */
  const handleIdentityDocumentFileDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
  ): void => {
    clearErrors('identityDocumentFile');
    if (fileRejections.length === 0) {
      setIsIdentityDocLoading(true);
      handleFileUpload(acceptedFiles, IndividualDocumentType.IDENTITY_DOCUMENT);
    } else {
      const error = fileRejections[0].errors[0].code;
      switch (error) {
        case 'file-too-large': {
          setError('identityDocumentFile', { type: 'custom', message: FormErrors.FileTooLarge });
          break;
        }
        default: {
          setError('identityDocumentFile', { type: 'custom', message: FormErrors.DropzoneDefault });
          break;
        }
      }
    }
  };

  /* ** Method to upload a file using axios ** */
  const handleFileUpload = async (
    acceptedFiles: File[],
    documentType: IndividualDocumentType,
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('document', acceptedFiles[0]);
    formData.append('individualIdentityId', individualIdentityId);
    formData.append('documentType', documentType);
    await axios.post(
      process.env['NX_API_CONTROLLER_ENDPOINT'] + '/identities/uploadKycDocument',
      formData,
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    await individualDocumentsReq.refetch();
    setIsIdentityDocLoading(false);
    setIsSecondaryDocLoading(false);
  };

  const handleFormSubmit: SubmitHandler<IVerificationUserForm> = (): void => {
    onSubmit();
  };

  /* ** Delete file handlers ** */
  const handleIdentityDocumentDelete = (): void => {
    handleFileDelete(IndividualDocumentType.IDENTITY_DOCUMENT);
  };
  const handleIdentityDocumentSecondaryDelete = (): void => {
    handleFileDelete(IndividualDocumentType.IDENTITY_DOCUMENT_SECONDARY);
  };
  const handleFileDelete = (documentType: IndividualDocumentType): void => {
    deleteDocument({
      variables: {
        deleteKycDocumentInput: {
          individualIdentityId,
          documentType,
        },
      },
      onCompleted: () => {
        individualDocumentsReq.refetch();
      },
    });
  };

  return (
    <Stack spacing="2">
      <form id="document-upload" onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing="4">
          <HStack>
            <Text fontWeight="500">{t('VerifyIdentityDownloadDocs')}</Text>
            <Tooltip
              hasArrow
              placement="top"
              label={
                <UnorderedList>
                  <ListItem>{t('FirstPiecePassportOrID')}</ListItem>
                  <ListItem>{t('SecondPiece')}</ListItem>
                </UnorderedList>
              }
            >
              <Icon as={InfoIcon} color="gray.600" />
            </Tooltip>
          </HStack>
          <Controller
            control={control}
            name="identityDocumentFile"
            render={(): JSX.Element => (
              <FormControl>
                <FormLabel>{t('IDCard1FrontBack')}</FormLabel>
                <DropzoneInput
                  accept={{ 'application/pdf': [], 'image/png': [], 'image/jpeg': [] }}
                  files={identityDocumentFileName}
                  isDisabled={individualDocumentsReq.loading}
                  isLoading={isIdentityDocLoading}
                  maxFiles={1}
                  subTitle={t('ImageUpTo5MB')}
                  onDelete={handleIdentityDocumentDelete}
                  onDrop={handleIdentityDocumentFileDrop}
                />
                <ErrorMessage error={formState.errors?.identityDocumentFile} />
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="identityDocumentSecondaryFile"
            render={(): JSX.Element => (
              <FormControl>
                <FormLabel>{t('IDCard2FrontBack')}</FormLabel>
                <DropzoneInput
                  accept={{ 'application/pdf': [], 'image/png': [], 'image/jpeg': [] }}
                  files={identityDocumentSecondaryFileName}
                  isDisabled={individualDocumentsReq.loading}
                  isLoading={isSecondaryDocLoading}
                  maxFiles={1}
                  subTitle={t('ImageUpTo5MB')}
                  onDelete={handleIdentityDocumentSecondaryDelete}
                  onDrop={handleIdentityDocumentSecondaryFileDrop}
                />
                <ErrorMessage error={formState.errors?.identityDocumentSecondaryFile} />
              </FormControl>
            )}
          />
        </Stack>
      </form>
    </Stack>
  );
}
