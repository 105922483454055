import { Avatar, Badge, Card, HStack, Heading, Icon, Skeleton, Stack } from '@chakra-ui/react';
import { UserGroupIcon } from '@heroicons/react/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IntlSpaceTypes } from '@blockpulse3/data/locales/types';
import { routes } from '@blockpulse3/data/shared';
import { useGetSpaceQuery } from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, useErrorToast } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

type Props = unknown;

/**
 * SpaceHeader.
 *
 * @returns {JSX.Element}
 */
export function SpaceHeader(): JSX.Element {
  const t = useTranslations();
  const i18nSpaceType = useTranslations('SpaceTypes');

  const navigate = useNavigate();
  const { spaceId: authSpaceId = '' } = useAuthUser();
  const { spaceId = authSpaceId } = useParams();

  const errorToast = useErrorToast();

  const { data, loading, error } = useGetSpaceQuery({
    variables: { spaceId },
    skip: !spaceId,
    onError: () => {
      navigate(routes.me.href);
      errorToast({ title: t('SpaceRetrievalError') });
    },
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="70px" />
      </Skeleton>
    );
  }

  if (error || !data) {
    return <ErrorQueryCard h="70px" />;
  }

  const { space } = data;

  return (
    <HStack
      borderColor="gray.200"
      borderStyle="solid"
      borderWidth="0 0 1px"
      data-cy="space-header"
      pb="3"
      rounded="sm"
    >
      <Avatar
        boxSize="14"
        flexShrink="0"
        icon={<Icon as={UserGroupIcon} boxSize="50%" />}
        rounded="sm"
      />
      <Stack alignItems="flex-start" spacing="1">
        <Heading size="md">{space.name}</Heading>
        <Badge colorScheme="cyan">{i18nSpaceType(space.type as IntlSpaceTypes)}</Badge>
      </Stack>
    </HStack>
  );
}

export type SpaceHeader = Props;
