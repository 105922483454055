import { HStack, Icon, Radio, Stack, Text, useRadioGroup } from '@chakra-ui/react';
import { OfficeBuildingIcon, UserIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useTranslations } from 'use-intl';

import { IdentityType } from '@blockpulse3/graphql/hooks';

import { IOperationSubscriber } from '../types';
import { operationSubscriberDefaultValues } from '../utils';
import {
  IOperationLegalSubscriberInformations,
  LegalSubscriberInformationsForm,
} from './LegalSubscriberInformationsForm';
import {
  IOperationNaturalSubscriberInformations,
  NaturalSubscriberInformationsForm,
} from './NaturalSubscriberInformationsForm';

type Props<T> = {
  defaultValues: IOperationSubscriber;
  amountRangeRequired: boolean;
  amountRequired: boolean;
  feesRequired: boolean;
  modalSubmitText: string;
  onSubmit: (subscriber: T) => void;
  onClose: () => void;
};

/**
 * SubscriberInformationsForm.
 * Form content of the OperationSubscribersModal. Render Legal or Natural version of the form.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SubscriberInformationsForm<T extends IOperationSubscriber>({
  defaultValues,
  amountRangeRequired,
  amountRequired,
  feesRequired,
  modalSubmitText,
  onClose,
  onSubmit,
}: Props<T>): JSX.Element {
  const t = useTranslations();

  const [subscriber, setSubscriber] = useState(defaultValues.subscriber);
  const [hasRadioChanged, setHasRadioChanged] = useState(false);

  const { value, getRootProps, getRadioProps, onChange } = useRadioGroup({
    name: 'identityType',
    defaultValue: defaultValues.isLegal ? IdentityType.COMPANY : IdentityType.INDIVIDUAL,
  });

  const isLegal = value === IdentityType.COMPANY;

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event);

    // Reset default values on radio change
    if (!hasRadioChanged) {
      setSubscriber(operationSubscriberDefaultValues.subscriber);
    } else {
      setSubscriber(defaultValues.subscriber);
    }

    setHasRadioChanged(!hasRadioChanged);
  };

  const handleFormSubmit = (data: T['subscriber']): void => {
    onSubmit({ isLegal, subscriber: data } as T);
    onClose();
  };

  return (
    <Stack spacing="4">
      <Stack direction={{ base: 'column', md: 'row' }}>
        <Stack direction={{ base: 'column', md: 'row' }} spacing="4" w="full" {...getRootProps()}>
          <Radio
            variant="identity"
            {...getRadioProps({ value: IdentityType.COMPANY })}
            onChange={handleRadioChange}
          >
            <HStack alignItems="center" data-cy="company-subscriber" spacing="3">
              <Icon as={OfficeBuildingIcon} boxSize="24px" />
              <Text>{t('ItIsACompany')}</Text>
            </HStack>
          </Radio>
          <Radio
            variant="identity"
            {...getRadioProps({ value: IdentityType.INDIVIDUAL })}
            onChange={handleRadioChange}
          >
            <HStack alignItems="center" data-cy="individual-subscriber" spacing="3">
              <Icon as={UserIcon} boxSize="24px" />
              <Text>{t('IndividualPersonType')}</Text>
            </HStack>
          </Radio>
        </Stack>
      </Stack>
      {isLegal ? (
        <LegalSubscriberInformationsForm
          amountRangeRequired={amountRangeRequired}
          amountRequired={amountRequired}
          defaultValues={subscriber as IOperationLegalSubscriberInformations}
          feesRequired={feesRequired}
          modalSubmitText={modalSubmitText}
          onSubmit={handleFormSubmit}
        />
      ) : (
        <NaturalSubscriberInformationsForm
          amountRangeRequired={amountRangeRequired}
          amountRequired={amountRequired}
          defaultValues={subscriber as IOperationNaturalSubscriberInformations}
          feesRequired={feesRequired}
          modalSubmitText={modalSubmitText}
          onSubmit={handleFormSubmit}
        />
      )}
    </Stack>
  );
}
