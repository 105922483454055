import { FiscalAccountInfosFragment } from '@blockpulse3/graphql/hooks';

import { IFiscalAccountForm } from './types';

export function getFiscalAccountValues(
  fiscalAccount: FiscalAccountInfosFragment,
): IFiscalAccountForm {
  return {
    id: fiscalAccount.id || '',
    holdingMethod: fiscalAccount.holdingMethod,
    accountNumber: fiscalAccount.accountNumber || '',
    bankCode: fiscalAccount.bankCode || '',
    bankName: fiscalAccount.bankName || '',
    bankEmail: fiscalAccount.bankEmail || '',
  };
}

export function mapFiscalAccountsByHoldingMethod(
  fiscalAccounts: FiscalAccountInfosFragment[],
): Record<string, FiscalAccountInfosFragment> {
  return fiscalAccounts.reduce((acc, cur) => ({ ...acc, [cur.holdingMethod]: cur }), {});
}
