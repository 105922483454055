import { DocumentInfosFragment } from '@blockpulse3/graphql/hooks';

/**
 * getDocumentIds.
 * Return subscription document ids.
 *
 * @param {Array} documents
 * @returns {Record<string, string>}
 */
export function getDocumentIds(documents: DocumentInfosFragment[]): Record<string, string> {
  return documents.reduce((acc, cur) => ({ ...acc, [cur.type]: cur.id || '' }), {});
}
