import * as yup from 'yup';

import {
  dateField,
  numberField,
  requiredBooleanField,
  requiredNumberField,
  requiredPastDateField,
  requiredStringField,
} from '@blockpulse3/data/shared';
import { AssetType, TokenDurationUnit } from '@blockpulse3/graphql/hooks';

import { IOptionAsset } from './types';

export const optionAssetTypeOptions = [
  { label: AssetType.AGA, value: AssetType.AGA },
  { label: AssetType.BSA, value: AssetType.BSA },
  { label: AssetType.BSA_AIR, value: AssetType.BSA_AIR },
  { label: AssetType.BSPCE, value: AssetType.BSPCE },
];

export const durationUnitOptions = [
  { label: TokenDurationUnit.day, value: TokenDurationUnit.day },
  { label: TokenDurationUnit.month, value: TokenDurationUnit.month },
];

export const schema = yup.object({
  name: requiredStringField,
  code: requiredStringField,
  type: yup.object({
    label: requiredStringField,
    value: requiredStringField,
  }),
  initialSupply: requiredNumberField,
  remainingSupply: numberField,
  date: requiredPastDateField,
  expirationDate: dateField,
  durationUnit: yup.object({
    label: requiredStringField,
    value: requiredStringField,
  }),
  attributionPeriod: requiredNumberField,
  subscriptionPeriod: requiredNumberField,
  subscriptionPrice: numberField,
  exercisePrice: numberField,
  conversionParity: requiredNumberField,
  delegated: requiredBooleanField,
  transferable: requiredBooleanField,
  accelerable: requiredBooleanField,
  approvable: requiredBooleanField,
  hasCurve: requiredBooleanField,
  cliffDuration: numberField,
  vestingDuration: numberField,
  intervalDuration: numberField,
}) as yup.ObjectSchema<IOptionAsset>;

export const defaultValues: IOptionAsset = {
  name: '',
  code: '',
  type: optionAssetTypeOptions[0],
  initialSupply: 0,
  remainingSupply: 0,
  date: '',
  expirationDate: '',
  durationUnit: durationUnitOptions[0],
  attributionPeriod: 0,
  subscriptionPeriod: 0,
  subscriptionPrice: 0,
  exercisePrice: 0,
  conversionParity: 0,
  delegated: false,
  transferable: false,
  accelerable: false,
  approvable: false,
  hasCurve: false,
  cliffDuration: 0,
  vestingDuration: 0,
  intervalDuration: 0,
};
