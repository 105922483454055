import { OptionAssetInfosFragment, StockAssetInfosFragment } from '@blockpulse3/graphql/hooks';

import { IStockAssetRow } from './types';

/**
 * getStockAssets.
 *
 * @param {StockAssetInfosFragment[]} data
 * @returns {IStockAsset[]}
 */
export function getStockAssets(data?: StockAssetInfosFragment[]): IStockAssetRow[] {
  if (!data) {
    return [];
  }

  return data.map((value) => ({
    id: value.asset.id,
    code: value.code.split('-')[0],
    name: value.name,
    type: {
      label: value.assetType,
      value: value.assetType,
    },
    value: value.asset.value || 0,
  }));
}

/**
 * getOptionAssets.
 *
 * @param {OptionAssetInfosFragment[]} data
 * @returns {IStockAssetRow[]}
 */
export function getOptionAssets(data?: OptionAssetInfosFragment[]): IStockAssetRow[] {
  if (!data) {
    return [];
  }

  return data.map((value) => ({
    id: value.asset.id,
    code: value.code.split('-')[0],
    name: value.name,
    type: {
      label: value.assetType,
      value: value.assetType,
    },
    value: value.exercisePrice || 0,
  }));
}
