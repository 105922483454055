import {
  Alert,
  AlertIcon,
  AlertTitle,
  Card,
  Link as ChakraLink,
  HStack,
  Icon,
  Link,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { PencilIcon } from '@heroicons/react/solid';
import { Link as ReactRouterLink, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IdentityVerificationStatus, isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import {
  CompanyStatus,
  CompanyType,
  IdentityType,
  useGetCompanyQuery,
  useGetQueuedCreditTransferTransactionsQuery,
} from '@blockpulse3/graphql/hooks';
import {
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  PhoneBanner,
} from '@blockpulse3/ui/commons';
import { AssetAndOptionList, Wallet } from '@blockpulse3/web-client/shareholding';

import { IdentityHeader } from './IdentityHeader';
import { InvitationList } from './InvitationList';
import { OperationList } from './OperationList';
import { SPVDashboardView } from './SPVDashboard';

type Props = unknown;

/**
 * CompanyDashboardView.
 * Dashboard of the company from the active workspace.
 *
 * @returns {JSX.Element}
 */
export function CompanyDashboardView(): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const editDisclosure = useDisclosure();

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const company = companyReq?.data?.company;

  const { data } = useGetQueuedCreditTransferTransactionsQuery({
    variables: { identityId: company?.identity?.id || '' },
    fetchPolicy: 'cache-and-network',
    skip: !company?.identity?.id,
  });
  const hasPendingSubscriptions = data?.getQueuedCreditTransferTransactions?.length;

  const renderPhoneBanner = isFeatureEnabled('phoneBanner');

  const isVerified = company?.kybVerificationStatus === IdentityVerificationStatus.APPROVED;

  if (!company) {
    return (
      <Skeleton>
        <Card h="80px" />
      </Skeleton>
    );
  }

  if (company.type === CompanyType.SPV && company.status !== CompanyStatus.RUNNING) {
    return <SPVDashboardView />;
  }

  return (
    <Stack spacing="6">
      <IdentityHeader>
        <IdentityCard onMouseEnter={editDisclosure.onOpen} onMouseLeave={editDisclosure.onClose}>
          <IdentityAvatar boxSize="14" src={company?.profilePicture} type={IdentityType.COMPANY} />
          <Stack spacing="0">
            <HStack spacing="3">
              <IdentityCardTitle isChecked={isVerified}>
                <Text>{company?.name && `${company.name} (${company.registrationNumber})`}</Text>
              </IdentityCardTitle>
              {editDisclosure.isOpen && (
                <HStack fontSize="sm" spacing="1">
                  <Icon as={PencilIcon} />
                  <Link fontWeight="400" href={routes.company.settings.href}>
                    {t('EditInformation')}
                  </Link>
                </HStack>
              )}
              <Link
                display={{ base: 'block', md: 'none' }}
                fontWeight="400"
                href={routes.me.settings.href}
              >
                <Icon as={PencilIcon} />
              </Link>
            </HStack>
            <IdentityCardDescription>
              <Text>{company?.address?.formatted}</Text>
            </IdentityCardDescription>
          </Stack>
        </IdentityCard>
      </IdentityHeader>
      {hasPendingSubscriptions && (
        <Alert status="info">
          <AlertIcon />
          <Stack spacing="0">
            <AlertTitle>
              {t.rich('ConsultPendingQueuedPayment', {
                link: (chunks) => (
                  <ChakraLink
                    as={ReactRouterLink}
                    fontWeight="600"
                    textDecoration="underline"
                    to={routes.payment.queuedPayments.href}
                  >
                    {chunks}
                  </ChakraLink>
                ),
              })}
            </AlertTitle>
          </Stack>
        </Alert>
      )}
      {renderPhoneBanner && <PhoneBanner />}
      <OperationList />
      <Wallet />
      <AssetAndOptionList />
      <InvitationList />
    </Stack>
  );
}

export type CompanyDashboardViewProps = Props;
