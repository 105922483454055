import { getBrand, getHostName } from '@blockpulse3/data/shared';

import {
  AdeoTheme,
  AuchanTheme,
  AxerealTheme,
  BlastTheme,
  BlockpulseTheme,
  CenitzTheme,
  CineventureTheme,
  DecathlonTheme,
  FranceAngelsTheme,
  HousebaseTheme,
  HowhenTheme,
  InvestTheme,
  LesaffreTheme,
  LineUpTheme,
  MobilisTheme,
  MobiviaTheme,
  PbaTheme,
  StomeaTheme,
  TyliaTheme,
  VivaltoTheme,
  YeastTheme,
} from '../brands';
import { Brand } from '../brands/types';

export function getBrandTheme(): Brand {
  const hostname = getHostName();
  const brand = getBrand(hostname);

  switch (brand) {
    case 'adeo':
      return AdeoTheme;
    case 'auchan':
      return AuchanTheme;
    case 'axereal':
      return AxerealTheme;
    case 'blast':
      return BlastTheme;
    case 'blockpulse':
      return BlockpulseTheme;
    case 'cenitz':
      return CenitzTheme;
    case 'cineventure':
      return CineventureTheme;
    case 'decathlon':
      return DecathlonTheme;
    case 'franceangels':
      return FranceAngelsTheme;
    case 'howhen':
      return HowhenTheme;
    case 'invest':
      return InvestTheme;
    case 'lesaffre':
      return LesaffreTheme;
    case 'lineup':
      return LineUpTheme;
    case 'mobilis':
      return MobilisTheme;
    case 'mobivia':
      return MobiviaTheme;
    case 'pba':
      return PbaTheme;
    case 'stomea':
      return StomeaTheme;
    case 'tylia':
      return TyliaTheme;
    case 'vivalto':
      return VivaltoTheme;
    case 'yeast':
      return YeastTheme;
    case 'housebase':
      return HousebaseTheme;
    default:
      return BlockpulseTheme;
  }
}
