import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  TableContainer as ChakraTableContainer,
  TableContainerProps as ChakraTableContainerProps,
  Icon,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { UIEventHandler, useRef, useState } from 'react';

type Props = ChakraTableContainerProps;

export function TableContainer({ children, ...props }: Props): JSX.Element {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  /* ** Hide arrows on `md` breakpoint ** */
  const showArrows = useBreakpointValue({ base: true, md: false }, { ssr: true });

  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(true);

  const handleRightArrowClick = (): void => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.scroll({ left: tableContainer.scrollLeft + 50, behavior: 'smooth' });
    }
  };

  const handleLeftArrowClick = (): void => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.scroll({ left: tableContainer.scrollLeft - 50, behavior: 'smooth' });
    }
  };

  const handleTableScroll: UIEventHandler<HTMLDivElement> = (e) => {
    const { scrollLeft, offsetWidth, scrollWidth } = e.currentTarget;
    setShowRightArrow(scrollLeft + offsetWidth < scrollWidth);
    setShowLeftArrow(scrollLeft > 0);
  };

  return (
    <Box position="relative">
      <ChakraTableContainer
        borderColor="gray.300"
        borderStyle="solid"
        borderWidth="1px"
        maxHeight="400px"
        overflowY="auto"
        ref={tableContainerRef}
        rounded="md"
        onScroll={handleTableScroll}
        {...props}
      >
        {children}
      </ChakraTableContainer>
      {showArrows && (
        <>
          {showLeftArrow && (
            <IconButton
              _hover={{ opacity: 1 }}
              aria-label="scroll-left"
              background="gray.100"
              border="1px solid"
              borderColor="gray.900"
              boxShadow="lg"
              icon={<Icon as={ChevronLeftIcon} color="gray.900" />}
              left="0"
              opacity="1"
              position="absolute"
              rounded="full"
              top="50%"
              transform="translate(-50%, -50%)"
              zIndex="10"
              onClick={handleLeftArrowClick}
            />
          )}
          {showRightArrow && (
            <IconButton
              _hover={{ opacity: 1 }}
              aria-label="scroll-right"
              background="gray.100"
              border="1px solid"
              borderColor="gray.900"
              boxShadow="lg"
              icon={<Icon as={ChevronRightIcon} color="gray.900" />}
              position="absolute"
              right="0"
              rounded="full"
              top="50%"
              transform="translate(50%, -50%)"
              zIndex="10"
              onClick={handleRightArrowClick}
            />
          )}
        </>
      )}
    </Box>
  );
}

export type TableContainerProps = Props;
