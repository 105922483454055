import { HStack, Icon, Link, Stack, Text } from '@chakra-ui/react';
import { PencilIcon } from '@heroicons/react/solid';
import { useParams } from 'react-router-dom';

import { IdentityVerificationStatus, isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import { IdentityType, useGetCompanyQuery } from '@blockpulse3/graphql/hooks';
import {
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  PhoneBanner,
  SPVMatriculationStatusAlert,
} from '@blockpulse3/ui/commons';

import { IdentityHeader } from './IdentityHeader';
import { OperationList } from './OperationList';

/**
 * SPVDashboardView.
 * Dashboard of the draft spv.
 *
 * @returns {JSX.Element}
 */
export function SPVDashboardView(): JSX.Element {
  const { companyId = '' } = useParams();
  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = companyReq?.data?.company;

  const renderPhoneBanner = isFeatureEnabled('phoneBanner');
  const isVerified = company?.kybVerificationStatus === IdentityVerificationStatus.APPROVED;

  return (
    <Stack spacing="6">
      <IdentityHeader>
        <IdentityCard>
          <IdentityAvatar boxSize="14" src={company?.profilePicture} type={IdentityType.COMPANY} />
          <Stack spacing="0">
            <HStack spacing="3">
              <IdentityCardTitle isChecked={isVerified}>
                <Text>{company?.name && `${company.name}`}</Text>
              </IdentityCardTitle>
              <Link
                display={{ base: 'block', md: 'none' }}
                fontWeight="400"
                href={routes.me.settings.href}
              >
                <Icon as={PencilIcon} />
              </Link>
            </HStack>
            <IdentityCardDescription>
              <Text>{company?.address?.formatted}</Text>
            </IdentityCardDescription>
          </Stack>
        </IdentityCard>
      </IdentityHeader>
      <SPVMatriculationStatusAlert companyId={companyId} spvStatus={company?.spvStatus} />
      {renderPhoneBanner && <PhoneBanner />}
      <OperationList />
    </Stack>
  );
}
