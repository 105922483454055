import { OperationInfosFragment, OperationType } from '@blockpulse3/graphql/hooks';

import { IOperationLegalSubscriberInformations } from './Forms';
import { IOperationSubscriber, IPartialOperationParameters } from './types';

export const operationSubscriberDefaultValues = {
  isLegal: false,
  subscriber: {
    email: '',
    name: '',
    identificationNumber: '',
    amount: 0,
    admissionFees: 0,
    verified: false,
    minimalAmount: null,
    maximalAmount: null,
  },
};

/**
 * isLegalSubscriber.
 * Check if subscriber argument is a legal version or not.
 *
 * @param {IOperationSubscriber['subscriber']} subscriber
 * @returns {subscriber is}
 */
export function isLegalSubscriber(
  subscriber: IOperationSubscriber['subscriber'],
): subscriber is IOperationLegalSubscriberInformations {
  return (
    (subscriber as IOperationLegalSubscriberInformations).name !== '' &&
    (subscriber as IOperationLegalSubscriberInformations).name !== undefined
  );
}

export function getOperationParameters(
  operation: OperationInfosFragment | undefined,
): IPartialOperationParameters {
  const { type, fundraising } = operation || {};

  let min = null;
  let max = null;

  if (type === OperationType.OPPORTUNITY) {
    const purchaseIntentStep = operation?.subscriptionSteps?.find(
      (step) => step.__typename === 'PurchaseIntentStepType',
    );

    if (purchaseIntentStep && purchaseIntentStep.__typename === 'PurchaseIntentStepType') {
      min = purchaseIntentStep.minimalAmount;
      max = purchaseIntentStep.maximalAmount;
    }

    return {
      hardCap: null,
      minimalAmount: min ?? null,
      maximalAmount: max ?? null,
      pricePerShare: operation?.pricePerShare || 1,
    };
  }

  if (type === OperationType.FUNDRAISING && fundraising) {
    const subscriptionDetailsStep = operation?.subscriptionSteps?.find(
      (step) => step.__typename === 'SubscriptionDetailsStepType',
    );

    if (
      subscriptionDetailsStep &&
      subscriptionDetailsStep.__typename === 'SubscriptionDetailsStepType'
    ) {
      min = subscriptionDetailsStep.minimalAmount;
      max = subscriptionDetailsStep.maximalAmount;
    }

    return {
      hardCap: fundraising.hardCap ?? null,
      minimalAmount: min ?? null,
      maximalAmount: max ?? null,
      pricePerShare: operation?.pricePerShare || 1,
    };
  }

  return {
    pricePerShare: 1,
    hardCap: null,
    minimalAmount: null,
    maximalAmount: null,
  };
}
