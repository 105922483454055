import {
  Button,
  Icon,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { ArrowNarrowRightIcon, CashIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { useGetStockAssetsQuery } from '@blockpulse3/graphql/hooks';
import { AssetBadge, ErrorQueryCard, TableContainer } from '@blockpulse3/ui/commons';

type Props = unknown;
// type Props = {
//   /* ** Callback on table row click ** */
//   onClick?: (assetId: string) => void;
// };

export function AssetTable(/*{ onClick = noop } : Props*/): JSX.Element {
  const t = useTranslations();

  const { companyId = '' } = useParams();
  const { data, loading, error } = useGetStockAssetsQuery({
    variables: { companyId: companyId || '' },
    skip: !companyId,
  });

  if (loading) {
    return <Skeleton h="100px" />;
  }

  if (error) {
    return <ErrorQueryCard h="100px" />;
  }

  const currentStocks = data?.getStockAssets || [];
  const isEmpty = currentStocks.length === 0;

  // const handleOptionClick = (assetId: string): void => {
  //   onClick(assetId);
  // };

  return isEmpty ? (
    <Stack layerStyle="emptyState">
      <Icon as={CashIcon} boxSize="40px" color="gray.500" />
      <Text>{t('NoAsset')}</Text>
    </Stack>
  ) : (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>{t('Name')}</Th>
            <Th>{t('Quantity')}</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {currentStocks.map((row) => (
            <Tr
              key={row.asset.id}
              // role="button"
              // onClick={(): void => handleOptionClick(row.asset.id)}
            >
              <Td fontWeight="600">{row.name}</Td>
              <Td fontWeight="600">
                <AssetBadge type={row.assetType} value={row.totalSupply} />
              </Td>
              <Td textAlign="right">
                <Button
                  isDisabled={true}
                  rightIcon={<Icon as={ArrowNarrowRightIcon} boxSize="5" />}
                  variant="secondary"
                >
                  {t('ShowDetails')}
                </Button>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export type AssetTableProps = Props;
