import * as yup from 'yup';

import {
  requiredBooleanField,
  requiredFutureDateField,
  requiredNumberField,
  requiredSelectField,
  requiredStringField,
  requiredTodayOrFutureDateField,
} from '@blockpulse3/data/shared';

import { ISecondaryOperationInformationsForm } from './types';

export const secondaryOperationInformationsSchema = yup.object({
  name: requiredStringField,
  startSubscriptionDate: requiredTodayOrFutureDateField,
  endSubscriptionDate: requiredFutureDateField,
  asset: requiredSelectField,
  pricePerShare: requiredNumberField,
  isPromiseToSell: requiredBooleanField,
  usePivotFiscalAccount: requiredBooleanField,
}) as yup.ObjectSchema<ISecondaryOperationInformationsForm>;

export const secondaryOperationInformationsDefaults: ISecondaryOperationInformationsForm = {
  name: '',
  startSubscriptionDate: '',
  endSubscriptionDate: '',
  asset: { label: '', value: '' },
  pricePerShare: 0,
  isPromiseToSell: false,
  usePivotFiscalAccount: false,
};
