import { InfoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Spinner,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Select, SingleValue } from 'chakra-react-select';
import { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { IntlCapitalTypeValues } from '@blockpulse3/data/locales/types';
import { noop } from '@blockpulse3/data/shared';
import { CompanyInfosFragment, IdentityVerificationStatus } from '@blockpulse3/graphql/hooks';
import { alpha3CountryOptions, formatCountry } from '@blockpulse3/helpers';
import {
  CompanyIdentityAvatar,
  ErrorMessage,
  InputImage,
  useErrorToast,
} from '@blockpulse3/ui/commons';

import { useStepFormContext } from '../../../provider';
import { CompanyOption, SearchCompanySelect } from '../../SearchCompanySelect';
import { capitalTypeOptions } from './constants';
import { companyInformationsFormSchema } from './schema';
import { CompanyInformationsForm } from './types';

type Props = {
  /* ** Disable form field(s) (`registrationNumber`) ** */
  isRegistrationNumberDisabled?: boolean;
  /* ** Activate additional fields when editing running company ** */
  isEditing?: boolean;
  /* ** Is form prefilled with external source (Pappers) ** */
  isPrefilled?: boolean;
  /* ** Default form values ** */
  defaultValues?: CompanyInformationsForm;
  /* ** Existing company data ** */
  company?: CompanyInfosFragment;
  /* ** Refetch company data ** */
  refetchCompany?: () => void;
  /* ** Step cancelled callback ** */
  onCancel?: () => void;
  /* ** Callback on form submit ** */
  onSubmit: (data: CompanyInformationsForm) => void;
};

/**
 * ExistingCompanyInformations.
 * Existing company informations form
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function ExistingCompanyInformations({
  isRegistrationNumberDisabled = false,
  isEditing = false,
  isPrefilled = false,
  defaultValues,
  company,
  refetchCompany = noop,
  onCancel = noop,
  onSubmit,
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nCapitalType = useTranslations('CapitalTypeValues');

  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);

  const errorToast = useErrorToast();

  const { control, register, formState, handleSubmit, setValue, trigger, reset } =
    useForm<CompanyInformationsForm>({
      defaultValues,
      resolver: yupResolver(companyInformationsFormSchema),
    });

  /* ** Update fields on company name change ** */
  const handleNameChange = (option: SingleValue<CompanyOption>): void => {
    if (!option) {
      return;
    }
    setValue('name', option.name);
    setValue('registrationNumber', option.registrationNumber);
    setValue('registrationEntity', option.registrationEntity);
    setValue('shareCapital', option.shareCapital);
    setValue('corporateForm', option.corporateForm);
    setValue('creationDate', option.creationDate);
    setValue('address.line', option.address.line);
    setValue('address.city', option.address.city);
    setValue('address.postalCode', option.address.postalCode);
    setValue('country', { label: formatCountry('FRA'), value: 'FRA' });
    setValue('address.country', {
      label: formatCountry(option.address.country),
      value: option.address.country,
    });
    setValue('businessActivityDescription', option.businessActivityDescription);
    trigger();
  };

  const handleFormSubmit: SubmitHandler<CompanyInformationsForm> = useCallback(
    (data) => {
      onSubmit(data);
    },
    [onSubmit],
  );

  const { setCancelHandler, setSubmitHandler } = useStepFormContext();
  const handleStepSubmit = useCallback((): void => {
    handleSubmit(handleFormSubmit)();
  }, [handleSubmit, handleFormSubmit]);

  const handleStepCancel = useCallback((): void => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    setSubmitHandler(handleStepSubmit);
    setCancelHandler(handleStepCancel);
  }, [handleStepSubmit, handleStepCancel, setSubmitHandler, setCancelHandler]);

  const handleProfilePictureUpload = async (files: FileList): Promise<void> => {
    if (files.length > 0 && company?.identity?.id) {
      setIsImageUploading(true);

      const formData = new FormData();
      formData.append('identityId', company.identity.id);
      formData.append('document', files[0]);

      await axios
        .post(
          process.env['NX_API_CONTROLLER_ENDPOINT'] + '/identities/uploadProfilePicture',
          formData,
          {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then(() => {
          refetchCompany();
          setIsImageUploading(false);
        })
        .catch(() => {
          errorToast({ title: t('FileUploadError') });
          setIsImageUploading(false);
        });
    }
  };

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  const isVerified = company?.identity?.verificationStatus === IdentityVerificationStatus.APPROVED;

  return (
    <>
      {isPrefilled && (
        <Alert status="info">
          <AlertIcon />
          <AlertTitle>{t('PreFilledInfoFromSIREN')}</AlertTitle>
        </Alert>
      )}
      <form id="company-informations" onSubmit={handleSubmit(handleFormSubmit)}>
        <Stack spacing="4">
          {!isRegistrationNumberDisabled && (
            <FormControl>
              <FormLabel htmlFor="searchCompany">{t('SearchCompany')}</FormLabel>
              <SearchCompanySelect
                id="searchCompany"
                menuPlacement="auto"
                placeholder={t('SearchByNameOrSIREN')}
                value={null}
                onChange={handleNameChange}
              />
            </FormControl>
          )}
          {isEditing && (
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.profilePicture}>
                <FormLabel>{t('CompanyLogo')}</FormLabel>
                <HStack spacing="4">
                  <Box position="relative">
                    {isImageUploading && (
                      <Spinner
                        color="whiteAlpha.700"
                        h="50%"
                        left="25%"
                        position="absolute"
                        speed="1s"
                        thickness="4px"
                        top="25%"
                        w="50%"
                        zIndex="1"
                      />
                    )}
                    <CompanyIdentityAvatar boxSize="16" src={company?.identity?.profilePicture} />
                  </Box>
                  <InputImage<CompanyInformationsForm>
                    control={control}
                    isDisabled={isImageUploading}
                    name="profilePicture"
                    onUpload={handleProfilePictureUpload}
                  />
                </HStack>
              </FormControl>
            </Stack>
          )}
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.name}>
              <FormLabel htmlFor="name">{t('CompanyName')}</FormLabel>
              <Input id="name" isDisabled={isVerified} type="text" {...register('name')} />
              <ErrorMessage error={formState.errors?.name} />
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.registrationNumber}>
              <HStack alignItems="flex-start" spacing="0">
                <FormLabel htmlFor="identification">{t('RegistrationNumber')}</FormLabel>
                <Tooltip hasArrow label={t('NonFrenchCompanyIdentificationNumber')} placement="top">
                  <Icon as={InfoIcon} color="gray.500" />
                </Tooltip>
              </HStack>
              <Input
                id="identification"
                isDisabled={isRegistrationNumberDisabled}
                type="text"
                {...register('registrationNumber')}
              />
              <ErrorMessage error={formState.errors?.registrationNumber} />
            </FormControl>
            <FormControl isInvalid={!!formState.errors?.registrationEntity}>
              <FormLabel htmlFor="registrationEntity">{t('RegistrationEntityCity')}</FormLabel>
              <Input
                id="registrationEntity"
                isDisabled={isVerified}
                type="text"
                {...register('registrationEntity')}
              />
              <ErrorMessage error={formState.errors?.registrationEntity} />
            </FormControl>
            <FormControl isInvalid={!!formState.errors?.corporateForm}>
              <FormLabel htmlFor="corporateForm">{t('CorporateForm')}</FormLabel>
              <Input
                id="corporateForm"
                isDisabled={isVerified}
                type="text"
                {...register('corporateForm')}
              />
              <ErrorMessage error={formState.errors?.corporateForm} />
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.creationDate}>
              <FormLabel htmlFor="creationDate">{t('CreationDate')}</FormLabel>
              <Input
                id="creationDate"
                isDisabled={isVerified}
                type="date"
                {...register('creationDate')}
              />
              <ErrorMessage error={formState.errors?.creationDate} />
            </FormControl>
            <Controller
              control={control}
              name="country"
              render={({ field }): JSX.Element => (
                <FormControl isInvalid={!!formState.errors?.country}>
                  <FormLabel htmlFor="nationality">{t('CountryOfCreation')}</FormLabel>
                  <Select
                    id="nationality"
                    isDisabled={isVerified}
                    menuPlacement="auto"
                    options={alpha3CountryOptions}
                    {...field}
                  />
                  <ErrorMessage error={formState.errors?.country?.value} />
                </FormControl>
              )}
            />
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
            <FormControl isInvalid={!!formState.errors?.shareCapital}>
              <FormLabel htmlFor="socialCapital">{t('ShareCapital')}</FormLabel>
              <InputGroup>
                <Input
                  id="socialCapital"
                  isDisabled={isVerified}
                  step="0.01"
                  type="number"
                  {...register('shareCapital')}
                />
                <InputRightAddon>€</InputRightAddon>
              </InputGroup>
              <ErrorMessage error={formState.errors?.shareCapital} />
            </FormControl>
            {isEditing ? (
              <Controller
                control={control}
                name="capitalType"
                render={({ field }): JSX.Element => (
                  <FormControl isInvalid={!!formState.errors?.capitalType}>
                    <FormLabel htmlFor="capitalType">{t('CapitalType')}</FormLabel>
                    <Select
                      id="capitalType"
                      isDisabled={isVerified}
                      menuPlacement="auto"
                      options={capitalTypeOptions}
                      getOptionLabel={(option): string =>
                        option.label ? i18nCapitalType(option.label as IntlCapitalTypeValues) : ''
                      }
                      {...field}
                    />
                    <ErrorMessage error={formState.errors?.capitalType?.value} />
                  </FormControl>
                )}
              />
            ) : (
              <FormControl />
            )}
          </Stack>
          {isEditing && (
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.nominalValue}>
                <FormLabel htmlFor="nominalCapital">{t('NominalValueOfShare')}</FormLabel>
                <InputGroup>
                  <Input
                    id="nominalCapital"
                    step="0.01"
                    type="number"
                    {...register('nominalValue')}
                  />
                  <InputRightAddon>€</InputRightAddon>
                </InputGroup>
                <ErrorMessage error={formState.errors?.nominalValue} />
              </FormControl>
              <FormControl />
            </Stack>
          )}
          <Stack bg="gray.50" borderRadius="md" p="3" spacing="2">
            <Text fontSize="lg" fontWeight="bold">
              {t('Headquarters')}
            </Text>
            <Stack spacing="4">
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.address?.line}>
                  <FormLabel htmlFor="line">{t('Address', { nb: 1 })}</FormLabel>
                  <Input
                    id="line"
                    isDisabled={isVerified}
                    type="string"
                    {...register('address.line')}
                  />
                  <ErrorMessage error={formState.errors?.address?.line} />
                </FormControl>
                <FormControl isInvalid={!!formState.errors?.address?.city}>
                  <FormLabel htmlFor="city">{t('City')}</FormLabel>
                  <Input
                    id="city"
                    isDisabled={isVerified}
                    type="string"
                    {...register('address.city')}
                  />
                  <ErrorMessage error={formState.errors?.address?.city} />
                </FormControl>
              </Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
                <FormControl isInvalid={!!formState.errors?.address?.postalCode}>
                  <FormLabel htmlFor="zipcode">{t('PostalCode', { nb: 1 })}</FormLabel>
                  <Input
                    id="zipcode"
                    isDisabled={isVerified}
                    type="string"
                    {...register('address.postalCode')}
                  />
                  <ErrorMessage error={formState.errors?.address?.postalCode} />
                </FormControl>
                <Controller
                  control={control}
                  name="address.country"
                  render={({ field }): JSX.Element => (
                    <FormControl isInvalid={!!formState.errors.address?.country}>
                      <FormLabel htmlFor="country">{t('Country')}</FormLabel>
                      <Select
                        id="country"
                        isDisabled={isVerified}
                        menuPlacement="auto"
                        options={alpha3CountryOptions}
                        {...field}
                      />
                      <ErrorMessage error={formState.errors.address?.country?.value} />
                    </FormControl>
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </>
  );
}

export type ExistingCompanyInformationsProps = Props;
