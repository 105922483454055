import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { XCircleIcon } from '@heroicons/react/outline';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  useCloseOpportunityMutation,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import { useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';

import { OpportunityOperationCloseAlertModal } from './OpportunityOperationCloseAlertModal';

export function OpportunityOperationCloseAlert(): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const confirmRef = useRef(null);

  const confirmDeleteModal = useDisclosure();

  const successToast = useSuccessToast();
  const errorToast = useErrorToast();

  const [closeOpportunity, { loading: closeOpportunityLoading }] = useCloseOpportunityMutation();

  const { refetch, loading: operationLoading } = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
    notifyOnNetworkStatusChange: true,
  });

  const handleCloseOpportunity = (): void => {
    closeOpportunity({
      variables: { operationId },
      onCompleted: () => {
        refetch();
        confirmDeleteModal.onClose();
        successToast({ title: t('OperationClosedSuccessfully') });
      },
      onError: () => {
        errorToast({ title: t('OperationClosedError') });
      },
    });
  };

  return (
    <>
      <Alert boxShadow="md" status="info">
        <Stack
          alignItems="stretch"
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          spacing="4"
          width="full"
        >
          <HStack alignItems="flex-start" spacing="0">
            <AlertIcon as={XCircleIcon} />
            <Stack spacing="0">
              <AlertTitle fontWeight="bold">{t('OperationToBeClosedPrematurely')}</AlertTitle>
              <AlertDescription>{t('OperationToBeClosedPrematurelyDescription')}</AlertDescription>
            </Stack>
          </HStack>
          <HStack alignItems="center" ml={{ base: '0', md: '4' }}>
            <Button className="confirm" fontSize="sm" onClick={confirmDeleteModal.onOpen}>
              {t('CloseOperationPrematurely')}
            </Button>
          </HStack>
        </Stack>
      </Alert>
      <OpportunityOperationCloseAlertModal
        isOpen={confirmDeleteModal.isOpen}
        leastDestructiveRef={confirmRef}
        onClose={confirmDeleteModal.onClose}
        onSubmit={handleCloseOpportunity}
        isLoading={closeOpportunityLoading || operationLoading}
      />
    </>
  );
}
