import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { IntlHoldingMethodTypeValues } from '@blockpulse3/data/locales/types';

import { ErrorMessage, ResponsiveModal, ResponsiveModalFooter, ResponsiveModalProps } from '../';
import { fiscalAccountDefaults, fiscalAccountSchema } from './schema';
import { IFiscalAccountForm } from './types';

type Props = {
  onSubmit: (data: IFiscalAccountForm) => void;
  formPartialValues?: Partial<IFiscalAccountForm>;
} & Omit<ResponsiveModalProps, 'children'>;

/**
 * FiscalAccountFormModal.
 * Modal form to create a fiscal account. A modal forces a reset.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function FiscalAccountFormModal({
  onSubmit,
  formPartialValues,
  ...props
}: Props): JSX.Element {
  const t = useTranslations();
  const i18nHoldingMethodType = useTranslations('HoldingMethodTypeValues');

  /* ** Overwrite default values with existing option ** */
  const defaultValues: IFiscalAccountForm = useMemo(() => {
    return {
      ...fiscalAccountDefaults,
      ...formPartialValues,
    };
  }, [formPartialValues]);

  const { register, formState, handleSubmit, reset, watch } = useForm<IFiscalAccountForm>({
    defaultValues,
    resolver: yupResolver(fiscalAccountSchema),
    shouldUseNativeValidation: false,
  });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [reset, defaultValues]);

  const holdingMethod = watch('holdingMethod');
  const id = watch('id');

  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    // Prevent validation of parent forms
    event.stopPropagation();
    return handleSubmit(onSubmit)(event);
  };

  return (
    <ResponsiveModal {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {id
            ? t('ModifyMyHoldingMethod', {
                holdingMethod: i18nHoldingMethodType(holdingMethod as IntlHoldingMethodTypeValues),
              })
            : t('AddHoldingMethod', {
                holdingMethod: i18nHoldingMethodType(holdingMethod as IntlHoldingMethodTypeValues),
              })}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="fiscal-account" onSubmit={handleFormSubmit}>
            <Stack spacing="4">
              <FormControl isInvalid={!!formState.errors.accountNumber}>
                <FormLabel htmlFor="accountNumber">{t('SecuritiesAccountNumber')}</FormLabel>
                <Input id="accountNumber" type="text" {...register('accountNumber')} />
                <ErrorMessage error={formState.errors.accountNumber} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.bankName}>
                <FormLabel htmlFor="bankName">{t('Bank', { nb: 1 })}</FormLabel>
                <Input id="bankName" type="text" {...register('bankName')} />
                <ErrorMessage error={formState.errors?.bankName} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.bankName}>
                <FormLabel htmlFor="bankCode">{t('BankCode')}</FormLabel>
                <Input id="bankCode" type="text" {...register('bankCode')} />
                <ErrorMessage error={formState.errors?.bankCode} />
              </FormControl>
              <FormControl isInvalid={!!formState.errors?.bankEmail}>
                <FormLabel htmlFor="bankerEmail">{t('BankContactEmail')}</FormLabel>
                <Input id="bankerEmail" type="email" {...register('bankEmail')} />
              </FormControl>
            </Stack>
          </form>
        </ModalBody>
        <Divider />
        <ResponsiveModalFooter>
          <Button form="fiscal-account" type="submit">
            {t('Save')}
          </Button>
        </ResponsiveModalFooter>
      </ModalContent>
    </ResponsiveModal>
  );
}

export type FiscalAccountFormModalProps = Props;
