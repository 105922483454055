import { InfoIcon } from '@chakra-ui/icons';
import {
  Card,
  CardBody,
  Flex,
  HStack,
  Heading,
  Hide,
  Icon,
  Skeleton,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { isFeatureEnabled, routes } from '@blockpulse3/data/shared';
import {
  AssetType,
  FundraisingWorkflowType,
  OperationStatus,
  OperationType,
  SubscriptionSide,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import {
  CompanyIdentityAvatar,
  ErrorQueryCard,
  SubscriptionBadge,
  useErrorToast,
} from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

type Props = unknown;

/**
 * SubscriptionHeader.
 *
 * @returns {JSX.Element}
 */
export function SubscriptionHeader(): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();
  const { subscriptionId = '', companyId } = useParams();

  const { identityId } = useIdentity();

  const errorToast = useErrorToast();

  const { data, loading, error } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
    onError: () => {
      if (companyId) {
        navigate(generatePath(routes.company.href, { companyId }));
      } else {
        navigate(routes.me.href);
      }
      errorToast({ title: t('SubscriptionRetrievalError') });
    },
  });

  if (loading || !data) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard />;
  }

  const { subscription } = data;

  const isAllInSubscriptionWorkflow =
    subscription.operation?.fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;

  const tooltipLabel = isAllInSubscriptionWorkflow
    ? 'AllStepsToBeCompletedByDateAllIn'
    : 'AllStepsToBeCompletedByDate';

  const endDate = subscription.operation.endSubscriptionDate;
  const isClosed = [
    OperationStatus.CLOSED,
    OperationStatus.REVIEWED,
    OperationStatus.FINALIZED,
  ].includes(subscription.operation.status);

  const isFeatureFinancingInfo = isFeatureEnabled('financingInfoBanner');

  return (
    <Card>
      <Flex as={CardBody} direction={{ base: 'column', md: 'row' }}>
        <Hide below="md">
          <CompanyIdentityAvatar
            boxSize="32"
            src={subscription.operation.company.identity?.profilePicture}
          />
        </Hide>
        <Stack
          alignItems="flex-start"
          justifyContent="center"
          pb={{ base: '4', md: '0' }}
          px={{ base: '0', md: '6' }}
          width="full"
        >
          <Text color="gray.500" fontSize="xs" fontWeight="semibold" textTransform="uppercase">
            {subscription.operation.type === OperationType.FUNDRAISING &&
              (subscription.operation.assetType === AssetType.BOND
                ? t('BondIssuance')
                : t('CapitalIncrease'))}
            {subscription.operation.type === OperationType.SECONDARY && t('TransferOfShare')}
          </Text>
          <Heading fontWeight="bold">{subscription?.operation.name}</Heading>
          <SubscriptionBadge
            operationType={subscription.operation.type}
            subscriptionSide={subscription.side || SubscriptionSide.BUYER}
          />
        </Stack>
        <Flex
          alignItems="flex-end"
          bg="gray.50"
          direction="column"
          flexShrink="0"
          justifyContent="center"
          p="5"
          rounded="sm"
        >
          {endDate ? (
            <>
              <HStack>
                <Text color="gray.600" fontWeight="500">
                  {t('FinishOn')}
                </Text>
                {!isClosed && (
                  <Tooltip
                    hasArrow
                    placement="top"
                    label={`${t(tooltipLabel, {
                      date: formatDate(endDate, 'DD MMM YYYY à HH:mm z'),
                    })}${
                      isFeatureFinancingInfo
                        ? ` (${t('FinancingInfoAmountValidation', {
                            date: formatDate(endDate, 'DD MMM YYYY à HH:mm z'),
                          })})`
                        : ''
                    }`}
                  >
                    <Icon as={InfoIcon} color="gray.600" />
                  </Tooltip>
                )}
              </HStack>
              <Text color="gray.600" fontSize="2xl" fontWeight="600">
                {formatDate(endDate, 'D MMMM YYYY')}
              </Text>
              {!isClosed && (
                <Text color="gray.600" fontSize="sm" fontWeight="600">
                  {t('RemainingDays', { nb: subscription?.operation?.remainingDays })}
                </Text>
              )}
            </>
          ) : (
            <>
              <Text color="gray.600" fontWeight="500">
                {t('OpenMarket')}
              </Text>
              <Text color="gray.600" fontWeight="500">
                {t('Continuous').toLowerCase()}
              </Text>
            </>
          )}
        </Flex>
      </Flex>
    </Card>
  );
}

export type SubscriptionHeaderProps = Props;
