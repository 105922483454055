export enum SubscriptionStepType {
  MIFID = 'MIFID',
  DETAILS = 'DETAILS',
  HOLDING_METHOD = 'HOLDING_METHOD',
  DOCUMENT_SIGNATURE = 'DOCUMENT_SIGNATURE',
  IDENTITY_CHOICE = 'IDENTITY_CHOICE',
  IDENTITY_VERIFICATION = 'IDENTITY_VERIFICATION',
  PAYMENT = 'PAYMENT',
  SALE = 'SALE',
  SWAN_ONBOARDING = 'SWAN_ONBOARDING',
  PURCHASE_INTENT = 'PURCHASE_INTENT',
}
