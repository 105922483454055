import { Button } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import {
  SubscriptionInfosFragment,
  useGetSubscriptionQuery,
  useValidateSubscriptionMifidMutation,
} from '@blockpulse3/graphql/hooks';
import { useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';
import { useManagerRole } from '@blockpulse3/web-client/auth';

type Props = {
  subscriptionId: SubscriptionInfosFragment['id'];
};

export function ValidateMifid({ subscriptionId }: Props): JSX.Element | null {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const [validateMifid, { loading: loadingMifid }] = useValidateSubscriptionMifidMutation();

  const { refetch, loading } = useGetSubscriptionQuery({
    variables: { subscriptionId },
    skip: !subscriptionId,
  });

  const isUserAuthorized = useManagerRole({ subscriptionId });
  if (!isUserAuthorized) return null;

  const handleMifidValidation = (): void => {
    validateMifid({
      variables: {
        validateSubscriptionMifidInput: {
          subscriptionId,
        },
      },
      onCompleted: () => {
        successToast({
          title: t('QuestionnaireValidated'),
        });
        refetch();
      },
      onError: (error) => {
        errorToast({
          title: t('QuestionnaireValidationError'),
          description: error.message,
        });
      },
    });
  };

  return (
    <Button
      isDisabled={loadingMifid || loading}
      isLoading={loadingMifid || loading}
      size="sm"
      variant="secondary"
      onClick={handleMifidValidation}
    >
      {t('Validate')}
    </Button>
  );
}
