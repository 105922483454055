import {
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Icon,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { DownloadIcon } from '@heroicons/react/solid';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  CompanyType,
  OperationEntityInfosFragment,
  useGenerateRmtExtractUrlMutation,
  useGenerateRmtUrlMutation,
  useGetCompanyQuery,
  useGetCompanyShareTransferRegisterQuery,
  useGetOperationShareTransferRegisterQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { ShareTransferRegisterTable } from './ShareTransferRegisterTable';

type Props = {
  operation?: OperationEntityInfosFragment | undefined;
};

/**
 * ShareTransferRegister.
 * RMT view.
 *
 * @returns {JSX.Element}
 */
export function ShareTransferRegister({ operation }: Props): JSX.Element | null {
  const t = useTranslations();

  const { companyId = '' } = useParams();

  const companyReq = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });

  const companyRegisterReq = useGetCompanyShareTransferRegisterQuery({
    variables: { companyId },
    skip: !companyId || !!operation?.id,
  });
  const operationRegisterReq = useGetOperationShareTransferRegisterQuery({
    variables: { operationId: operation?.id || '' },
    skip: !operation?.id,
  });

  const [generateRmtUrl, { loading: isRmtLoading }] = useGenerateRmtUrlMutation();
  const [generateRmtExtractUrl, { loading: isRmtExtractLoading }] =
    useGenerateRmtExtractUrlMutation();

  const handleDownloadRmt = (): void => {
    if (companyId) {
      generateRmtUrl({
        variables: { companyId },
        onCompleted: (data) => {
          window.open(data?.generateRmtUrl, '_blank');
        },
      });
    }
    if (operation?.id) {
      generateRmtExtractUrl({
        variables: { operationId: operation?.id },
        onCompleted: (data) => {
          window.open(data?.generateRmtExtractUrl, '_blank');
        },
      });
    }
  };

  if (companyRegisterReq.loading || operationRegisterReq.loading) {
    return (
      <Skeleton>
        <Card h="270px" />
      </Skeleton>
    );
  }

  if (companyRegisterReq.error || operationRegisterReq.error) {
    return <ErrorQueryCard />;
  }

  const company = companyReq?.data?.company;
  const movements =
    companyRegisterReq.data?.getCompanyShareTransferRegister ||
    operationRegisterReq.data?.getOperationShareTransferRegister ||
    [];

  if (!movements.length) {
    return null;
  }

  return (
    <Card>
      <CardHeader
        as={Stack}
        direction={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
      >
        <Heading size="md">
          {operation ? operation.name + ' - ' : undefined}
          {t(
            operation || company?.type === CompanyType.PARTIAL
              ? 'ShareTransferRegisterExtract'
              : 'ShareTransferRegister',
          )}
        </Heading>
        {(companyId || operation?.id) && movements.length > 0 && (
          <Button
            isLoading={isRmtLoading || isRmtExtractLoading}
            loadingText={t('DocumentGenerationInProgress')}
            variant="secondary"
            width={{ base: 'fit-content' }}
            onClick={(): void => handleDownloadRmt()}
          >
            <HStack>
              <Text>{t('ExportAction')}</Text>
              <Icon as={DownloadIcon} boxSize="5" color="secondary" />
            </HStack>
          </Button>
        )}
      </CardHeader>
      <CardBody>
        <ShareTransferRegisterTable movements={movements} />
      </CardBody>
    </Card>
  );
}
