import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  HStack,
  Heading,
  Icon,
  Link,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { PencilIcon } from '@heroicons/react/solid';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  IdentityType,
  IdentityVerificationStatus,
  useGetCompanyQuery,
} from '@blockpulse3/graphql/hooks';
import { formatCountry, formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import { ErrorQueryCard, IdentityAvatar, VerificationBadge } from '@blockpulse3/ui/commons';
import {
  IdentityVerification,
  useAuthUser,
  useManagedIndividual,
} from '@blockpulse3/web-client/auth';

import { canUserUpdateCompany } from './utils';

type Props = {
  onOpen: () => void;
};

export function SettingsCompanyInformations({ onOpen }: Props): JSX.Element {
  const t = useTranslations();
  const i18nCapitalType = useTranslations('CapitalTypeValues');

  const { user: authUser } = useAuthUser();
  const { individual } = useManagedIndividual();
  const { companyId = '' } = useParams();
  const { data, loading, error, refetch } = useGetCompanyQuery({
    variables: { companyId },
    skip: !companyId,
  });
  const company = data?.company;

  const pappersLink = `https://www.pappers.fr/entreprise/${company?.registrationNumber}`;
  const shouldDisplayHelp = !company?.creationDate;

  const verificationStatus = useMemo(() => {
    switch (data?.company?.kybVerificationStatus) {
      case IdentityVerificationStatus.APPROVED:
        return 'valid';
      case IdentityVerificationStatus.PROCESSING:
        return 'in-progress';
      case IdentityVerificationStatus.PENDING:
        return 'pending';
      case IdentityVerificationStatus.REFUSED:
        return 'error-identity';
      default:
        return 'none';
    }
  }, [data?.company]);

  if (error || !data) {
    return (
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: 5, lg: 8 }}>
        <Text flexBasis={{ lg: 250 }} fontSize="lg" fontWeight="medium">
          {t('MyCompany')}
        </Text>
        <ErrorQueryCard h="100px" maxW={{ lg: '3xl' }} w="full" />
      </Stack>
    );
  }

  /* ** Should display verified green badge ** */
  const isVerified = data?.company.identity?.isVerified;

  const isEditDisabled = !canUserUpdateCompany(authUser, individual, company);

  return (
    <Stack
      alignItems="flex-start"
      direction={{ base: 'column', lg: 'row' }}
      spacing={{ base: 5, lg: 8 }}
    >
      <Stack
        alignItems={{ base: 'center', lg: 'flex-start' }}
        direction={{ base: 'row', lg: 'column' }}
        flexBasis={{ lg: 250 }}
      >
        <Text fontSize="lg" fontWeight="medium">
          {t('MyCompany')}
        </Text>
        {isVerified && <VerificationBadge status="valid" title={t('VerifiedCompany')} />}
      </Stack>
      <Skeleton isLoaded={!loading} maxWidth={{ lg: '3xl' }} width="full">
        <Card maxW={{ lg: '3xl' }} variant="divider-bottom">
          <CardHeader>
            <HStack spacing="3">
              <IdentityAvatar
                boxSize="16"
                src={company?.profilePicture || ''}
                type={IdentityType.COMPANY}
              />
              <Heading size="md">{company?.name}</Heading>
            </HStack>
          </CardHeader>
          <CardBody>
            <Stack spacing="4">
              {shouldDisplayHelp && (
                <Alert status="info">
                  <AlertIcon />
                  <Stack spacing="1">
                    <AlertTitle>{t('FrenchCompaniesInfoPublicBases')}</AlertTitle>
                    <AlertTitle>
                      <Link isExternal href={pappersLink}>
                        {t('ShowArrow')}
                      </Link>
                    </AlertTitle>
                  </Stack>
                </Alert>
              )}
              <HStack alignItems="flex-start" spacing="2">
                <Heading flex="4" size="xs">
                  {t('RegistrationNumber')}
                </Heading>
                <Text flex="6">{company?.registrationNumber || '-'}</Text>
              </HStack>
              <HStack alignItems="flex-start" spacing="2">
                <Heading flex="4" size="xs">
                  {t('RegistrationEntityCity')}
                </Heading>
                <Text flex="6">{company?.registrationEntity || '-'}</Text>
              </HStack>
              <HStack alignItems="flex-start" spacing="2">
                <Heading flex="4" size="xs">
                  {t('CorporateForm')}
                </Heading>
                <Text flex="6">{company?.corporateForm || '-'}</Text>
              </HStack>
              <HStack alignItems="flex-start" spacing="2">
                <Heading flex="4" size="xs">
                  {t('CreationDate')}
                </Heading>
                <Text flex="6">
                  {company?.creationDate ? formatDate(company?.creationDate) : '-'}
                </Text>
              </HStack>
              <HStack alignItems="flex-start" spacing="2">
                <Heading flex="4" size="xs">
                  {t('CountryOfCreation')}
                </Heading>
                <Text flex="6">{company?.country ? formatCountry(company?.country) : '-'}</Text>
              </HStack>
              <HStack alignItems="flex-start" spacing="2">
                <Heading flex="4" size="xs">
                  {t('ShareCapital')}
                </Heading>
                <Text flex="6">
                  {company?.shareCapital ? formatNumberCurrency(company.shareCapital) : '-'}
                </Text>
              </HStack>
              <HStack alignItems="flex-start" spacing="2">
                <Heading flex="4" size="xs">
                  {t('CapitalType')}
                </Heading>
                <Text flex="6">
                  {company?.capitalType ? i18nCapitalType(company.capitalType) : '-'}
                </Text>
              </HStack>
              <HStack alignItems="flex-start" spacing="2">
                <Heading flex="4" size="xs">
                  {t('NominalValueOfShare')}
                </Heading>
                <Text flex="6">
                  {company?.nominalValue ? formatNumberCurrency(company.nominalValue) : '-'}
                </Text>
              </HStack>
              <HStack alignItems="flex-start" spacing="2">
                <Heading flex="4" size="xs">
                  {t('Headquarters')}
                </Heading>
                <Stack flex="6" spacing="0">
                  <Text>{company?.address?.line || '-'}</Text>
                  <Text>
                    {company?.address?.postalCode || '-'} {company?.address?.city || '-'}
                  </Text>
                  <Text>
                    {company?.address?.country ? formatCountry(company?.address?.country) : '-'}
                  </Text>
                </Stack>
              </HStack>
              <Button isDisabled={isEditDisabled} variant="secondary" onClick={onOpen}>
                <Icon as={PencilIcon} mr="1" /> {t('Edit')}
              </Button>
            </Stack>
          </CardBody>
          <Divider />
          <CardFooter justifyContent="flex-end">
            <IdentityVerification refetchData={refetch} status={verificationStatus} />
          </CardFooter>
        </Card>
      </Skeleton>
    </Stack>
  );
}

export type SettingsCompanyInformationsProps = Props;
