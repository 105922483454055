import { Card, CardBody, Stack, useDisclosure } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  ManagementType,
  Manager,
  useDeleteManagerMutation,
  useGetManagementsQuery,
} from '@blockpulse3/graphql/hooks';
import { ConfirmModal, useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { PAGE_SIZE } from '../utils';
import { SpaceManagementsControls } from './SpaceManagementsControls';
import { SpaceManagementsTable } from './SpaceManagementsTable';

type Props = {
  type: ManagementType;
};

export function SpaceManagements({ type }: Props): JSX.Element {
  const t = useTranslations();

  const { spaceId: authSpaceId = '' } = useAuthUser();
  const { spaceId = authSpaceId } = useParams();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const confirmRef = useRef(null);
  const deleteManagerModal = useDisclosure();

  const [deleteManagerId, setDeleteManagerId] = useState<Manager['id'] | null>(null);

  const { refetch } = useGetManagementsQuery({
    variables: { first: PAGE_SIZE, spaceId, type },
    skip: true,
  });

  const [deleteManager] = useDeleteManagerMutation();

  const handleManagerDeleteModal = (managerId: Manager['id']): void => {
    setDeleteManagerId(managerId);
    deleteManagerModal.onOpen();
  };

  const handleManagerDelete = (): void => {
    if (!deleteManagerId) return;

    deleteManager({
      variables: {
        managerId: deleteManagerId,
      },
      onCompleted: () => {
        successToast({ title: t('DeleteManagerSuccess') });
        setDeleteManagerId(null);
        deleteManagerModal.onClose();
        refetch();
      },
      onError: () => {
        errorToast({ title: t('DeleteManagerError') });
        setDeleteManagerId(null);
        deleteManagerModal.onClose();
      },
    });
  };

  return (
    <Card>
      <CardBody as={Stack} spacing="4">
        <SpaceManagementsControls />
        <SpaceManagementsTable type={type} onRowDeleteClick={handleManagerDeleteModal} />
      </CardBody>
      <ConfirmModal
        isOpen={deleteManagerModal.isOpen}
        leastDestructiveRef={confirmRef}
        subtitle={t('DefinitiveAction')}
        title={t('ConfirmManagerDeleteTitle')}
        onClose={deleteManagerModal.onClose}
        onConfirm={handleManagerDelete}
      />
    </Card>
  );
}

export type SpaceManagementsProps = Props;
