import { Skeleton, Stack, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import { IdentityType, useGetMovementQuery } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import {
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
} from '@blockpulse3/ui/commons';

export function MovementPanelIdentityCard(): JSX.Element {
  const { companyId = '', movementId = '' } = useParams();

  const { data, loading, error } = useGetMovementQuery({
    variables: {
      companyId,
      movementId,
    },
    skip: !companyId || !movementId,
  });

  if (loading || !data || error) {
    return (
      <Stack px="4">
        <Skeleton h="80px" />
      </Stack>
    );
  }

  const { movement } = data;
  const movementIdentity = movement.fromWallet?.identity ?? movement.toWallet?.identity;

  const isLegal = movementIdentity?.type === IdentityType.COMPANY;

  const address = isLegal
    ? movementIdentity?.companyIdentity?.address
    : movementIdentity?.individualIdentity?.address;

  const name = `${movementIdentity?.name}${isLegal ? ` (${movementIdentity?.identifier})` : ''}`;

  const birthdate = movementIdentity?.individualIdentity?.birthdate;

  return (
    <IdentityCard px="4">
      <IdentityAvatar
        boxSize="14"
        src={movementIdentity?.profilePicture}
        type={movementIdentity?.type}
      />
      <Stack spacing="0">
        <IdentityCardTitle isChecked={movementIdentity?.isVerified}>
          <Text>{name}</Text>
        </IdentityCardTitle>
        <IdentityCardDescription>
          {birthdate && <Text>{formatDate(birthdate)}</Text>}
          <Text>{address?.formatted}</Text>
          <Text>{movementIdentity?.email}</Text>
          <Text>{movementIdentity?.phone}</Text>
        </IdentityCardDescription>
      </Stack>
    </IdentityCard>
  );
}
