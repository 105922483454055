import { SearchIcon } from '@chakra-ui/icons';
import { Icon, IconButton, Input, InputGroup, InputRightElement, Stack } from '@chakra-ui/react';
import { XCircleIcon } from '@heroicons/react/outline';
import { useTranslations } from 'use-intl';

type Props = unknown;

export function SpaceManagementsControls(): JSX.Element {
  const t = useTranslations();

  return (
    <Stack
      alignItems={{ base: 'flex-start', md: 'flex-end' }}
      direction={{ base: 'column', md: 'row' }}
    >
      <InputGroup width={{ base: 'full', md: '300px' }}>
        <Input isDisabled paddingRight="80px" placeholder={t('Search')} />
        <InputRightElement
          flexDirection="row-reverse"
          justifyContent="space-between"
          paddingX="1"
          width="80px"
        >
          <IconButton
            isDisabled
            aria-label="search-spaces"
            icon={<Icon as={SearchIcon} />}
            size="sm"
            variant="secondary"
          />
          <IconButton
            aria-label="reset"
            icon={<Icon as={XCircleIcon} />}
            size="sm"
            variant="ghost"
          />
        </InputRightElement>
      </InputGroup>
    </Stack>
  );
}

export type SpaceManagementsControlsProps = Props;
