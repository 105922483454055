import { Skeleton } from '@chakra-ui/react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import {
  FundraisingWorkflowType,
  OperationStatus,
  useGetOperationQuery,
  useUpdateFundraisingMutation,
} from '@blockpulse3/graphql/hooks';

import { getCrowdfundingFundraisingParameters } from '../../utils';
import { ICrowdfundingFundraisingParametersForm } from '../types';
import { NewCrowdfundingFundraisingInformations } from './NewCrowdfundingFundraisingInformations';

type Props = unknown;

/**
 * NewCrowdfundingFundraisingParamters.
 * First step of fundraising funnel, parameters.
 *
 * @returns {JSX.Element}
 */
export function NewCrowdfundingFundraisingParameters(): JSX.Element {
  const t = useTranslations();

  const { operationId = '', companyId = '' } = useParams();
  const navigate = useNavigate();

  const { data, loading, refetch } = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
    onCompleted: (data) => {
      if (data.operation.status !== OperationStatus.DRAFT) {
        navigate(generatePath(routes.company.href, { companyId }));
      }
    },
    onError: () => {
      navigate(generatePath(routes.company.href, { companyId }));
    },
  });

  const [updateFundraising, { loading: updateLoading }] = useUpdateFundraisingMutation();

  const defaultValues = getCrowdfundingFundraisingParameters(data?.operation);

  const handleFormSubmit = (data: ICrowdfundingFundraisingParametersForm): void => {
    const selectedFiscalAdvantages = data.allowedFiscalAdvantages.map(
      (fiscalAdvantage) => fiscalAdvantage.value,
    );

    const isAllInFundraising = data.workflowType === FundraisingWorkflowType.ALL_IN;
    const assetId = data.asset.value !== data.asset.type ? data.asset.value : undefined;
    const assetType = data.asset.type;

    updateFundraising({
      variables: {
        updateFundraisingInput: {
          operationId,
          name: data.name,
          pricePerShare: data.pricePerShare,
          subscriptionPeriod: data.subscriptionPeriod.value,
          assetId,
          assetType,
          allowedFiscalAdvantages: selectedFiscalAdvantages,
          hardCap: data.hardCap,
          workflowType: data.workflowType,
          minimalAmount: data.minimalAmount,
          maximalAmount: data.maximalAmount,
          hasMifid: data.hasMifid,
          softCap: data.softCap,
          closingDate: isAllInFundraising ? data.closingDate : null,
        },
      },
      onCompleted: () => {
        if (companyId) {
          navigate('../' + routes.company.newFundraising.crowdfunding.edit.documentation.href);
          refetch();
        }
      },
    });
  };

  return (
    <Skeleton isLoaded={!loading} w="full">
      <NewCrowdfundingFundraisingInformations
        defaultValues={defaultValues}
        isLoading={updateLoading}
        loadingText={t('Update')}
        onSubmit={handleFormSubmit}
      />
    </Skeleton>
  );
}

export type NewCrowdfundingFundraisingParametersProps = Props;
