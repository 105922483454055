import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';
import { ErrorMessage } from '@blockpulse3/ui/commons';

import { schema } from './schema';
import { IUpdateEmailForm } from './types';

type Props = {
  defaultValues?: IUpdateEmailForm;
  onSubmit?: (data: IUpdateEmailForm) => void;
};

/**
 * UpdateEmailForm.
 * Form to update the email of the user.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function UpdateEmailForm({ defaultValues, onSubmit = noop }: Props): JSX.Element {
  const t = useTranslations();

  /* ** Control if the form is "touched (!= changed) ** */
  const [isTouched, setIsTouched] = useState<boolean>(false);
  /* ** Update email form ** */
  const { register, formState, handleSubmit } = useForm<IUpdateEmailForm>({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  /* ** Submit button disabled state ** */
  const isDisabled = useMemo(() => {
    return !formState.isValid || !isTouched;
  }, [isTouched, formState.isValid]);

  const handleFormTouched = (): void => {
    if (!isTouched) {
      setIsTouched(true);
    }
  };

  const handleFormSubmit: SubmitHandler<IUpdateEmailForm> = (data) => {
    onSubmit(data);
  };

  return (
    <form onChange={handleFormTouched} onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: 5, lg: 8 }}>
        <Text flexBasis={{ lg: 250 }} fontSize="lg" fontWeight="medium">
          Email
        </Text>
        <Card maxW={{ lg: '3xl' }} variant="divider-bottom" w="full">
          <CardBody>
            <Stack direction={{ base: 'column', md: 'row' }} spacing="4">
              <FormControl isInvalid={!!formState.errors?.email} w={{ base: 'full', md: '49%' }}>
                <FormLabel htmlFor="email">Email</FormLabel>
                <Input id="email" type="text" {...register('email')} />
                <ErrorMessage error={formState.errors?.email} />
              </FormControl>
            </Stack>
          </CardBody>
          <Divider />
          <CardFooter justifyContent="flex-end">
            <Button disabled={isDisabled} type="submit">
              {t('Save')}
            </Button>
          </CardFooter>
        </Card>
      </Stack>
    </form>
  );
}

export type UpdateEmailFormProps = Props;
