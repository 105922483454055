import { InfoIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Card,
  HStack,
  Icon,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { FiscalAdvantage, noop } from '@blockpulse3/data/shared';
import {
  HoldingMethod,
  OperationStatus,
  useCreateFiscalAccountMutation,
  useGetFiscalAccountsQuery,
  useGetSubscriptionQuery,
  useUpdateFiscalAccountMutation,
  useUpdateSubscriptionHoldingMethodMutation,
} from '@blockpulse3/graphql/hooks';
import {
  ErrorQueryCard,
  FiscalAccountFormModal,
  HoldingMethodSelect,
  IFiscalAccountForm,
  useErrorToast,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { useIdentity, useManagedIndividual } from '@blockpulse3/web-client/auth';

import { isFiscalAdvantageAllowed } from './utils';

type Props = {
  allowedFiscalAdvantages?: FiscalAdvantage[];
  isSeller?: boolean;
  hasDefaultHoldingMethod?: boolean;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * SubscriptionHoldingMethod.
 * Does not render if subscription is a company.
 *
 * @param {Props}
 * @returns {JSX.Element | null}
 */
export function SubscriptionHoldingMethod({
  allowedFiscalAdvantages,
  isSeller = false,
  hasDefaultHoldingMethod = true,
  setIsLoading = noop,
}: Props): JSX.Element | null {
  const t = useTranslations();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const createFormModal = useDisclosure();
  const updateFormModal = useDisclosure();

  const { individual } = useManagedIndividual();
  const { identityId } = useIdentity();
  const { subscriptionId = '' } = useParams();

  const subscriptionReq = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });

  const fiscalAccountReq = useGetFiscalAccountsQuery({
    variables: { individualIdentityId: individual?.id || '' },
    skip: !individual?.id,
  });

  const [createFiscalAccount] = useCreateFiscalAccountMutation();
  const [updateFiscalAccount] = useUpdateFiscalAccountMutation();
  const [updateSubscriptionHoldingMethod] = useUpdateSubscriptionHoldingMethodMutation();

  const [fiscalAccountPartial, setFiscalAccountPartial] = useState<
    Partial<IFiscalAccountForm> | undefined
  >();

  const { subscription } = subscriptionReq.data || {};
  /* ** Allowed holding methods for filtering ** */
  const subscriptionAllowedFiscalAdvantages =
    allowedFiscalAdvantages || subscription?.operation?.allowedFiscalAdvantages || [];

  if (subscriptionReq.loading || fiscalAccountReq.loading) {
    return (
      <Skeleton>
        <Card h="200px" />
      </Skeleton>
    );
  }

  if (subscriptionReq.error || fiscalAccountReq.error) {
    return <ErrorQueryCard />;
  }

  if (!subscriptionReq.data || !fiscalAccountReq.data || !subscription) {
    return <ErrorQueryCard />;
  }

  /* ** Conditions to add and update holding method ** */
  const canAddHoldingMethod = isFiscalAdvantageAllowed(allowedFiscalAdvantages) && !isSeller;
  const isFundraisingClosed = [OperationStatus.FINALIZED, OperationStatus.CLOSED].includes(
    subscription?.operation.status,
  );

  const handleFiscalAccountCreateModalOpening = (holdingMethod: HoldingMethod): void => {
    setFiscalAccountPartial({ holdingMethod });
    createFormModal.onOpen();
  };

  const handleFiscalAccountUpdateModalOpening = (fiscalAccount: IFiscalAccountForm): void => {
    setFiscalAccountPartial(fiscalAccount);
    updateFormModal.onOpen();
  };

  const handleHoldingMethodChange = (holdingMethod: HoldingMethod): void => {
    if (!subscriptionId || !holdingMethod) return;

    setIsLoading(true);
    updateSubscriptionHoldingMethod({
      variables: {
        updateSubscriptionHoldingMethodInput: {
          subscriptionId,
          holdingMethod,
        },
      },
      onCompleted: async () => {
        await subscriptionReq.refetch();
        setIsLoading(false);
      },
      onError: () => {
        errorToast({ title: t('ErrorRecordingOwnershipMode') });
        setIsLoading(false);
      },
    });
  };

  const handleFiscalAccountCreate = async (data: IFiscalAccountForm): Promise<void> => {
    if (!individual?.id) return;

    createFiscalAccount({
      variables: {
        createFiscalAccountInput: {
          individualIdentityId: individual.id,
          accountNumber: data.accountNumber,
          holdingMethod: data.holdingMethod,
          bankName: data.bankName,
          bankCode: data.bankCode,
          bankEmail: data.bankEmail || null,
        },
      },
      onCompleted: (data) => {
        fiscalAccountReq.refetch();
        const fiscalAccount = data.createFiscalAccount;

        updateSubscriptionHoldingMethod({
          variables: {
            updateSubscriptionHoldingMethodInput: {
              subscriptionId: subscription.id,
              holdingMethod: fiscalAccount.holdingMethod,
            },
          },
          onCompleted: () => {
            successToast({ title: t('HoldingMethodRecorded') });
            createFormModal.onClose();
            subscriptionReq.refetch();
          },
          onError: () => {
            errorToast({ title: t('ErrorRecordingOwnershipMode') });
          },
        });
      },
      onError: () => {
        errorToast({ title: t('ErrorAddingOwnershipMode') });
      },
    });
  };

  const handleFiscalAccountUpdate = async (data: IFiscalAccountForm): Promise<void> => {
    if (!individual?.id || !data.id) return;

    updateFiscalAccount({
      variables: {
        updateFiscalAccountInput: {
          fiscalAccountId: data.id,
          individualIdentityId: individual?.id,
          accountNumber: data.accountNumber,
          bankName: data.bankName,
          bankCode: data.bankCode,
          bankEmail: data.bankEmail || null,
        },
      },
      onCompleted: (data) => {
        fiscalAccountReq.refetch();
        const fiscalAccount = data.updateFiscalAccount;

        updateSubscriptionHoldingMethod({
          variables: {
            updateSubscriptionHoldingMethodInput: {
              subscriptionId: subscription.id,
              holdingMethod: fiscalAccount.holdingMethod,
            },
          },
          onCompleted: () => {
            successToast({ title: t('HoldingMethodRecorded') });
            updateFormModal.onClose();
            subscriptionReq.refetch();
          },
          onError: () => {
            errorToast({ title: t('ErrorRecordingOwnershipMode') });
          },
        });
      },
      onError: () => {
        errorToast({ title: t('ErrorRecordingOwnershipMode') });
      },
    });
  };

  return (
    <>
      <Stack spacing="2">
        {!isSeller && (
          <HStack>
            <Text fontWeight="500">{t('OnTheSupport')}</Text>
            <Tooltip hasArrow label={t('FiscalAdvantageEligibilityInstructions')} placement="top">
              <Icon as={InfoIcon} color="gray.600" />
            </Tooltip>
          </HStack>
        )}
        <HoldingMethodSelect
          allowedFiscalAdvantages={subscriptionAllowedFiscalAdvantages}
          defaultValue={subscription?.holdingMethod}
          hasDefaultHoldingMethod={hasDefaultHoldingMethod}
          individualIdentityId={individual?.id || ''}
          isDisabled={isFundraisingClosed}
          onFiscalAccountCreate={handleFiscalAccountCreateModalOpening}
          onFiscalAccountUpdate={handleFiscalAccountUpdateModalOpening}
          onHoldingMethodChange={handleHoldingMethodChange}
        />
        {canAddHoldingMethod && (
          <Alert status="warning">
            <AlertIcon as={ExclamationIcon} />
            <AlertTitle>{t('PEAInvestmentInfo')}</AlertTitle>
          </Alert>
        )}
      </Stack>
      {createFormModal.isOpen && (
        <FiscalAccountFormModal
          formPartialValues={fiscalAccountPartial}
          isOpen={createFormModal.isOpen}
          onClose={createFormModal.onClose}
          onSubmit={handleFiscalAccountCreate}
        />
      )}
      {updateFormModal.isOpen && (
        <FiscalAccountFormModal
          formPartialValues={fiscalAccountPartial}
          isOpen={updateFormModal.isOpen}
          onClose={updateFormModal.onClose}
          onSubmit={handleFiscalAccountUpdate}
        />
      )}
    </>
  );
}
