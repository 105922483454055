import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { ISubscription } from '@blockpulse3/data/shared';
import { TableContainer } from '@blockpulse3/ui/commons';

import { InvitationRow } from './InvitationRow';
import { InvitationsTableEmpty } from './InvitationsTableEmpty';

type Props = {
  /* ** Table values ** */
  values: ISubscription[];
};

export function InvitationsTable({ values }: Props): JSX.Element {
  const t = useTranslations();

  const isEmpty = !values.length;

  return isEmpty ? (
    <InvitationsTableEmpty />
  ) : (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th width="fit-content" />
            <Th>{t('Operation', { nb: 1 })}</Th>
            <Th>{t('Type')}</Th>
            <Th>{t('CompanyName')}</Th>
            <Th>{t('InvitedIdentity')}</Th>
            <Th isNumeric>{t('Amount', { nb: 1 })}</Th>
            <Th>{t('EndDate')}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {values.map((subscription, index) => (
            <InvitationRow
              key={subscription.id + subscription.side + index}
              subscription={subscription}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

export type InvitationsTableProps = Props;
