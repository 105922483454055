import { Spinner, Stack, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { useRefundSubscriptionMutation } from '@blockpulse3/graphql/hooks';

let isRefundInitiated = false;

export function InitiateRefund(): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const amount = searchParams.get('amount');
  const label = searchParams.get('label');
  const bankTransferId = searchParams.get('bankTransferId');

  const navigate = useNavigate();

  const [refundSubscription] = useRefundSubscriptionMutation();

  useEffect(() => {
    if (subscriptionId && amount && !isRefundInitiated) {
      isRefundInitiated = true;

      refundSubscription({
        variables: {
          refundSubscriptionInput: {
            subscriptionId,
            bankTransferId: bankTransferId || undefined,
            amount: parseFloat(amount),
            label: label || '',
          },
        },
        onCompleted: (data) => {
          setTimeout(() => {
            const initiateRefundUrl = data.refundSubscription;
            window.location.href = initiateRefundUrl;
          }, 2000);
        },
        onError: (err) => {
          navigate(routes.closePopup.href);
        },
      });
    }
  }, [subscriptionId, amount, searchParams, navigate, refundSubscription]);

  return (
    <Stack alignItems="center" h="full" justifyContent="center" py="4" spacing="4">
      <Stack
        alignItems="center"
        borderColor="gray.200"
        borderRadius="xl"
        borderStyle="solid"
        borderWidth="2px"
        justifyContent="center"
        p="4"
      >
        <Text color="gray.600" fontWeight="600">
          {t('PreparingCreditTransfer', { nb: subscriptionId ? 1 : 2 })}
        </Text>
        <Spinner color="gray.600" />
      </Stack>
    </Stack>
  );
}
