import { HStack, Stack, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { generatePath } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { MovementInfosFragment } from '@blockpulse3/graphql/hooks';
import { formatDate } from '@blockpulse3/helpers';
import { AssetBadge, IdentityAvatar, TableContainer } from '@blockpulse3/ui/commons';
import { useDisclosureLink } from '@blockpulse3/ui/ui-hooks';
import { MovementPanel } from '@blockpulse3/web-client/operation/commons';

type Props = {
  movements: MovementInfosFragment[];
  isPreview?: boolean;
};

/**
 * ShareTransferRegisterTable.
 * RMT table.
 *
 * @returns {JSX.Element}
 */
export function ShareTransferRegisterTable({ movements, isPreview = false }: Props): JSX.Element {
  const t = useTranslations();
  const i18nMovementType = useTranslations('MovementTypeValues');

  const rmtRoutes = routes.company.captable.rmt;

  const { isOpen, onOpen, onClose } = useDisclosureLink({
    hiddenHref: rmtRoutes.href,
    visibleHref: rmtRoutes.movement.href,
  });

  const handleRowClick = (movementId: string): void => {
    if (isPreview) return;
    onOpen(generatePath(rmtRoutes.movement.href, { movementId }));
  };

  return (
    <>
      {!isPreview && <MovementPanel isOpen={isOpen} onClose={onClose} />}
      <TableContainer maxHeight="none">
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>{t('Number')}</Th>
              <Th>{t('Holder')}</Th>
              <Th>{t('Recipient', { nb: 1 })}</Th>
              <Th>{t('AssetsCount')}</Th>
              <Th textAlign="right">{t('MovementDate')}</Th>
              <Th textAlign="right">{t('NatureOfMovement')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {movements.map((movement) => (
              <Tr key={movement.id} role="button" onClick={(): void => handleRowClick(movement.id)}>
                <Td>
                  <Text>{movement.number === 0 ? '-' : movement.number}</Text>
                </Td>
                <Td>
                  {movement.fromWallet?.identity ? (
                    <HStack spacing="2.5">
                      <IdentityAvatar identity={movement.fromWallet.identity} />
                      <Stack spacing="1">
                        <Text fontWeight="600">{movement.fromWallet?.identity?.name}</Text>
                        {movement.fromWalletIndex && (
                          <Text color="gray.500" fontWeight="400">
                            {movement.fromWalletIndex}
                          </Text>
                        )}
                      </Stack>
                    </HStack>
                  ) : (
                    <Text fontWeight="600" textAlign="left">
                      -
                    </Text>
                  )}
                </Td>
                <Td>
                  {movement.toWallet?.identity ? (
                    <HStack spacing="2.5">
                      <IdentityAvatar identity={movement.toWallet.identity} />
                      <Stack spacing="1">
                        <Text fontWeight="600">{movement.toWallet?.identity?.name}</Text>
                        {movement.toWalletIndex && (
                          <Text color="gray.500" fontWeight="400">
                            {movement.toWalletIndex}
                          </Text>
                        )}
                      </Stack>
                    </HStack>
                  ) : (
                    <Text fontWeight="600" textAlign="left">
                      -
                    </Text>
                  )}
                </Td>
                <Td>
                  <AssetBadge type={movement.asset.type} value={movement.amount} />
                </Td>
                <Td textAlign="right">
                  <Text fontWeight="600">{formatDate(movement.createdAt, 'D MMMM YYYY')}</Text>
                </Td>
                <Td color="secondary" fontWeight="600" textAlign="right">
                  <Text fontWeight="600">
                    {movement?.type ? i18nMovementType(movement?.type) : ''}
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
