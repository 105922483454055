import { ThemeOverride, extendTheme } from '@chakra-ui/react';

import { colors } from './colors';
import { components } from './components';

const layerStyles = {
  emptyState: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: '2px',
    borderStyle: 'dashed',
    borderColor: 'gray.300',
    borderRadius: 'md',
    spacing: 2,
    p: [5, 12],
    minHeight: '120px',
    textColor: 'gray.900',
    textAlign: ['center', 'inherit'],
    fontWeight: 400,
  },
  border: {
    borderColor: 'gray.300',
    borderStyle: 'solid',
    borderWidth: '1px',
  },
  container: {
    display: 'flex',
    justifyContent: 'start',
    w: 'full',
    h: 'full',
    p: 4,
    bg: 'white',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.300',
    borderRadius: '3px',
    boxShadow: 'md',
    whiteSpace: 'normal',

    textAlign: 'start',
    _hover: {
      bg: 'blackAlpha.50',
    },
  },
};

const styles = {
  global: {
    'html': {
      fontSize: { base: '14px', md: '16px' },
    },
    '.chakra-accordion__button': {
      border: '0px !important',
    },
    '.chakra-tabs__tablist': {
      'msOverflowStyle': 'none',
      'scrollbarWidth': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    ':not(.chakra-dont-set-collapse) > .chakra-collapse': {
      overflow: 'initial !important',
    },

    'input': {
      '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active':
        {
          WebkitBoxShadow: '0 0 0 30px white inset !important',
        },
    },
  },
};

export const theme: ThemeOverride = extendTheme({
  styles,
  colors,
  layerStyles,
  components,
});
