import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';

import {
  AssetType,
  CompanyInfosFragment,
  FundraisingWorkflowType,
  IdentityType,
  IdentityVerificationStatus,
  OperationInfosFragment,
  SubscriptionInfosFragment,
} from '@blockpulse3/graphql/hooks';
import { capitalizeFirstLetter, formatDate } from '@blockpulse3/helpers';
import {
  AllowedFiscalAdvantageOption,
  IOperationSubscriber,
} from '@blockpulse3/web-client/operation/commons';

import {
  ICrowdfundingFundraisingParametersForm,
  crowdfundingParametersDefaultValues,
  crowdfundingSubscriptionPeriodOptions,
} from './NewCrowdfundingFundraising';
import {
  IPrivateFundraisingParametersForm,
  privateParametersDefaultValues,
  privateSubscriptionPeriodOptions,
} from './NewPrivateFundraising';

/**
 * privateFundraisingParametersDefaults.
 * Returns default private fundraising form values from a workspace.
 *
 * @param {CompanyInfosFragment} company
 * @returns {IPrivateFundraisingParametersForm}
 */
export function privateFundraisingParametersDefaults(
  company: CompanyInfosFragment,
  opportunity?: OperationInfosFragment,
): IPrivateFundraisingParametersForm {
  const date = dayjs();

  /* ** Default fundraising name ** */
  const name = `${company?.name} - ${capitalizeFirstLetter(date.format('MMMM'))} ${date.format(
    'YYYY',
  )}`;

  const defaultFundraisingParams = {
    ...privateParametersDefaultValues,
    name,
    asset: opportunity?.asset
      ? { label: opportunity.asset.name, value: opportunity.asset.id, type: opportunity.asset.type }
      : {
          label: 'default',
          value: AssetType.ORDINARY_SHARE,
          type: AssetType.ORDINARY_SHARE,
        },
    pricePerShare: opportunity?.pricePerShare || company?.nominalValue || 0,
    valuation: company?.shareCapital || 0,
  };

  return defaultFundraisingParams;
}

/**
 * crowdfundingFundraisingParametersDefaults.
 * Returns default crowdfunding fundraising form values from a workspace.
 *
 * @param {CompanyInfosFragment} company
 * @returns {ICrowdfundingFundraisingParametersForm}
 */
export function crowdfundingFundraisingParametersDefaults(
  company: CompanyInfosFragment,
): ICrowdfundingFundraisingParametersForm {
  const privateDefaults = privateFundraisingParametersDefaults(company);

  return {
    ...privateDefaults,
    subscriptionPeriod: crowdfundingSubscriptionPeriodOptions[0],
    hardCap: null,
    softCap: null,
    workflowType: FundraisingWorkflowType.FIRST_IN,
    minimalAmount: null,
    maximalAmount: null,
    hasMifid: false,
    closingDate: null,
  };
}

/**
 * getPrivateFundraisingParameters.
 * Format fundraising query into a private form object.
 *
 * @param {OperationInfosFragment | undefined} operation
 * @returns {IFundraisingParametersForm}
 */
export function getPrivateFundraisingParameters(
  operation: OperationInfosFragment | undefined,
): IPrivateFundraisingParametersForm {
  if (!operation?.fundraising || operation?.data?.__typename !== 'FundraisingData') {
    return privateParametersDefaultValues;
  }

  // Default subscription period
  const selectedSubscriptionPeriod = privateSubscriptionPeriodOptions.find(
    (option) => option.value === operation?.subscriptionPeriod,
  );

  // Default fiscal advantages
  const fiscalAdvantagesOption: AllowedFiscalAdvantageOption[] =
    operation?.allowedFiscalAdvantages?.map((fiscalAdvantage) => {
      return {
        label: fiscalAdvantage.toString(),
        value: fiscalAdvantage,
      };
    }) || [];

  // Default asset
  const { asset } = operation;
  let label = 'default';
  let value = AssetType.ORDINARY_SHARE.toString();
  let assetType = AssetType.ORDINARY_SHARE;
  if (asset) {
    label = asset.name || 'default';
    value = asset.id;
    assetType = asset.type;
  }

  return {
    name: operation.name,
    asset: { label, value, type: assetType },
    pricePerShare: operation.pricePerShare || 1,
    subscriptionPeriod: selectedSubscriptionPeriod || privateSubscriptionPeriodOptions[0],
    valuation: operation.data?.valuation || privateParametersDefaultValues.valuation,
    allowedFiscalAdvantages: fiscalAdvantagesOption,
    isRelatedToSecondary: operation.transactionDebitDate ? true : false,
    transactionDebitDate: operation?.transactionDebitDate
      ? formatDate(operation.transactionDebitDate, 'YYYY-MM-DD')
      : privateParametersDefaultValues.transactionDebitDate,
  };
}

/**
 * getCrowdfundingFundraisingParameters.
 * Format fundraising query into a crowdfunding form object.
 *
 * @param {OperationInfosFragment | undefined} operation
 * @returns {ICrowdfundingFundraisingParametersForm}
 */
export function getCrowdfundingFundraisingParameters(
  operation: OperationInfosFragment | undefined,
): ICrowdfundingFundraisingParametersForm {
  if (!operation?.fundraising) {
    return crowdfundingParametersDefaultValues;
  }

  const { fundraising } = operation;

  const privateFundraisingParameters = getPrivateFundraisingParameters(operation);

  const selectedSubscriptionPeriod = crowdfundingSubscriptionPeriodOptions.find(
    (option) => option.value === operation.subscriptionPeriod,
  );

  let min = null;
  let max = null;

  const subscriptionDetailsStep = operation.subscriptionSteps?.find(
    (step) => step.__typename === 'SubscriptionDetailsStepType',
  );

  if (
    subscriptionDetailsStep &&
    subscriptionDetailsStep.__typename === 'SubscriptionDetailsStepType'
  ) {
    min = subscriptionDetailsStep.minimalAmount;
    max = subscriptionDetailsStep.maximalAmount;
  }

  const mifidStep = operation.subscriptionSteps?.find(
    (step) => step.__typename === 'MifidStepType',
  );
  const hasMifid = !!mifidStep;

  return {
    ...privateFundraisingParameters,
    subscriptionPeriod: selectedSubscriptionPeriod || crowdfundingSubscriptionPeriodOptions[0],
    hardCap: fundraising.hardCap ?? null,
    softCap: fundraising.softCap ?? null,
    workflowType: fundraising.workflowType ?? FundraisingWorkflowType.FIRST_IN,
    minimalAmount: min ?? null,
    maximalAmount: max ?? null,
    hasMifid,
    closingDate: operation.closingDate ? dayjs(operation.closingDate).format('YYYY-MM-DD') : null,
  };
}

/**
 * getPricePerShare.
 *
 * @param {BigNumber} valuation
 * @param {number} shareNumber
 * @returns {number}
 */
export function getPricePerShare(valuation: BigNumber, shareNumber: number): number {
  if (!valuation.isNaN()) {
    const shares = new BigNumber(shareNumber);
    return valuation.dividedBy(shares).dp(2).toNumber();
  }
  return 0;
}

/**
 * getValuation.
 *
 * @param {BigNumber} sharePrice
 * @param {number} shareNumber
 * @returns {number}
 */
export function getValuation(sharePrice: BigNumber, shareNumber: number): number {
  if (!sharePrice.isNaN()) {
    const shares = new BigNumber(shareNumber);
    return sharePrice.times(shares).dp(2).toNumber();
  }
  return 0;
}

/**
 * parseSubscriber.
 *
 * @param {SubscriptionInfosFragment} subscriber
 * @returns {IOperationSubscriber}
 */
export function parseSubscriber(subscriber: SubscriptionInfosFragment): IOperationSubscriber {
  if (subscriber.buyerIdentity?.type === IdentityType.COMPANY) {
    const companyRepIndividualIdentity =
      subscriber.buyerIdentity?.companyIdentity?.signer?.individualIdentity;
    return {
      isLegal: true,
      subscriber: {
        name: subscriber.buyerIdentity?.companyIdentity?.name || '',
        email: companyRepIndividualIdentity?.email || '',
        identificationNumber: subscriber.buyerIdentity?.companyIdentity?.registrationNumber || '',
        amount: subscriber.investAmount,
        verified:
          subscriber.buyerIdentity.verificationStatus === IdentityVerificationStatus.APPROVED,
        admissionFees: subscriber.admissionFees,
        minimalAmount: subscriber.minimalAmount || null,
        maximalAmount: subscriber.maximalAmount || null,
      },
    };
  }
  return {
    isLegal: false,
    subscriber: {
      firstName: subscriber.buyerIdentity?.individualIdentity?.firstName || '',
      lastName: subscriber.buyerIdentity?.individualIdentity?.lastName || '',
      email: subscriber.buyerIdentity?.individualIdentity?.email || '',
      amount: subscriber.investAmount,
      verified:
        subscriber.buyerIdentity?.verificationStatus === IdentityVerificationStatus.APPROVED,
      name: '', // TypeScript type check, isLegalSusbcriber(). TODO: refactor
      admissionFees: subscriber.admissionFees,
      minimalAmount: subscriber.minimalAmount || null,
      maximalAmount: subscriber.maximalAmount || null,
    },
  };
}
