import { OperationType } from '@blockpulse3/graphql/hooks';

import { OperationRow } from '../types';
import { OperationTableFundraisingRow } from './OperationTableFundraisingRow';
import { OperationTableOpportunityRow } from './OperationTableOpportunityRow';
import { OperationTableSecondaryRow } from './OperationTableSecondaryRow';
import { OperationTableUnknownRow } from './OperationTableUnknownRow';

type Props = {
  row: OperationRow;
};

/**
 * OperationTableRows.
 * Switch table to render different type of Operations, since the table mixes all types.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function OperationTableRows({ row }: Props): JSX.Element {
  switch (row.type) {
    case OperationType.SECONDARY:
      return <OperationTableSecondaryRow row={row} />;
    case OperationType.FUNDRAISING:
      return <OperationTableFundraisingRow row={row} />;
    case OperationType.OPPORTUNITY:
      return <OperationTableOpportunityRow row={row} />;
    default:
      return <OperationTableUnknownRow row={row} />;
  }
}

export type OperationTableRowsProps = Props;
