import { Heading, Stack } from '@chakra-ui/react';
import { useTranslations } from 'use-intl';

import { useAuthUser } from '@blockpulse3/web-client/auth';

import { SpaceHeader } from './SpaceHeader';

type Props = unknown;

export function SpaceDashboard(): JSX.Element {
  const t = useTranslations();
  const { user } = useAuthUser();

  const name = user?.individualIdentity?.firstName;

  return (
    <Stack spacing="6">
      <SpaceHeader />
      <Heading>{t('HelloName', { name })}</Heading>
    </Stack>
  );
}

export type SpaceDashboardProps = Props;
