import { IdentityType, RepresentativeRole } from '@blockpulse3/data/shared';
import { GetSpvQuery } from '@blockpulse3/graphql/hooks';
import { formatCountry, formatNationality } from '@blockpulse3/helpers';

import { spvShareholderFormDefaultValues } from './schema';
import { SPVShareholderForm } from './types';

export function getSPVShareholders(data: GetSpvQuery['company'] | undefined): SPVShareholderForm {
  if (!data) {
    return spvShareholderFormDefaultValues;
  }

  const shareholder = data?.companyRepresentative.find(
    (rep) => rep.role === RepresentativeRole.SHAREHOLDER,
  );

  const isCompany = shareholder?.representativeIdentity.type === IdentityType.COMPANY;
  const shareholderIndividual = shareholder?.representativeIdentity?.individualIdentity;
  const shareholderCompany = shareholder?.representativeIdentity?.companyIdentity;
  const filiation = shareholderIndividual?.filiation;

  const firstName =
    (isCompany ? data?.signer?.individualIdentity?.firstName : shareholderIndividual?.firstName) ||
    spvShareholderFormDefaultValues.firstName;
  const lastName =
    (isCompany
      ? data?.signer?.individualIdentity?.lastName
      : shareholderIndividual?.lastName || '') || spvShareholderFormDefaultValues.lastName;

  const email =
    (isCompany ? data?.signer?.individualIdentity?.email : shareholderIndividual?.email) ||
    spvShareholderFormDefaultValues.email;

  const _address = isCompany ? data?.address : shareholderIndividual?.address;

  const address: SPVShareholderForm['address'] = {
    line: _address?.line || spvShareholderFormDefaultValues.address.line,
    city: _address?.city || spvShareholderFormDefaultValues.address.city,
    postalCode: _address?.postalCode || spvShareholderFormDefaultValues.address.postalCode,
    country: {
      label: formatCountry(_address?.country || 'FRA'),
      value: _address?.country || spvShareholderFormDefaultValues.address.country.value,
    },
  };

  const position =
    (isCompany ? shareholderCompany?.signer?.individualRepresentative?.position : '') ||
    spvShareholderFormDefaultValues.position;

  return {
    address,
    firstName,
    lastName,
    email,
    nationality: {
      label: formatNationality(shareholderIndividual?.nationality || 'FRA'),
      value: shareholderIndividual?.nationality || 'FRA',
    },
    birthdate: shareholderIndividual?.birthdate || spvShareholderFormDefaultValues.birthdate,
    birthplace: shareholderIndividual?.birthplace || spvShareholderFormDefaultValues.birthplace,
    birthCityPostalCode:
      shareholderIndividual?.birthCityPostalCode ||
      spvShareholderFormDefaultValues.birthCityPostalCode,
    birthCountry: {
      label: formatCountry(shareholderIndividual?.birthCountry || 'FRA'),
      value: shareholderIndividual?.birthCountry || 'FRA',
    },
    preferredSharesPercentages: spvShareholderFormDefaultValues.preferredSharesPercentages,
    residenceCertificate: spvShareholderFormDefaultValues.residenceCertificate,
    motherFirstName: filiation?.motherFirstName || spvShareholderFormDefaultValues.motherFirstName,
    motherLastName: filiation?.motherLastName || spvShareholderFormDefaultValues.motherLastName,
    fatherFirstName: filiation?.fatherFirstName || spvShareholderFormDefaultValues.fatherFirstName,
    fatherLastName: filiation?.fatherLastName || spvShareholderFormDefaultValues.fatherLastName,
    corporateForm:
      shareholderCompany?.corporateForm || spvShareholderFormDefaultValues.corporateForm,
    creationDate: shareholderCompany?.creationDate || spvShareholderFormDefaultValues.creationDate,
    name: shareholderCompany?.name || spvShareholderFormDefaultValues.name,
    registrationEntity:
      shareholderCompany?.registrationEntity || spvShareholderFormDefaultValues.registrationEntity,
    registrationNumber:
      shareholderCompany?.registrationNumber || spvShareholderFormDefaultValues.registrationNumber,
    shareCapital: shareholderCompany?.shareCapital || spvShareholderFormDefaultValues.shareCapital,
    position,
  };
}
