import {
  Badge,
  Button,
  Card,
  CardBody,
  HStack,
  Icon,
  ModalHeader,
  Skeleton,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ChartPieIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CurrencyEuroIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  PencilIcon,
  TemplateIcon,
  UserGroupIcon,
} from '@heroicons/react/outline';
import {
  SortingState,
  Updater,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, resolvePath, useNavigate } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { IdentityVerificationStatus, routes } from '@blockpulse3/data/shared';
import {
  CompanyType,
  CompanyWithBankAccountListInfosFragment,
  Identity,
  IdentityType,
  useOnboardCompanyMutation,
} from '@blockpulse3/graphql/hooks';
import { formatDate, formatNumberCurrency } from '@blockpulse3/helpers';
import {
  ConfirmModal,
  IconButtonWithTooltip,
  IdentityAvatar,
  IdentityCard,
  IdentityCardDescription,
  IdentityCardTitle,
  IdentityValidationModal,
  PaginationButtons,
  TableContainer,
  useSuccessToast,
} from '@blockpulse3/ui/commons';
import { PaginationHandlers } from '@blockpulse3/ui/ui-hooks';
import { useManagerRole } from '@blockpulse3/web-client/auth';

import { AdminSpaceManagerModal } from '../AdminSpaces';
import { AdminCompaniesTypesModal } from './AdminCompaniesTypesModal';

type Props = PaginationHandlers<CompanyWithBankAccountListInfosFragment> & {
  onRowClick?: (id: CompanyWithBankAccountListInfosFragment['id']) => void;
  sorting: SortingState;
  onSortingChange: (sortFn: Updater<SortingState>) => void;
};

type CompanyIdAndType = {
  id: string;
  type: CompanyType;
};
/**
 * AdminCompaniesTable.
 * Display companies in a Table
 *
 * @returns {JSX.Element}
 */
export function AdminCompaniesTable({
  results,
  sorting,
  pageInfo,
  pageCount,
  currentPage,
  loading,
  isLoadingMore,
  handleNextPage,
  handlePreviousPage,
  onSortingChange,
}: Props): JSX.Element {
  const t = useTranslations();

  const navigate = useNavigate();

  const [identityId, setIdentityId] = useState<string>();
  const [companyIdAndType, setCompanyIdAndType] = useState<CompanyIdAndType | undefined>();
  const [onboardingLink, setOnboardingLink] = useState<string>();
  const [clickedRow, setClickedRow] = useState<string>();

  const isUserAuthorized = useManagerRole({ identityId });

  const confirmRef = useRef(null);

  const identityModal = useDisclosure();
  const companyTypeModal = useDisclosure();
  const onboardingModal = useDisclosure();
  const spaceManagerModal = useDisclosure();

  const { onCopy, value, setValue, hasCopied } = useClipboard('');
  const successToast = useSuccessToast();

  const [onboardCompany, { loading: onboardingCoLoading }] = useOnboardCompanyMutation();

  useEffect(() => {
    if (!value) return;

    onCopy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!hasCopied || !value) return;

    successToast({ title: `Id: ${value} copié` });
    setValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCopied, value]);

  const getColorBadge = useMemo(
    () =>
      (status: IdentityVerificationStatus): { color: string; label: string } => {
        switch (status) {
          case 'APPROVED':
            return { color: 'green', label: t('Approved') };
          case 'PENDING':
          case 'PROCESSING':
            return { color: 'yellow', label: t('InProgress') };
          case 'REFUSED':
            return { color: 'red', label: t('Rejected') };
          default:
            return { color: 'gray', label: t('None') };
        }
      },
    [t],
  );

  const handleCopy = useCallback(
    (id: string): void => {
      setValue(id);
    },
    [setValue],
  );

  const handleRowEditClick = useCallback(
    (companyId: string): void => {
      navigate(
        resolvePath(
          routes.company.settings.href,
          generatePath(routes.space.company.full, { companyId }),
        ).pathname,
      );
    },
    [navigate],
  );

  const handleRowDocumentsClick = useCallback(
    (companyId: string): void => {
      navigate(
        resolvePath(
          routes.company.documents.href,
          generatePath(routes.space.company.full, { companyId }),
        ).pathname,
      );
    },
    [navigate],
  );

  const handleRowCaptableClick = useCallback(
    (companyId: string): void => {
      navigate(
        resolvePath(
          routes.company.captable.href,
          generatePath(routes.space.company.full, { companyId }),
        ).pathname,
      );
    },
    [navigate],
  );

  const handleRowManagerClick = useCallback(
    (identityId?: Identity['id']): void => {
      if (!identityId) return;
      setIdentityId(identityId);
      spaceManagerModal.onOpen();
    },
    [setIdentityId, spaceManagerModal],
  );

  const handleRowBadgeClick = useCallback(
    (identityId: string): void => {
      if (!identityId) return;
      setIdentityId(identityId);
      identityModal.onOpen();
    },
    [setIdentityId, identityModal],
  );

  const handleRowEditTypeClick = useCallback(
    (id: string, type: CompanyType): void => {
      setCompanyIdAndType({ id, type });
      companyTypeModal.onOpen();
    },
    [setCompanyIdAndType, companyTypeModal],
  );

  const handleCreateOnboarding = async (companyId: string): Promise<void> => {
    await onboardCompany({
      variables: {
        onboardCompanyInput: {
          companyId,
        },
      },
      onCompleted: (data) => {
        setOnboardingLink(data.onboardCompany);
        onboardingModal.onOpen();
      },
    });
  };

  const columnHelper = useMemo(
    () => createColumnHelper<CompanyWithBankAccountListInfosFragment>(),
    [],
  );
  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: t('Identity', { nb: 1 }),
        enableSorting: true,
        cell: (info) => {
          const company = info.row.original;
          return (
            <Td>
              <IdentityCard>
                <IdentityAvatar
                  src={company.identity?.profilePicture}
                  type={IdentityType.COMPANY}
                />
                <Stack alignItems="flex-start" spacing="1">
                  <IdentityCardTitle
                    fontSize="md"
                    fontWeight="500"
                    isChecked={company.identity?.isVerified}
                  >
                    <Text fontWeight="600">{company.name}</Text>
                    <Text fontWeight="500">({company.registrationNumber})</Text>
                  </IdentityCardTitle>
                  <IdentityCardDescription fontSize="md" fontWeight="400">
                    {company.identity?.email}
                  </IdentityCardDescription>
                </Stack>
              </IdentityCard>
            </Td>
          );
        },
      }),
      columnHelper.accessor('id', {
        header: 'id',
        enableSorting: false,
        cell: (info) => (
          <Td>
            <HStack maxWidth="250px" spacing="1">
              <Text isTruncated color="gray.500" maxW="100px">
                {info.getValue()}
              </Text>
              <Icon
                as={DocumentDuplicateIcon}
                boxSize="20px"
                cursor="pointer"
                onClick={(): void => handleCopy(info.getValue())}
              />
            </HStack>
          </Td>
        ),
      }),
      columnHelper.accessor(() => 'actions', {
        header: 'Actions',
        enableSorting: false,
        cell: (info) => {
          const data = info.row.original;
          return (
            <Td>
              <HStack spacing="1">
                <IconButtonWithTooltip
                  aria-label="edit"
                  icon={<Icon as={PencilIcon} boxSize="18px" color="gray.700" />}
                  label={t('EditInformation')}
                  variant="secondary"
                  onClick={(): void => handleRowEditClick(data.id)}
                />
                <IconButtonWithTooltip
                  aria-label="Documents"
                  icon={<Icon as={FolderIcon} boxSize="18px" color="gray.700" />}
                  isDisabled={!data.identity?.id}
                  label={t('AddDocument')}
                  variant="secondary"
                  onClick={(): void => handleRowDocumentsClick(data.id)}
                />
                <IconButtonWithTooltip
                  aria-label="Actionnariat"
                  icon={<Icon as={ChartPieIcon} boxSize="18px" color="gray.700" />}
                  label={t('Shareholding')}
                  variant="secondary"
                  onClick={(): void => handleRowCaptableClick(data.id)}
                />
                <IconButtonWithTooltip
                  aria-label="onboard"
                  icon={<Icon as={CurrencyEuroIcon} boxSize="18px" color="gray.700" />}
                  isDisabled={!!data.identity?.bankAccount?.id}
                  isLoading={onboardingCoLoading && clickedRow === data.id}
                  label={t('GenerateSwanOnboardingLink')}
                  variant="secondary"
                  onClick={(): void => {
                    setClickedRow(data.id);
                    handleCreateOnboarding(data.id);
                  }}
                />
                <IconButtonWithTooltip
                  aria-label="manager"
                  icon={<Icon as={UserGroupIcon} boxSize="18px" color="gray.700" />}
                  isDisabled={!data.id}
                  label={t('ManageCompanyBySpace')}
                  variant="secondary"
                  onClick={(): void => handleRowManagerClick(data?.identity?.id)}
                />
              </HStack>
            </Td>
          );
        },
      }),
      columnHelper.accessor('kybVerificationStatus', {
        header: t('KYB'),
        enableSorting: true,
        cell: (info) => {
          const identity = info.row.original.identity;
          const badge = getColorBadge(info.getValue() as IdentityVerificationStatus);
          return (
            <Td>
              <Button
                variant="unstyled"
                onClick={(): void => handleRowBadgeClick(identity?.id || '')}
              >
                <Badge colorScheme={badge.color}>{badge.label}</Badge>
              </Button>
            </Td>
          );
        },
      }),
      columnHelper.accessor('creationDate', {
        header: t('CreationDate'),
        enableSorting: true,
        cell: (info) => (
          <Td align="right">{info.getValue() ? formatDate(info.getValue(), 'DD/MM/YYYY') : '-'}</Td>
        ),
      }),
      columnHelper.accessor('shareCapital', {
        header: t('ShareCapital'),
        enableSorting: true,
        cell: (info) => {
          const shareCapital = info.getValue() || 0;
          return <Td isNumeric>{shareCapital ? formatNumberCurrency(shareCapital) : '-'}</Td>;
        },
      }),
      columnHelper.accessor('nominalValue', {
        header: t('NominalValue'),
        enableSorting: true,
        cell: (info) => {
          const nominalValue = info.getValue() || 0;
          return <Td isNumeric>{nominalValue ? formatNumberCurrency(nominalValue) : '-'}</Td>;
        },
      }),
      columnHelper.accessor('capitalType', {
        header: t('CapitalType'),
        enableSorting: true,
        cell: (info) => {
          const capitalType = info.getValue();
          return <Td>{capitalType || '-'}</Td>;
        },
      }),
      columnHelper.accessor('type', {
        header: t('CompanyType'),
        enableSorting: true,
        cell: (info) => {
          const companyId = info.row.original.id;
          const type = info.getValue();
          return (
            <Td>
              {type ? (
                <HStack>
                  <Text>{type}</Text>
                  <IconButtonWithTooltip
                    aria-label="edit"
                    icon={<Icon as={PencilIcon} boxSize="18px" color="gray.700" />}
                    label={t('EditCompanyType')}
                    ml="auto"
                    variant="secondary"
                    onClick={(): void => handleRowEditTypeClick(companyId, type)}
                  />
                </HStack>
              ) : (
                '-'
              )}
            </Td>
          );
        },
      }),
    ],
    [
      t,
      columnHelper,
      getColorBadge,
      handleCopy,
      handleRowEditClick,
      handleRowBadgeClick,
      handleRowManagerClick,
      handleRowCaptableClick,
      handleRowEditTypeClick,
      handleRowDocumentsClick,
    ],
  );

  const table = useReactTable({
    data: (results.length ? results : []) as CompanyWithBankAccountListInfosFragment[],
    columns,
    state: { sorting },
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
  });

  if (loading) {
    return (
      <Skeleton>
        <Card h="400px" />
      </Skeleton>
    );
  }

  if (!isLoadingMore && !results.length) {
    return (
      <Stack layerStyle="emptyState">
        <Icon as={TemplateIcon} boxSize="40px" color="gray.500" />
        <Text>{t('NoFilterResult')}</Text>
      </Stack>
    );
  }

  return (
    <Stack spacing="4">
      <TableContainer maxH="none">
        <Table variant="striped">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    <HStack justifyContent="space-between" spacing="2">
                      <HStack
                        cursor={header.column.getCanSort() ? 'pointer' : 'initial'}
                        spacing="0"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Text>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </Text>
                        {header.column.getIsSorted() && (
                          <Icon
                            boxSize="16px"
                            as={
                              header.column.getIsSorted() === 'asc'
                                ? ChevronUpIcon
                                : ChevronDownIcon
                            }
                          />
                        )}
                      </HStack>
                    </HStack>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {isLoadingMore ? (
              <Tr key="0">
                <Td colSpan={5}>
                  <Stack alignItems="center" h="737px" justifyContent="center">
                    <Spinner />
                  </Stack>
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.original.id}>
                  {row.getVisibleCells().map((cell) =>
                    flexRender(cell.column.columnDef.cell, {
                      ...cell.getContext(),
                      key: cell.id,
                    }),
                  )}
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <PaginationButtons
        currentPage={currentPage}
        hasNextPage={pageInfo?.hasNextPage}
        hasPreviousPage={pageInfo?.hasPreviousPage}
        loading={isLoadingMore}
        pageCount={pageCount}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
      />
      {identityId && identityModal.isOpen && (
        <IdentityValidationModal
          identityId={identityId}
          isOpen={identityModal.isOpen}
          isUserAuthorized={isUserAuthorized}
          onClose={identityModal.onClose}
        />
      )}
      {companyIdAndType && (
        <AdminCompaniesTypesModal
          companyId={companyIdAndType.id}
          currentType={companyIdAndType.type}
          isOpen={companyTypeModal.isOpen}
          onClose={(): void => {
            companyTypeModal.onClose();
            setCompanyIdAndType(undefined);
          }}
        />
      )}
      <ConfirmModal
        isOpen={onboardingModal.isOpen}
        labelConfirmButton={t('CopyLink')}
        leastDestructiveRef={confirmRef}
        title={t('SwanOnboardingLink')}
        subtitle={
          <Card m="0" variant="rounded-gray">
            <CardBody>
              <Text fontSize="sm">{onboardingLink}</Text>
            </CardBody>
          </Card>
        }
        onClose={onboardingModal.onClose}
        onConfirm={(): void => {
          if (onboardingLink) handleCopy(onboardingLink);
          onboardingModal.onClose();
        }}
      />
      {identityId && spaceManagerModal.isOpen && (
        <AdminSpaceManagerModal
          identityId={identityId}
          isCompany={true}
          isOpen={spaceManagerModal.isOpen}
          onClose={(): void => spaceManagerModal.onClose()}
        >
          <ModalHeader>{t('ManageCompanyBySpace')}</ModalHeader>
        </AdminSpaceManagerModal>
      )}
    </Stack>
  );
}

export type AdminCompaniesTableProps = Props;
