import {
  Button,
  Link as ChakraLink,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { routes } from '@blockpulse3/data/shared';
import { BrandIcon } from '@blockpulse3/ui/brand-theme';
import { ErrorMessage } from '@blockpulse3/ui/commons';

import { schema, signUpFormDefaults } from './schema';
import { ISignUpForm } from './types';

type Props = {
  /* ** SignUp form default values, can hold email from redirect ** */
  defaultValues?: ISignUpForm;
  /* ** Disabled email field, from redirection ** */
  isDisabled?: boolean;
  /* ** Callback loading state ** */
  loading: boolean;
  /* ** Callback submit ** */
  onSubmit: (data: ISignUpForm) => void;
};

/**
 * SignUpForm.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SignUpForm({
  isDisabled = false,
  defaultValues = signUpFormDefaults,
  loading,
  onSubmit,
}: Props): JSX.Element {
  const t = useTranslations();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPassword2, setShowPassword2] = useState<boolean>(false);

  const { register, formState, handleSubmit } = useForm<ISignUpForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleSignUpSubmit: SubmitHandler<ISignUpForm> = (data) => {
    onSubmit(data);
  };

  return (
    <Container bg={{ base: 'transparent', md: 'bg-surface' }} maxW="lg">
      <Stack spacing="8">
        <Stack align="start" spacing="6">
          <BrandIcon />
          <Heading alignSelf="start" data-cy="signup-title" size="lg">
            {t('CreateAccount')}
          </Heading>
        </Stack>
        <form onSubmit={handleSubmit(handleSignUpSubmit)}>
          <Stack spacing="6">
            <Stack spacing="4">
              <fieldset disabled={isDisabled}>
                <FormControl isInvalid={!!formState.errors?.email}>
                  <FormLabel htmlFor="email">{t('Email', { nb: 1 })}</FormLabel>
                  <Input data-cy="email-input" id="email" type="text" {...register('email')} />
                  <ErrorMessage error={formState.errors?.email} />
                </FormControl>
              </fieldset>
              <FormControl isInvalid={!!formState.errors?.password}>
                <FormLabel htmlFor="password">{t('Password')}</FormLabel>
                <InputGroup>
                  <Input
                    data-cy="password-input"
                    id="password"
                    type={showPassword ? 'text' : 'password'}
                    {...register('password')}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label="preview"
                      display="flex"
                      icon={<Icon as={showPassword ? EyeIcon : EyeOffIcon} boxSize={4} />}
                      variant="unstyled"
                      onClick={(): void => setShowPassword((crr) => !crr)}
                    />
                  </InputRightElement>
                </InputGroup>
                <ErrorMessage error={formState.errors?.password} />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="confirmPassword">{t('PasswordConfirmation')}</FormLabel>
                <InputGroup>
                  <Input
                    data-cy="confirm-input"
                    id="confirmPassword"
                    isInvalid={!!formState.errors?.confirmPassword}
                    type={showPassword2 ? 'text' : 'password'}
                    {...register('confirmPassword')}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label="preview"
                      display="flex"
                      icon={<Icon as={showPassword2 ? EyeIcon : EyeOffIcon} boxSize={4} />}
                      variant="unstyled"
                      onClick={(): void => setShowPassword2((crr) => !crr)}
                    />
                  </InputRightElement>
                </InputGroup>
                <ErrorMessage error={formState.errors?.confirmPassword} />
              </FormControl>
              <FormControl alignItems="center" display="flex">
                <Stack spacing="0">
                  <Checkbox
                    data-cy="terms-checkbox"
                    isInvalid={!!formState.errors?.conditions}
                    {...register('conditions')}
                  >
                    <ChakraLink
                      isExternal
                      color="secondary"
                      data-cy="terms-link"
                      href="assets/Blockpulse_CGU.pdf"
                      textDecoration="underline"
                    >
                      {t('AcceptTerms')}
                    </ChakraLink>
                  </Checkbox>
                  <ErrorMessage error={formState.errors?.conditions} />
                </Stack>
              </FormControl>
            </Stack>
            <Stack spacing="2">
              <Button isLoading={loading} type="submit">
                {t('Validate')}
              </Button>
              <Link to={routes.login.href}>
                <Text color="secondary" textAlign="center" textDecoration="underline">
                  {t('AlreadyHaveAccount')}
                </Text>
              </Link>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}

export type SignUpFormProps = Props;
