import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  SearchIcon,
  UploadIcon,
  UserAddIcon,
  UserGroupIcon,
  UserRemoveIcon,
} from '@heroicons/react/outline';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  SubscriptionSide,
  useCreateDefaultAuthorizedIdentitiesMutation,
  useDeleteAuthorizedIdentitiesMutation,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import { DeleteConfirmModal, useErrorToast } from '@blockpulse3/ui/commons';
import {
  OperationCSVType,
  OperationSubscribersCSVModal,
} from '@blockpulse3/web-client/operation/commons';

import { NewSecondaryOperationSellerModal } from './NewSecondaryOperationSellerModal';

type Props = {
  isAddDisabled?: boolean;
  canAddAllShareholders?: boolean;
  onSearchInputChange: (input: string) => void;
};

/**
 * NewSecondaryOperationSellerTableControls.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function NewSecondaryOperationSellerTableControls({
  isAddDisabled = false,
  canAddAllShareholders = false,
  onSearchInputChange,
}: Props): JSX.Element {
  const t = useTranslations();

  const confirmRef = useRef(null);
  const errorToast = useErrorToast();

  const sellerCreateModal = useDisclosure();
  const sellersImportModal = useDisclosure();
  const sellersDeleteModal = useDisclosure();

  const { operationId = '' } = useParams();

  const {
    data,
    loading: operationLoading,
    refetch,
  } = useGetOperationQuery({
    variables: { operationId },
    notifyOnNetworkStatusChange: true,
  });
  const operation = data?.operation;

  const [createAuthorizedSellers, { loading: createAuhtorizedSellersLoading }] =
    useCreateDefaultAuthorizedIdentitiesMutation();
  const [deleteAuthorizedSellers, { loading: deleteAuthorizedSellersLoading }] =
    useDeleteAuthorizedIdentitiesMutation();

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onSearchInputChange(event.target.value);
  };

  const handleCreateSeller = (): void => {
    sellerCreateModal.onOpen();
  };

  const handleImportSellers = (): void => {
    sellersImportModal.onOpen();
  };

  const handleCreateAllSellers = (): void => {
    createAuthorizedSellers({
      variables: {
        createDefaultAuthorizedIdentities: {
          operationId,
          identitySide: SubscriptionSide.SELLER,
        },
      },
      onCompleted: () => {
        refetch();
      },
      onError: () => {
        errorToast({ title: t('ErrorAddingSellers') });
      },
    });
  };

  const handleDeleteAllSellers = (): void => {
    deleteAuthorizedSellers({
      variables: {
        deleteAuthorizedIdentities: {
          operationId,
          identitySide: SubscriptionSide.SELLER,
        },
      },
      onCompleted: () => {
        refetch();
        sellersDeleteModal.onClose();
      },
    });
  };

  const isLoading =
    operationLoading || createAuhtorizedSellersLoading || deleteAuthorizedSellersLoading;
  const deleteSellersDisabled = !operation?.authorizedSellers?.length;

  return (
    <Stack>
      <Heading size="md">{t('SellersList')}</Heading>
      <Stack direction={{ base: 'column', md: 'row' }} justifyContent="space-between" spacing="4">
        <InputGroup w={{ base: 'full', md: '300px' }}>
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} boxSize="18px" />
          </InputLeftElement>
          <Input placeholder={t('SearchSeller')} onChange={handleSearchInputChange} />
        </InputGroup>
        <Stack direction={{ base: 'column', md: 'row' }} spacing="2">
          <Button
            isDisabled={isAddDisabled || isLoading}
            leftIcon={<Icon as={UserAddIcon} boxSize="24px" />}
            onClick={handleCreateSeller}
          >
            {t('AddSeller')}
          </Button>
          <Menu>
            <MenuButton
              as={Button}
              isDisabled={isLoading}
              rightIcon={isLoading ? <Spinner size="xs" /> : <ChevronDownIcon />}
              variant="secondary"
            >
              {t('Action', { nb: 2 })}
            </MenuButton>
            <MenuList>
              {canAddAllShareholders && (
                <>
                  <MenuItem>
                    <Button
                      isLoading={isLoading}
                      variant="unstyled"
                      width="full"
                      onClick={handleCreateAllSellers}
                    >
                      <HStack>
                        <Icon as={UserGroupIcon} boxSize="5" />
                        <Text>{t('AddAllShareholders')}</Text>
                      </HStack>
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button variant="unstyled" width="full" onClick={handleImportSellers}>
                      <HStack>
                        <Icon as={UploadIcon} boxSize="5" color="secondary" />
                        <Text>{t('CSVImport')}</Text>
                      </HStack>
                    </Button>
                  </MenuItem>
                </>
              )}
              <MenuItem>
                <Button
                  isDisabled={deleteSellersDisabled}
                  isLoading={isLoading}
                  variant="unstyled"
                  width="full"
                  onClick={sellersDeleteModal.onOpen}
                >
                  <HStack>
                    <Icon as={UserRemoveIcon} boxSize="5" />
                    <Text>{t('DeleteAllSellers')}</Text>
                  </HStack>
                </Button>
              </MenuItem>
            </MenuList>
          </Menu>
        </Stack>
      </Stack>
      <NewSecondaryOperationSellerModal
        isOpen={sellerCreateModal.isOpen}
        onClose={sellerCreateModal.onClose}
      />
      <OperationSubscribersCSVModal
        isOpen={sellersImportModal.isOpen}
        operationCsvType={OperationCSVType.SECONDARY_SELLER}
        operationId={operationId}
        onClose={sellersImportModal.onClose}
        onComplete={refetch}
      />
      <DeleteConfirmModal
        isOpen={sellersDeleteModal.isOpen}
        leastDestructiveRef={confirmRef}
        subtitle={t('DefinitiveAction')}
        title={t('DeleteSellerQuestion', { nb: 2 })}
        onClose={sellersDeleteModal.onClose}
        onDelete={handleDeleteAllSellers}
      />
    </Stack>
  );
}

export type NewSecondaryOperationSellerTableControlsProps = Props;
