import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  HStack,
  Skeleton,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';
import MessageKeys from 'use-intl/dist/core/utils/MessageKeys';

import { RepresentativeRole } from '@blockpulse3/data/shared';
import {
  CompanyDocumentType,
  IdentityType,
  SpvStatus,
  useGetBankIdentitiesByCompanyQuery,
  useGetCompanyDocumentsQuery,
  useGetSpvQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard } from '@blockpulse3/ui/commons';

import { SPV_DOCUMENT_TYPE_TO_SIGN, getIdentityBadge } from '../../../utils';
import { SPVIdentitiesList } from './SPVIdentitiesList';

/**
 * SPVMatriculationIdentity.
 * Identity verification accordion.
 *
 * @returns {JSX.Element}
 */
export function SPVMatriculationIdentity(): JSX.Element {
  const t = useTranslations();

  const [isPollingStarted, setIsPollingStarted] = useState<boolean>(false);
  const [isAccordionLoading, setIsAccordionLoading] = useState<boolean>(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);

  const { companyId = '' } = useParams();

  const companyReq = useGetSpvQuery({ variables: { companyId } });
  const { data: companyData, loading, error } = companyReq;

  const companyDocumentsReq = useGetCompanyDocumentsQuery({
    variables: { companyId },
  });
  const companyDocuments = companyDocumentsReq?.data?.getCompanyDocuments;

  const company = companyData?.company;
  const president = company?.companyRepresentative?.find(
    (rep) => rep.role === RepresentativeRole.PRESIDENT,
  );
  const isIndividual = president?.representativeIdentity.type === IdentityType.INDIVIDUAL;

  const areAllDocumentsSigned = company?.spvStatus === SpvStatus.SIGNED;

  const bankIdentitiesReq = useGetBankIdentitiesByCompanyQuery({
    variables: {
      companyId,
    },
  });
  const bankIdentities = bankIdentitiesReq.data?.getBankIdentitiesByCompany || [];
  const hasBankIdentities = !!bankIdentities.length;

  const areAllDocumentsCreated =
    companyDocuments &&
    SPV_DOCUMENT_TYPE_TO_SIGN.every(
      (docType) =>
        companyDocuments?.some((doc) => {
          if (!isIndividual && docType === CompanyDocumentType.DNC) return true;
          return doc.type === docType;
        }),
    );

  useEffect(() => {
    /* ** Get real time update on accordion open/close status ** */
    const activeStep = company?.spvStatus === SpvStatus.SIGNED && hasBankIdentities ? true : false;

    setIsAccordionOpen(activeStep);
  }, [company, hasBankIdentities]);

  useEffect(() => {
    if (!bankIdentitiesReq) return;

    if (!isPollingStarted && areAllDocumentsCreated && !hasBankIdentities) {
      /* ** Start polling on spv when last document has pending signature ** */
      setIsPollingStarted(true);
      companyReq.startPolling(1000);

      setTimeout(() => {
        bankIdentitiesReq.stopPolling();
        companyReq.stopPolling();
        setIsAccordionLoading(false);
      }, 20_000);
    }

    if (!isAccordionLoading && areAllDocumentsSigned) {
      /* ** Start accordion spinner once documents are all signed ** */
      bankIdentitiesReq.startPolling(2000);
      companyReq.stopPolling();
      setIsAccordionLoading(true);
    }

    if (hasBankIdentities) {
      // Stop polling after 5 seconds in order to get updated status
      setTimeout(() => {
        bankIdentitiesReq.stopPolling();
      }, 5000);
      companyReq.stopPolling();
      setIsAccordionLoading(false);
    }
  }, [
    areAllDocumentsCreated,
    areAllDocumentsSigned,
    bankIdentitiesReq,
    hasBankIdentities,
    isAccordionLoading,
    isPollingStarted,
    companyReq,
  ]);

  if (loading) {
    return <Skeleton h="65px" />;
  }

  if (error) {
    return <ErrorQueryCard h="65px" />;
  }

  if (!companyData) {
    return <ErrorQueryCard h="65px" />;
  }

  const badgeValue = getIdentityBadge(company?.spvStatus, bankIdentities);

  const handleAccordionOpen = (): void => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <Accordion allowMultiple index={isAccordionOpen ? [0] : [-1]} onChange={handleAccordionOpen}>
      <AccordionItem isDisabled={!hasBankIdentities}>
        <AccordionButton>
          <AccordionIcon boxSize="8" />
          <Box flex="1" p="2" textAlign="left">
            <Text fontSize="xl" fontWeight="semibold">
              {t('IdentitiesVerification')}
            </Text>
          </Box>
          <Badge colorScheme={badgeValue.color}>
            <HStack alignItems="center" spacing="1">
              {isAccordionLoading && <Spinner size="xs" speed="0.7s" />}
              <Text>{t(badgeValue.label as MessageKeys<IntlMessages, keyof Messages>)}</Text>
            </HStack>
          </Badge>
        </AccordionButton>
        <AccordionPanel>
          <Stack spacing="4">
            <SPVIdentitiesList bankIdentities={bankIdentities} />
          </Stack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
