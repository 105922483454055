import { SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
} from '@chakra-ui/react';
import { PlusIcon, XCircleIcon } from '@heroicons/react/outline';
import { ChangeEventHandler, useState } from 'react';
import { useTranslations } from 'use-intl';

import { noop } from '@blockpulse3/data/shared';

type Props = {
  /* ** Callback on new space button click ** */
  onSpaceAdd?: () => void;
  /* ** Callback on search input submit ** */
  onSearchSubmit?: (input: string) => void;
};

/**
 * SpaceListControls.
 *
 * @param {Props}
 * @returns {JSX.Element}
 */
export function SpaceListControls({
  onSpaceAdd = noop,
  onSearchSubmit = noop,
}: Props): JSX.Element {
  const t = useTranslations();

  const [searchInput, setSearchInput] = useState<string>('');

  const handleSpaceAdd = (): void => {
    onSpaceAdd();
  };

  const handleSearchInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchInputReset = (): void => {
    setSearchInput('');
  };

  const handleSearchInputSubmit = (): void => {
    onSearchSubmit(searchInput);
  };

  return (
    <Stack
      alignItems={{ base: 'flex-start', md: 'flex-end' }}
      direction={{ base: 'column', md: 'row' }}
      justifyContent="space-between"
    >
      <HStack direction={{ base: 'column', md: 'row' }}>
        <InputGroup width={{ base: 'full', md: '300px' }}>
          <Input
            isDisabled
            paddingRight="80px"
            placeholder={t('Search')}
            value={searchInput}
            onChange={handleSearchInputChange}
            onKeyDown={(e): void => {
              if (e.key === 'Enter') {
                handleSearchInputSubmit();
              }
            }}
          />
          <InputRightElement
            flexDirection="row-reverse"
            justifyContent="space-between"
            paddingX="1"
            width="80px"
          >
            <IconButton
              isDisabled
              aria-label="search-spaces"
              icon={<Icon as={SearchIcon} />}
              size="sm"
              variant="secondary"
              onClick={handleSearchInputSubmit}
            />
            {!!searchInput && (
              <IconButton
                aria-label="reset"
                icon={<Icon as={XCircleIcon} />}
                size="sm"
                variant="ghost"
                onClick={handleSearchInputReset}
              />
            )}
          </InputRightElement>
        </InputGroup>
      </HStack>
      <Button
        flexShrink="0"
        leftIcon={<Icon as={PlusIcon} />}
        variant="secondary"
        onClick={handleSpaceAdd}
      >
        {t('AddSpace')}
      </Button>
    </Stack>
  );
}

export type SpaceListControlsProps = Props;
