import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  radioAnatomy.keys,
);

const baseStyle = definePartsStyle({
  control: {
    '&[data-checked]': {
      'bg': 'secondary',
      'borderColor': 'secondary',
      '&:hover': {
        bg: 'secondary',
        borderColor: 'secondary',
      },
    },
  },
  label: {
    width: 'full',
  },
});

const solid = definePartsStyle({
  label: {
    lineHeight: 1,
    width: 'full',
  },
  control: {
    marginBottom: '1',
  },
  container: {
    background: 'gray.50',
    padding: '4',
    alignItems: 'flex-start',
  },
});

const answer = definePartsStyle({
  label: {
    lineHeight: 1,
    width: 'full',
  },
  container: {
    'borderColor': 'gray.200',
    'borderWidth': '1px',
    'borderStyle': 'solid',
    'borderRadius': '5px',
    'padding': '3',
    'alignItems': 'flex-start',
    '&[data-checked]': {
      bg: 'gray.50',
      borderColor: 'gray.400',
    },
  },
});

const choice = definePartsStyle({
  label: {
    lineHeight: 1,
    width: 'full',
  },
  container: {
    'borderColor': 'gray.300',
    'borderWidth': '1px',
    'borderStyle': 'solid',
    'borderRadius': '5px',
    'padding': '3',
    'width': 'full',
    '&[data-checked]': {
      borderColor: 'primary',
      borderWidth: '2px',
    },
  },
});

const identity = definePartsStyle({
  label: {
    'lineHeight': 1,
    'width': 'full',
    '&[data-disabled]': {
      opacity: 1,
    },
  },
  control: {
    display: 'none',
  },
  container: {
    'borderColor': 'gray.300',
    'borderWidth': '3px',
    'borderStyle': 'solid',
    'borderRadius': '5px',
    'fontWeight': '500',
    'height': 'full',
    'lineHeight': '1.4',
    'padding': '3',
    'color': 'gray.700',
    'width': 'full',
    '&[data-disabled]': {
      opacity: 0.5,
    },
    '&[data-checked]': {
      borderColor: 'secondary',
      color: 'gray.900',
    },
  },
});

export const radioTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    solid,
    answer,
    choice,
    identity,
  },
});
