import { Card, Skeleton } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  FundraisingWorkflowType,
  OperationType,
  useGetSubscriptionQuery,
} from '@blockpulse3/graphql/hooks';
import { ErrorQueryCard, Progression, ProgressionStep } from '@blockpulse3/ui/commons';
import { useIdentity } from '@blockpulse3/web-client/auth';

import { ISubscriptionProgressionStep } from './types';
import { getOperationStep } from './utils';

type Props = unknown;

export function SubscriptionProgression(): JSX.Element {
  const t = useTranslations();

  const { subscriptionId = '' } = useParams();
  const { identityId } = useIdentity();

  const { data, loading, error } = useGetSubscriptionQuery({
    variables: { subscriptionId, identityId },
    skip: !subscriptionId || !identityId,
  });

  if (loading || !data) {
    return (
      <Skeleton>
        <Card h="80px" />
      </Skeleton>
    );
  }

  if (error) {
    return <ErrorQueryCard h="80px" />;
  }

  const { operation } = data.subscription;

  const isAllInFundraising = operation.fundraising?.workflowType === FundraisingWorkflowType.ALL_IN;

  const totalSteps = isAllInFundraising ? 4 : 3;

  const endSubscriptionDatePassed = dayjs(operation?.endSubscriptionDate).isBefore(dayjs());
  const addStep = isAllInFundraising && endSubscriptionDatePassed ? 2 : 0;

  // ++currentStep only if we are in fundraising ALL_IN and if the endSubscriptionDate is passed
  const currentStep = getOperationStep(operation.status) + addStep;

  const fundraisingProgressionStep = [
    {
      isCompleted: currentStep >= 1,
      subTitle: dayjs(operation?.startSubscriptionDate).format('D MMMM YYYY'),
      title: t('LaunchFundraising'),
    },
    {
      isCompleted: currentStep >= 2,
      subTitle: currentStep >= 3 ? t('Done') : t('InProgress'),
      title: t('InvestorsSubscription'),
    },
    {
      isCompleted: currentStep >= 3,
      subTitle: dayjs(operation?.endSubscriptionDate).format('D MMMM YYYY'),
      title: isAllInFundraising ? t('LimitDateCaseCompletion') : t('FundraisingClosed'),
    },
    {
      isCompleted: currentStep >= 4,
      // for fundraising without closingDate use endSubscriptionDate to not diplay "Invalid Date"
      subTitle: dayjs(operation?.closingDate ?? operation?.endSubscriptionDate).format(
        'D MMMM YYYY',
      ),
      title: t('FundraisingClosed'),
    },
  ];

  const secondaryProgressionStep = [
    {
      isCompleted: currentStep >= 1,
      subTitle: operation?.startSubscriptionDate
        ? dayjs(operation?.startSubscriptionDate).format('D MMMM YYYY')
        : '',
      title: t('LaunchOperation'),
    },
    {
      isCompleted: currentStep >= 2,
      subTitle: currentStep >= 3 ? t('Done') : t('InProgress'),
      title: t('TransferOfShares'),
    },
    {
      isCompleted: currentStep >= 3,
      subTitle: operation?.endSubscriptionDate
        ? dayjs(operation?.endSubscriptionDate).format('D MMMM YYYY')
        : '',
      title: t('OperationClosed'),
    },
  ];

  const opportunityProgressionStep = [
    {
      isCompleted: currentStep >= 1,
      subTitle: operation?.startSubscriptionDate
        ? dayjs(operation?.startSubscriptionDate).format('D MMMM YYYY')
        : '',
      title: t('LaunchOperation'),
    },
    {
      isCompleted: currentStep >= 2,
      subTitle: currentStep >= 3 ? t('Done') : t('InProgress'),
      title: t('IntentCollection'),
    },
    {
      isCompleted: currentStep >= 3,
      subTitle: operation?.endSubscriptionDate
        ? dayjs(operation?.endSubscriptionDate).format('D MMMM YYYY')
        : '',
      title: t('OperationClosed'),
    },
  ];

  const progressionStep = ((): ISubscriptionProgressionStep[] => {
    switch (operation.type) {
      case OperationType.FUNDRAISING:
        return fundraisingProgressionStep;
      case OperationType.SECONDARY:
        return secondaryProgressionStep;
      case OperationType.OPPORTUNITY:
        return opportunityProgressionStep;
      default:
        return [];
    }
  })();

  return (
    <Progression boxShadow="none" currentStep={currentStep} totalSteps={totalSteps}>
      {[...Array(totalSteps)].map((_, index) => (
        <ProgressionStep
          key={index}
          isCompleted={progressionStep[index].isCompleted}
          subTitle={progressionStep[index].subTitle}
          title={progressionStep[index].title}
        />
      ))}
    </Progression>
  );
}

export type SubscriptionProgressionProps = Props;
