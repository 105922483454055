import { Card, CardBody, Stack, useDisclosure } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import {
  Member,
  MemberStatus,
  useDeleteMemberMutation,
  useGetMembersQuery,
} from '@blockpulse3/graphql/hooks';
import { ConfirmModal, useErrorToast, useSuccessToast } from '@blockpulse3/ui/commons';
import { useAuthUser } from '@blockpulse3/web-client/auth';

import { PAGE_SIZE } from '../utils';
import { NewSpaceMemberForm, UpdateSpaceMemberForm } from './SpaceMemberForm';
import { SpaceMembersControls } from './SpaceMembersControls';
import { SpaceMembersTable } from './SpaceMembersTable';

type Props = unknown;

export function SpaceMembers(): JSX.Element {
  const t = useTranslations();

  const confirmRef = useRef(null);

  const { spaceId: authSpaceId = '' } = useAuthUser();
  const { spaceId = authSpaceId } = useParams();

  const errorToast = useErrorToast();
  const successToast = useSuccessToast();

  const createMemberModal = useDisclosure();
  const updateMemberModal = useDisclosure();
  const deleteMemberModal = useDisclosure();

  const [updateMemberId, setUpdateMemberId] = useState<Member['id'] | null>(null);
  const [deleteMemberId, setDeleteMemberId] = useState<Member['id'] | null>(null);

  const [deleteMember] = useDeleteMemberMutation();

  const { refetch } = useGetMembersQuery({
    variables: { first: PAGE_SIZE, spaceId },
    skip: true,
  });

  const handleMemberAddModal = (): void => {
    createMemberModal.onOpen();
  };

  /*
  const handleMemberAddError = (): void => {
    createMemberModal.onClose();
    errorToast({ title: t('CreateMemberError') });
  };
  */

  const handleMemberUpdateModal = (memberId: Member['id']): void => {
    setUpdateMemberId(memberId);
    updateMemberModal.onOpen();
  };

  const handleMemberUpdateSuccess = (): void => {
    setUpdateMemberId(null);
    updateMemberModal.onClose();
    refetch();
  };

  const handleMemberUpdateError = (): void => {
    setUpdateMemberId(null);
    updateMemberModal.onClose();
  };

  const handleMemberDeleteModal = (memberId: Member['id']): void => {
    setDeleteMemberId(memberId);
    deleteMemberModal.onOpen();
  };

  const handleMemberDelete = (): void => {
    if (!deleteMemberId) return;

    deleteMember({
      variables: {
        memberId: deleteMemberId,
      },
      onCompleted: () => {
        successToast({ title: t('DeleteMemberSuccess') });
        setDeleteMemberId(null);
        deleteMemberModal.onClose();
        refetch();
      },
      onError: () => {
        errorToast({ title: t('DeleteMemberError') });
        setDeleteMemberId(null);
        deleteMemberModal.onClose();
      },
    });
  };

  const handleSpaceMemberAddSuccess = (memberStatus: MemberStatus): void => {
    switch (memberStatus) {
      case MemberStatus.INVITATION_SENT: {
        successToast({ title: t('MemberInvited') });
        break;
      }
      case MemberStatus.VALIDATED: {
        successToast({ title: t('MemberAdded') });
        break;
      }
      default: {
        errorToast({ title: t('MemberInvitedError') });
        break;
      }
    }
    createMemberModal.onClose();
    refetch();
  };

  return (
    <Card>
      <CardBody as={Stack} spacing="4">
        <SpaceMembersControls onMemberAdd={handleMemberAddModal} />
        <SpaceMembersTable
          onRowDeleteClick={handleMemberDeleteModal}
          onRowUpdateClick={handleMemberUpdateModal}
        />
      </CardBody>
      <NewSpaceMemberForm
        isOpen={createMemberModal.isOpen}
        onClose={createMemberModal.onClose}
        onSubmitSuccess={handleSpaceMemberAddSuccess}
      />
      {updateMemberId && (
        <UpdateSpaceMemberForm
          isOpen={updateMemberModal.isOpen}
          memberId={updateMemberId}
          onClose={updateMemberModal.onClose}
          onSubmitError={handleMemberUpdateError}
          onSubmitSuccess={handleMemberUpdateSuccess}
        />
      )}
      <ConfirmModal
        isOpen={deleteMemberModal.isOpen}
        leastDestructiveRef={confirmRef}
        subtitle={t('DefinitiveAction')}
        title={t('ConfirmMemberDeleteTitle')}
        onClose={deleteMemberModal.onClose}
        onConfirm={handleMemberDelete}
      />
    </Card>
  );
}

export type SpaceMembersProps = Props;
