import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  HStack,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { FlagIcon } from '@heroicons/react/outline';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslations } from 'use-intl';

import { FundraisingType, routes } from '@blockpulse3/data/shared';
import {
  AssetType,
  OperationStatus,
  OperationType,
  useGetOperationByOpportunityIdQuery,
  useGetOperationQuery,
} from '@blockpulse3/graphql/hooks';
import { OperationTypeModal } from '@blockpulse3/web-client/operation/commons';

export function OpportunityOperationFinalizeAlert(): JSX.Element {
  const t = useTranslations();

  const { operationId = '' } = useParams();

  const navigate = useNavigate();

  const operationReq = useGetOperationQuery({
    variables: { operationId },
    skip: !operationId,
  });
  const operation = operationReq.data?.operation;

  const { data } = useGetOperationByOpportunityIdQuery({
    variables: { opportunityId: operationId },
    skip: !operationId,
    fetchPolicy: 'cache-and-network',
  });
  const relatedOperation = data?.getOperationByOpportunityId;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleFinalizeOperation = (): void => {
    // Redirect to related operation if any
    if (relatedOperation) {
      if (
        relatedOperation.type === OperationType.FUNDRAISING &&
        relatedOperation?.fundraising?.type === FundraisingType.PRIVATE
      ) {
        navigate(
          generatePath(
            relatedOperation.status === OperationStatus.DRAFT
              ? routes.company.newFundraising.private.edit.full
              : routes.company.fundraising.full,
            {
              companyId: relatedOperation.company.id,
              operationId: relatedOperation.id,
            },
          ),
        );
      }

      if (relatedOperation.type === OperationType.SECONDARY) {
        navigate(
          generatePath(
            relatedOperation.status === OperationStatus.DRAFT
              ? routes.company.newSecondary.edit.full
              : routes.company.secondary.full,
            {
              companyId: relatedOperation.company.id,
              operationId: relatedOperation.id,
            },
          ),
        );
      }

      return;
    }

    // Open operation type selection modal otherwise
    onOpen();
  };

  const hasSecondary =
    operation?.assetType &&
    [AssetType.ORDINARY_SHARE, AssetType.PREFERRED_SHARE].includes(operation.assetType);

  return (
    <>
      <Alert boxShadow="md" status="info">
        <Stack
          alignItems="stretch"
          direction={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          spacing="4"
          width="full"
        >
          <HStack alignItems="flex-start" spacing="0">
            <AlertIcon as={FlagIcon} />
            <Stack spacing="0">
              <AlertTitle fontWeight="bold">{t('OpportunityReadyToBeFinalized')}</AlertTitle>
              <AlertDescription>
                {relatedOperation
                  ? t('ResumeRelatedOperationDescription')
                  : t('OpportunityReadyToBeFinalizedDescription')}
              </AlertDescription>
            </Stack>
          </HStack>
          <HStack alignItems="center" ml={{ base: '0', md: '4' }}>
            <Button className="confirm" fontSize="sm" onClick={handleFinalizeOperation}>
              {relatedOperation ? t('ResumeRelatedOperation') : t('FinalizeOperation')}
            </Button>
          </HStack>
        </Stack>
      </Alert>
      {isOpen && (
        <OperationTypeModal
          opportunityId={operationId}
          hasSecondary={hasSecondary}
          hasCrowdfunding={false}
          hasOpportunity={false}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </>
  );
}
